import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../../../../shared/utility"
import { generic_errors } from "../../../../../../../texts/esp/genericErrors"


const useAddPurchaseGroupModal = ({open, content, onClose, language, onCompleted, id_product}) => {

    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))


    useEffect(() => {
        if(!open){
            actions.onInitModule()
        }
    }, [open])

    
    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form_data, null, content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                const new_group = await private_server.post(`/purchasegroup`, data2send)
                console.log(new_group)
                await private_server.patch(`/product/${id_product}`, {purchase_group_id: new_group.data.data.purchase_group.id_purchase_group})
                onCompleted()
                setError(null)
                setSending(false)
                onClose()
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions} 
}


export default useAddPurchaseGroupModal

const form_data = {
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type:'distance', min:0, max:255}
    },
    description: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {type:'distance', min:0, max:255}
    },

}
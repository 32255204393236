import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const tableStyles = makeStyles(theme => ({
    
    cell:{
        border:'none',
    },
    cell_center:{
        //background:'red',
        textAlign:'center',
        border:'none'
    },
    row:{
        background:'white',
        borderTop:'10px solid #fbfbfb',
        borderBottom:'10px solid #fbfbfb',
        //boxSizing:'border-box',
        //marginTop:10,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        border:'none',
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        padding:2,
        width:40,
        height:40,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
        background:'#fbfbfb'
    },
    nodata:{
        marginTop:'12%',
        //background:'green',   
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        marginRight:16
    },
    receipt:{
        background:'rgba(54, 54, 54, 0.08)',
        borderRadius:3,
        padding:'8px 12px',
        fontWeight:500
    },
    payment_type:{
        background:'rgba(179, 10, 16, 0.05)',
        borderRadius:3,
        padding:'8px 12px',
        fontWeight:500,
        textAlign:'center'
    },
    empty:{
        //width:80,
        //marginBottom:40
        width:'100%',
        height:'100%',
        //objectFit:'contain'
    },
    img_container:{
        marginBottom:40,
        borderRadius:'50%',
        background:'white',
        boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        width:100,
        height:100,
        padding:25,
        boxSizing:'border-box'
        
    }
}))

export default tableStyles
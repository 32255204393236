

export const translations = {
    title:'Detalle de compra',
    general:{
        back_button:['Cancelar', 'Cancelar pedido', 'Regresar pedido', 'Regresar pedido'],
        start_date:'Fecha inicio',
        order_date:'Fecha pedido',
        reception_date:'Fecha recepción',
        cancel_date:'Fecha cancelación',
        provider:'Provedor',
        status:'Status',
        file:'Archivo',
        subtotal:'Subtotal',
        taxes:'Impuestos',
        total:'Total',
        next_button:['Ordenar', 'Recibir', 'Cancelar', 'Volver a crear']
    },
    products:{
        title:'Productos',
        button:'Agregar',
        code:'Código',
        description:'Producto',
        quantity:'Cantidad',
        price:'Precio',
        total:'Total',
        header:[
            'Código',
            'Marca',
            'Producto',
            'Cantidad',
            'Precio',
            'Total',
            ''
        ]
    }
    
}

export default translations
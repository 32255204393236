import { Grid, makeStyles, Typography } from "@material-ui/core"
import Header from "../../../../../components/Structure/Texts/Header"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import InputForm from "../../../../../components/Forms/InputForm"
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import system_translations from "../../../../../texts/system_translations"
import useAddPurchaseGroupModal from "./useAddPurchaseGroupModal"
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"



const AddPurchaseGroupModal = ({open, onClose, language, onCompleted, id_product}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.purchase_group_modal
    const {loading, sending, error, form, actions} = useAddPurchaseGroupModal({open, language, content, onCompleted, onClose, id_product})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.add.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.form.name.tag}</Header>
                    <InputForm data={form.name} onChange={actions.onChangeForm} />     
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.form.description.tag}</Header>
                    <InputForm data={form.description} onChange={actions.onChangeForm} />     
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton onClick={actions.onSubmit} loading={sending}>{content.add.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddPurchaseGroupModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
import { amber, blue, cyan, green, indigo, orange, purple, red, teal, pink, lime } from "@material-ui/core/colors";


export const catalogs = {
    user_statuses:[
        {value:1, label:'Activo', color:green[500], back:'rgba(52, 158, 35, 0.07)'},
        {value:2, label:'Suspendido', color:red[500], back:'rgba(211, 47, 47, 0.07)'},
    ],
    user_types:[
        {value:1, label:'Admin'},
        {value:2, label:'Gerente'},
        {value:3, label:'Vendedor'},
        {value:4, label:'Visor'},
    ],
    genders:[
        {value:1, label:'Masculino'},
        {value:2, label:'Femenino'},
        {value:3, label:'Otro'}, 
    ],
    purchases_orders_statuses:[
        {value:-1, label:'Todas', color:'black', back:'white'},
        {value:1, label:'Creada', color:blue[500], back:'rgba(33, 150, 243, 0.07)'},
        {value:2, label:'Ordenada', color:orange[500], back:'rgba(255, 152, 0, 0.07)'},
        {value:3, label:'Recibida', color:green[500], back:'rgba(52, 158, 35, 0.07)'},
        {value:4, label:'Cancelada', color:red[700], back:'rgba(211, 47, 47, 0.07)'},
    ],
    orders_statuses:[
        {value:-1, label:'Todas', color:'black', back:'white'},
        {value:1, label:'Creada', color:blue[500], back:'rgba(33, 150, 243, 0.07)'},
        {value:2, label:'Pagada', color:orange[500], back:'rgba(255, 152, 0, 0.07)'},
        {value:3, label:'Entregada', color:green[500], back:'rgba(52, 158, 35, 0.07)'},
        {value:4, label:'Cancelada', color:red[700], back:'rgba(211, 47, 47, 0.07)'},
    ],
    return_orders_statuses:[
        {value:-1, label:'Todas', color:'black', back:'white'},
        {value:1, label:'Creada', color:blue[500], back:'rgba(33, 150, 243, 0.07)'},
        {value:2, label:'Completada', color:orange[500], back:'rgba(255, 152, 0, 0.07)'},
        {value:3, label:'Cancelada', color:red[700], back:'rgba(211, 47, 47, 0.07)'},
    ],
    sales_statuses:[
        {value:-1, label:'Todas', color:'black', back:'white'},  
        {value:1, label:'Creada', color:blue[500], back:'rgba(33, 150, 243, 0.07)'},  
        {value:2, label:'Pagada', color:orange[500], back:'rgba(255, 152, 0, 0.07)'}, 
        {value:3, label:'Entregada', color:green[500], back:'rgba(52, 158, 35, 0.07)'},
        {value:4, label:'Cancelada', color:red[700], back:'rgba(211, 47, 47, 0.07)'},
    ],
    payment_types:[
        {value:1, label:'Efectivo', sat_key:'01'},
        {value:2, label:'Tarjeta de Crédito', sat_key:'04'}, 
        {value:3, label:'Tarjeta de Débito', sat_key:'28'},  
    ],
    receipt_types:[
        {value:1, label:'Nota foliada'},
        {value:2, label:'Nota sin registro'}, 
        {value:3, label:'Factura'},  
    ],
    tax_systems:[
        {value:1, code:601, label:'General de Ley Personas Morales'},
        {value:2, code:603, label:'Personas Morales con Fines no Lucrativos'}, 
        {value:3, code:605, label:'Sueldos y Salarios e Ingresos Asimilados a Salarios'},  
        {value:4, code:606, label:'Arrendamiento'},
        {value:5, code:608, label:'Demás ingresos'}, 
        {value:6, code:609, label:'Consolidación'}, 
        {value:7, code:610, label:'Residentes en el Extranjero sin Establecimiento Permanente en México'},
        {value:8, code:611, label:'Ingresos por Dividendos (socios y accionistas)'}, 
        {value:9, code:612, label:'Personas Físicas con Actividades Empresariales y Profesionales'}, 
        {value:10, code:614, label:'Ingresos por intereses'},
        {value:11, code:616, label:'Sin obligaciones fiscales'}, 
        {value:12, code:620, label:'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'}, 
        {value:13, code:621, label:'Incorporación Fiscal'},
        {value:14, code:622, label:'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'}, 
        {value:15, code:623, label:'Opcional para Grupos de Sociedades'}, 
        {value:16, code:624, label:'Coordinados'},
        {value:17, code:628, label:'Hidrocarburos'}, 
        {value:18, code:607, label:'Régimen de Enajenación o Adquisición de Bienes'}, 
        {value:19, code:629, label:'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'},
        {value:20, code:630, label:'Enajenación de acciones en bolsa de valores'}, 
        {value:21, code:615, label:'Régimen de los ingresos por obtención de premios'}, 
        {value:22, code:625, label:'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'}, 
        {value:23, code:626, label:'Régimen Simplificado de Confianza'}, 
    ]
}

export default catalogs
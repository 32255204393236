import { makeStyles } from "@material-ui/core"
import { connect } from 'react-redux';
import PageAdmin from "../../../components/Structure/Layouts/PageAdmin"
import system_translations from "../../../texts/system_translations";
import DeleteUserModal from "../Users/modals/DeleteUserModal";
import UserInfo from "./components/UserInfo"
import ChangePasswordModal from "./modals/ChangePasswordModal";
import EditUserModal from "./modals/EditUserModal/EditUserModal";
import useUserView from "./useUserView";


const UserView = ({language, match, history}) => {

    const id = match.params.id
    const classes = useStyles()
    const content = system_translations[language].views.user
    const {system, view_data, actions, modals} = useUserView({language, match, history})

    return(
        <PageAdmin title={content.title} goback error={system.error} onCloseErrorModal={actions.onClearError} 
            loading={system.loading} language={language}>       
            <EditUserModal open={modals.edit_user} onClose={()=>actions.onChangeModalStatus('edit_user', false)} 
                language={language} onCompleted={actions.onInitModule} origin={view_data.user} />
            <DeleteUserModal language={language} open={modals.delete_user} onClose={()=>actions.onChangeModalStatus('delete_user', false)}
                onCompleted={()=>{history.push('/users')}} id={id} />
            <ChangePasswordModal language={language} open={modals.change_password} onClose={()=>actions.onChangeModalStatus('change_password', false)}
                id={id} onCompleted={actions.onInitModule}/>
            <UserInfo content={content.edit_section} {...view_data.user} language={language} actions={actions} nopicture={content.nopicture} />
        </PageAdmin>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(UserView)

const useStyles = makeStyles(theme => ({
    edit_button:{
        background:theme.palette.primary.main,
        borderRadius:20,
        boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.31)',
        textTransform:'none',
        color:'white',
        padding:'8px 28px'
    },
    delete_button:{
        background:'white',
        color:theme.palette.primary.main,
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)'
    }
    
}))
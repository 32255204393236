import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import cx from 'classnames'
import system_translations from "../../../../texts/system_translations"
import usePaymentMethodModal from "./usePaymentMethodModal"
import { onGetCurrencyValue } from "../../../../shared/utility"


const PaymentMethodModal = ({open, onClose, language, total, id_order, history, onUpdateOrderData}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.payment_method
    const catalogs = system_translations[language].catalogs
    const {loading, sending, error, form, actions, selected} = usePaymentMethodModal({open, language, id_order, history, onUpdateOrderData})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' >
            <div className={classes.title}>
                <Typography variant='h6'>{content.title}</Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container justifyContent='center' spacing={3} alignItems='center'>
                        <Grid item><Typography style={{fontWeight:500}}>{content.subtitle}</Typography></Grid>
                        <Grid item><Typography variant='h6'>{onGetCurrencyValue(total)}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent='center' spacing={4} >
                        {catalogs.payment_types.map(item => {
                            return(
                                <Grid item key={item}>
                                    <Button className={cx({[classes.option_button]:true, [classes.option_button_active]: selected === item.value})} 
                                    onClick={()=>actions.onSelectedOption(item.value)}>{item.label}</Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <RoundedButton disabled={selected===null} onClick={actions.onSubmit}>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default PaymentMethodModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    option_button:{
        background:theme.palette.primary.light,
        border:'1px solid #B30A10',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:theme.palette.primary.dark
    },
    option_button_active:{
        background:theme.palette.primary.dark,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
}))
import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import SelectAutocompleteForm from '../../../../../components/Forms/SelectAutocompleteForm'
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"
import Header from "../../../../../components/Structure/Texts/Header"
import system_translations from "../../../../../texts/system_translations"
import useEditPurchaseModal from "./useEditPurchaseModal"


const EditPurchaseModal = ({open, onClose, language, onCompleted, origin, id}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.purchase_modal
    const {loading, sending, error, form, actions, provider_form} = useEditPurchaseModal({open, id, language, onClose, onCompleted, content, origin})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' loading={loading} >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.edit.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={3}> 
                        <Grid item xs={12}>
                            <Header>{content.form.provider_id.tag}</Header>
                            <SelectAutocompleteForm data={form.provider_id} onChange={actions.onChangeForm} 
                            //onInputChange={actions.onSearchProvider}
                            />
                        </Grid>                                   
                    </Grid>      
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton loading={sending} onClick={actions.onSubmit}>{content.edit.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default EditPurchaseModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
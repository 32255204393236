import nouser from '../../assets/placeholders/nouser.png'

export const translations = {
    title:'Usuarios',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Nombre'},
            {id:3, label:'Usuario'},
            {id:4, label:'Tipo'},
            {id:5, label:'Estatus'},
            {id:6, label:''},
        ],
        nodata:'No existen usuarios registrados',
        nouser:nouser
    }
    
}

export default translations
import { Button, Grid, Icon, IconButton, makeStyles, Step, StepLabel, Stepper, Typography } from "@material-ui/core"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import system_translations from "../../../../../texts/system_translations"
import usePaymentMethodModal from "./useReceiptModal"
import PaymentMethod from './steps/PaymentMethod'
import ReceiptTypes from "./steps/ReceiptTypes"
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"

const ReceiptModal = ({open, onClose, language, client, id, onCompleted, total, onGetInvoiceData, onGetReceiptType}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.receipt
    const catalogs = system_translations[language].catalogs
    const {loading, sending, error, actions, active_step, payment_method, disabled, 
        receipt_type, invoice_loading, invoice_open, form} = usePaymentMethodModal({open, language, 
            client, id, onCompleted, onClose, onGetInvoiceData, onGetReceiptType})

    let contentView

    switch (active_step) {
        case 0:
            contentView = <PaymentMethod content={content.payment_method} payment_types={catalogs.payment_types} 
                            selected={payment_method} onSelectedOption={actions.onSelectedPaymentMethod} total={total}/>
            break;
        case 1:
            contentView = <ReceiptTypes receipts={catalogs.receipt_types} open={invoice_open} loading={invoice_loading}
                            form={form} selected={receipt_type} onSelectedOption={actions.onSelectedReceiptType}
                            actions={actions} />
            break;
        default:
            break;
    }

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' loading={loading} >
            <div className={classes.root}>
                {active_step !== 0 ? 
                <IconButton onClick={actions.handleBack} className={classes.backButton} fullWidth>
                    <Icon>arrow_back_ios</Icon>
                </IconButton>
                : null}
                <Stepper activeStep={active_step} alternativeLabel>
                    {content.steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
                <div>
                    <div>
                        {contentView}
                        <div style={{marginTop:40}}>
                        <DisplayError>{error}</DisplayError>
                        <Grid container spacing={2} justifyContent="center" >
                            <Grid item>                      
                                {/* <DisplayError>{error}</DisplayError> */}                       
                                <RoundedButton color="primary" onClick={actions.onSubmit} loading={sending} disabled={disabled}>
                                    {active_step === content.steps.length - 1 ? content.button[1] : content.button[0]}
                                </RoundedButton>                   
                            </Grid>
                        </Grid>
                        </div>
                    </div>
                </div>
            </div>      
        </SimpleModal>
    )

}

export default ReceiptModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #363636',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:'#363636'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
}))
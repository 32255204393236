import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import system_translations from '../../../texts/system_translations'
import AddUserModal from './modals/AddUserModal/AddUserModal';
import SimpleTable from './components/SimpleTable'
import useUsersView from './useUsersView';
import DeleteUserModal from './modals/DeleteUserModal';

const UsersView = ({language, match, history}) => {

    const content = system_translations[language].views.users
    const catalogs = system_translations[language].catalogs
    const {system, view_data, actions, modals} = useUsersView({language, match, history})

    return(
        
            <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
                <AddUserModal open={modals.add_user} onClose={()=>actions.onChangeModalStatus('add_user', false)} 
                    language={language} onCompleted={actions.onInitModule} />
                <DeleteUserModal language={language} open={modals.delete_user} onClose={()=>actions.onChangeModalStatus('delete_user', false)}
                    onCompleted={actions.onInitModule} id={view_data.selected} />
                <SimpleTable 
                    catalogs={catalogs}
                    loading={system.loading}
                    language={language}
                    content={content.table} 
                    data={view_data.users}
                    onSelectedItem={actions.onSelectedItem}
                    onChangeSearch={actions.onChangeSeach}
                    search={view_data.search}
                    tableFilter={view_data.tableFilter}
                    onUpdateTableFilter={actions.onChangeTableFilter}
                    total={view_data.total}
                    onAddRegister={()=>actions.onChangeModalStatus('add_user', true)}
                    onUpdateSearch={actions.onResetTableFiler}
                />
            </Page>
      
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(UsersView) 
import { Button, Dialog, Grid, Grow, Icon, makeStyles, Typography } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors';
import React from 'react'
import { onGetCurrencyValue } from '../../../shared/utility';
import system_translations from '../../../texts/system_translations';
import EquivalencesTable from '../components/EquivalencesTable2';
import SimpleModal from "../../../components/Modals/SimpleModal"

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:32,
        paddingBottom:0
    },
    icon:{
        color:'white',
        background:red[700],
        borderRadius:'50%',
        fontSize:54,
        padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
        maxWidth:300
    }
}))

const NoProductModal = (props) => {

    const classes = useStyles()
    const {open, message, onClose, language, equivalences, equivalences_content, onAdd, loading, wholesale_price, retail_price} = props
    const content = system_translations[language].modals.error_modal

    const onAddItem = (value) =>{
        onAdd(value)
        onClose()
    }

    return(
        <SimpleModal open={open} maxWidth='md' onClose={onClose}>
            <div className={classes.root}>
                <Grid container direction='column' justifyContent='center' alignItems='center' spacing={0}>
                    <Grid item>
                        <div className={classes.iconContainer}>
                            <Icon className={classes.icon}>close</Icon>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle1' style={{paddingTop:8}}>Opps...</Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.message}>
                            <Typography custom_color='bluegrey' align='center'>{message ? message : content.default_message}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item><Icon style={{color:red[700]}}>arrow_downward</Icon></Grid>
                            <Grid item><Typography style={{color:red[700]}}>{onGetCurrencyValue(wholesale_price)}</Typography></Grid>
                            <Grid item><Typography>-</Typography></Grid>
                            <Grid item><Icon style={{color:green[500]}}>arrow_upward</Icon></Grid>
                            <Grid item><Typography style={{color:green[500]}}>{onGetCurrencyValue(retail_price)}</Typography></Grid>
                        </Grid>
                    </Grid>                  
                    {equivalences && equivalences.length !== 0 ? <Grid item >
                        <EquivalencesTable data={equivalences} content={equivalences_content}
                            onAdd={onAddItem} loading={loading} />
                    </Grid> : null}                 
                    <Grid item>
                        <div style={{marginTop:40}}></div>
                        <Button size='large' onClick={onClose}>{content.button}</Button>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default NoProductModal

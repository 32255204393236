import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../../../../components/Modals/SimpleModal"
import Header from "../../../../../../../components/Structure/Texts/Header"
import system_translations from "../../../../../../../texts/system_translations"
import useAddEquivalenceModal from "./useAddComplementModal"
import DisplayError from "../../../../../../../components/Structure/DisplayData/DisplayError"
//import SelectAutocompleteForm from "../../../../../../../components/Forms/SelectAutocompleteForm"
//import ProductAutocompleteForm from "../../../../../../../components/Forms/ProductAutocomplete"
import ProductSearchBar from "../../../../../../../components/Actions/ProductSearchBar"


const AddComplementModal = ({open, onClose, language, id, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.complement_modal
    const {loading, sending, error, form, actions, prod_form, options, product_selected, loading_prod, search} = useAddEquivalenceModal({open, language, onClose, id, onCompleted})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.form.complement_product_id.tag}</Header>
                    {/* <ProductAutocompleteForm options={options} onChange={actions.onChangeProduct} loading={loading_prod}
                    onInputChange={actions.onInputChange} onSearchProduct={actions.onSearchProduct} /> */}
                    <ProductSearchBar options={options} value={search} loading={loading_prod} onSelectProduct={actions.onChangeProduct}
                    onChange={actions.onInputChange} onUpdateSearch={actions.onSearchProduct} />
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton loading={sending} disabled={product_selected === null} onClick={actions.onSubmit}>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddComplementModal

const useStyles = makeStyles(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    pic_container:{
        position:'relative'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    }
    
}))
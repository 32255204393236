import { useEffect, useState } from "react"
import axios from 'axios'
import { private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"


const useAddUserModal = ({open, language, content, onCompleted, onClose, catalogs}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [img_modal, setImgModal] = useState(false)
    const [data_image, setDataImage] = useState(null)
    const [file, setFile] = useState(null)
    const [crop_image, setCropImage] = useState(null)


    useEffect(() => {
        if(!open){
            actions.onInitModule()
        }
    }, [open])
 
    
    const actions = {
        onInitModule: async() => {
            setError(null)
            setFile(null)
            setDataImage(null)
            setCropImage(null)
            let _form = onInitForm(form_data, null, content.form)
            _form.gender_id.options = catalogs.genders
            _form.user_type_id.options = catalogs.user_types
            _form.user_status_id.options = catalogs.user_statuses
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(id === 'password') temp['confirm_password'].value2 = temp[id].value
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImgModal: (status) =>{
            setImgModal(status)
        },
        onUpdateDataImage: (data, _file, crop) =>{
            setDataImage(data)
            setFile(_file)
            setCropImage(crop)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            delete data2send.confirm_password
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                const new_user = await private_server.post(`/user`, data2send)
                //console.log(new_user)
                const id = new_user.data.data.user.id_user
                if(data_image && file){
                    const image_req =  await private_server.post(`/user/${id}/image`, data_image)
                    const options = {headers: {'Content-Type': file.type}}
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(image_req.data.data.url, file, options);
                } 
                setError(null)
                setSending(false)
                onCompleted()
                onClose()
            } catch (error) {
                setSending(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, crop_image, img_modal} 
}


export default useAddUserModal

const form_data = {
    first_name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    last_name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    username: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'username',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    password: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
        },
        rules: {type: 'distance', min:8, max:255}
    },
    confirm_password: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'confirm_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {type: 'equals'}
    },
    birth_date: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    hired_at: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'hired_at',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    gender_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },
    mobile: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'phone_number'}
    },
    user_type_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'user_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },
    user_status_id: {
        value:1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'user_status_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },

}
import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import InputForm from "../../../../../components/Forms/InputForm"
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import Header from "../../../../../components/Structure/Texts/Header"
import AvatarPicture from '../../../../../components/Structure/DisplayData/AvatarPicture'
import system_translations from "../../../../../texts/system_translations"
import useAddClientModal from "./useAddClientModal"
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"


const AddClientModal = ({open, onClose, language, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.client_modal
    const catalogs = system_translations[language].catalogs
    const {sending, error, form, actions} = useAddClientModal({open, language, content, onCompleted, onClose, catalogs})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.add.title}</Typography>
                </Grid>    
                <Grid item xs={12}>
                    <Header>{content.form.name.tag}</Header>
                    <InputForm data={form.name} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.form.email.tag}</Header>
                    <InputForm data={form.email} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.rfc.tag}</Header>
                    <InputForm data={form.rfc} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.cfdi_use.tag}</Header>
                    <InputSelectForm data={form.cfdi_use} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.zip.tag}</Header>
                    <InputForm data={form.zip} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.tax_system.tag}</Header>
                    <InputSelectForm data={form.tax_system} onChange={actions.onChangeForm} />
                </Grid>
            </Grid>       
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton onClick={actions.onSubmit} loading={sending}>{content.add.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddClientModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
import moment from "moment"
import { useEffect, useState } from "react"
import { private_server } from "../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"

//PENDIENTE

const useOrderView = ({ history, onUpdatePayModal, match, language, edit_order_products, blur}) => {

    //console.log(order)
    const order_id = match.params.id

    const [loading, setLoading] = useState(false)
    const [product_loading, setProductLoading] = useState(false)
    const [item_loading, setItemLoading] = useState(false);
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [noproduct_error, setNoproductError] = useState(null);


    const [wholesale, setWholesale] = useState(0)
    
    const [local_order, setLocalOrder] = useState(null)
    const [item_selected, setItemSelected] = useState(null)
    const [product_selected, setProductSelected] = useState(null)
    const [equivalences, setEquivalences] = useState([])
    const [complements, setComplements] = useState([])

    const [noproduct_equivalences, setNoproductEquivalences] = useState([]);
    const [noproduct_wholesale_price, setNoproductWholesalePrice] = useState(null);
    const [noproduct_retail_price, setNoproductRetailPrice] = useState(null);

    const [modals, setModals] = useState({
        payment_method:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [wholesale])

    useEffect(() => {
        actions.onGetProductInfo()
    }, [item_selected])

    const actions = {
        onInitModule: async() => {
            try{
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const _order = await private_server.get(`order/${order_id}`)
                //console.log(_order)
                setLocalOrder(_order.data.data.order)
                setLoading(false)
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
            
        },
        onGetProductInfo: async() =>{
            setProductLoading(true)
            try{
                if(product_selected !== null){  
                    updateAuthorizationHeader(private_server)        
                    const equ = await private_server.get(`equivalence/all/product/${product_selected}?limit=10&offset=0&order=0&order_by=id_equivalence`)
                    setEquivalences(equ.data.data.equivalences)
                    const comp = await private_server.get(`complement/all?limit=10&offset=0&order=0&order_by=id_complement&filter={"AND":[{"field":"main_product_id","operator":"=","value":"${product_selected}"}]}`)
                    setComplements(comp.data.data.complements)
                }
                setProductLoading(false)
            }catch(error){
                setProductLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeGlobalWholesale:async (new_wholesale) =>{
            //setLoading(true)
            updateAuthorizationHeader(private_server)
            if(local_order && local_order.order_items.length !== 0){
                //console.log(local_order.order_items)
                /* local_order.order_items.forEach(async (el) => {
                    try{                                        
                        const new_el = await private_server.patch(`orderitem/${el.id_order_item}`, {wholesale:new_wholesale})
                        console.log(new_el) 
                    }catch(error){
                        console.log(error)
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                }); */  
                Promise.all(local_order.order_items.map(async (item) => {
                    //if(item.wholesale !== new_wholesale){
                        try{                                        
                            const new_el = await private_server.patch(`orderitem/${item.id_order_item}/me`, {wholesale:new_wholesale})
                            //console.log(new_el) 
                        }catch(error){
                            console.log(error)
                            const _error = onGetErrorMessage(error, language)
                            setError(_error ? _error.message : null)
                        }
                        //setLoading(false)
                    //}
                }))
                console.log(new_wholesale)
                setWholesale(new_wholesale)
      
                try{
                    const new_order = await private_server.get(`order/${order_id}`)
                    console.log(new_order.data.data)
                    setLocalOrder(new_order.data.data.order)
                    //console.log('ready')
                    //onUpdateOrderData(new_order.data.data.order)
                }catch(error){
                    console.log(error)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                //setLoading(false)
            }
           
        },
        onCancelledOrder:async () =>{
            //console.log(edit_order_products)
            if(edit_order_products){
                console.log('editando productos')
                history.goBack()
            }else{
                console.log('cancelado orden')
                try{
                    updateAuthorizationHeader(private_server)
                    await private_server.post(`order/${local_order.id_order}/cancel/me`)
                    await private_server.delete(`order/${local_order.id_order}/me`)
                    //onUpdateOrderData(null)
                    setLocalOrder(null)
                    history.goBack()
                }catch(error){
                    console.log(error)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
            }
            
        },
        onAddOrderItem:async (product_id) =>{
            //console.log('add item')
            try{
                setItemLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`product/${product_id}`)
                const _product = req.data.data.product 
                console.log(_product)
                if(_product && _product.quantity <= 0){
                    //console.log('faltante')
                    setNoproductWholesalePrice(_product.wholesale_price)
                    setNoproductRetailPrice(_product.retail_price)
                    const eq = await private_server.get(`equivalence/all/product/${product_id}?limit=5`)
                    const _equivalences= eq.data.data.equivalences
                    if(_equivalences && _equivalences.length !== 0){
                        setNoproductEquivalences(_equivalences)
                        //console.log(_equivalences)
                        //const equi = _equivalences.map(element => element.product_name);
                        //console.log(equi)
                        //setError(`El producto no se encuentra en existencia, intente elegir alguno de sus equivalentes: ${equi.join(', ')}`)
                        setNoproductError(`El producto no se encuentra en existencia, intente elegir alguno de sus equivalentes:`)
                        return
                    }else{
                        setNoproductError(`El producto no se encuentra en existencia`)
                        return
                    }
                }else{
                    const data2send = {
                        order_id:local_order.id_order, 
                        wholesale: wholesale,
                        quantity:1,
                        product_id:product_id
                    }
                    try{
                        updateAuthorizationHeader(private_server)
                        await private_server.post(`orderitem/me`, data2send)
                        const new_order = await private_server.get(`order/${local_order.id_order}`)
                        setLocalOrder(new_order.data.data.order)
                        //onUpdateOrderData(new_order.data.data.order)
                    }catch(error){
                        console.log(error)
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                }
                
                //onUpdateOrderData(new_order.data.data.order)
            }catch(error){
                console.log(error)
                //const _error = onGetErrorMessage(error, language)
                //setError(_error ? _error.message : null)
            }
            setItemLoading(false)

            /* const data2send = {
                order_id:local_order.id_order, 
                wholesale: wholesale,
                quantity:1,
                product_id:product_id
            }
            try{
                updateAuthorizationHeader(private_server)
                await private_server.post(`orderitem`, data2send)
                const new_order = await private_server.get(`order/${local_order.id_order}`)
                setLocalOrder(new_order.data.data.order)
                //onUpdateOrderData(new_order.data.data.order)
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            } */
        },
        onDeleteOrderItem: async(id) =>{
            setSending(true)
            try{
                updateAuthorizationHeader(private_server)
                await private_server.delete(`orderitem/${id}/me`)
                const new_order = await private_server.get(`order/${local_order.id_order}`)
                setLocalOrder(new_order.data.data.order)
                //onUpdateOrderData(new_order.data.data.order)
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            setSending(false)
        },
        onChangeQuantity: async(id, quantity) =>{
            const data2send={quantity:quantity}
            try{
                updateAuthorizationHeader(private_server)
                await private_server.patch(`orderitem/${id}/me`, data2send)
                const new_order = await private_server.get(`order/${local_order.id_order}`)
                setLocalOrder(new_order.data.data.order)
                //onUpdateOrderData(new_order.data.data.order)
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onChangeWholesale: async(id, old_wholesale) =>{

            const data2send={wholesale:old_wholesale === 1 ? 0 : 1}
            try{
                updateAuthorizationHeader(private_server)
                await private_server.patch(`orderitem/${id}/me`, data2send)
                const new_order = await private_server.get(`order/${order_id}`)
                setLocalOrder(new_order.data.data.order)
                //onUpdateOrderData(new_order.data.data.order)
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onSetItemSelected:(item, product)=>{
            setItemSelected(item)
            setProductSelected(product)
        },
        onSubmit: () =>{
            history.push(`/sales/${local_order.id_order}`)
            onUpdatePayModal(true)
        },
        onDeleteCart: async() => {
            setLoading(true)
            try{
                updateAuthorizationHeader(private_server)
                await private_server.delete(`orderitem/all/${order_id}/me`)
                const new_order = await private_server.get(`order/${order_id}`)
                setLocalOrder(new_order.data.data.order)
                //onUpdateOrderData(new_order.data.data.order)
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        },
        onClearError:()=>{
            setError(null)
            setNoproductError(null)
        },
    }

    const system = {loading, sending, error, product_loading, item_loading, noproduct_error}
    const view_data = {wholesale, item_selected, equivalences, complements, local_order, noproduct_equivalences, noproduct_wholesale_price, noproduct_retail_price}

    return {system, modals, view_data, actions}
}

export default useOrderView
import moment from "moment"
import { useEffect, useState } from "react"
import { private_server } from "../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"

const useProvidersView = ({ language }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [providers, setProviders] = useState([])
    const [selected, setSelected] = useState(null)
    
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_provider'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add_provider:false,
        edit_provider:false,
        delete_provider:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                const req = await private_server.get(`provider/all?&filter={"AND":[{"field":"name","operator":"LIKE","value":"%25${search}%25"}]}`, {params:{...params}})
                //console.log(req)
                const _providers = req.data.data
                setProviders(_providers.providers)
                setTotal(_providers.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onSelectItem: (id, action) => {
            setSelected(id)
            if(action === 'edit') actions.onChangeModalStatus('edit_provider',true)  
            if(action === 'delete') actions.onChangeModalStatus('delete_provider',true)  
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onResetTableFiler: () => setTableFilter({limit:10, offset:0, order:1, order_by:'id_provider'}),
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => { 
            //let newTableFilter = {...tableFilter}
            //newTableFilter.offset = 0
            setTableFilter(newTableFilter)
        },
        onCompleted:async () =>{
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`provider/all`, {params:{...tableFilter}})
                console.log(req)
                const _providers = req.data.data
                setProviders(_providers.providers)
                setTotal(_providers.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onClearError:()=>{
            setError(null)
        }
    }

    const system = {loading, sending, error}
    const view_data = {providers, tableFilter, search, total, selected}

    return {system, modals, view_data, actions}
}

export default useProvidersView
import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import StatusData from "../../../../components/Structure/DisplayData/StatusData"
import catalogs from "../../../../texts/esp/catalogs"
import tableStyles from "../../../../styles/tableStyles"
import DownloadButton from "../../../../components/Actions/DownloadButton"
import { onGetCurrencyValue, onGetDateFormat } from "../../../../shared/utility"
import miniTableStyles from "../../../../styles/miniTableStyles"


const MiniTableV2 = props => {

    const { data, header, onDownloadReceipt, history } = props
    //const classes = useStyles()
    const classes = miniTableStyles()

    return(
        <div>
            {data && data.length > 0 ?
            <div className={classes.tableWrapper}> 
                <Table className={classes.table}>
                    {header ? (
                        <TableHead>
                            <TableRow>
                                {header.map(item=>{
                                    return(
                                        <TableCell padding='default' key={item.id.toString()} >
                                            <Typography className={classes.header} >{item.label}</Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    ):null}
                    <TableBody>
                        {data.map((item,key) => {
                            const {id_order, sold_date, order_status_id, total, payment_type, receipt_type, invoiced, generated_receipt} = item

                            return(
                                <TableRow key={key.toString()}  >
                                    <TableCell className={classes.cell} padding="checkbox" ><Typography>{id_order}</Typography></TableCell>
                                    <TableCell className={classes.cell} ><Typography>{onGetDateFormat(sold_date, 'DD/MM/YYYY')}</Typography></TableCell>
                                    <TableCell className={classes.cell} ><StatusData catalogs={catalogs.orders_statuses} id={order_status_id} /></TableCell>
                                    <TableCell className={classes.cell} ><Typography>{onGetCurrencyValue(total)}</Typography></TableCell>
                                    <TableCell className={classes.cell} ><div className={classes.payment_type}>{payment_type}</div></TableCell>
                                    <TableCell className={classes.cell_center} ><div className={classes.receipt}>{receipt_type}</div></TableCell>
                                    <TableCell className={classes.cell_center} padding="checkbox"><DownloadButton disabled={!(Boolean(invoiced))} onClick={()=>onDownloadReceipt(generated_receipt, invoiced, id_order)} /></TableCell>
                                    <TableCell className={classes.cell_center} padding="checkbox"><IconButton onClick={()=>history.push(`sales/${id_order}`)} color='primary' style={{width:40, height:40}} ><Icon>navigate_next</Icon></IconButton></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
           </div> : null}
        </div>
    )
}

export default MiniTableV2






import React, {useState, useEffect} from 'react';
import { AppBar, Button, Grid, Icon, IconButton, makeStyles, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {translations} from '../../../texts/esp/general-translations'
import cx from 'classnames'
import { DASHBOARD_SETTINGS, private_server, USER_TYPES } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { onClearMemory, onGetErrorMessage, updateAuthorizationHeader } from '../../../shared/utility';
import UserButton from '../../../components/Actions/UserButton'
import OrderButton from '../../../components/Actions/OrderButton';
import ErrorModal from '../../../components/Modals/ErrorModal';
import PrinterStatus from './PrinterStatus';


const Topbar = ({user, history, is_auth, cart, onUpdateSignInModal, onUpdateUserData, onUpdateAuthStatus, drawer_status,
        onUpdateDrawerStatus, content, language, onUpdateOrderData, onUpdateEditFlag}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
 
    const [error, setError] = useState(null)

    const onItemSelected = async(url) => {
        switch (url) {
            case '/logout':
                onUpdateUserData(null)
                onUpdateAuthStatus(false)
                onClearMemory()
                history.push('/')
                break;
            case '/create_order':{
              
                /* try{
                    updateAuthorizationHeader(private_server)
                    const data={seller_id:user.id_user}
                    const req = await private_server.post(`order`,data)
                    //onUpdateOrderData(req.data.data.order)
                    history.push(`/order/${req.data.data.order.id_order}`)

                    //pruebas
                    history.push(`/order`) 
                }catch(error){
                    console.log(error)
                } */
            }
            default:
                history.push(url)
                break;
        }
    }

    const onCreateOrder = async() =>{
        //history.push(`/order/8`)
        try{
            updateAuthorizationHeader(private_server)
            const data={seller_id:user.id_user}
            const req = await private_server.post(`order/me`,data)
            //onUpdateOrderData(req.data.data.order)
            history.push(`/order/${req.data.data.order.id_order}`)
            onUpdateEditFlag(false)
        }catch(error){
            console.log(error)
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
    }

   
    return ( 
        <AppBar position='fixed' className={cx({
            [classes.appbar]:true,
            [classes.appbarShift]:drawer_status
        })}>
            <div className={classes.root}>  
            <ErrorModal open={Boolean(error)} message={error} onClose={()=>setError(null)} language={language}/>        
                <Grid container wrap='nowrap' alignItems='center' spacing={3}>
                    <Grid item>
                       <IconButton color="secondary" onClick={() => onUpdateDrawerStatus(!drawer_status)}>
                           <Icon>menu</Icon>
                       </IconButton>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item>
                        <PrinterStatus />
                    </Grid>
                    { user && user.user_type_id !== USER_TYPES.VISOR ? <Grid item>
                        <OrderButton onClick={onCreateOrder} />
                    </Grid> : null}
                    <Grid item>
                        <UserButton ismobile={ismobile} {...user} options={content.user_button} onItemSelected={onItemSelected} 
                            language={language}/>
                    </Grid>   
                </Grid>
           </div>
        </AppBar>
     );
}

const useStyles = makeStyles(theme => ({ 
    root:{
        //background:'red',
        padding:'12px 20px'
    },
    appbar:{
        background:'white',
        boxShadow:'none',
        boxSizing:'border-box',
        padding:'0px 8px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appbarShift:{
        background:'white',
        color:'black',
        width: `calc(100% - ${DASHBOARD_SETTINGS.SIDEBAR.WIDTH}px)`,
        marginLeft: DASHBOARD_SETTINGS.SIDEBAR.WIDTH,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    
}))

const mapStateToProps = state => {
    return {
        user:state.user,
        is_auth:state.is_auth,
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSignInModal: (signin_modal) => dispatch({type:actionTypes.MODAL_UPDATE_SIGNIN, signin_modal}),
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateOrderData: (order) => dispatch({type:actionTypes.AUTH_UPDATE_ORDER, order}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateDrawerStatus: (drawer_status) =>dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status}),
        onUpdateEditFlag: (edit_order_products) => dispatch({type:actionTypes.SYS_UPDATE_EDIT_FLAG, edit_order_products}),
    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Topbar));

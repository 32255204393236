import noprovider from '../../assets/placeholders/noprovider.png'


export const translations = {
    title:'Proveedores',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Nombre'},
            {id:3, label:'Contacto'},
            {id:4, label:'Email'},
            {id:5, label:'Teléfono'},
            {id:6, label:''},
        ],
        nodata:'No existen proveedores registrados',
        noprovider:noprovider
    }
    
}

export default translations
import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { onGetFullname } from '../../../shared/utility'
import { green, grey } from '@material-ui/core/colors'
import SearchBar from '../../../components/Actions/SearchBar'
import ActionBar from '../../../components/Actions/ActionBar'
import empty from '../../../assets/placeholders/nomissing.png'
import UserAvatarName from '../../../components/Structure/DisplayData/UserAvatarName'
import DeleteButton from '../../../components/Actions/DeleteButton'
import tableStyles from '../../../styles/tableStyles'
import DownloadButton from '../../../components/Actions/DownloadButton'
import FilterButton from '../../../components/Actions/FilterButton'

const SimpleTable = props => {

    const classes = tableStyles()
    const { content, data, onAddRegister, onDownloadExcel, onRefresh, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onUpdateSearch, allProviders, filters, onUpdateFilters} = props

    const header = content.header

    const all_option = [{value:-1, label:'Todos'}]
    const options = allProviders.map(provider => ({
        value: provider.id_provider,
        label: provider.name
      })
    );

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='center' wrap='nowrap'>
                    <Grid item><FilterButton options={[...all_option, ...options]} selected={filters.provider} onChange={(value)=>onUpdateFilters('provider', value)} /></Grid>
                    <Grid item><SearchBar value={search} onChange={onChangeSearch} onUpdateSearch={onUpdateSearch} /></Grid>
                    <Grid item><IconButton onClick={onRefresh} className={classes.button} ><Icon  >cached</Icon></IconButton></Grid>    
                    <Grid item><DownloadButton variant='table' onClick={onDownloadExcel} /></Grid>
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}          
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                <Typography variant='subtitle2'>{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {code, id_product, description, quantity, pending_products, name, purchase_group, brand, purchase_quantity} = item

                                return(
                                    <TableRow key={key.toString()} className={classes.row} >
                                        <TableCell className={classes.cell}><Typography>{id_product}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{code}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{brand}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{name}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{description}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{quantity}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography >{purchase_quantity}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{pending_products}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{purchase_group}</Typography></TableCell>
                                    </TableRow>
                                )
                            })}
                            
                        </TableBody>
                    </Table>
                </div>           
            ): null}   
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>              
            ) : null}        
        </div>
    )
}

export default SimpleTable
import { useEffect, useRef, useState } from "react"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"
import { private_server } from '../../../../../config'


const useReceiptModal = ({open, language, client, id, onCompleted, onClose, onGetInvoiceData, onGetReceiptType}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [payment_method, setPaymentMethod] = useState(null)
    const [receipt_type, setReceiptType] = useState(null)

    const [active_step, setActiveStep] = useState(0)
    const [disabled, setDisabled] = useState(true)

    //invoice
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [invoice_open, setInvoiceOpen] = useState(false)
    const invoice_loading = invoice_open && form.client_id.options.length === 0;
    
    useEffect(() => {
        let active = true;
        let catalogs = []
    
        if (!invoice_loading) {
          return undefined;
        }
    
        (async () => {
          try{
            updateAuthorizationHeader(private_server)
            const req_clients = await private_server.get(`client/all`)
            catalogs = onGetCatalogs(req_clients.data.data.clients, 'id_client', 'name' )
            console.log(catalogs)
          }catch(error){
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
          }
          if (active) {
              let _form = {...form}
              _form.client_id.options = catalogs
              setForm(_form)
          }
        })();
    
        return () => {
          active = false;
        };
    }, [invoice_loading]);

    useEffect(() => {
        if(!open){
            setPaymentMethod(null)
            setReceiptType(null)
            setActiveStep(0)
        }else{
            actions.onInitModule()
        }
    }, [open])
    
    const actions = {
        onInitModule: async() => { 
            setLoading(true)
            try{
                let _form = onInitForm(form, client, null)   
                const req_cfdi = await private_server.get(`order/sat/cfdiuses`)
                const cfdi_catalogs = req_cfdi.data.data.cfdi_uses.map(item => {
                    return{
                        value: item.code, label:item.code + '-' + item.name
                    }
                })     
                _form.cfdi_use.options = cfdi_catalogs
                setForm(_form)
                setLoading(false)
            }catch(error){
                setLoading(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }

        },
        handleBack: () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        },
        onSelectedPaymentMethod: (value) =>{
            setPaymentMethod(value)
            setDisabled(false)
        },
        onSelectedReceiptType: (value) =>{
            setReceiptType(value)
            setDisabled(false)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeSelectOpen:(new_status) => {
            console.log('open')
            setInvoiceOpen(new_status)
        },
        onSubmit: async() => {
            
            if(active_step === 0){
               if(payment_method !== null && receipt_type !== null){
                   setDisabled(false)
               }else{setDisabled(true)}
                
               setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }

            if(active_step == 1){
                if(receipt_type !== 3){
                    const pay_data = {payment_type_id:payment_method, receipt_type_id:receipt_type}
                    //onGetReceiptType('receipt')
                    try {
                        setSending(true)
                        updateAuthorizationHeader(private_server)
                        await private_server.post(`/order/${id}/pay/me`, pay_data)
                        //await private_server.post(`/receipt`, {order_id:id})
                        setError(null)
                        setSending(false)
                        onCompleted()
                        onClose()
                    } catch (error) {
                        setSending(false)
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                }else{
                    //invoice
                    //onGetReceiptType('invoice')
                    /* const errors = isFormValid(form)
                    if(errors && errors.length){
                        const _form = onSetErrorsToForm(form, errors)
                        setForm(_form)
                        setError(generic_errors[language].INCOMPLETE_FORM)
                        return
                    }
        
                    let data2send
                    // Clean data
                    if(client){
                        data2send = onGetSelectedFormData(form, client)
                    }else{
                        data2send = onGetFormData(form)
                    }

                    console.log(data2send)
                    onGetInvoiceData(data2send) */

                    const pay_data = {payment_type_id:payment_method, receipt_type_id:receipt_type}

                    try {
                        setSending(true)
                        updateAuthorizationHeader(private_server)
                        await private_server.post(`/order/${id}/pay/me`, pay_data)
                        //await private_server.post(`/order/${id}/invoice/create/me`, data2send)
                        setError(null)
                        setSending(false)
                        onCompleted()
                        onClose()
                    } catch (error) {
                        setSending(false)
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                }
            }   
        }
    }

    return {loading, sending, error, actions, active_step, disabled, payment_method, 
        receipt_type, invoice_open, invoice_loading, form} 
}


export default useReceiptModal

const form_data = {
    client_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'client_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    cfdi_use: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'cfdi_use',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
}

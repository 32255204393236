import { Icon, IconButton, makeStyles } from "@material-ui/core"


const EditButton = ({size, onClick, card}) =>{

    const classes = useStyles()

    return(
        <IconButton onClick={onClick} size={size ? size : 'medium'} className={card ? classes.button_card : classes.edit_pic_button}><Icon>edit</Icon></IconButton>
    )

}

export default EditButton

const useStyles = makeStyles(theme => ({
    edit_pic_button:{
        background:theme.palette.primary.dark,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    button_card:{
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)',
        color:theme.palette.primary.main,
        padding:8
    }
    
}))
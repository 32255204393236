import React, { useState } from 'react';
import { Avatar, Card, Grid,  Icon,  IconButton,  makeStyles, Popover, Typography } from '@material-ui/core';
import nouser from '../../assets/placeholders/nouser.png'
import { CONFIG_SETTINGS, LAYOUT_SETTINGS, nodata } from '../../config';
import { blueGrey, grey } from '@material-ui/core/colors';
import AvatarPicture from '../Structure/DisplayData/AvatarPicture'
import cx from 'classnames'
import { theme } from '../../theme';
import UserType from '../Structure/DisplayData/UserType';
import { onGetFullname } from '../../shared/utility';
import UserAvatarName from '../Structure/DisplayData/UserAvatarName';

const UserButton = ({ismobile, first_name, last_name, user_type_id, image, onItemSelected, options, language}) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)


    const onInnerItemSelected = (url) => {
        setAnchorEl(null)
        onItemSelected(url)
    }
    
    const onInnerClose = () => {
        setAnchorEl(null)
    }
    

    //let userContent = null

    /* if(!user){
        userContent = (
            <div className={classes.icon_container}>
                <SVGIcon src={userIcon} size={24} color='white'/>
            </div>
        )
    }else{
        const imgSrc = user.image ? `${CONFIG_SETTINGS.S3_SERVER_URL}${user.image}` : null
        userContent =  <Avatar src={imgSrc} alt='' className={classes.avatar} />
    } */

    //const menu_options = !isauth ? content.nouser : content.user
    const menu_options = options ? options : []

    return ( 
        <div>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} classes={{paper:classes.paper}}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                <div className={classes.popper_container}>
                    <Grid container>
                        {menu_options.map(item => {
                            return(
                                <Grid item xs={12} key={item.id.toString()}>
                                    <OptionComponent {...item} onItemSelected={onInnerItemSelected}/>
                                </Grid>
                            )
                        })}
                    </Grid> 
                </div>
            </Popover>
            <div className={classes.root} >
                <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                    <Grid item><AvatarPicture src={image} nopicture={nouser} size={44} /></Grid>
                    {ismobile ? null : 
                    <Grid item>
                        <Typography variant='caption' style={{color:'black'}}>{onGetFullname(first_name, last_name)}</Typography>
                        <UserType language={language} id={user_type_id} size='small' />
                    </Grid>}
                    <Grid item>
                        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} className={classes.expand_button}><Icon>expand_more</Icon></IconButton>
                    </Grid>
                </Grid>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    card:{
        borderRadius:24,
        padding:'5px 12px',
        paddingRight:8,
        cursor:'pointer',
        boxShadow:'none',
        background:'white',
        border:`2px solid ${grey[300]}`,
        '&:hover':{
            background: grey[100]
        }
    },
    card_mono:{
        background:'white',
        boxShadow:LAYOUT_SETTINGS.BOXSHADOW,
        border:'none'
    },
    avatar:{
        //width:32,
        //height:32
        '& .MuiAvatar-img':{
            objectFit:'contain',
        },
        border:'1px solid #BFBFBE'
    },
    container:{
        marginTop:8,
        borderRadius:16,
        
    },
    icon_container:{
        background:theme.palette.primary.main,
        borderRadius:'50%',
        padding:4
    },
    paper:{
        marginTop:4,
        borderRadius:12,
        width:250
    },
    popper_container:{
        padding:'12px 0px'
    },
    user_type:{
        background:'rgba(237, 28, 36, 0.07)',
        borderRadius:2,
        color:'#909090',
        padding:'4px 8px',
        fontSize:'0.7rem'
    },
    expand_button:{
        padding:6
    }
}))
 
export default UserButton;

const useStyles4Option = makeStyles(theme=>({
    root:{
        position:'relative',
        
        paddingLeft:theme.spacing(3),
        paddingRight:theme.spacing(3),
        fontFamily:theme.typography.fontFamily,
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            fontWeight:700,
            //background:theme.palette.primary.light,
            background:grey[100],
            transition:theme.transitions.create(['background','font-weight'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    label_container:{
        padding:'12px 0px',
        
    },
    border:{
        borderTop:`1px solid ${grey[200]}`
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {label, onItemSelected, url, top_line} = props
    const classes = useStyles4Option()

    return(
        <div className={cx({
            [classes.root]:true,

        })} onClick={() => onItemSelected(url)}>
            <div className={cx({
            [classes.label_container]:true,
            [classes.border]:top_line,
        })}>
                {label}
            </div>
        </div>
    )
}
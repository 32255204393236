import { Button, Grid, Icon, makeStyles } from "@material-ui/core"
import SearchBar from "./SearchBar"


const Topbar = ({content, onCancelledOrder, onAddItem, history}) => {

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <div className={classes.logo_container}>
                        <img src={content.logo} alt='' className={classes.logo}/>
                    </div>
                </Grid>
                <Grid item><SearchBar onAddItem={onAddItem} /></Grid>
                <Grid item>
                    <Button className={classes.button} onClick={onCancelledOrder}
                    startIcon={<Icon style={{color:'white'}}>shopping_cart</Icon>}
                    >{content.button}</Button>
                </Grid>
            </Grid>
        </div>
    )

}

export default Topbar

const useStyles = makeStyles(theme => ({
    root:{
        //width:'100%',
        padding:'12px 60px',
        boxShadow:'0px 4px 12px rgba(0, 0, 0, 0.09)'
    },
    logo:{
        width:120
    },
    logo_container:{
        width:'100%',
        //background:'green',
        textAlign:'center'
    }, 
    button:{
        background:theme.palette.primary.dark,
        borderRadius:24,
        padding:'8px 24px',
        color:'white',
        textTransform:'none',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    }
    
}))
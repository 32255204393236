import { Button, CircularProgress, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import DeleteButton from '../../../components/Actions/DeleteButton';
import EditButton from '../../../components/Actions/EditButton';
import RoundedButton from '../../../components/Actions/RoundedButton';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import StatusData from '../../../components/Structure/DisplayData/StatusData';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import Header from '../../../components/Structure/Texts/Header';
import { nodata, PURCHASES_ORDER_STATUSES, USER_TYPES } from '../../../config';
import { isValidDocument, onGetCurrencyValue, onGetDateFormat } from '../../../shared/utility';
import system_translations from '../../../texts/system_translations'
import Products from './components/Products';
import AddPurchaseItemModal from './modals/AddPurchaseItemModal/AddPurchaseItemModal';
import CancelModal from './modals/CancelModal';
import DeletePurchaseItemModal from './modals/DeletePurchaseItemModal';
import DeletePurchaseModal from './modals/DeletePurchaseModal';
import EditPurchaseModal from './modals/EditPurchaseModal/EditPurchaseModal';
import UploadFileModal from './modals/UploadFileModal';
import usePurchaseView from './usePurchaseView';


const PurchaseView = ({language, history, match, user}) => {

    const ID = match.params.id
    const content = system_translations[language].views.purchase
    const catalogs = system_translations[language].catalogs
    const classes = useStyles()
    const {actions, modals, purchase, system, view_data} = usePurchaseView({history, match, language})
    const status = purchase ? purchase.purchase_order_status_id : -1

    let can_edit = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_edit = false

    return(
        <Page title={content.title} goback loading={system.loading} language={language} error={system.error} 
            onCloseErrorModal={actions.onClearError} >
            <UploadFileModal language={language} open={modals.upload_file} onClose={()=>actions.onChangeModalStatus('upload_file', false)}
                id={purchase ? purchase.id_purchase_order : null} onCompleted={actions.onInitModule}/>
            <DeletePurchaseModal language={language} open={modals.delete_purchase} onClose={()=>actions.onChangeModalStatus('delete_purchase', false)}
                onCompleted={()=>history.push('/purchases')} id={ID}/>
            <CancelModal language={language} open={modals.cancel} onClose={()=>actions.onChangeModalStatus('cancel', false)} 
                onCompleted={actions.onInitModule} id={ID} status={status}/>
            <EditPurchaseModal language={language} open={modals.edit_purchase} onCompleted={actions.onInitModule}
                onClose={()=>actions.onChangeModalStatus('edit_purchase', false)} origin={purchase} id={ID} />
            <AddPurchaseItemModal language={language} open={modals.add_purchase_item} onClose={()=>actions.onChangeModalStatus('add_purchase_item', false)} 
                onCompleted={actions.onInitModule} id={ID}/>
            <DeletePurchaseItemModal language={language} open={modals.delete_purchase_item} onClose={()=>actions.onChangeModalStatus('delete_purchase_item', false)}
                onCompleted={actions.onInitModule} id={view_data.purchse_item_id}/>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                <div className={classes.topbar}>
                    {can_edit ? <Grid container justifyContent='flex-end' spacing={2} alignItems='center'>
                        
                        <Grid item>
                            <RoundedButton 
                                onClick={()=>actions.onChangeModalStatus('cancel', true)}
                                disabled={status === 4} 
                                >
                                {status !== -1 ? content.general.back_button[status-1] : nodata}
                            </RoundedButton>
                        </Grid>
                        <Grid item>
                            <EditButton card onClick={()=>actions.onChangeModalStatus('edit_purchase', true)} />
                        </Grid>
                        <Grid item>
                            <DeleteButton card disabled={status !== PURCHASES_ORDER_STATUSES.CANCELLED} onClick={()=>actions.onChangeModalStatus('delete_purchase', true)} />
                        </Grid>             
                    </Grid> : null } 
                </div>
                <SimpleCard>
                    <div className={classes.container}>
                        <Grid container spacing={4} alignItems='center'>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.start_date}</Header>
                                <Typography variant='body2'>
                                    {purchase ? onGetDateFormat(purchase.start_date, 'DD/MM/YYYY') : nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.order_date}</Header>
                                <Typography variant='body2'>
                                    {purchase ? onGetDateFormat(purchase.ordered_date, 'DD/MM/YYYY') :  nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.reception_date}</Header>
                                <Typography variant='body2'>
                                    {purchase ? onGetDateFormat(purchase.reception_date, 'DD/MM/YYYY') :  nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.cancel_date}</Header>
                                <Typography variant='body2'>
                                    {purchase ? onGetDateFormat(purchase.cancelled_date, 'DD/MM/YYYY') :  nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.provider}</Header>
                                <Typography variant='body2'>
                                    {purchase ? purchase.provider : nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.status}</Header>
                                <Typography variant='body2'>
                                    <StatusData id={purchase ? purchase.purchase_order_status_id : null} catalogs={catalogs.purchases_orders_statuses} />
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.file}</Header>
                                <Grid container >
                                    <Grid item><IconButton style={{color:'black'}} onClick={()=>actions.onChangeModalStatus('upload_file', true)}><Icon >cloud_upload</Icon></IconButton></Grid>
                                    <Grid item><IconButton color='primary' onClick={actions.onDownloadFile} disabled={!(purchase && isValidDocument(purchase.file))}><Icon >cloud_download</Icon></IconButton></Grid>
                                    <Grid item><IconButton color='primary' onClick={actions.onDeleteFile} disabled={!(purchase && isValidDocument(purchase.file)) || !can_edit}><Icon >clear</Icon></IconButton></Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                {status === PURCHASES_ORDER_STATUSES.CANCELLED ? null : 
                                <Button className={classes.next_button} disabled={system.next_sending || !can_edit || status === PURCHASES_ORDER_STATUSES.RECEIVED} onClick={()=>actions.onClickNextButton(status)} >
                                    {status !== -1 ? content.general.next_button[status-1] : nodata}
                                    {system.next_sending ? (<CircularProgress size={24} className={classes.progress}/>) : null}
                                </Button>}
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container alignItems='center' spacing={2} >
                        <Grid item md={3} xs={12}>
                            <div className={classes.price_container}>
                            <Header>{content.general.subtotal}</Header>
                            <Typography className={classes.price} variant='body2'>
                                {purchase ? onGetCurrencyValue(purchase.subtotal) : nodata}
                            </Typography>
                            </div>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <div className={classes.price_container}>
                            <Header>{content.general.taxes}</Header>
                            <Typography className={classes.price} variant='body2'>
                                {purchase ? onGetCurrencyValue(purchase.tax) : nodata}
                            </Typography>
                            </div>
                        </Grid>
                        <Grid item xs />
                        <Grid item >
                            <div >
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item><Typography style={{fontWeight:700}} color='primary'>{content.general.total}</Typography></Grid>
                                    <Grid item><Typography variant='subtitle1'>{purchase ? onGetCurrencyValue(purchase.total) : nodata}</Typography></Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </SimpleCard>
                </Grid>
                <Grid item xs={12}>
                    <Products can_edit={can_edit} status={status} content={content.products} data={purchase ? purchase.purchase_order_items : []}
                        onAdd={()=>actions.onChangeModalStatus('add_purchase_item', true)} onDelete={actions.onDeletePurchaseItem}
                        />
                </Grid>
            </Grid>
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user:state.user
    }
}

export default connect(mapStateToProps)(PurchaseView) 

const useStyles = makeStyles(theme => ({
    topbar:{
        marginBottom:20
    },
    container:{
        paddingBottom:48,
        borderBottom:'1px solid #D2D2D2',
        marginBottom:20
    },
    price_container:{
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end'
        }
    },
    price:{
        [theme.breakpoints.down('sm')]:{
            marginLeft:8
        }
    },
    next_button:{
        background:theme.palette.primary.dark,
        padding:'12px 20%',
        color:'white',
        textTransform:'none',
        '&:hover':{
            background:theme.palette.primary.main,
            color:'white'
        },
        '&:disabled':{
            //background:'#909090',
            //color:'rgba(54, 54, 54, 0.5)'
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
}))
import { Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import DeleteButton from "../../../../../../components/Actions/DeleteButton"
import RoundedButton from "../../../../../../components/Actions/RoundedButton"
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"


const EquivalenceTable = props => {

    const { content, rows, onAdd, onDelete, can_edit } = props
    const classes = useStyles()

    console.log(rows)

    return(
        <SimpleCard>
            <Grid container justifyContent='space-between'>
                <Grid item>
                    <Typography variant='subtitle2'>{content.title}</Typography>
                </Grid>
                {can_edit ? <Grid item>
                    <RoundedButton onClick={onAdd} >{content.button}</RoundedButton>
                </Grid> : null}
            </Grid>
            {rows && rows.length ?
                <div className={classes.rows}>
                    <div className={classes.header}>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>{content.id}</Grid>
                        <Grid item xs={2}>{content.code}</Grid>
                        <Grid item xs={2}>{content.brand}</Grid>
                        <Grid item xs>{content.description}</Grid>
                    </Grid>
                    </div>
                    {rows.map(item => {
                        return(
                            <Row {...item} key={item.code} onDelete={can_edit ? ()=>onDelete(item.id_equivalence) : null}  />
                        )
                    })}
                </div>
            : null}
        </SimpleCard>
    )
}

export default EquivalenceTable

const useStyles = makeStyles(theme => ({
    rows:{
        marginTop:40
    },
    header:{
        font:theme.typography.fontFamily,
        fontSize:'0.875rem',
        color:'#909090',
        fontWeight:500
    },
}))

const Row = ({product_code, product_name, onDelete, brand, product_id}) => {

    const classes = useStylesRow()

    return(
        <div className={classes.root}>
            <Grid container spacing={3} alignItems='center'>
                <Grid item xs={2}>{product_id}</Grid>
                <Grid item xs={2}>{product_code}</Grid>
                <Grid item xs={2}>{brand}</Grid>
                <Grid item xs>{product_name}</Grid>
                {onDelete ? <Grid item><DeleteButton onClick={onDelete} /></Grid> : null}
            </Grid>
        </div>
    )
}

const useStylesRow = makeStyles(theme => ({
    root:{
        borderBottom:'1px solid #D2D2D2',
        padding:'16px 0px',
        font:theme.typography.fontFamily,
        fontWeight:500
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
    },
    
}))




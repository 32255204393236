import { Grid, Icon, makeStyles, Typography } from "@material-ui/core"
import { amber, yellow } from "@mui/material/colors"
import { useState } from "react"
import ActionModalBar from "../../../../components/Actions/ActionModalBar"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import DisplayError from "../../../../components/Structure/DisplayData/DisplayError"
import { private_server } from "../../../../config"
import { onGetErrorMessage } from "../../../../shared/utility"
import system_translations from "../../../../texts/system_translations"


const CancelReceiptModal = ({open, onClose, language, receipt_type_id, order, onUpdateOrder}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.cancel_receipt

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const onSubmit = async() =>{
        
        if(receipt_type_id === 3){
            //cancelar factura
            setLoading(true)
            try{
                await private_server.post(`/order/${order.id_order}/invoice/cancel/me`)
                onUpdateOrder()
                onClose()
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        }
        if(receipt_type_id === 2){
            setLoading(true)
            try{
                await private_server.delete(`/nfreceipt/${order.nf_receipt_id}`)
                onUpdateOrder()
                onClose()
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        }
        if(receipt_type_id === 1){
            setLoading(true)
            try{
                await private_server.delete(`/receipt/${order.receipt_id}`)
                onUpdateOrder()
                onClose()
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            setLoading(false)
        }
    }

    return(
        <SimpleModal open={open} onClose={onClose}  >
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <div className={classes.icon_container}>
                                    <Icon fontSize='large' className={classes.icon}>warning</Icon>
                                </div>                       
                            </Grid>
                            <Grid item>
                                <Typography align='center'>{receipt_type_id === 3 ? content.invoiced_message : content.receipt_message}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.button_container}>
                            <DisplayError>{error}</DisplayError>
                            <RoundedButton loading={loading} onClick={onSubmit}>{content.button}</RoundedButton>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )

}

export default CancelReceiptModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    icon:{
        color:'white'
    },
    icon_container:{
        borderRadius:'50%',
        background:amber[500],
        padding:12
    },
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #363636',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:'#363636'
    },
    button_container:{
        textAlign:'center',
        margin:'12px 0px 0px'
    },

    
}))
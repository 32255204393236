import { Grid, makeStyles, Typography } from "@material-ui/core"
import MonthFilter from "../../../../../../components/Actions/MonthFilter"
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"


const ShoppingGraph = ({data, x_scale, limit}) => {

    const classes = useStyles()

    return(
        <div >
            <Typography variant='subtitle2'>Compras</Typography>
            <div className={classes.filters}>
                <Grid container spacing={2}>
                    <Grid item><MonthFilter /></Grid>
                </Grid>
            </div>
            <SimpleCard>
                <div className={classes.main}>
                    <div className={classes.root}>
                        <div className={classes.months}>
                            <Grid container direction='column' alignItems='center' spacing={5} >
                                {data.map(item => {
                                    return(
                                        <Grid item key={item.month}> <div>{item.month}</div></Grid>                                  
                                    )
                                })}
                            </Grid>
                        </div>
                        <div className={classes.graph}>
                            <div className={classes.scale}>
                                <Grid container justifyContent='space-between'>
                                    {x_scale.map(item =>{
                                        return(
                                            <Grid item key={item}>
                                                {item}                                    
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </div>
                            <div className={classes.amounts_container}>
                                <Grid container direction='column' spacing={5} >
                                    {data.map(item => {
                                        const width = (item.amount / limit) *100
                                        return(
                                            <Grid item key={item.amount}>
                                                <div style={{width:`${width}%`, background:'red', borderRadius:'0px 15px 15px 0px', color:'red'}}>
                                                    {item.amount}
                                                </div>
                                            </Grid>                                  
                                        )
                                    })}
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </SimpleCard>
        </div>
    )

}

export default ShoppingGraph

const useStyles = makeStyles(theme => ({
    main:{
        overflowY:'hidden',
        overflowX:'auto'
    },
    root:{
        display:'flex',
        minWidth:750
        
    },
    months:{
        width:'15%',
        //background:'red',
        padding:'40px 0px',
        //boxSizing:'border-box'
    },
    graph:{
        width:'85%',
        //background:'green',
        //height:500,
        position:'relative',
        //boxSizing:'border-box'
    },
    scale:{
        position:'absolute',
        bottom:0,
        left:0,
        width:'100%',
        //background:'blue',
        //height:'100%',
        //textAlign:'center'
    },
    graph_item:{
        height:'100%',
        width:'100%',
        borderLeft:'1px dashed #DDDDDD',
        marginLeft:4
        //background:'pink'
    },
    label:{
        width:'100%'
    },
    amounts_container:{
        padding:'40px 0px'
    },
    amount:{
        background:'red',
        width:'50%'
    },
    filters:{
        margin:'20px 0px'
    }
}))
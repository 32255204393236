import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { isValidDocument, onGetCurrencyValue, onGetDateFormat, onGetFullname } from '../../../../shared/utility'
import { blue, green, grey, orange, red } from '@material-ui/core/colors'
import SearchBar from '../../../../components/Actions/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import empty from '../../../../assets/placeholders/nopurchases.png'
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName'
import DeleteButton from '../../../../components/Actions/DeleteButton'
import tableStyles from '../../../../styles/tableStyles'
import StatusData from '../../../../components/Structure/DisplayData/StatusData'
import DownloadButton from '../../../../components/Actions/DownloadButton'
import { PURCHASES_ORDER_STATUSES } from '../../../../config'
import FilterButton from '../../../../components/Actions/FilterButton'
import MonthFilter from '../../../../components/Actions/MonthFilter'
import AddButton from '../../../../components/Actions/AddButton'


const SimpleTable = props => {

    const classes = tableStyles()
    const { content, catalogs, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, 
        onSelectedItem, loading, filter, onChangeStatusFilter, startDate, endDate, onChangeDate, onDownloadFile, onUpdateSearch} = props

    const header = content.header

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container  spacing={2} alignItems='center' > 
                    <Grid item><FilterButton options={catalogs.purchases_orders_statuses} selected={filter} onChange={onChangeStatusFilter} /></Grid>
                    <Grid item><MonthFilter value={startDate} onChange={(value)=>onChangeDate(value,'start_date')} /></Grid>
                    <Grid item>-</Grid>
                    <Grid item><MonthFilter value={endDate} onChange={(value)=>onChangeDate(value,'end_date')} /></Grid>
                    <Grid item xs></Grid>
                    <Grid item><SearchBar value={search} onChange={onChangeSearch} onUpdateSearch={onUpdateSearch} /></Grid>
                    {onAddRegister ? <Grid item><AddButton variant='table' onClick={onAddRegister}/></Grid> : null}    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}          
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell padding='default' className={classes.cellHeader} key={item.id.toString()}>
                                                <Typography align={item.id === 6 ? 'center' : 'left'} variant='subtitle2'>{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {id_purchase_order, ordered_date, provider, purchase_order_status_id, total, file} = item

                                return(
                                    <TableRow key={key.toString()} className={classes.row} >
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(id_purchase_order)}><Typography>{id_purchase_order}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(id_purchase_order)}><Typography>{onGetDateFormat(ordered_date, 'DD/MM/YYYY')}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(id_purchase_order)}><Typography>{provider}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(id_purchase_order)}><StatusData id={purchase_order_status_id} catalogs={catalogs.purchases_orders_statuses} /></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(id_purchase_order)}><Typography>{onGetCurrencyValue(total)}</Typography></TableCell>
                                        <TableCell className={classes.cell_center} ><DownloadButton disabled={!isValidDocument(file)} onClick={()=>onDownloadFile(id_purchase_order)} /></TableCell>
                                    </TableRow>
                                )
                            })}
                            
                        </TableBody>
                    </Table>
                </div>           
            ): null}   
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>              
            ) : null}        
        </div>
    )
}

export default SimpleTable
import FileSaver from 'file-saver'
import axios from 'axios'
import { useEffect, useState } from "react"
import { ORDER_STATUSES, private_server, RETURN_ORDER_STATUSES } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import { getBottomNavigationActionUtilityClass } from '@mui/material'

const useSaleView = ({ history, match, language, onUpdatePayModal, onUpdateEditFlag}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [order, setOrder] = useState(null)
    const [client, setClient] = useState(null)

    const [receipt_type, setReceiptType] = useState(null)
    const [invoice_data, setInvoiceData] = useState(null)

    const [back_loading, setBackLoading] = useState(false)
    const [next_sending, setNextSending] = useState(false)
    const [invoice_loading, setInvoiceLoading] = useState(false)

    //return order
    const [return_orders, setReturnOrders] = useState([]);
    const [return_id, setReturnId] = useState(null);
    const [return_status, setReturnStatus] = useState(null);
    const [return_type, setReturnType] = useState(null);
    const [return_item_id, setReturnItemId] = useState(null);

    const [return_sending, setReturnSending] = useState(false);


    const [modals, setModals] = useState({
        receipt:false,
        select_client:false,
        download_doc:false,
        generate_invoice:false,
        cancel_receipt:false,
        add_client:false,
        cancel:false,
        delete_order:false,
        add_return_order:false,
        delete_return_order:false,
        cancel_return:false,
        add_return_item:false,
        delete_return_item:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`order/${id}`)
                const _order = req.data.data.order
                setOrder(_order)
                if(_order && _order.client_id){
                    const req_client = await private_server.get(`client/${_order.client_id}`)
                    setClient(req_client.data.data.client)
                }  
                const req_return = await private_server.get(`returnorder/all/?filter={"AND":[{"field":"order_id","operator":"=","value":"${id}"}]}`)
                //console.log(req_return)
                setReturnOrders(req_return.data.data.return_orders)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onClickNextButton:async (status) =>{
            if(status === ORDER_STATUSES.CREADED){
                //actions.onChangeModalStatus('receipt', true)
                onUpdatePayModal(true)
            }
            if(status === ORDER_STATUSES.PAID){
                try {
                    //setSending(true)
                    setNextSending(true)
                    updateAuthorizationHeader(private_server)
                    const new_order = await private_server.post(`/order/${id}/deliver/me`)
                    //console.log(req)
                    setOrder(new_order.data.data.order)
                    setError(null)
                    //setSending(false)
                } catch (error) {
                    //setSending(false)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setNextSending(false)
            }
        },
        onClickBackButton:async (status) =>{
            if(status === ORDER_STATUSES.CREADED){
                try {
                    setBackLoading(true)
                    updateAuthorizationHeader(private_server)
                    const new_order = await private_server.post(`/order/${id}/cancel/me`)
                    //console.log(req)
                    setOrder(new_order.data.data.order)
                    setError(null)
                    setBackLoading(false)
                } catch (error) {
                    setBackLoading(false)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
            }
            if(status === ORDER_STATUSES.PAID){
                try {
                    setBackLoading(true)
                    updateAuthorizationHeader(private_server)
                    const new_order = await private_server.post(`/order/${id}/created/me`)
                    //console.log(req)
                    setOrder(new_order.data.data.order)
                    setError(null)
                    setBackLoading(false)
                } catch (error) {
                    setBackLoading(false)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
            }
            if(status === ORDER_STATUSES.DELIVERED){
                try {
                    setBackLoading(true)
                    updateAuthorizationHeader(private_server)
                    const new_order = await private_server.post(`/order/${id}/pay/me`)
                    //console.log(req)
                    setOrder(new_order.data.data.order)
                    setError(null)
                    setBackLoading(false)
                } catch (error) {
                    setBackLoading(false)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
            }
        },
        onGenerateReceipt: async(receipt_type_id) =>{
            //console.log(receipt_type)
            if(order.generated_receipt === 0 && order.invoiced === 0 ){
                if(receipt_type_id === 3){
                    //factura
                    console.log('factura')
                    actions.onChangeModalStatus('generate_invoice', true)
                }
                if(receipt_type_id === 2){
                    console.log('nota sin registro')
                    setInvoiceLoading(true)
                    try{
                        await private_server.post(`/nfreceipt/me`, {order_id:id})
                        actions.onUpdateOrder()
                    }catch(error){
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                    setInvoiceLoading(false)
                }
                if(receipt_type_id === 1){
                    console.log('nota foliada')
                    setInvoiceLoading(true)
                    try{
                        await private_server.post(`/receipt/me`, {order_id:id})
                        actions.onUpdateOrder()
                    }catch(error){
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                    setInvoiceLoading(false)
                }
            }else{
                if(receipt_type_id === 3){
                    //cancelar factura
                    setReceiptType(3)
                    actions.onChangeModalStatus('cancel_receipt', true)
                    /* setInvoiceLoading(true)
                    try{
                        await private_server.post(`/order/${id}/invoice/cancel/me`)
                        actions.onUpdateOrder()
                    }catch(error){
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                    setInvoiceLoading(false) */
                }
                if(receipt_type_id === 2){
                    console.log(' cancelar nota sin registro')
                    setReceiptType(2)
                    actions.onChangeModalStatus('cancel_receipt', true)
                    /* setInvoiceLoading(true)
                    try{
                        await private_server.delete(`/nfreceipt/${order.nf_receipt_id}`)
                        actions.onUpdateOrder()
                    }catch(error){
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                    setInvoiceLoading(false) */
                }
                if(receipt_type_id === 1){
                    console.log('cancelar nota foliada')
                    setReceiptType(1)
                    actions.onChangeModalStatus('cancel_receipt', true)
                    /* setInvoiceLoading(true)
                    try{
                        await private_server.delete(`/receipt/${order.receipt_id}`)
                        actions.onUpdateOrder()
                    }catch(error){
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                    setInvoiceLoading(false) */
                }
                /* else{
                    //cancelar recibo
                    setInvoiceLoading(true)
                    try{
                        await private_server.post(`/order/${id}/receipt/cancel/me`)
                        actions.onUpdateOrder()
                    }catch(error){
                        const _error = onGetErrorMessage(error, language)
                        setError(_error ? _error.message : null)
                    }
                    setInvoiceLoading(false)
                } */
            }      
        },
        onUpdateOrder: async() =>{ 
            const req = await private_server.get(`order/${id}`)
            const _order = req.data.data.order
            setOrder(_order)     
        },
        onDownloadReceipt: async(type_file) =>{
            if(order && Boolean(order.generated_receipt)){
                /* try{
                    await private_server.post(`/receipt`, {order_id:id})
                }catch(error){
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                } */
            }
            if(order && Boolean(order.invoiced)){
                console.log('invoice')
                try{
                    if(type_file === 'PDF'){
                        const pdf = await private_server.get(`/order/${id}/invoice/download/pdf/me`, {responseType: 'blob'})
                        FileSaver.saveAs(pdf.data, `factura`)
                    }else{
                        const xml = await private_server.get(`/order/${id}/invoice/download/xml/me`, {responseType: 'blob'})
                        FileSaver.saveAs(xml.data, `factura`)
                    }                   
                }catch(error){
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
            }
        },
        onGetInvoiceData: (data) =>{
            setInvoiceData(data)
        },
        onGetReceiptType: (type) =>{
            setReceiptType(type)
        },
        onClearError:()=>{
            setError(null)
        },
        onAddClientCompleted: async(id_client) =>{
            try{
                const req_client = await private_server.get(`client/${id_client}`)
                setClient(req_client.data.data.client)
            }catch(error){
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            
        },
        onEditProducts: () => {
            onUpdateEditFlag(true)
            history.push(`/order/${id}`)
        },
        onReturnCompleted: async () =>{
            try{
                const req_return = await private_server.get(`returnorder/all/?filter={"AND":[{"field":"order_id","operator":"=","value":"${id}"}]}`)
                setReturnOrders(req_return.data.data.return_orders)
            }catch (error) {
                //setSending(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onClickNextButtonReturn:async (status, return_id) =>{
            if(status === RETURN_ORDER_STATUSES.CREADED){
                setReturnSending(true)
                try{
                    updateAuthorizationHeader(private_server)
                    await private_server.post(`/returnorder/${return_id}/complete`)
                    const req_return = await private_server.get(`returnorder/all/?filter={"AND":[{"field":"order_id","operator":"=","value":"${id}"}]}`)
                    setReturnOrders(req_return.data.data.return_orders)
                    actions.onInitModule()
                }catch (error) {
                    //setSending(false)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setReturnSending(false)
            }
            if(status === RETURN_ORDER_STATUSES.COMPLETED){
                setReturnSending(true)
                try {
                    updateAuthorizationHeader(private_server)
                    await private_server.post(`/returnorder/${return_id}/cancel`)
                    const req_return = await private_server.get(`returnorder/all/?filter={"AND":[{"field":"order_id","operator":"=","value":"${id}"}]}`)
                    setReturnOrders(req_return.data.data.return_orders)
                    setError(null)
                    //setSending(false)
                } catch (error) {
                    //setSending(false)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setReturnSending(false)
            }
            if(status === RETURN_ORDER_STATUSES.CANCELLED){
                setReturnSending(true)
                try {
                    updateAuthorizationHeader(private_server)
                    await private_server.post(`/returnorder/${return_id}/created`)
                    const req_return = await private_server.get(`returnorder/all/?filter={"AND":[{"field":"order_id","operator":"=","value":"${id}"}]}`)
                    setReturnOrders(req_return.data.data.return_orders)
                    setError(null)
                    //setSending(false)
                } catch (error) {
                    //setSending(false)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setReturnSending(false)
            }
        },
        onClickBackButtonReturn: (status, value_id) =>{
            setReturnStatus(status)
            setReturnId(value_id)
            actions.onChangeModalStatus('cancel_return', true)
        },
        onDeleteReturnOrder: (value_id) =>{
            setReturnId(value_id)
            actions.onChangeModalStatus('delete_return_order', true)
        },
        onAddReturnItem: (value_id, type) =>{
            setReturnId(value_id)
            setReturnType(type)
            actions.onChangeModalStatus('add_return_item', true)
        },
        onDeleteReturnItem : (value_id) =>{
            setReturnItemId(value_id)
            actions.onChangeModalStatus('delete_return_item', true)
        }
        
    }

    const system = {loading, sending, error, back_loading, next_sending, invoice_loading, return_sending}
    const view_data = {order, client, receipt_type, return_orders, return_id, return_status, return_type, return_item_id}

    return {system, modals, view_data, actions}
}

export default useSaleView
import { useState } from 'react';
import { connect } from 'react-redux';
import PageAdmin from '../../components/Structure/Layouts/PageAdmin';
import TopbarMenu from "../../components/Structure/Nav/TopbarMenu"
import ComingSoon from '../../components/Temp/ComingSoon';
import system_translations from "../../texts/system_translations"
import useAnalyticalsView from './useAnalyticalsView';
import General from './views/General/General';
import Products from './views/Products/Products';


const ProductView = ({language}) => {

    const content = system_translations[language].views.analyticals
    const {system, modals, actions, antotal, user_data, users, month_selected, year_selected} = useAnalyticalsView({language, content})

    const [view, setView] = useState(1)

    let contentView = null

    switch(view){
        case 1:
            contentView = <General {...antotal[month_selected]} content={content.general} user_data={user_data} users={users} actions={actions}
                user_loading={system.user_loading} total_data={antotal} total_loading={system.total_loading} month_selected={month_selected}
                year_selected={year_selected} />
            break
        case 2:
            contentView = <Products content={content.products} language={language} />
            break
        default:
            break
    }
  
    return(
        <PageAdmin title={content.title} language={language} loading={system.loading} >
            <div style={{marginTop:8}} />
            <TopbarMenu selected={view} menuOptions={content.menu} onChange={(id)=>setView(id)} />
            {contentView}
        </PageAdmin>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(ProductView) 
import { Icon, IconButton, makeStyles } from "@material-ui/core"
import cx from 'classnames'

const AddButton = ({size, onClick, color, variant}) =>{

    const classes = useStyles()

    return(
        <IconButton onClick={onClick} size={size ? size : 'small'} 
            className={cx({
                [classes.root]:true, 
                [classes.primary]: color === 'primary',
                [classes.table]: variant === 'table'})}>
            <Icon >add</Icon>
        </IconButton>
    )

}

export default AddButton

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.main
        }
    },
    primary:{
        background:theme.palette.primary.dark,
    },
    table:{
        background:theme.palette.primary.main,
        fontSize:28,
        width:40,
        height:40
    }
    
}))
import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from '../../../../../../../components/Actions/RoundedButton'
import InputForm from "../../../../../../../components/Forms/InputForm"
import SimpleModal from "../../../../../../../components/Modals/SimpleModal"
import AvatarPicture from '../../../../../../../components/Structure/DisplayData/AvatarPicture'
import system_translations from "../../../../../../../texts/system_translations"
import DisplayError from '../../../../../../../components/Structure/DisplayData/DisplayError'
import useAddBrandModal from "./useAddBrandModal"
import ChangeImageModal from "./ChangeImageModal"
import Header from "../../../../../../../components/Structure/Texts/Header"



const AddBrandModal = ({open, onClose, language, onCompleted, id_product}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.brand_modal
    const {loading, sending, error, form, actions, img_modal, crop_image} = useAddBrandModal({open, language, content, onCompleted, onClose, id_product})

    return(
        <SimpleModal open={open} onClose={onClose}>
            <ChangeImageModal open={img_modal} onClose={()=>actions.onChangeImgModal(false)} language={language} 
                onUpdateDataImage={actions.onUpdateDataImage}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.add.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item>
                            <AvatarPicture size={120} src_temp={crop_image} onChangePicture={()=>actions.onChangeImgModal(true)} 
                             nopicture={content.nopicture}/>
                        </Grid>
                        <Grid item xs>
                            <Header>{content.form.brand.tag}</Header>
                            <InputForm data={form.name} onChange={actions.onChangeForm} />
                        </Grid>             
                    </Grid>      
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.form.description.tag}</Header>
                    <InputForm data={form.description} onChange={actions.onChangeForm} />
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton onClick={actions.onSubmit} loading={sending} >{content.add.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddBrandModal

const useStyles = makeStyles(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    pic_container:{
        position:'relative'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    }
    
}))
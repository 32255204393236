import moment from "moment"
import axios from 'axios'
import FileSaver from 'file-saver'
import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import queryString from 'query-string'


const usePurchasesView = ({ history, language }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [purchases, setPurchases] = useState([])
    
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_purchase_order'})
    const [search, setSearch] = useState('')

    const [status_filter, setStatusFilter] = useState(-1)
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))

    const [modals, setModals] = useState({
        add_purchase:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onConvertDateToUtc = (_date) =>{
        if(!_date) return null
        const momentdata = moment(_date)
        if(!momentdata.isValid()) return null

        return moment(_date).add(6, 'hours').format("YYYY-MM-DD h:mm:ss")
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                /* let req
                if(status_filter !== -1){
                    const filter = {filter:{
                        "AND":[
                            {"field":"purchase_order_status_id","operator":"=","value":`${status_filter}`},
                            {"field":"start_date","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
                            {"field":"start_date","operator":"<=","value":`${onConvertDateToUtc(endDate)}`}
                        ]
                    }}
                    //req = await private_server.get(`purchaseorder/all?filter={"AND":[{"field":"purchase_order_status_id","operator":"=","value":"${status_filter}"}]}`, {params:{...tableFilter}})
                    req = await private_server.get(`purchaseorder/all`, {params:{...tableFilter, ...filter}})
                }else{
                    const filter = {filter:{
                        "AND":[
                            {"field":"start_date","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
                            {"field":"start_date","operator":"<=","value":`${onConvertDateToUtc(endDate)}`},
                        ]
                        
                    }}
                    req = await private_server.get(`purchaseorder/all`, {params:{...params, ...filter}})
                } */

                //new
                let filter = [
                    {"field":"start_date","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
                    {"field":"start_date","operator":"<=","value":`${onConvertDateToUtc(endDate)}`},
                ]

                if(status_filter !== -1){
                    filter.push({"field":"purchase_order_status_id","operator":"=","value":`${status_filter}`})
                }
        
                if(search){
                    filter.push({"field":"provider","operator":"LIKE", "value":`%${search}%`})
                }
        
                let finalArgs = {"AND":filter}
                const params2send = `?${queryString.stringify(params)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
                
                const req = await private_server.get(`purchaseorder/all${params2send}`)

                const _purchases = req.data.data
                setPurchases(_purchases.purchase_orders)
                setTotal(_purchases.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onSelectItem: (id) => {
            history.push(`purchases/${id}`)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onResetTableFiler: () => setTableFilter({limit:10, offset:0, order:1, order_by:'id_purchase_order'}),
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeDate: (value, id) => {
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            let aux
            if(id === 'start_date'){
                aux = moment(value)
                console.log()
                if(moment(endDate).diff(aux) > 0) setStartDate(value)
            }
            if(id === 'end_date'){
                aux = moment(value)
                if(aux.diff(moment(startDate)) > 0) setEndDate(value)
            }
        },
        onChangeTableFilter: (newTableFilter) => { 
            //let newTableFilter = {...tableFilter}
            //newTableFilter.offset = 0
            setTableFilter(newTableFilter)
        },
        onChangeStatusFilter: (new_status) =>{
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            setStatusFilter(new_status)
        },
        onCompleted:async () =>{
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`purchaseorder/all`, {params:{...tableFilter}})
                console.log(req)
                const _purchases = req.data.data
                setPurchases(_purchases.purchase_orders)
                setTotal(_purchases.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onDownloadFile: async(id) => {
            try {
                const url = await private_server.get(`purchaseorder/${id}/file?name=file`)
                delete axios.defaults.headers.common["Authorization"]
                const response = await axios.get(url.data.data.url, {responseType: 'blob'})   
                FileSaver.saveAs(response.data, `file`)
            }catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onClearError:()=>{
            setError(null)
        }
    }

    const system = {loading, sending, error}
    const view_data = {purchases, total, tableFilter, search, status_filter, endDate, startDate}

    return {system, modals, view_data, actions}
}

export default usePurchasesView
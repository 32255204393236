import { Grid, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import MiniTableClient from "./MiniTableClientV2"


const Client = ({content, actions, client}) => {

    //const data = {client:'Carlos Jimenez', user:'carlos@email.com', rfc:'1234KSAFSDF13', invoice_usage:'Gastos en genral'}

    return(
        <div>
            <Typography variant='h6'>{content.title}</Typography>
            <div style={{marginBottom:20}}>
            {/* <Grid container justifyContent='flex-end'>
                <Grid item>
                    <RoundedButton onClick={()=>actions.onChangeModalStatus('select_client', true)} >{content.button}</RoundedButton>
                </Grid>
            </Grid> */}
            </div>
            <MiniTableClient data={client} header={content.header}/>
        </div>
    )
}

export default Client
import { Avatar, Badge, Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import { yellow } from "@material-ui/core/colors"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard"
import UserAvatarName from "../../../../../components/Structure/DisplayData/UserAvatarName"
import {onGetCurrencyValue} from '../../../../../shared/utility'


const VendorsTable = props => {

    const { title, rows } = props
    const classes = useStyles()

    return(
        <SimpleCard>
            <Grid container >
                <Grid item>
                    <Typography variant='subtitle2'>{title}</Typography>
                </Grid>
                {/* <Grid item>
                    <RoundedButton onClick={onAdd}>{content.button}</RoundedButton>
                </Grid> */}
            </Grid>
            <div>
                <Grid container spacing={2} justifyContent='center' alignItems='center'>
                    <Grid item>
                        <div className={classes.avatar_container}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                 <Icon style={{color:yellow[700]}}>star</Icon>
                                }
                            >
                                <Avatar className={classes.avatar} src="/static/images/avatar/2.jpg" />
                            </Badge>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.main_avatar_container}>
                            <Typography className={classes.avatar_top} >Top 3</Typography>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                 <Icon style={{color:yellow[700]}}>star</Icon>
                                }
                            >
                                <Avatar className={classes.main_avatar} src="/static/images/avatar/2.jpg" />
                            </Badge>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.avatar_container}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                 <Icon style={{color:yellow[700]}}>star</Icon>
                                }
                            >
                                <Avatar className={classes.avatar} src="/static/images/avatar/2.jpg" />
                            </Badge>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {rows && rows.length ?
                <div className={classes.rows}>
                    {rows.map(item => {
                        return(
                            <Row {...item} key={item.code}  />
                        )
                    })}
                </div>
            : null}
        </SimpleCard>
    )
}

export default VendorsTable

const useStyles = makeStyles(theme => ({
    rows:{
        marginTop:40
    },
    avatar_top:{
        color:theme.palette.primary.dark,
        fontWeight:700,
        fontSize:'0.8rem',
        marginBottom:8
    },
    main_avatar:{
        width:84,
        height:84
    },
    main_avatar_container:{
        textAlign:'center',
        //background:'green'
    },
    avatar_container:{
        paddingTop:20
    },
    avatar:{
        width:58,
        height:58
    }
}))

const Row = ({name, quantity}) => {

    const classes = useStylesRow()

    return(
        <div className={classes.root}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item ><UserAvatarName name={name} caption /></Grid>
                <Grid item >
                    <Typography className={classes.quantity} >{onGetCurrencyValue(quantity)}</Typography>  
                </Grid>
            </Grid>
        </div>
    )
}

const useStylesRow = makeStyles(theme => ({
    root:{
        borderBottom:'1px solid #D2D2D2',
        padding:'8px 0px',
        font:theme.typography.fontFamily,
        fontWeight:500,
        //color:theme.palette.primary.main
    },
    quantity:{
        color:theme.palette.primary.main,
        fontSize:'1.1rem',
        fontWeight:600
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
    },
    
}))




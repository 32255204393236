import { Grid, InputBase, makeStyles, Typography } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

const MonthFilter = props => {

    const classes = useStyles()
    const {id, label, value, onChange} = props

    return(
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
                <Typography variant='caption' className={classes.subtitle}>{label}</Typography>
                <div className={classes.root} >
                    <Grid container direction='column'>
                        {/* <Grid item >
                            <Typography variant='caption' className={classes.subtitle}>{label}</Typography>
                        </Grid> */}
                        <Grid item >
                            <InputBase value={value} type='date' fullWidth onChange={(e) => onChange(e.currentTarget.value, id)}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default MonthFilter

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.2)',
        padding:'4px 16px',
        borderRadius:40,
        '&:hover':{
            cursor:'pointer'
        }
    },
    icon:{
        color:blueGrey[500],

    },
    subtitle:{
        paddingLeft:0,
        paddingBottom:0,
        color:theme.palette.primary.main
    },
    popper:{
        marginTop:8,
        padding:'16px 8px',
        paddingLeft:0,
        borderRadius:32,
        width:200,
        boxSizing:'border-box',
        overflow:'hidden'
    },
    container:{
        maxHeight:250,
        //background:'red',
        overflowX:'hidden',
        overflowY:'auto',
        
    },
    item:{
        fontFamily: theme.typography.fontFamily,
        padding:"12px 16px",
        borderTop:`1px solid ${grey[200]}`,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected:{
        fontWeight:500
    }
}))
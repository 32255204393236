import { Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import DeleteButton from "../../../../components/Actions/DeleteButton"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import { onGetCurrencyValue } from "../../../../shared/utility"
import miniTableStyles from "../../../../styles/miniTableStyles"


const MiniTable = props => {

    const { content, rows, onAdd , onDelete, header} = props
    const classes = miniTableStyles()

    console.log(header)

    return(
        
        <SimpleCard>     
            <div className={classes.root}> 
            {rows && rows.length ?
                <div className={classes.rows}>
                    <div className={classes.row_header}>
                    <Grid container spacing={3}>
                        {/* {header.map(item => {
                            if(item === ''){
                                return(
                                    <Grid item xs={1} key={item} >
                                        <Typography className={classes.header} variant="caption" >{item}</Typography>
                                    </Grid>
                                )
                            }else{
                                return(
                                    <Grid item xs key={item} >
                                        <Typography className={classes.header} variant="caption" >{item}</Typography>
                                    </Grid>
                                )
                            }
                        })} */}
                        <Grid item xs>
                            <Typography className={classes.header} variant="caption" >{header[0]}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.header} variant="caption" >{header[1]}</Typography>
                        </Grid>
                        <Grid item xs  >
                            <div style={{minWidth:300}}><Typography className={classes.header} variant="caption" >{header[2]}</Typography></div>
                        </Grid>
                        <Grid item xs>
                            <div style={{textAlign:'center'}} ><Typography className={classes.header} variant="caption" >{header[3]}</Typography></div>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.header} variant="caption" >{header[4]}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.header} variant="caption" >{header[5]}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.header} variant="caption" >{header[6]}</Typography>
                        </Grid>
                    </Grid>
                    </div>
                    {rows.map(item => {
                        return(
                            <Row {...item} key={item.id} onDelete={onDelete ? ()=>onDelete(item.id_purchase_order_item) : null}  />
                        )
                    })}
                </div>
            : null}
           </div>
        </SimpleCard>
        
    )
}

export default MiniTable

const useStyles = makeStyles(theme => ({
    root:{
        //minWidth:600,
        
        /* overflowY:'hidden',
        [theme.breakpoints.down('sm')]:{
            overflowX:'auto',
        } */
    },
    rows:{
        marginTop:40,
        //width:'100%',   
        [theme.breakpoints.down('sm')]:{
            minWidth:600,
        }
        //overflowX:'auto'
    },
    header:{
        font:theme.typography.fontFamily,
        fontSize:'0.875rem',
        color:'#909090',
        fontWeight:500
    },
}))

const Row = ({code, brand, product, price, quantity, total, onDelete}) => {

    const classes = miniTableStyles()

    return(
        <div className={classes.row_root}>
            <Grid container spacing={3} alignItems='center'>
                <Grid item xs>{code}</Grid>
                <Grid item xs>{brand}</Grid>
                <Grid item xs><div style={{minWidth:300}} >{product}</div></Grid>
                <Grid item xs><div style={{textAlign:'center'}}>{quantity}</div></Grid>
                <Grid item xs>{onGetCurrencyValue(price)}</Grid>
                <Grid item xs>{onGetCurrencyValue(total)}</Grid>
                <Grid item xs>{onDelete ? <DeleteButton onClick={onDelete}/> : null}</Grid>
            </Grid>
        </div>
    )
}

const useStylesRow = makeStyles(theme => ({
    root:{
        borderBottom:'1px solid #D2D2D2',
        padding:'8px 0px',
        font:theme.typography.fontFamily,
        fontWeight:500,
        
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
    },
    
}))




import { makeStyles, Typography } from "@material-ui/core";
import system_translations from "../../../texts/system_translations";



const UserType = ({language, id, size}) => {

    const classes = useStyles()
    const catalogs = system_translations[language].catalogs
    const status = catalogs.user_types.find(el=>el.value === id)

    return ( 
        <div className={classes.root} style={{background:status ? status.background : 'white', padding: size === 'small' ? '2px 16px' : '8px 16px'}}>
            <Typography variant={size==='small' ? 'caption' : 'body1' } >{status ? status.label : '-'}</Typography>
        </div>
    );
}
 
export default UserType;

const useStyles = makeStyles(theme => ({
    root:{
        background:'rgba(237, 28, 36, 0.07)',
        borderRadius:2,
        color:'#909090',
        padding:'8px 16px',
        //float:'left'
    },

}))
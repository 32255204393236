import { Grid, makeStyles, Typography } from "@material-ui/core"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import system_translations from "../../../../texts/system_translations"
import useOrdersClientModal from "./useOrdersClientModal"
import MiniTableV2 from "./MiniTableV2"



const OrdersClientModal = ({open, onClose, language, id, client, onCompleted, history}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.client_modal
    const {loading, orders, actions} = useOrdersClientModal({open, language, id, content, onClose, onCompleted})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='md'  >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.orders.title}</Typography>
                </Grid>    
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1} style={{textAlign:'center'}} >
                        <Grid item xs={12}>
                            <Typography style={{fontWeight:900}} >{client?.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography >{client?.email}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MiniTableV2 header={content.orders.header} data={orders} history={history} onDownloadReceipt={actions.onDownloadReceipt} />
                </Grid>
            </Grid>       
        </SimpleModal>
    )

}

export default OrdersClientModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import system_translations from "../../../../../texts/system_translations"
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"
import InputForm from "../../../../../components/Forms/InputForm"
import useAddReturnItemModal from "./useAddReturnItemModal"
import { RETURN_ORDER_TYPE } from "../../../../../config"
import InputSelectForm from "../../../../../components/Forms/InputSelectForm"
import Header from "../../../../../components/Structure/Texts/Header"
import ProductSearchBar from "../../../../../components/Actions/ProductSearchBar"


const AddReturnItemModal = ({open, onClose, language, id, onCompleted, type, return_id}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.add_product_item
    const {loading, sending, error, form, actions, prod_form, max_quantity, options, loading_prod, search} = useAddReturnItemModal({open, language, onClose, id, onCompleted, content, type, return_id})

    


    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.current}</Header>
                    <InputSelectForm data={form.order_item_id} onChange={actions.onChangeForm} />
                </Grid>
                {type === RETURN_ORDER_TYPE.CHANGE ? <Grid item xs={12}>
                    <Header>{content.new}</Header>
                    {/* <ProductAutocompleteForm options={options} onChange={actions.onChangeProduct} 
                    onInputChange={actions.onInputChange} onSearchProduct={actions.onSearchProduct} loading={loading_prod}  /> */}
                    <ProductSearchBar options={options} value={search} loading={loading_prod} onSelectProduct={actions.onChangeProduct}
                    onChange={actions.onInputChange} onUpdateSearch={actions.onSearchProduct} />
                </Grid> : null}
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item><Typography variant="subtitle2">{content.form.quantity.tag}</Typography></Grid>
                        <Grid item xs={2}><InputForm data={form.quantity} onChange={actions.onChangeForm} /></Grid>
                        <Grid item><Typography variant="body2" style={{color:'#767676'}}>Máx. { max_quantity}</Typography></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton loading={sending} onClick={actions.onSubmit}>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddReturnItemModal

const useStyles = makeStyles(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    pic_container:{
        position:'relative'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    }
    
}))
import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onGetSelectedFormData,  onInitForm,  onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"


const useEditPurchaseModal = ({open, language, onCompleted, onClose, origin, content, id}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [options, setOptions] = useState([])

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
        if(!open){
            setForm(form_data)
            setError(null)
        }
    },  [open])

    let provider_form = {...form.provider_id}
    provider_form.options = [...options]
    
    const actions = {
        onInitModule: async() => {
            //console.log(origin)
            setLoading(true)
            try {
                /* const params = {limit:30, }
                const filter = {filter:
                    {"AND":[
                        {"field":"name","operator":"LIKE","value":`%${e.target.value}%`},
                    ]}           
                } */
                const req = await private_server.get(`/provider/all`)
                const cat = req.data.data.providers.map(item => {
                    return {value: item.id_provider, label: item.name}})
        
                let _form = onInitForm(form, origin, content.form)
                _form.provider_id.options = cat
                setForm(_form)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
            //setOptions([{value:origin.provider_id, label:origin.provider}])
            //let _form = onInitForm(form, origin, content.form)
            //_form.provider_id.options = [{value:origin.provider_id, label:origin.provider}]
            //setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSearchProvider: async(e) => {
            //console.log(e.target.value)
            //setOptions([])
            if(!e || e.target.value === "" || e.target.value === 0) return
            try {
                const params = {limit:30, }
                const filter = {filter:
                    {"AND":[
                        {"field":"name","operator":"LIKE","value":`%${e.target.value}%`},
                    ]}           
                }
                const req = await private_server.get(`/provider/all`, {params:{...params, ...filter}})
                const cat = req.data.data.providers.map(item => {
                    return {value: item.id_provider, label: item.name}})
        
                setOptions(cat)
            } catch (error) {
                console.log(error)
            }
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, origin)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/purchaseorder/${id}`, data2send)
                onCompleted()
                setError(null)
                setSending(false)
                onClose()

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, provider_form} 
}


export default useEditPurchaseModal

const form_data = {
    provider_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'provider_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },

}
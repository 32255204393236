import { Typography } from "@material-ui/core"
import { red } from "@material-ui/core/colors"


const DisplayError = (props) =>{

    return(
        <Typography variant='subtitle1' align="center" style={{marginBottom:20, color:red[500]}}>{props.children}</Typography>
    )
}

export default DisplayError
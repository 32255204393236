import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { generic_errors } from "../../../../texts/esp/genericErrors"
import system_translations from "../../../../texts/system_translations"

const usePaymentMethodModal = ({open, history, id_order, onUpdateOrderData}) => {


    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    

    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if(!open){
            actions.onInitModule()
        }
    }, [open])

    
    const actions = {
        onInitModule: async() => {
            setSelected(null)
        },
        onSelectedOption:(option) =>{
            setSelected(option)
        },
        onSubmit: async() => {
        
            const data2send = {payment_type_id:selected}
            console.log(data2send)

            /* try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/order/${id_order}`, data2send)  
                onUpdateOrderData(null)
                history.push('/')
                setError(null)
                setSending(false)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            } */
            
        }
    }

    return {loading, sending, error, actions, selected} 
}


export default usePaymentMethodModal

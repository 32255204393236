import { Typography } from "@material-ui/core"


const Header = (props) => {

    const { color, marginBottom } = props

    return(
        <div style={{marginBottom: marginBottom ? marginBottom : 8}}>
            <Typography variant='caption' style={{color: color ? color : '#909090'}}>{props.children}</Typography>
        </div>
    )
}

export default Header
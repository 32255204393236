import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import InputForm from "../../../../components/Forms/InputForm"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import Header from "../../../../components/Structure/Texts/Header"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import nopicture from '../../../../assets/placeholders/noprovider.png'
import system_translations from "../../../../texts/system_translations"
import useAddProviderModal from "./useAddProviderModal"
import ChangeImageModal from "./ChangeImageModal"
import DisplayError from "../../../../components/Structure/DisplayData/DisplayError"


const AddProviderModal = ({open, onClose, language, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.provider_modal
    const {loading, sending, error, form, actions, img_modal, crop_image} = useAddProviderModal({open, language, content, onCompleted, onClose})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm' >
            <ChangeImageModal open={img_modal} onClose={()=>actions.onChangeImgModal(false)} language={language} 
                onUpdateDataImage={actions.onUpdateDataImage}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.add.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item>
                            <AvatarPicture nopicture={nopicture} src_temp={crop_image} 
                            onChangePicture={()=>actions.onChangeImgModal(true)}  />                              
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Header>{content.form.name.tag}</Header>
                                    <InputForm data={form.name} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Header>{content.form.relation_started_at.tag}</Header>
                                    <InputForm data={form.relation_started_at} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Header>{content.form.description.tag}</Header>
                                    <InputForm data={form.description} onChange={actions.onChangeForm} />
                                </Grid>
                            </Grid>
                        </Grid>             
                    </Grid>      
                </Grid>
            </Grid>
            <div className={classes.contact_title}>
                <Grid container alignItems='baseline'>
                    <Grid item><Typography variant='subtitle2'>{content.contact}</Typography></Grid>
                    <Grid item xs><div className={classes.line} /></Grid>
                </Grid>
            </div>
            <div className={classes.contact_info}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Header>{content.form.contact_name.tag}</Header>
                        <InputForm data={form.contact_name} onChange={actions.onChangeForm} />
                    </Grid> 
                    <Grid item xs={6}>
                        <Header>{content.form.contact_email.tag}</Header>
                        <InputForm data={form.contact_email} onChange={actions.onChangeForm} />
                    </Grid> 
                    <Grid item xs={6}>
                        <Header>{content.form.contact_phone.tag}</Header>
                        <InputForm data={form.contact_phone} onChange={actions.onChangeForm} />
                    </Grid> 
                    <Grid item xs={6}>
                        <Header>{content.form.address.tag}</Header>
                        <InputForm data={form.address} onChange={actions.onChangeForm} />
                    </Grid> 
                </Grid>
            </div>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton onClick={actions.onSubmit} loading={sending}>{content.add.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddProviderModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { onGetCurrencyValue } from "../../../../../../shared/utility"
import cx from 'classnames'


const PaymentMethod = ({selected, onSelectedOption, content, total, payment_types}) => {

    const classes = useStyles()

    return(
        <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container justifyContent='center' spacing={3} alignItems='center'>
                        <Grid item><Typography style={{fontWeight:500}}>{content.subtitle}</Typography></Grid>
                        <Grid item><Typography variant='h6'>{onGetCurrencyValue(total)}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent='center' spacing={4} >
                        {payment_types.map(item => {
                            return(
                                <Grid item key={item}>
                                    <Button className={cx({[classes.option_button]:true, [classes.option_button_active]: selected === item.value})} 
                                    onClick={()=>onSelectedOption(item.value)}>{item.label}</Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default PaymentMethod

const useStyles = makeStyles(theme => ({
    option_button:{
        background:theme.palette.primary.light,
        border:'1px solid #B30A10',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:theme.palette.primary.dark
    },
    option_button_active:{
        background:theme.palette.primary.dark,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    
}))
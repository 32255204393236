import { useEffect, useState } from "react"
import { CONFIG_SETTINGS, private_server } from "../../../../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../../../shared/utility"
import { generic_errors } from "../../../../../../../texts/esp/genericErrors"


const useEditProductModal = ({open, origin, language, content, onCompleted, onClose}) => {

    //console.log(origin)
    const id = origin ? origin.id_product : null

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [modals, setModals] = useState({
        add_brand:false,
        change_image:false,
        add_purchasegroup:false,
    })

    //sat forms
    //const [sat_product, setSatProduct] = useState('vacuna')
    //const [sat_unit, setSatUnit] = useState('unidad')

    const [unit_cat, setUnitCat] = useState([{value:origin ? origin.sat_unit_code : null, label: origin ? origin.sat_unit_code : null}])
    const [product_cat, setProductCat] = useState([{value:origin ? origin.sat_product_code : null, label:origin ? origin.sat_product_code : null}])
  
    useEffect(() => {
        if(open){
            actions.onInitModule()
            setUnitCat([{value:origin ? origin.sat_unit_code : null, label: origin ? origin.sat_unit_code : null}])
            setProductCat([{value:origin ? origin.sat_product_code : null, label:origin ? origin.sat_product_code : null}])
        }
        if(!open){
            setError(null)
        }
    }, [origin, open])

    /* useEffect(() => {
        if(open){
            actions.onGetSatProductCatalogs()
        }
    }, [sat_product]) */

    /* useEffect(() => {
        let _form = {...form}
        _form.sat_unit_code.options = unit_cat
        setForm(_form)
    }, [unit_cat]) */

    let unit = {...form.sat_unit_code}
    unit.options = [...unit_cat]

    let prod = {...form.sat_product_code}
    prod.options = [...product_cat]
    
    const actions = {
        onInitModule: async() => {
            //cuando tenga los datos reales
            let _form = onInitForm(form, origin, content.form)
            //setForm(_form)
            //setSatProduct(origin.sat_product_code)
            //setSatUnit(origin.sat_unit_code)
            
            setLoading(true)
                try{
                const req_brands = await private_server.get(`/brand/all`)
                //console.log(req_brands.data.data.brands)
                //const brands_cat = onGetCatalogs(req_brands.data.data.brands, 'id_brand', 'name')
                const brands_cat = req_brands.data.data.brands.map(item => {
                    //const _src = `${CONFIG_SETTINGS.S3_SERVER_URL}${item.logo}`
                    return{
                        value: item.id_brand, label:item.name, src: item.logo, nopicture: content.nobrand
                    }
                })
                //console.log(brands_cat)
                //console.log(brand_cat)
                const req_groups = await private_server.get(`/purchasegroup/all`)
                const groups_cat = onGetCatalogs(req_groups.data.data.purchase_groups, 'id_purchase_group', 'name')
                if(origin.purchase_group_id === null){
                    _form.purchase_group_id.options = [...groups_cat]
                }else{
                    const delete_pg = [{value:-1, label:'Eliminar grupo de compra'}]
                    _form.purchase_group_id.options = [...delete_pg, ...groups_cat]
                }   
                /* const req_sat_unit = await private_server.get(`/product/sat/units?search=${origin.sat_unit_code}&limit=10`)
                const sat_unit_cat = onGetCatalogs(req_sat_unit.data.data.units.data, 'key', 'key')  
                const req_sat_products = await private_server.get(`/product/sat/products?search=${origin.sat_product_code}&limit=10`)
                const sat_products_cat = onGetCatalogs(req_sat_products.data.data.products.data, 'key', 'key') 
                //console.log(sat_unit_cat)
                _form.sat_unit_code.options = [...sat_unit_cat]
                _form.sat_product_code.options = [...sat_products_cat] */
                _form.brand_id.options = brands_cat     
                setForm(_form)     
                setLoading(false)
            }catch(error){
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            //console.log(re)
            //_form.brand_id.options = catalogs.brands
            //_form.sale_group_id.options = catalogs.sale_groups
            
        },
        /* onGetSatProductCatalogs: async () =>{
            try{
                let _form = {...form}
                const req_sat_products = await private_server.get(`/product/sat/products?search=${sat_product}&limit=10`)
                //console.log(req_sat_products)
                //const sat_products_cat = onGetCatalogs(req_sat_products.data.data.products.data, 'key', 'description')
                const sat_products_cat = req_sat_products.data.data.products.data.map(item => {
                    return{
                        value: item.description, label:item.key + ' - ' + item.description
                    }
                })
                //console.log(sat_products_cat)
                _form.sat_product_code.options = sat_products_cat
                setForm(_form)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onGetSatUnitCatalogs: async () =>{
            try{
                let _form = {...form}
                const req_sat_unit = await private_server.get(`/product/sat/units?search=${sat_unit}&limit=10`)
                //console.log(req_sat_products)
                //const sat_unit_cat = onGetCatalogs(req_sat_unit.data.data.units.data, 'key', 'description')
                
                const sat_unit_cat = req_sat_unit.data.data.units.data.map(item => {
                    return{
                        value: item.key, label:item.key
                    }
                })
                console.log(sat_unit_cat)
                console.log(sat_unit)
                _form.sat_unit_code.options = sat_unit_cat
                setForm(_form)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        }, */
        onChangeForm: async(data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            /* if(id === 'purchase_group_id'){
                if(temp[purchase_group_id].value == -1){
                    updateAuthorizationHeader(private_server)
                    await private_server.patch(`/product/${id_product}`, {purchase_group_id:'@null!!'}) 
                }
            } */
            if(id === 'retail_percentage'){
                temp['retail_price'].value = parseFloat(temp['cost'].value) + (parseFloat(temp['cost'].value) * (parseFloat(temp['retail_percentage'].value)/100))
            }
            if(id === 'wholesale_percentage'){
                temp['wholesale_price'].value = parseFloat(temp['cost'].value) + (parseFloat(temp['cost'].value) * (parseFloat(temp['wholesale_percentage'].value)/100))
            }
            if(id === 'retail_price'){
                temp['retail_percentage'].value = ( parseFloat(temp['retail_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
            }
            if(id === 'wholesale_price'){
                temp['wholesale_percentage'].value = ( parseFloat(temp['wholesale_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
            }
            if(id === 'cost'){
                //temp['wholesale_percentage'].value = ( parseFloat(temp['wholesale_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
                //temp['retail_percentage'].value = ( parseFloat(temp['retail_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
                temp['wholesale_price'].value = parseFloat(temp['cost'].value) * ( 1 + (parseFloat(temp['wholesale_percentage'].value) / 100) )
                temp['retail_price'].value = parseFloat(temp['cost'].value) * ( 1 + (parseFloat(temp['retail_percentage'].value) / 100) )
            }
            setForm(temp)
        },
        /* onChangeSatProductForm:(event, value)=>{
            if(event && event.type === 'click'){
                return
            }
            if(value !== '' && value !== null){
                setSatProduct(value)
            }
        },
        onChangeSatUnitForm:(event, value)=>{
            console.log(value)
            if(event && event.type === 'click'){
                return
            }
            if(value !== '' && value !== null){
                setSatUnit(value)
            }
        }, */
        onSearchSatUnit: async(e) => {
            if(!e || e.target.value === "" || e.target.value === 0) return
            if(error) setError(null)
            try {
                const req_sat_unit = await private_server.get(`/product/sat/units?search=${e.target.value}&limit=10`)
                //const sat_unit_cat = onGetCatalogs(req_sat_unit.data.data.units.data, 'key', 'key')
                const sat_unit_cat = req_sat_unit.data.data.units.data.map(item => {
                    return{
                        value: item.key, label:item.key + ' - ' + item.description
                    }
                })
                //console.log(sat_unit_cat)
                setUnitCat(sat_unit_cat)
                /* let _form = {...form}
                _form.sat_unit_code.options= [...sat_unit_cat]
                setForm(_form) */
            } catch (error) {
                console.log(error)
            }
        },
        onSearchSatProduct: async(e) => {
            if(!e || e.target.value === "" || e.target.value === 0) return
            if(error) setError(null)
            try {
                const req_sat_products = await private_server.get(`/product/sat/products?search=${e.target.value}&limit=10`)
                //console.log(req_sat_products)
                //const sat_products_cat = onGetCatalogs(req_sat_products.data.data.products.data, 'key', 'key')  
                const sat_products_cat = req_sat_products.data.data.products.data.map(item => {
                    return{
                        value: item.key, label:item.key + ' - ' + item.description
                    }
                }) 
                setProductCat(sat_products_cat)
                /* let _form = {...form}
                _form.sat_product_code.options= [...sat_products_cat]
                setForm(_form) */
            } catch (error) {
                console.log(error)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, origin)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                if(data2send.purchase_group_id && data2send.purchase_group_id === -1){
                    console.log('delete')
                    await private_server.patch(`/product/${id}`, {purchase_group_id:'@null!!'}) 
                    delete data2send.purchase_group_id
                    let temp = {...form}
                    temp.purchase_group_id.value = ''
                    setForm(temp)
                }
                await private_server.patch(`/product/${id}`, data2send)          
                onCompleted()
                onClose()
                setError(null)
                setSending(false)

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        },
    }

    return {loading, sending, error, form, actions, modals, unit, prod} 
}


export default useEditProductModal

const form_data = {
    code: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'code',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    location: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'location',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    description: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {type: 'none'}
    },
    brand_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'brand_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    purchase_group_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'purchase_group_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },
    quantity: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'quantity',
            type: 'number',
            //fullWidth: true,
        },
        rules: {type: 'none'}
    },
    min_quantity: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'min_quantity',
            type: 'number',
            //fullWidth: true,
        },
        rules: {type: 'none'}
    },
    max_quantity: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'max_quantity',
            type: 'number',
            //fullWidth: true,
        },
        rules: {type: 'none'}
    },
    cost: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'cost',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    wholesale_price: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'wholesale_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    retail_price: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'retail_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    wholesale_percentage: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'wholesale_percentage',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    retail_percentage: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'retail_percentage',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    sat_unit_code: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'sat_unit_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:4}
    },
    sat_product_code: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'sat_product_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },

}
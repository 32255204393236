import moment from "moment"
import FileSaver from 'file-saver'
import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import queryString from 'query-string'


const useSalesView = ({ history, language }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [orders, setOrders] = useState([])

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_order'})
    const [search, setSearch] = useState('')

    const [status_filter, setStatusFilter] = useState(-1)
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'))
    const [filters, setFilters] = useState({
        status:-1,
        receipt:-1
    })

    /* const date = "2022-03-07 00:40:35"
    //console.log(moment(date))
    const prueba = moment(date)
    const utc_date = moment(date).utc().format()
    console.log(moment.utc(date).local().format())
    console.log(prueba.utc().local().format()) */

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onConvertDateToUtc = (_date) =>{
        if(!_date) return null
        const momentdata = moment(_date)
        if(!momentdata.isValid()) return null

        return moment(_date).add(6, 'hours').format("YYYY-MM-DD h:mm:ss")
    }

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = tableFilter
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(params2send)}`

        let filtersArgs = [
            {"field":"start_date","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
            {"field":"start_date","operator":"<=","value":`${onConvertDateToUtc(endDate)}`}
        ]

        if(filters.status !== -1){
            filtersArgs.push(
                {"field":"order_status_id","operator":"=","value":`${filters.status}`},
                //{"field":"start_date","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
                //{"field":"start_date","operator":"<=","value":`${onConvertDateToUtc(endDate)}`}
            ) 
        }

        if(filters.receipt !== -1){
            filtersArgs.push(
                {"field":"receipt_type_id","operator":"=","value":`${filters.receipt}`},
            ) 
        }
    
        if(search)filtersArgs.push({"field":"delivery_man","operator":"LIKE","value":`%${search}%`})
            
        if(filtersArgs.length){
            let finalArgs = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                const req = await private_server.get(`order/all${params}`)
                /* if(status_filter !== -1){
                    const filter = {filter:{
                        "AND":[
                            {"field":"order_status_id","operator":"=","value":`${status_filter}`},
                            {"field":"start_date","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
                            {"field":"start_date","operator":"<=","value":`${onConvertDateToUtc(endDate)}`}
                        ]
                    }}
                    req = await private_server.get(`order/all`, {params:{...params, ...filter}})
                }else{
                    const filter = {filter:{
                                        "AND":[
                                            {"field":"start_date","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
                                            {"field":"start_date","operator":"<=","value":`${onConvertDateToUtc(endDate)}`}
                                        ]
                                        
                                    }}
                    req = await private_server.get(`order/all`, {params:{...params, ...filter}})
                } */
                //console.log(req)
                const _orders = req.data.data
                setOrders(_orders.orders)
                setTotal(_orders.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onSelectItem: (id) => {
            history.push(`sales/${id}`)
        },
        onChangeSearch: (value)=>{
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            setSearch(value)
        },
        onChangeStatusFilter: (new_status) =>{
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            setStatusFilter(new_status)
        },
        onUpdateFilters: (_key, _value) => {
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            setFilters({...filters, [_key]:_value})
        },
        onChangeTableFilter: (data) => { 
            setTableFilter(data)
        },
        onChangeDate: (value, id) => {
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            let aux
            if(id === 'start_date'){
                aux = moment(value)
                if(moment(endDate).diff(aux) > 0) setStartDate(value)
            }
            if(id === 'end_date'){
                aux = moment(value)
                if(aux.diff(moment(startDate)) > 0) setEndDate(value)
            }
        },
        onCompleted:async () =>{
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`user/all?&filter={"OR":[{"field":"order_status_id","operator":"LIKE","value":"2"}, {"field":"order_status_id","operator":"LIKE","value":"3"}]}`, {params:{...tableFilter}})
                console.log(req)
                const _orders = req.data.data
                setOrders(_orders.orders)
                setTotal(_orders.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onDownloadReceipt: async(generated_receipt, invoiced, id) =>{
            if(Boolean(generated_receipt)){
                /* try{
                    await private_server.post(`/receipt`, {order_id:id})
                }catch(error){
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                } */
            }
            if(Boolean(invoiced)){
                console.log('invoice')
                try{
                    const pdf = await private_server.get(`/order/${id}/invoice/download/pdf/me`, {responseType: 'blob'})
                    FileSaver.saveAs(pdf.data, `factura`)
                }catch(error){
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
            }
        },
        onClearError:()=>{
            setError(null)
        }
    }

    const system = {loading, sending, error}
    const view_data = {orders, total, tableFilter, search, status_filter, endDate, startDate, filters}

    return {system, view_data, actions}
}

export default useSalesView
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import { USER_TYPES } from '../../../config';
import system_translations from '../../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import AddPurchaseGroupModal from './modals/AddPurchaseGroupModal/AddPurchaseGroupModal';
import DeletePurchaseGroupModal from './modals/DeletePurchaseGroupModal';
import usePurchaseGroupsView from './usePurchaseGroupsView';


const PurchaseGroupsView = ({language, history, user}) => {

    const content = system_translations[language].views.sale_groups
    const {actions, modals, view_data, system} = usePurchaseGroupsView({history, language})
    
    let can_create = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_create = false

    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <AddPurchaseGroupModal language={language} open={modals.add_purchase} onClose={()=>actions.onChangeModalStatus('add_purchase', false)} 
                onCompleted={actions.onCompleted}/>
            <DeletePurchaseGroupModal language={language} open={modals.delete_purchase} onClose={()=>actions.onChangeModalStatus('delete_purchase', false)}
                onCompleted={actions.onCompleted} id={view_data.selected}/>
            <SimpleTable 
                loading={system.loading}
                content={content.table} 
                data={view_data.purchase_group}
                onSelectedItem={actions.onSelectItem}
                onAddRegister={can_create ? ()=>actions.onChangeModalStatus('add_purchase', true) : null}
                tableFilter={view_data.tableFilter}
                total={view_data.total}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                can_create={can_create}
                onUpdateSearch={actions.onResetTableFiler}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user:state.user
    }
}

export default connect(mapStateToProps)(PurchaseGroupsView) 
import nouser from '../../assets/placeholders/nouser.png'
import nobrand from '../../assets/placeholders/nobrand.png'

export const translations = {
    title:'Analíticos',
    nouser:nouser,
    menu:[{id:1, label:'General'}, {id:2, label:'Productos'}],
    general:{
        metrics:{
            title:'Métricas',
            balance:{
                title:'Balance',
                sales:'Ventas',
                return:'Devoluciones',
                purchase:'Compras'
            },
            sales:{
                title:'Ventas',
                sales_receipt:'Nota',
                sales_nf_receipt:'Nota no foliada',
                sales_invoice:'Factura'
            },
            upt:{
                title:'Upt',
                sales:'Vendidas',
                return:'Devueltas'
            }
        },
        historic:{
            title:'Histórico',
            header:[
                {id:1, label:'Mes'},
                {id:2, label:'UPT'},
                {id:3, label:'TPU'},
                {id:4, label:'Unidades vendidas'},
                {id:5, label:'Unidades devueltas'},
                {id:6, label:'Unidades compradas'},           
                {id:7, label:'Ventas nota'},
                {id:8, label:'Ventas sin folio'},
                {id:9, label:'Ventas factura'},
                {id:10, label:'Vendido'},
                {id:11, label:'Devuelto'},
                {id:12, label:'Comprado'},
                {id:13, label:'Balance'},  
                {id:14, label:'Costo'}, 
            ],
            filter_options:[
                {value:2022, label:'2022'},
                {value:2023, label:'2023'},
                {value:2024, label:'2024'},
                {value:2025, label:'2025'},
                {value:2026, label:'2026'},
                {value:2027, label:'2027'},
            ]
        },
        user:{
            title:'Usuario',
            header:[
                {id:1, label:'Mes'},
                {id:2, label:'UPT'},
                {id:3, label:'TPU'},
                {id:4, label:'Unidades vendidas'},
                {id:5, label:'Unidades devueltas'},          
                {id:7, label:'Vendido'},
                {id:8, label:'Devuelto'},
                {id:10, label:'Balance'},  
            ],
        },
    },
    products:{
        top:{
            title:'Top',
            year_filter_options:[
                {value:2022, label:'2022'},
                {value:2023, label:'2023'},
                {value:2024, label:'2024'},
                {value:2025, label:'2025'},
                {value:2026, label:'2026'},
                {value:2027, label:'2027'},
            ],
            header:[
                {id:1, label:'ID'},
                {id:2, label:'Código'},
                {id:3, label:'Marca'},
                {id:4, label:'Nombre'},
                {id:5, label:'Unidades vendidas'},
                {id:6, label:'Unidades devueltas'},
                {id:7, label:'Unidades compradas'},          
                {id:8, label:'Vendido'},
                {id:9, label:'Devuelto'},
                {id:10, label:'Comprado'},  
            ],
            nobrand:nobrand,
        },
        product_historic:'Histórico del producto'
    }
    
        
    
    
}

export default translations
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { onGetCurrencyValue } from "../../../../../shared/utility"


const CircularGraph = ({title, total, subtitle, data_array, price_sign}) => {

    

    const classes = useStyles()

    const gradient = data_array.map(item => item.color + ' ' + item.percent )
    //console.log(gradient.toString())

    return(
        <div className={classes.root}>
            <Typography variant='subtitle1'>{title}</Typography>
            <div className={classes.subcontainer}>
                <div className={classes.graph} style={{background:`conic-gradient( ${gradient.toString()}`}}>
                    <div className={classes.graph_center_wrapper}>
                        <div className={classes.graph_center}>
                            <div>
                            <Typography variant='h4'>{price_sign ? onGetCurrencyValue(total) : total}</Typography>
                            <Typography variant='subtitle2' style={{color:'#868686'}}>{subtitle}</Typography>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>  
            <Grid container justifyContent='center' spacing={2}>
                {data_array.map(item => {
                    return(
                        <Grid item key={item}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item><div className={classes.dot} style={{background:item.color}} /></Grid>
                                <Grid item><Typography variant='caption'>{item.label}</Typography></Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>                  
        </div>
    )
}

export default CircularGraph

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        //padding:'0px 20px'
    },
    subcontainer:{
        display:'flex',
        justifyContent:'center'
        //textAlign:'center'
    },
    graph:{
        position:'relative',
        borderRadius:'50%',
        width:300,
        height:300,
        margin:'50px 0px 30px',
        [theme.breakpoints.down('sm')]:{
            width:250,
            height:250,
        }
        //background:'conic-gradient( red 70%, blue 30%)',
    },
    graph_center_wrapper:{ 
        position:'absolute',       
        top:'50%',
        left:'50%',
        //background:'green',
        transform:'translate(-50%, -50%)',      
    },
    graph_center:{
        background:'white',
        width:240,
        height:240,
        borderRadius:'50%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        textAlign:'center',
        [theme.breakpoints.down('sm')]:{
            width:210,
            height:210,
        }
    },
    dot:{
        width:10,
        height:10
    }

}))
import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Icon, makeStyles, Popper, TextField, Typography } from '@material-ui/core'
import { Box, Card, ClickAwayListener, IconButton, Stack } from '@mui/material'
import { private_server } from '../../../config'
import AddButton from './AddButton'
import { useRef } from 'react'
import useProduct from '../../../hooks/api/useProduct'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'8px 28px',
        borderRadius:26,
        border:`0.5px solid ${theme.palette.primary.dark}`,
        boxShadow:'0px 4px 8px rgba(0, 0, 0, 0.07)',
        color:'black',
        width:600,
        [theme.breakpoints.down('md')]:{
            width:500
        },
        [theme.breakpoints.down('sm')]:{
            width:400
        },
        [theme.breakpoints.down('xs')]:{
            width:300
        }
    },
    icon:{
        color:theme.palette.primary.main,

    },
    select:{
        width:'100%',
        //background:'green',
        '& .MuiOutlinedInput-root':{
            //borderRadius:12,
            background:'green',
            '&.Mui-focused fieldset':{
              borderColor:'green'
            }
          },
        '& .MuiAutocomplete-inputRoot':{
            borderColor:'green',
            //background:'red'
        },
        '&. .MuiAutocomplete-input':{
            borderColor:'pink'
        }
    },
    input:{
        //padding:8,
        //background:'pink',
        borderColor:'blue',
        //border:'1px solid blue',
        //border:'none',
        '& .MuiOutlinedInput-root': {
            //borderRadius:12,
            borderColor:'pink',
            '& fieldset': {
              //borderColor: 'blue',
              borderBottom:'1px solid blue'
            },
            '&:hover fieldset': {
              borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'green',
            },
        },
    },
    line:{
        width:8,
        background:'#949494',
        height:0.4,
        margin:'0px 8px'
    },
    option_container:{
        padding:'12px 8px',
        borderBottom:'1px solid #E8E8E8'
    }
}))

const SearchBar = props => {

    const classes = useStyles()
    const {value, onChange, onAddItem} = props

    const productManager = useProduct()


    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState(null)
    const [first_search, setFirstSearch] = useState(false)

    const [open, setOpen] = useState(false)
    const [sending, setSending] = useState(false)
    const [searching, setSearching] = useState(false)

    const timerRef = useRef()
    const inputRef =  useRef()

    const textRef = useRef()

    const onSelectedItem = async(value) => {
        //onChange(e.target.value)
        setSending(true)
        await onAddItem(value)
        setSending(false)
        //window.scrollTo(0,document.body.scrollHeight)
    }


    const onInputChange = (e) =>{
        //if(!e || e.target.value === "" || e.target.value === 0) return
        setSearch(e.target.value)
    }
    
    const onSearchProduct = async(e) => {

        //console.log(e.target.value)
        if(!search || search === "" || search === 0) return
        setSearching(true)
        try {

            
            if(!search){
                const params = {limit:100}
                const filter = {filter:
                    {"OR":[
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                        {"field":"code","operator":"LIKE","value":`%${search}%`},
                        {"field":"brand","operator":"LIKE","value":`%${search}%`},
                        {"field":"description","operator":"LIKE","value":`%${search}%`},
                    ]}           
                }
                const req = await private_server.get(`/product/all`, {params:{...params, ...filter}})
                const cat = req.data.data.products.map(item => {
                return {value: item.id_product, label:item.code + ' - ' + item.brand + ' - ' + item.name, code: item.code, brand:item.brand, name:item.name}
                })
                //console.log(cat)
                setOptions(cat)
            }else{
                const params = {limit:100, search: search}
                const _productdata = await productManager.onSearch(params)
                const cat = _productdata.data.map(item => {
                    return {value: item.id_product, label:item.code + ' - ' + item.brand + ' - ' + item.name, code: item.code, brand:item.brand, name:item.name}
                })
                //console.log(cat)
                //console.log(_productdata)
                //console.log(cat)
                setOptions(cat)
            }

            
        } catch (error) {
            console.log(error)
        }
        setSearching(false)
        setFirstSearch(true)
        setOpen(true)
    }
   

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onSearchProduct, 500);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onClickAway = () => {
        setOptions([])
        setOpen(false)
        setSearch('')
        setFirstSearch(false)
    }

    const onEmptySearch = () => {
        setSearch('')
        setOptions([])
        setFirstSearch(false)
        
        textRef.current.focus()

        
    }
    
    return(
        <div className={classes.root} ref={inputRef}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap' justifyContent='space-between'>   
                <Grid item xs>
                <TextField 
                    inputRef={textRef}
                    value={search}
                    fullWidth
                    InputProps={{
                        endAdornment: <Stack direction='row' spacing={1}>
                            {searching && <CircularProgress size={20} />}
                            {!!(search??'').length && <IconButton size='small' onClick={onEmptySearch}><Icon fontSize='small'>close</Icon></IconButton>}
                        </Stack>
                    }}
                    onKeyUp={onStartCountDown}
                    onKeyDown={onStopCountDown}
                    onChange={onInputChange}
                    onClick={() => setOpen(true)}


                />
                <Popper
                    open={open}
                    anchorEl={inputRef.current}
                    placement='bottom-start'
                    
                >
                    <ClickAwayListener onClickAway={onClickAway}>
                        <Card sx={{
                            width:inputRef?.current?.getBoundingClientRect()?.width ?? 557,
                            mt:0.5,
                            borderRadius:2,
                            maxHeight:350,
                            overflowY:'auto'
                        }}>
                            <Stack>
                                {first_search && (!options.length) && (
                                    <Box sx={{
                                        py:3, px:2
                                    }}>
                                        <Typography align='center' color='textSecondary'>No se encontró ningún producto</Typography>
                                    </Box>
                                )}
                                {options.map((option, key) => {
                                    return(
                                        <div className={classes.option_container} key={`serachresult-${key}`}>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item xs>
                                                    <div style={{display:'flex', alignItems:'center'}}>
                                                    <Typography color='primary'>{option.code}</Typography>
                                                    <div className={classes.line}></div>
                                                    <Typography style={{fontWeight:700}}>{option.brand}</Typography>
                                                    <div className={classes.line}></div>
                                                    <Typography >{option.name}</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item><AddButton disabled={sending} {...props} onClick={()=>onSelectedItem(option.value)} /></Grid>
                                            </Grid>       
                                        </div>
                                    )
                                })}
                            </Stack>
                        </Card>
                    </ClickAwayListener>
                    
                </Popper>
                
                {/*<Autocomplete
                    disablePortal
                    id="products"
                    options={options}
                    //onKeyUp={onStartCountDown}
                    //onKeyDown={onStopCountDown}
                    //onInputChange={onInputChange}
                    className={classes.select}
                    renderInput={(params) => 
                        <TextField className={classes.input} color='secondary' {...params} 
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }} 
                        />
                    }
                    noOptionsText={<Typography variant='body2'>Sin opciones </Typography>}
                    renderOption={(props, option) => {
                        console.log(option)
                        return(
                        <div className={classes.option_container} >
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                    <Typography color='primary'>{option.code}</Typography>
                                    <div className={classes.line}></div>
                                    <Typography style={{fontWeight:700}}>{option.brand}</Typography>
                                    <div className={classes.line}></div>
                                    <Typography >{option.name}</Typography>
                                    </div>
                                </Grid>
                                <Grid item><AddButton disabled={sending} {...props} onClick={()=>onSelectedItem(option.value)} /></Grid>
                            </Grid>       
                        </div>
                    )}
                    }
                    />
                    */}
                </Grid>
                <Grid item>
                    <Icon className={classes.icon} fontSize='default'>search</Icon>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar
import moment from "moment"
import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"

const usePurchaseGroupView = ({ history, match, language }) => {

    const id = match.params.id
    const products_params = {limit:10, offset:0, order:1, order_by:'id_product'}

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [purchase_group, setPurchaseGroup] = useState(null)
    const [products, setProducts] = useState([])
    const [id_product, setIdProduct] = useState(null)

    const [modals, setModals] = useState({
        edit_purchasegroup:false,
        delete_purchasegroup:false,
        add_product:false,
        delete_product:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`purchasegroup/${id}`)
                //console.log(req)
                const _group = req.data.data.purchase_group
                setPurchaseGroup(_group)
                const req_products = await private_server.get(`product/all?&filter={"AND":[{"field":"purchase_group_id","operator":"=","value":"${id}"}]}`, {params:{...products_params}})
                setProducts(req_products.data.data.products)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onClearError:()=>{
            setError(null)
        },
        onDeleteProduct:(idproduct)=>{
            //console.log(idproduct)
            setIdProduct(idproduct)
            actions.onChangeModalStatus('delete_product', true)
        },
        onCompletedProducts: async() =>{
            try{
                const req_products = await private_server.get(`product/all?&filter={"AND":[{"field":"purchase_group_id","operator":"=","value":"${id}"}]}`, {params:{...products_params}})
                setProducts(req_products.data.data.products)
            }catch{
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        }
    }

    const system = {loading, sending, error}
    const view_data = {purchase_group, products, id_product}

    return {system, modals, view_data, actions}
}

export default usePurchaseGroupView
import { Grid, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import RoundedButton from "../../../components/Actions/RoundedButton"
import InputForm from "../../../components/Forms/InputForm"
import SimpleModal from "../../../components/Modals/SimpleModal"
import DisplayError from "../../../components/Structure/DisplayData/DisplayError"
import Header from "../../../components/Structure/Texts/Header"
import { private_server } from "../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../shared/utility"
import { generic_errors } from "../../../texts/esp/genericErrors"
import system_translations from "../../../texts/system_translations"



const ChangePasswordModal = ({open, onClose, language, id, onCompleted}) => {

    const content = system_translations[language].modals.change_password
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const onChangeForm = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(error) setError(null)
        setForm(temp)
    }

    const onSubmit = async() =>{

        const errors = isFormValid(form)
        if(errors && errors.length){
            console.log(errors)
            const _form = onSetErrorsToForm(form, errors)
            setForm(_form)
            setError(generic_errors[language].INCOMPLETE_FORM)
            return
        }

        const data2send = onGetFormData(form)
        console.log(data2send)

        try {
            setLoading(true)
            updateAuthorizationHeader(private_server)
            await private_server.post(`/user/me/passwordchange`, data2send)    
            setError(null)
            setLoading(false)
            onClose()
            //onCompleted()     
        } catch (error) {
            setLoading(false)
            console.log(error)
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
    }

    useEffect(() => {
        if(!open){
            let _form = onInitForm(form, null, content.form)
            setForm(_form) 
            setError(null)
        }
    }, [open])

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'  >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>                   
                <Grid item xs={12}>
                    <Header>{content.form_me.previous_password.tag}</Header>
                    <InputForm data={form.previous_password} onChange={onChangeForm} />
                </Grid>   
                <Grid item xs={12}>
                    <Header>{content.form_me.new_password.tag}</Header>
                    <InputForm data={form.new_password} onChange={onChangeForm} />
                </Grid> 
            </Grid>       
            <div style={{margin:'40px 0px 0px', textAlign:'center'}}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton onClick={onSubmit} loading={loading}>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default ChangePasswordModal 

const form_data = {
    previous_password: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'previous_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {type: 'distance', min:8, max:255}
    },
    new_password: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'new_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {type: 'distance', min:8, max:255}
    },

}
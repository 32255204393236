import { Button, Grid, Icon, makeStyles, Typography } from "@material-ui/core"
import { amber, yellow } from "@mui/material/colors"
import { useState, useEffect } from "react"
import ActionModalBar from "../../../../components/Actions/ActionModalBar"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import DisplayError from "../../../../components/Structure/DisplayData/DisplayError"
import { private_server } from "../../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../shared/utility"
import system_translations from "../../../../texts/system_translations"
import cx from 'classnames'


const AddReturnOrderModal = ({open, onClose, language, id, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.return_order_modal

    const [error, setError] = useState('')
    const [sending, setSending] = useState(false);

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if(!open){
            setSelected(null)
            setError(null)
        }
    }, [open])
 
    const onSubmit = async() =>{

        const data2send = {order_id:id, return_order_type_id:selected}
        //console.log(data2send)

        try {
            setSending(true)
            updateAuthorizationHeader(private_server)
            await private_server.post(`/returnorder`, data2send)
            onCompleted()
            onClose()
            setError(null)
            setSending(false)

        } catch (error) {
            console.log(error)
            setSending(false)
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        
    }

    return(
        <SimpleModal open={open} onClose={onClose}  >
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.title}>
                        <Typography variant='h6'>{content.title}</Typography>
                        </div>
                    </Grid> 
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' spacing={4} >
                            {content.options.map(item => {
                                return(
                                    <Grid item key={item}>
                                        <Button className={cx({[classes.option_button]:true, [classes.option_button_active]: selected === item.value})} 
                                        onClick={()=>setSelected(item.value)}>{item.label}</Button>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.button_container}>
                            <DisplayError>{error}</DisplayError>
                            <RoundedButton loading={sending} onClick={onSubmit} disabled={selected===null}>{content.button}</RoundedButton>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )

}

export default AddReturnOrderModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #848484',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:theme.palette.secondary.main
    },
    option_button_active:{
        background:'rgba(179, 10, 16, 0.05)',
        border:'1px solid #B30A10',
        color:'#B30A10',
        '&:hover':{
            background:'rgba(179, 10, 16, 0.02)',
        }
    },
    button_container:{
        textAlign:'center',
        margin:'12px 0px 0px'
    },

    
}))
import { Grid, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import { onGetCurrencyValue } from "../../../../shared/utility"
import miniTableStyles from "../../../../styles/miniTableStyles"


const MiniTable = props => {

    const {rows, header } = props
    const classes = miniTableStyles()

    return(
        <SimpleCard>
            <div className={classes.root}>      
            {rows && rows.length ?
                <div className={classes.rows}>
                    <div className={classes.row_header} >
                    <Grid container spacing={3} >
                        {header.map(item => {
                            if(item === 'Producto')
                            return(
                                <Grid item xs key={item}>
                                    <div style={{minWidth:300}}>
                                    <Typography  className={classes.header} variant="caption" >{item}</Typography>
                                    </div>
                                </Grid>
                            )
                            else
                            return(
                                <Grid item xs key={item}>
                                    <div style={{textAlign:item === 'Cantidad' ? 'center' : 'left'}} >
                                        <Typography  className={classes.header} variant="caption" >{item}</Typography>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                    </div>
                    {rows.map(item => {
                        return(
                            <Row {...item} key={item.id}  />
                        )
                    })}
                </div>
            : null}
            </div>
        </SimpleCard>
    )
}

export default MiniTable

/* const useStyles = makeStyles(theme => ({
    root:{
        overflowY:'hidden',
        overflowX:'hidden',
        [theme.breakpoints.down('sm')]:{
            overflowX:'auto'
        }
    },
    rows:{
        //minWidth:750,
        //background:'red'
        //width:'100%'
        //marginTop:40
    },
    header:{
        font:theme.typography.fontFamily,
        fontSize:'0.9rem',
        fontWeight:700,
        borderBottom:'1px solid #E2E2E2',
        paddingLeft:40,
        paddingBottom:20
    },
})) */

const Row = ({code, product, price, quantity, total, brand, return_quantity}) => {

    const classes = miniTableStyles()

    return(
        <div >
            <Grid container spacing={3} alignItems='center' className={classes.row_root}>
                <Grid item xs><div className={classes.root}>{code}</div></Grid>
                <Grid item xs><div className={classes.root}>{brand}</div></Grid>
                <Grid item xs><div className={classes.root} style={{minWidth:300}}>{product}</div></Grid>
                <Grid item xs><div className={classes.row_center} >{quantity}</div></Grid>
                <Grid item xs><div className={classes.row_center} >{return_quantity}</div></Grid>
                <Grid item xs><div className={classes.root} >{onGetCurrencyValue(price)}</div></Grid>
                <Grid item xs><div className={classes.root} >{onGetCurrencyValue(total)}</div></Grid>
            </Grid>
        </div>
    )
}

/* const useStylesRow = makeStyles(theme => ({
    root:{
        //paddingLeft:40,
        paddingBottom:20,
        paddingTop:20,
        font:theme.typography.fontFamily,
    },
 
    
})) */




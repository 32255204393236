import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import InputForm from "../../../../components/Forms/InputForm"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import Header from "../../../../components/Structure/Texts/Header"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import system_translations from "../../../../texts/system_translations"
import useEditClientModal from "./useEditClientModal"
import { red } from "@material-ui/core/colors"
import DisplayError from "../../../../components/Structure/DisplayData/DisplayError"
import InputSelectForm from "../../../../components/Forms/InputSelectForm"


const EditClientModal = ({open, onClose, language, id, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.client_modal
    const {loading, sending, error, form, actions} = useEditClientModal({open, language, id, content, onClose, onCompleted})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' loading={loading} >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.edit.title}</Typography>
                </Grid>    
                <Grid item xs={12}>
                    <Header>{content.form.name.tag}</Header>
                    <InputForm data={form.name} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.form.email.tag}</Header>
                    <InputForm data={form.email} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.rfc.tag}</Header>
                    <InputForm data={form.rfc} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.cfdi_use.tag}</Header>
                    <InputSelectForm data={form.cfdi_use} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.zip.tag}</Header>
                    <InputForm data={form.zip} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.tax_system.tag}</Header>
                    <InputSelectForm data={form.tax_system} onChange={actions.onChangeForm} />
                </Grid>
            </Grid>       
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton onClick={actions.onSubmit} loading={sending}>{content.edit.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default EditClientModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
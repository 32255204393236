import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import SearchBar from '../../../../components/Actions/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import empty from '../../../../assets/placeholders/noproducts.png'
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName'
import tableStyles from '../../../../styles/tableStyles'
import { onGetCurrencyValue } from '../../../../shared/utility'
import { green, red } from '@material-ui/core/colors'
import AddButton from '../../../../components/Actions/AddButton'
import DownloadButton from '../../../../components/Actions/DownloadButton'
import FilterButton from '../../../../components/Actions/FilterButton'

const SimpleTable = props => {

    const classes = tableStyles()
    const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onUpdateSearch, onUpdateFilters, onDownloadExcel, allProviders, filters} = props

    const header = content.header

    const all_option = [{value:-1, label:'Todos'}]
    const options = allProviders.map(provider => ({
        value: provider.id_provider,
        label: provider.name
      })
    );

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='center' wrap='nowrap' >
                    <Grid item><FilterButton options={[...all_option, ...options]} selected={filters.provider} onChange={(value)=>onUpdateFilters('provider', value)} /></Grid>
                    <Grid item><SearchBar value={search} onChange={onChangeSearch} onUpdateSearch={onUpdateSearch}/></Grid>
                    {onAddRegister ? <Grid item><AddButton variant='table' onClick={onAddRegister}/></Grid> : null}  
                    <Grid item><DownloadButton variant='table' onClick={onDownloadExcel} /></Grid> 
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                <Typography variant='subtitle2' >{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {id_product, code, name, cost, location, brand, retail_price, wholesale_price, brand_logo, description} = item

                                return(
                                    <TableRow key={key.toString()} className={classes.row} onClick={() => onSelectedItem(id_product)}>
                                        <TableCell className={classes.cell}><Typography>{id_product}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{code}</Typography></TableCell>
                                        <TableCell className={classes.cell}><UserAvatarName name={brand} nopicture={content.nobrand} image={brand_logo} /></TableCell>
                                        <TableCell className={classes.cell}><Typography>{name}</Typography></TableCell>
                                        <TableCell className={classes.cell}><Typography>{description}</Typography></TableCell>
                                        <TableCell className={classes.cell}>
                                            <Grid container spacing={1}>
                                                <Grid item><Icon style={{color:red[700]}}>arrow_downward</Icon></Grid>
                                                <Grid item><Typography style={{color:red[700]}}>{onGetCurrencyValue(wholesale_price)}</Typography></Grid>
                                                <Grid item><Typography>-</Typography></Grid>
                                                <Grid item><Icon style={{color:green[500]}}>arrow_upward</Icon></Grid>
                                                <Grid item><Typography style={{color:green[500]}}>{onGetCurrencyValue(retail_price)}</Typography></Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={classes.cell}><Typography>{location}</Typography></TableCell>
                                        
                                        {/* <TableCell className={classes.cell} padding='checkbox'>
                                            <IconButton className={classes.delete_button}><Icon>delete</Icon></IconButton>
                                        </TableCell> */}
                                    </TableRow>
                                )
                            })}
                            
                        </TableBody>
                    </Table>
                </div>         
            ): null}     
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>         
            ) : null}      
        </div>
    )
}

export default SimpleTable
import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputForm from "../../../../../components/Forms/InputForm"
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import Header from "../../../../../components/Structure/Texts/Header"
import AvatarPicture from '../../../../../components/Structure/DisplayData/AvatarPicture'
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"
import ChangeImageModal from "./ChangeImageModal"
import useAddUserModal from "./useAddUserModal"
import system_translations from "../../../../../texts/system_translations"


const AddUserModal = ({open, onClose, language, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.user_modal
    const catalogs = system_translations[language].catalogs
    const {sending, error, form, actions, crop_image, img_modal} = useAddUserModal({open, language, content, onCompleted, onClose, catalogs})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='md' >
            <ChangeImageModal open={img_modal} onClose={()=>actions.onChangeImgModal(false)} language={language} 
                onUpdateDataImage={actions.onUpdateDataImage}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.add.title}</Typography>
                </Grid>  
                <Grid item xs={12}>                    
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <div className={classes.pic_container}>
                                <AvatarPicture src_temp={crop_image} onChangePicture={()=>actions.onChangeImgModal(true)} 
                                nopicture={content.nopicture}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Header>{content.form.first_name.tag}</Header>
                                    <InputForm data={form.first_name} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Header>{content.form.last_name.tag}</Header>
                                    <InputForm data={form.last_name} onChange={actions.onChangeForm} />
                                </Grid>
                            </Grid>
                        </Grid>       
                    </Grid>
                </Grid>         
                <Grid item xs={6}>
                    <Header>{content.form.birth_date.tag}</Header>
                    <InputForm data={form.birth_date} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.gender_id.tag}</Header>
                    <InputSelectForm data={form.gender_id} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.username.tag}</Header>
                    <InputForm data={form.username} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.user_type_id.tag}</Header>
                    <InputSelectForm data={form.user_type_id} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.user_status_id.tag}</Header>
                    <InputSelectForm data={form.user_status_id} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.hired_at.tag}</Header>
                    <InputForm data={form.hired_at} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.email.tag}</Header>
                    <InputForm data={form.email} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.mobile.tag}</Header>
                    <InputForm data={form.mobile} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.password.tag}</Header>
                    <InputForm data={form.password} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.form.confirm_password.tag}</Header>
                    <InputForm data={form.confirm_password} onChange={actions.onChangeForm} />
                </Grid>    
            </Grid>       
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton onClick={actions.onSubmit} loading={sending}>{content.add.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddUserModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    },
    pic_container:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
    
    
}))
import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import InputForm from "../../../../components/Forms/InputForm"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import Header from "../../../../components/Structure/Texts/Header"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import system_translations from "../../../../texts/system_translations"
import useEditClientModal from "./useEditClientModal"


const EditClientModal = ({open, onClose, language}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.client_modal
    const {loading, sending, error, form, actions} = useEditClientModal({open, language})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>    
                <Grid item xs={12}>
                    <Header>{content.name}</Header>
                    <InputForm data={form.name} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.email}</Header>
                    <InputForm data={form.email} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.rfc}</Header>
                    <InputForm data={form.rfc} onChange={actions.onChangeForm} />
                </Grid>
                <Grid item xs={6}>
                    <Header>{content.invoice_usage}</Header>
                    <InputForm data={form.invoice_usage} onChange={actions.onChangeForm} />
                </Grid>
            </Grid>       
            <div className={classes.button_container}>
                <RoundedButton>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default EditClientModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
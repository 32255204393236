import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onGetSelectedFormData,  onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"


const useAddPurchaseModal = ({open, language, onCompleted, onClose}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [loading_search, setLoadingSearch] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [options, setOptions] = useState([])
    const [search, setSearch] = useState(null)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
        if(!open){
            setForm(form_data)
            setError(null)
        }
    },  [open])

    let provider_form = {...form.provider_id}
    provider_form.options = [...options]
    
    const actions = {
        onInitModule: async() => {
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onInputChange: (e) =>{
            if(!e || e.target.value === "" || e.target.value === 0) return
            setSearch(e.target.value)
        },
        onSearchProvider: async(e) => {
            //console.log(e.target.value)
            //if(!e || e.target.value === "" || e.target.value === 0) return
            setLoadingSearch(true)
            try {
                const params = {limit:30, }
                const filter = {filter:
                    {"AND":[
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                    ]}           
                }
                const req = await private_server.get(`/provider/all`, {params:{...params, ...filter}})
                const cat = req.data.data.providers.map(item => {
                    return {value: item.id_provider, label: item.name}})
                setOptions(cat)
            } catch (error) {
                console.log(error)
            }
            setLoadingSearch(false)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.post(`/purchaseorder`, data2send)
                onCompleted()
                setError(null)
                setSending(false)
                onClose()

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, provider_form, loading_search} 
}


export default useAddPurchaseModal

const form_data = {
    provider_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'provider_id',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },

}
import general_es from './esp/general-translations'
import front_end_errors_es from './esp/frontend-erros'
import users_translations_es from './esp/users-translations'
import user_translations_es from './esp/user-translations'
import product_translations_es from './esp/product-translations'
import catalogs_es from './esp/catalogs'
import modals_es from './esp/modals-translations'
import products_translations_es from './esp/products-translations'
import brands_translations_es from './esp/brands-translations'
import providers_translations_es from './esp/providers-translations'
import sale_groups_translations_es from './esp/sale-groups-translations'
import sale_group_translations_es from './esp/sale-group-translations'
import clients_translations_es from './esp/clients-translations'
import analyticals_translations_es from './esp/analyticals-translations'
import purchases_translations_es from './esp/purchases-translations'
import purchase_translations_es from './esp/purchase-translations'
import sales_translations_es from './esp/sales-translations'
import sale_translations_es from './esp/sale-translations'
import missing_translations_es from './esp/missing-translations'
import order_translations_es from './esp/order-translations'
import my_profile_translations_es from './esp/my-profile-translations'
import errors_codes_es from './esp/errorCodes'
import tracking_translations_es from './esp/tracking-translations'


const system_translations = {
    es:{
        errors: front_end_errors_es,
        error_codes: errors_codes_es,
        general:general_es,
        views:{
            users:users_translations_es,
            user:user_translations_es,
            product:product_translations_es,
            products:products_translations_es,
            brands:brands_translations_es,
            providers:providers_translations_es,
            sale_groups:sale_groups_translations_es,
            sale_group:sale_group_translations_es,
            clients:clients_translations_es,
            analyticals:analyticals_translations_es,
            purchases:purchases_translations_es,
            purchase:purchase_translations_es,
            sales:sales_translations_es,
            sale:sale_translations_es,
            missing:missing_translations_es,
            order:order_translations_es,
            my_profile:my_profile_translations_es,
            tracking:tracking_translations_es
        },
        modals:{
            brand_modal:modals_es.brand_modal,
            equivalence_modal:modals_es.equivalence_modal,
            picture_modal:modals_es.picture_modal,
            change_image_modal:modals_es.change_image_modal,
            warning_modal:modals_es.warning_modal,
            provider_modal:modals_es.provider_modal,
            client_modal:modals_es.client_modal,
            payment_method:modals_es.payment_method,
            receipt:modals_es.receipt,
            select_client:modals_es.select_client,
            download_doc:modals_es.download_doc,
            purchase_group_modal:modals_es.purchase_group_modal,
            product_modal:modals_es.product_modal,
            user_modal:modals_es.user_modal,
            change_password:modals_es.change_password_modal,
            error_modal:modals_es.error_modal,
            document_modal:modals_es.document_modal,
            error_document: modals_es.error_document,
            add_product_to_pg: modals_es.add_product_to_pg,
            generate_invoice:modals_es.generate_invoice,
            cancel_receipt:modals_es.cancel_receipt,
            complement_modal:modals_es.complement_modal,
            cancel_modal:modals_es.cancel_modal,
            purchase_modal:modals_es.purchase_modal,
            add_product_item:modals_es.add_product_item,
            return_order_modal:modals_es.return_order_modal,
            finish_day:modals_es.finish_day
        },
        catalogs:catalogs_es
    },
    eng:{
        
    }
}

export default system_translations
import { useEffect, useState } from "react"
import axios from 'axios'
import { private_server } from "../../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { generic_errors } from "../../../../texts/esp/genericErrors"


const useAddProviderModal = ({open, language, content, onCompleted, onClose}) => {


    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    //image modal
    const [img_modal, setImgModal] = useState(false)
    const [data_image, setDataImage] = useState(null)
    const [file, setFile] = useState(null)
    const [crop_image, setCropImage] = useState(null)

    useEffect(() => {
        if(!open){
            actions.onInitModule()
        }
    }, [open])
    
    const actions = {
        onInitModule: async() => {
            setError(null)
            setFile(null)
            setDataImage(null)
            setCropImage(null)
            let _form = onInitForm(form_data, null, content.form)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImgModal: (status) =>{
            setImgModal(status)
        },
        onUpdateDataImage: (data, _file, crop) =>{
            setDataImage(data)
            setFile(_file)
            setCropImage(crop)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                const new_provider =  await private_server.post(`/provider`, data2send)
                const id = new_provider.data.data.provider.id_provider
                if(data_image && file){
                    const image_req =  await private_server.post(`/provider/${id}/image`, data_image)
                    const options = {headers: {'Content-Type': file.type}}
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(image_req.data.data.url, file, options);
                } 
                onCompleted()
                setError(null)
                setSending(false)
                onClose()
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, img_modal, crop_image} 
}


export default useAddProviderModal

const form_data = {
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    description: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {type: 'distance', min:1, max:255}
    },
    relation_started_at: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'relation_started_at',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'date'}
    },
    contact_name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    contact_email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    contact_phone: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_phone',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'phone_number'}
    },
    address: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'address',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },

}
import { Grid, Typography, makeStyles, Icon, IconButton, Avatar, CircularProgress } from '@material-ui/core'
import SimpleModal from '../../../../../../../components/Modals/SimpleModal'
import InputForm from '../../../../../../../components/Forms/InputForm'
import InputSelectForm from '../../../../../../../components/Forms/InputSelectForm'
import useEditProductModal from './useEditProductModal'
import RoundedButton from '../../../../../../../components/Actions/RoundedButton'
import AddBrandModal from '../AddBrandModal/AddBrandModal'
import Header from '../../../../../../../components/Structure/Texts/Header'
import EditButton from '../../../../../../../components/Actions/EditButton'
import ChangeImageModal from './ChangeImageModal'
import ImageContainer from '../../../../../../../components/Ecommerce/ImageContainer'
import SelectAutocompleteForm from '../../../../../../../components/Forms/SelectAutocompleteForm'
import InputSelectFormAvatar from '../../../../../../../components/Forms/InputSelectFormAvatar'
import AddPurchaseGroupModal from '../AddPurchaseGroupModal/AddPurchaseGroupModal'
import DisplayError from '../../../../../../../components/Structure/DisplayData/DisplayError'
import AddButton from '../../../../../../../components/Actions/AddButton'



const EditProductModal = ({open, onClose, content, language, origin, onCompleted, nopicture}) => {

    const classes = useStyles()
    const {loading, sending, error, form, actions, modals, unit, prod} = useEditProductModal({open, language, content, origin, onCompleted, onClose})

    return(
        <div>      
            <SimpleModal open={open} onClose={onClose} maxWidth='md' loading={loading}>
                <AddBrandModal language={language} open={modals.add_brand} onClose={()=>actions.onChangeModalStatus('add_brand', false)} 
                    onCompleted={onCompleted} id_product={origin ? origin.id_product : null}/>
                <AddPurchaseGroupModal language={language} open={modals.add_purchasegroup} onClose={()=>actions.onChangeModalStatus('add_purchasegroup', false)} 
                    onCompleted={onCompleted} id_product={origin ? origin.id_product : null}/>
                <ChangeImageModal language={language} open={modals.change_image} onClose={()=>actions.onChangeModalStatus('change_image', false)} 
                    id={origin ? origin.id_product : null} onCompleted={onCompleted}/>   
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{marginBottom:20}}>
                        <Typography variant='h6'>{content.edit_modal.title}</Typography>
                        </div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div style={{position:'relative'}}>
                            <ImageContainer onEdit={()=>actions.onChangeModalStatus('change_image', true)} 
                            src={origin ? origin.image : null} nopicture={nopicture} />
                        </div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.info_container}>
                            <Grid container spacing={4} alignItems='center'>
                                <Grid item xs={6}>
                                    <Header>{content.form.code.tag}</Header>
                                    <InputForm data={form.code} onChange={actions.onChangeForm} />  
                                </Grid>   
                                <Grid item xs={6}>
                                    <Header>{content.form.location.tag}</Header>
                                    <InputForm data={form.location} onChange={actions.onChangeForm} />  
                                </Grid>
                                <Grid item xs={12}>                           
                                    <Header marginBottom={0}>{content.form.name.tag}</Header>
                                    <InputForm data={form.name} onChange={actions.onChangeForm} />                                 
                                </Grid>
                                <Grid item xs={12}>
                                    <Header>{content.form.description.tag}</Header>
                                    <InputForm data={form.description} onChange={actions.onChangeForm} />  
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{marginBottom:8}}>
                                        <Grid container justifyContent='space-between' alignItems='center'>
                                            <Grid item><Header>{content.form.brand_id.tag}</Header></Grid>
                                            <Grid item>
                                                <AddButton size='small' color='primary' onClick={()=>actions.onChangeModalStatus('add_brand', true)}/>
                                            </Grid>
                                        </Grid>
                                    </div>                           
                                    <InputSelectFormAvatar data={form.brand_id} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{marginBottom:8}}>
                                        <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
                                            <Grid item><Header>{content.form.purchase_group_id.tag}</Header></Grid>
                                            <Grid item><AddButton color='primary' size='small' onClick={()=>actions.onChangeModalStatus('add_purchasegroup', true)} /></Grid>
                                        </Grid>
                                    </div> 
                                    <InputSelectForm data={form.purchase_group_id} onChange={actions.onChangeForm} />
                                </Grid>
                                
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.bottom_section}>
                    {/* <Grid container spacing={2} >
                        <Grid item md={3} >
                            <Typography className={classes.title}>{content.quantity_title}</Typography>
                            <div style={{textAlign:'center', marginTop:20, width:220}}>
                            <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                                <Grid item >
                                    <Header>{content.form.quantity.tag}</Header>
                                    <InputForm data={form.quantity} onChange={actions.onChangeForm} /> 
                                </Grid>
                                <Grid item >
                                    <Header>{content.form.min_quantity.tag}</Header>
                                    <InputForm data={form.min_quantity} onChange={actions.onChangeForm} /> 
                                </Grid>
                                <Grid item>
                                    <Header color='white' >-</Header>
                                    <Typography>-</Typography>
                                </Grid>
                                <Grid item >
                                    <Header>{content.form.max_quantity.tag}</Header>
                                    <InputForm data={form.max_quantity} onChange={actions.onChangeForm} /> 
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>
                        <Grid item md>
                            <div className={classes.price_section}>
                                <Typography className={classes.title}>{content.price_title}</Typography>
                                <div style={{marginTop:20}}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                                                <Grid item md >
                                                    <div className={classes.price_header}><Header>{content.form.cost.tag}</Header></div>
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:8}}>$</Typography>
                                                        <InputForm data={form.cost} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className={classes.price_header}><Header>{content.form.wholesale_price.tag}</Header></div>
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:8}}>$</Typography>
                                                        <InputForm data={form.wholesale_price} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.line} style={{marginTop:32}}></div>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.price_header}><Header>{content.form.retail_price.tag}</Header></div>
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:8}}>$</Typography>
                                                        <InputForm data={form.retail_price} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' >
                                                <Grid item md >
                                                    <div className={classes.price_container}>
                                                    
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:4}}>%</Typography>
                                                        <InputForm data={form.wholesale_percentage} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.line}></div>
                                                </Grid>
                                                <Grid item >
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:4}}>%</Typography>
                                                        <InputForm data={form.retail_percentage} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                            </Grid>    
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.title}>{content.sat_title}</Typography>
                            <div style={{textAlign:'center', marginTop:20}}>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={5}>
                                    <Header>{content.form.sat_unit_code.tag}</Header>
                                    <InputForm data={form.sat_unit_code} onChange={actions.onChangeForm} /> 
                                </Grid>
                                <Grid item xs={5}>
                                    <Header>{content.form.sat_product_code.tag}</Header>
                                    <InputForm data={form.sat_product_code} onChange={actions.onChangeForm} /> 
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={2} >
                        <Grid item  >
                            <Typography className={classes.title}>{content.quantity_title}</Typography>
                            <div style={{textAlign:'center', marginTop:20, width:220}}>
                            <Header>{content.form.quantity.tag}</Header>
                            <InputForm data={form.quantity} onChange={actions.onChangeForm} /> 
                            <Grid container spacing={2} wrap='nowrap' alignItems='center'>
                                <Grid item >
                                    <Header>{content.form.min_quantity.tag}</Header>
                                    <InputForm data={form.min_quantity} onChange={actions.onChangeForm} /> 
                                </Grid>
                                <Grid item>
                                    <Header color='white' >-</Header>
                                    <Typography>-</Typography>
                                </Grid>
                                <Grid item >
                                    <Header>{content.form.max_quantity.tag}</Header>
                                    <InputForm data={form.max_quantity} onChange={actions.onChangeForm} /> 
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>
                        <Grid item md>
                            <div className={classes.price_section}>
                                <Typography className={classes.title}>{content.price_title}</Typography>
                                <div style={{marginTop:20}}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                                                <Grid item md >
                                                    <div className={classes.price_header}><Header>{content.form.cost.tag}</Header></div>
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:8}}>$</Typography>
                                                        <InputForm data={form.cost} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className={classes.price_header}><Header>{content.form.wholesale_price.tag}</Header></div>
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:8}}>$</Typography>
                                                        <InputForm data={form.wholesale_price} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.line} style={{marginTop:32}}></div>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.price_header}><Header>{content.form.retail_price.tag}</Header></div>
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:8}}>$</Typography>
                                                        <InputForm data={form.retail_price} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' >
                                                <Grid item md >
                                                    <div className={classes.price_container}>
                                                    
                                                    </div>
                                                </Grid>
                                                <Grid item >
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:4}}>%</Typography>
                                                        <InputForm data={form.wholesale_percentage} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div className={classes.line}></div>
                                                </Grid>
                                                <Grid item >
                                                    <div className={classes.price_container}>
                                                        <Typography style={{fontWeight:500, marginRight:4}}>%</Typography>
                                                        <InputForm data={form.retail_percentage} onChange={actions.onChangeForm} /> 
                                                    </div>
                                                </Grid>
                                            </Grid>    
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={3} >
                            <Typography className={classes.title}>{content.sat_title}</Typography>
                            <div style={{textAlign:'center', marginTop:20}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <Header>{content.form.sat_unit_code.tag}</Header>
                                    <InputForm data={unit} onChange={actions.onChangeForm} 
                                        onInputChange={actions.onSearchSatUnit} /> 
                                </Grid>
                                <Grid item xs={12}>
                                    <Header>{content.form.sat_product_code.tag}</Header>
                                    <InputForm  data={prod} onInputChange={actions.onSearchSatProduct} 
                                        onChange={actions.onChangeForm} 
                                        /> 
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button_container}>
                    <DisplayError>{error}</DisplayError>
                    <RoundedButton onClick={actions.onSubmit} loading={sending}>{content.edit_modal.button}</RoundedButton>
                </div>
                
            </SimpleModal>
        </div>
    )

}

export default EditProductModal

const useStyles = makeStyles(theme => ({
    info_container:{
        //background:'green',
        height:'100%',
        boxSizing:'border-box',
        paddingLeft:40,
        [theme.breakpoints.down('sm')]:{
            paddingLeft:0
        }
    },
    bottom_section:{
        //textAlign:'center'
        marginTop:40
    },
    title:{
        fontWeight:500
    },
    button_container:{
        textAlign:'center',
        margin:'60px 0px 20px'
    },
    edit_pic_button_container:{
        position:'absolute',
        top:20,
        right:20
    },
    price_container:{
        display:'flex',
        alignItems:'center',
        width:110
    },
    price_header:{
        marginLeft:20
    },
    line:{
        background:'black',
        width:10,
        height:0.5
    },
    price_section:{
        padding:'0px 40px',
        [theme.breakpoints.down('sm')]:{
            padding:0
        }
    },
    loading_container:{
        height:200,
        display:'flex',
        justifyContent:'center'
    }
}))



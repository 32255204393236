


export const translations = {
    title:'Seguimiento',
    button:'Terminar día',
    day:'Día',
    voucher:{
        title:'Comprobante',
        headlands:['Nota foliada', 'Nota sin registro', 'Factura']
    },
    payment:{
        title:'Pago',
        headlands:['Efectivo', 'Tarjeta débito', 'Tarjeta crédito']
    },
    table:{
        title:'Facturas',
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Fecha'},
            {id:3, label:'Efectivo Subtotal'},
            {id:4, label:'Efectivo Impuesto'},
            {id:5, label:'Efectivo Total'},
            {id:6, label:'Efectivo Factura'},
            {id:7, label:'Tarjeta Subtotal'},
            {id:8, label:'Tarjeta Impuesto'},
            {id:9, label:'Tarjeta Total'},
            {id:10, label:'Tarjeta Factura'},
            {id:11, label:'Costo'},
        ],
        nodata:'No existen registros registrados',
    }
    
}

export default translations
import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS, private_server } from "../../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"


/*NOTAS 17 NOV-- el campo name es requerido pero no esta en los mockups y descripcion no esta como campo, por mientras 
pondre name cmo descripcion */

const useAddProductModal = ({open, language, content, onCompleted, onClose, history}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [modals, setModals] = useState({
        add_brand:false,
        change_image:false,
        add_purchasegroup:false,
    })

    //image
    const [data_image, setDataImage] = useState(null)
    const [file, setFile] = useState(null)
    const [crop_image, setCropImage] = useState(null)

    //sat forms
    const [sat_product, setSatProduct] = useState('vacuna')
    const [sat_unit, setSatUnit] = useState('unidad')

    const [unit_cat, setUnitCat] = useState([])
    const [product_cat, setProductCat] = useState([])
  
    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
        if(!open){
            setError(null)
            //setForm(JSON.parse(JSON.stringify(form_data)))
            /* let _form = {...form_data}
            _form.wholesale_price.value = null
            _form.retail_price.value = null
            _form.wholesale_percentage.value = null
            _form.retail_percentage.value = null
            setForm(_form) */
            let _form ={...form_data}
            Object.keys(form_data).forEach(item => {            
                _form[item] = {..._form[item], error:false}
            })
            setForm(_form)
            //setForm(form_data)
            setSending(false)
        }
    }, [open])

    /* useEffect(() => {
        if(open){
            actions.onGetSatProductCatalogs()
        }
    }, [open, sat_product])

    useEffect(() => {
        if(open){
            actions.onGetSatUnitCatalogs()
        }
    }, [open, sat_unit]) */

    let unit = {...form.sat_unit_code}
    unit.options = [...unit_cat]

    let prod = {...form.sat_product_code}
    prod.options = [...product_cat]
    
    const actions = {
        onInitModule: async() => {
            setDataImage(null)
            setFile(null)
            setCropImage(null)
            setError(null)
            let _form = onInitForm(form, null, content.form)
            //setForm(_form)
            setLoading(true)
            try{
                const req_brands = await private_server.get(`/brand/all`)
                //console.log(req_brands.data.data.brands)
                //const brands_cat = onGetCatalogs(req_brands.data.data.brands, 'id_brand', 'name')
                const brands_cat = req_brands.data.data.brands.map(item => {
                    //const _src = `${CONFIG_SETTINGS.S3_SERVER_URL}${item.logo}`
                    return{
                        value: item.id_brand, label:item.name, src: item.logo, nopicture:content.nobrand
                    }
                })
                //console.log(brands_cat)
                //console.log(brand_cat)
                const req_groups = await private_server.get(`/purchasegroup/all`)
                const groups_cat = onGetCatalogs(req_groups.data.data.purchase_groups, 'id_purchase_group', 'name')
                
                _form.brand_id.options = brands_cat
                _form.purchase_group_id.options = groups_cat
                setForm(_form)
                setLoading(false)
            }catch(error){
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            //console.log(re)
            //_form.brand_id.options = catalogs.brands
            //_form.sale_group_id.options = catalogs.sale_groups
            
        },
        /* onGetSatProductCatalogs: async () =>{
            try{
                let _form = onInitForm(form_data, null, content.form)
                const req_sat_products = await private_server.get(`/product/sat/products?search=${sat_product}&limit=10`)
                //console.log(req_sat_products)
                const sat_products_cat = onGetCatalogs(req_sat_products.data.data.products.data, 'key', 'description')
                console.log(sat_products_cat)
                _form.sat_product_code.options = sat_products_cat
                setForm(_form)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onGetSatUnitCatalogs: async () =>{
            try{
                let _form = onInitForm(form_data, null, content.form)
                const req_sat_unit = await private_server.get(`/product/sat/units?search=${sat_unit}&limit=10`)
                //console.log(req_sat_products)
                const sat_unit_cat = onGetCatalogs(req_sat_unit.data.data.units.data, 'key', 'description')
                console.log(sat_unit_cat)
                _form.sat_unit_code.options = sat_unit_cat
                setForm(_form)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        }, */
        onChangeForm: (data) => {
            //console.log(data)
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(temp[id].value !== null || temp[id].value !== ''){
                temp[id].isRequired = true
            }
            if(error) setError(null)
            if(id === 'retail_percentage'){
                temp['retail_price'].value = parseFloat(temp['cost'].value) + (parseFloat(temp['cost'].value) * (parseFloat(temp['retail_percentage'].value)/100))
                temp['retail_price'].isValid =true
                temp['retail_price'].isVisited = true
            }
            if(id === 'wholesale_percentage'){
                temp['wholesale_price'].value = parseFloat(temp['cost'].value) + (parseFloat(temp['cost'].value) * (parseFloat(temp['wholesale_percentage'].value)/100))
                temp['wholesale_price'].isVisited = true
                temp['wholesale_price'].isValid = true
            }
            if(id === 'retail_price'){
                temp['retail_percentage'].value = ( parseFloat(temp['retail_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
                temp['retail_percentage'].isVisited = true
                temp['retail_percentage'].isValid = true
            }
            if(id === 'wholesale_price'){
                temp['wholesale_percentage'].value = ( parseFloat(temp['wholesale_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
                temp['wholesale_percentage'].isVisited = true
                temp['wholesale_percentage'].isValid = true
            }
            if(id === 'cost'){
                temp['wholesale_percentage'].value = ( parseFloat(temp['wholesale_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
                temp['retail_percentage'].value = ( parseFloat(temp['retail_price'].value) - parseFloat(temp['cost'].value) ) * 100 / parseFloat(temp['cost'].value)
            }
            setForm(temp)
        },
        /* onChangeSatProductForm:(event, value)=>{
            if(event && event.type === 'click'){
                return
            }
            if(value !== '' && value !== null){
                setSatProduct(value)
            }
        },
        onChangeSatUnitForm:(event, value)=>{
            if(event && event.type === 'click'){
                return
            }
            if(value !== '' && value !== null){
                setSatUnit(value)
            }
        }, */
        onSearchSatUnit: async(e) => {
            if(!e || e.target.value === "" || e.target.value === 0) return
            try {
                const req_sat_unit = await private_server.get(`/product/sat/units?search=${e.target.value}&limit=10`)
                //const sat_unit_cat = onGetCatalogs(req_sat_unit.data.data.units.data, 'key', 'key')
                //console.log(sat_unit_cat)
                const sat_unit_cat = req_sat_unit.data.data.units.data.map(item => {
                    return{
                        value: item.key, label:item.key + ' - ' + item.description
                    }
                })
                //console.log(sat_unit_cat)
                setUnitCat(sat_unit_cat)
                /* let _form = {...form}
                _form.sat_unit_code.options= [...sat_unit_cat]
                setForm(_form) */
            } catch (error) {
                console.log(error)
            }
        },
        onSearchSatProduct: async(e) => {
            if(!e || e.target.value === "" || e.target.value === 0) return
            try {
                const req_sat_products = await private_server.get(`/product/sat/products?search=${e.target.value}&limit=10`)
                //console.log(req_sat_products)
                //const sat_products_cat = onGetCatalogs(req_sat_products.data.data.products.data, 'key', 'key')   
                const sat_products_cat = req_sat_products.data.data.products.data.map(item => {
                    return{
                        value: item.key, label:item.key + ' - ' + item.description
                    }
                })
                setProductCat(sat_products_cat)
                /* let _form = {...form}
                _form.sat_product_code.options= [...sat_products_cat]
                setForm(_form) */
            } catch (error) {
                console.log(error)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onUpdateDataImage: (data, _file, crop) =>{
            setDataImage(data)
            setFile(_file)
            setCropImage(crop)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                const new_product = await private_server.post(`/product`, data2send)
                const id = new_product.data.data.product.id_product
                if(data_image && file){
                    const image_req =  await private_server.post(`/product/${id}/image`, data_image)
                    const options = {headers: {'Content-Type': file.type}}
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(image_req.data.data.url, file, options);
                }   
                onCompleted()
                onClose()
                setError(null)
                setSending(false)
                history.push(`/products/${id}`)
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        },
    } 

    return {loading, sending, error, form, actions, modals, crop_image, unit, prod}
}

export default useAddProductModal


const form_data = {
    code: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'code',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    location: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'location',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    description: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {type: 'none'}
    },
    brand_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'brand_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    purchase_group_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'purchase_group_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    quantity: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'quantity',
            type: 'number',
            //fullWidth: true,
        },
        rules: {type: 'numeric', min:'0'}
    },
    min_quantity: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'min_quantity',
            type: 'number',
            //fullWidth: true,
        },
        rules: {type: 'numeric', min:'0'}
    },
    max_quantity: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'max_quantity',
            type: 'number',
            //fullWidth: true,
        },
        rules: {type: 'numeric', min:'0'}
    },
    cost: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'cost',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'numeric', min:'0'}
    },
    wholesale_price: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'wholesale_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'numeric', min:'0'}
    },
    retail_price: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'retail_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'numeric', min:'0'}
    },
    wholesale_percentage: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'wholesale_percentage',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    retail_percentage: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'retail_percentage',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    sat_unit_code: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'sat_unit_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:4}
    },
    sat_product_code: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'sat_product_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:126}
    },

}


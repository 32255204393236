import { Grid, makeStyles, Typography } from "@material-ui/core"
import DeleteButton from "../../../../components/Actions/DeleteButton"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import { RETURN_ORDER_TYPE } from "../../../../config"
import { onGetCurrencyValue } from "../../../../shared/utility"
import miniTableStyles from "../../../../styles/miniTableStyles"


const MiniTable = props => {

    const {rows, header, onDeleteItem, type} = props
    const classes = miniTableStyles()

    return(    
        <div className={classes.root}>      
            {rows && rows.length ?
                <div className={classes.rows}>
                    <div className={classes.row_header}>
                        {/* <Grid container spacing={3} >
                            {header.map(item => {
                                if(item === ''){
                                    return(
                                        <Grid item xs={1} key={item} ><div  >
                                            <Typography className={classes.header} variant="caption" >{item}</Typography></div>
                                        </Grid>
                                    )
                                }else{
                                    return(
                                        <Grid item xs key={item} ><div style={{textAlign: item === 'Cantidad' ? 'center' : 'left'}} >
                                            <Typography className={classes.header} variant="caption" >{item}</Typography></div>
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid> */}
                        <Grid container spacing={3} >
                            <Grid item xs>
                                <div><Typography className={classes.header} variant="caption" >{header[0]}</Typography></div>
                            </Grid>
                            {type === RETURN_ORDER_TYPE.CHANGE ?
                                <Grid item xs>
                                    <div><Typography className={classes.header} variant="caption" >{header[1]}</Typography></div>
                                </Grid>
                            : null}
                            <Grid item xs>
                                <div style={{textAlign:'center'}} ><Typography className={classes.header} variant="caption" >{header[2]}</Typography></div>
                            </Grid>
                            <Grid item xs>
                                <div><Typography className={classes.header} variant="caption" >{header[3]}</Typography></div>
                            </Grid>
                            <Grid item xs={1}>
                                <div><Typography className={classes.header} variant="caption" >{header[4]}</Typography></div>
                            </Grid>
                        </Grid>
                    </div>
                    {rows.map(item => {
                        return(
                            
                                <Row {...item} key={item.id} onDelete={onDeleteItem ? ()=>onDeleteItem(item.id_return_order_item) : null} type={type}  />
                            
                        )
                    })}
                    
                </div>
            : null}
        </div>   
    )
}

export default MiniTable


const Row = ({code, brand, product, quantity, total, onDelete, order_item_code, order_item_brand, order_item_product, type}) => {

    const classes = miniTableStyles()

    return(
        <div className={classes.row_root} >
            <Grid container spacing={3} alignItems='center'>
                <Grid item xs>
                    <div className={classes.root}>
                        <font style={{color:'red'}}>{order_item_code}</font> - <strong>{order_item_brand}</strong> - {order_item_product} 
                    </div>
                </Grid>
                {type === RETURN_ORDER_TYPE.CHANGE ?
                    <Grid item xs>                
                        <div className={classes.root}><font style={{color:'red'}}>{code}</font> - <strong>{brand}</strong> - {product}</div>
                    </Grid>
                : null
                }
                <Grid item xs><div className={classes.root} style={{textAlign:'center'}}>{quantity}</div></Grid>
                <Grid item xs><div className={classes.root} style={{color:total.charAt(0) === '-' ? '#CA3B40' : 'black'}} >{onGetCurrencyValue(total)}</div></Grid>
                <Grid item xs={1}>{onDelete ? <DeleteButton onClick={onDelete} /> : null}</Grid>
            </Grid>
        </div>
    )
}








import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/PageAdmin'
import { USER_TYPES } from '../../config';
import system_translations from '../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import AddProviderModal from './modals/AddProviderModal/AddProviderModal';
import DeleteProviderModal from './modals/DeleteProviderModal';
import EditProviderModal from './modals/EditProviderModal/EditProviderModal';
import useProvidersView from './useProvidersView';


const ProvidersView = ({language, history, user}) => {

    const content = system_translations[language].views.providers
    const {actions, modals, view_data, system} = useProvidersView({language})

    let can_create = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_create = false

    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <AddProviderModal language={language} open={modals.add_provider} onClose={()=>actions.onChangeModalStatus('add_provider', false)} 
                onCompleted={actions.onCompleted}/>
            <EditProviderModal language={language} open={modals.edit_provider} onClose={()=>actions.onChangeModalStatus('edit_provider', false)} 
                onCompleted={actions.onCompleted} id={view_data.selected} can_edit={can_create} />
            <DeleteProviderModal language={language} open={modals.delete_provider} onClose={()=>actions.onChangeModalStatus('delete_provider', false)}
                onCompleted={actions.onCompleted} id={view_data.selected} />
            <SimpleTable 
                loading={system.loading}
                content={content.table} 
                data={view_data.providers}
                onSelectedItem={actions.onSelectItem}
                onAddRegister={can_create ? ()=>actions.onChangeModalStatus('add_provider', true) : null}
                tableFilter={view_data.tableFilter}
                total={view_data.total}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onUpdateSearch={actions.onResetTableFiler}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user: state.user
    }
}

export default connect(mapStateToProps)(ProvidersView) 
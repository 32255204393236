import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import system_translations from '../../texts/system_translations'
import Topbar from './components/Topbar';
import useOrderView from './useOrderView';
import CartCard from './components/CartCard';
import PaymentMethodModal from './modals/PaymentMethodModal/PaymentMethodModal';
import CustomSwitch from './components/CustomSwitch';
import ComplementsTable from './components/ComplementsTable2';
import EquivalencesTable from './components/EquivalencesTable2';
import ErrorModal from '../../components/Modals/ErrorModal';
import DeleteButton from '../../components/Actions/DeleteButton';
import NoProductModal from './modals/NoProductModal';


const OrderView = ({language, history, onUpdatePayModal, match, edit_order_products, blur}) => {

    const content = system_translations[language].views.order
    const {actions, modals, view_data, system} = useOrderView({history, onUpdatePayModal, match, language, edit_order_products, blur})
    const {local_order, noproduct_equivalences, noproduct_retail_price, noproduct_wholesale_price} = view_data
    const classes = useStyles()
    

    return(
        <div>
            <ErrorModal open={Boolean(system.error)} message={system.error} onClose={actions.onClearError} language={language} />
            <NoProductModal open={Boolean(system.noproduct_error)} message={system.noproduct_error} onClose={actions.onClearError} language={language} 
                equivalences_content={content.equivalences} onAdd={actions.onAddOrderItem} equivalences={noproduct_equivalences}
                wholesale_price={noproduct_wholesale_price} retail_price={noproduct_retail_price}/>
            <PaymentMethodModal open={modals.payment_method} onClose={()=>actions.onChangeModalStatus('payment_method', false)} 
                language={language} {...local_order} history={history} onUpdateOrderData/>
            <Topbar content={content.topbar} history={history} onCancelledOrder={actions.onCancelledOrder} onAddItem={actions.onAddOrderItem} />
            <div className={classes.root}>
                <Typography variant='h6'>{content.title}</Typography>        
                <Grid container spacing={5}>
                    <Grid item md={6}>
                        <div style={{marginTop:8, marginBottom:32}}>
                            <Grid container justifyContent='space-between'>
                                <Grid item><CustomSwitch label1={content.min} label2={content.max} selected={view_data.wholesale} onSwitch={actions.onChangeGlobalWholesale} /></Grid>
                                <Grid item><DeleteButton onClick={actions.onDeleteCart} /></Grid>
                            </Grid>                
                        </div>
                        {system.loading ? <div style={{textAlign:'center'}}><CircularProgress size={32} /></div> :
                        <CartCard sending={system.sending} {...local_order} selected={view_data.item_selected}  actions={actions} content={content.cart}/>}
                    </Grid>
                    <Grid item md={6}>
                        {system.product_loading ? <div style={{textAlign:'center'}}><CircularProgress size={32} /></div> :
                        <Grid container spacing={3}>
                            {view_data.equivalences && view_data.equivalences.length !== 0 ? <Grid item xs={12}>
                                <EquivalencesTable data={view_data.equivalences} content={content.equivalences}
                                    onAdd={actions.onAddOrderItem} loading={system.item_loading} />
                            </Grid> : null}
                            {view_data.complements && view_data.complements.length !== 0 ? <Grid item xs={12}>
                                <ComplementsTable data={view_data.complements} content={content.complementary} 
                                    onAdd={actions.onAddOrderItem} loading={system.item_loading} />
                            </Grid> : null}
                        </Grid>}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        edit_order_products:state.edit_order_products,
        blur:state.blur
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdatePayModal: (pay_modal) => dispatch({type:actionTypes.SYS_UPDATE_PAY_MODAL, pay_modal}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderView) 

const useStyles = makeStyles(theme => ({
    root:{
        padding:'50px 100px'
    },
   
    
}))
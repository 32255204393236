import { Button, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import DeleteButton from "../../../../components/Actions/DeleteButton"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import GeneralDisplayer from "../../../../components/Structure/DisplayData/GeneralDisplayer"
import StatusData from "../../../../components/Structure/DisplayData/StatusData"
import UserAvatarName from "../../../../components/Structure/DisplayData/UserAvatarName"
import Header from "../../../../components/Structure/Texts/Header"
import Products from './Products'
import { onGetCurrencyValue, onGetDateFormat } from "../../../../shared/utility"
import { RETURN_ORDER_STATUSES } from "../../../../config"


const ReturnOrderView = ({content, start_date, return_date, cancelled_date, seller, seller_image, return_order_type, return_order_type_id, return_order_status_id,
    subtotal, tax, total, catalogs, sending, return_order_items, onDelete, onNext, onBack, onAddItem, onDeleteItem}) => {

        //console.log(return_order_items)

    const classes = useStyles()

    return(
        <div>
            <div className={classes.topbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='center'>
                    <Grid item>
                        <RoundedButton disabled={return_order_status_id !== RETURN_ORDER_STATUSES.CREADED}
                        onClick={onBack}
                         >
                            {content.back_button}
                        </RoundedButton>
                    </Grid>
                    <Grid item>
                        <DeleteButton disabled={return_order_status_id !== RETURN_ORDER_STATUSES.CANCELLED} onClick={onDelete} card/>
                    </Grid>
                </Grid>  
            </div>
            <SimpleCard>
                <div className={classes.container} >
                    <Grid container spacing={4} >
                        <Grid item md={3} sm={6} xs={12}>
                            <Header>{content.start_date}</Header>
                            <Typography variant='body2'>
                                {onGetDateFormat(start_date, 'DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Header>{content.return_date}</Header>
                            <Typography variant='body2'>
                                {onGetDateFormat(return_date, 'DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Header>{content.cancelled_date}</Header>
                            <Typography variant='body2'>
                                {onGetDateFormat(cancelled_date, 'DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Header>{content.seller}</Header>
                            <UserAvatarName name={seller} src={seller_image} nopicture={content.nouser} />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Header>{content.type}</Header>
                            <GeneralDisplayer>{return_order_type}</GeneralDisplayer>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Header>{content.status}</Header>
                            <StatusData catalogs={catalogs.return_orders_statuses} id={return_order_status_id} />
                        </Grid>
                        
                        <Grid item md={3} sm={6} xs={12}>
                            <Button className={classes.next_button} onClick={onNext} disabled={return_order_status_id !== RETURN_ORDER_STATUSES.CREADED} >
                                {content.next_button}
                                {sending ? (<CircularProgress size={24} className={classes.progress}/>) : null}
                            </Button>
                        </Grid>
        
                    </Grid>
                </div>
                <Grid container alignItems='center' spacing={2} >
                    <Grid item md={3} xs={12}>
                        <div className={classes.price_container}>
                        <Header>{content.subtotal}</Header>
                        <Typography 
                        //style={{color:subtotal.charAt(0) === '-' ? '#CA3B40' : 'black'}} 
                        variant='body2'>
                            {onGetCurrencyValue(subtotal)}
                        </Typography>
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <div className={classes.price_container}>
                        <Header>{content.tax}</Header>
                        <Typography 
                        //style={{color:tax.charAt(0) === '-' ? '#CA3B40' : 'black'}} 
                        variant='body2'>
                            {onGetCurrencyValue(tax)}
                        </Typography>
                        </div>
                    </Grid>
                    <Grid item xs />
                    <Grid item >
                        <div >
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item><Typography style={{fontWeight:700, color:'#CA3B40'}}>{content.total}</Typography></Grid>
                                <Grid item><Typography variant='subtitle1' style={{color:total.charAt(0) === '-' ? '#CA3B40' : 'black'}}>{onGetCurrencyValue(total)}</Typography></Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div>
                    <Products content={content.products} status={return_order_status_id} data={return_order_items} onAddItem={onAddItem}
                        onDeleteItem={onDeleteItem} type={return_order_type_id}/>
                </div>
            </SimpleCard>
        </div>
    )
}

export default ReturnOrderView

const useStyles = makeStyles(theme => ({
    topbar:{
        marginBottom:20,
        marginTop:20
    },
    price_container:{
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end'
        }
    },
    container:{
        paddingBottom:48,
        borderBottom:'1px solid #D2D2D2',
        marginBottom:20
    }, 
    next_button:{
        background:theme.palette.secondary.main,
        padding:'12px 20%',
        color:'white',
        textTransform:'none',
        '&:hover':{
            background:theme.palette.secondary.main,
            color:'white'
        },
        '&:disabled':{
            /* background:'#909090',
            color:'rgba(54, 54, 54, 0.5)' */
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    pay_button:{
        background:theme.palette.primary.dark,
        padding:'12px 56px',
        color:'white',
        textTransform:'none',
        '&:hover':{
            background:theme.palette.primary.main,
            color:'white'
        },
        '&:disabled':{
            //background:'#909090',
            //color:'rgba(54, 54, 54, 0.5)'
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    payment_method_displayer:{
        background:'rgba(179, 10, 16, 0.05)',
        color:theme.palette.primary.dark,
        padding:'8px 24px',
        float:'left'
    },
    progress:{
        color:'white',
        marginLeft:8
    },
}))
import { Checkbox, Grid, Icon, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import InputForm from "../../../../components/Forms/InputForm"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import Header from "../../../../components/Structure/Texts/Header"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import system_translations from "../../../../texts/system_translations"
import useFinishDayModal from "./useFinishDayModal"
import Scrollbars from "react-custom-scrollbars";
import { onGetCurrencyValue, onGetDateFormat } from "../../../../shared/utility"



const FinishDayModal = ({open, onClose, language, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.finish_day
    const {loading, sending, error, form, actions, orders, checks} = useFinishDayModal({open, onClose, language, onCompleted})

    let total_array = []

    checks.forEach(element => {
        const cost = parseFloat(element.cost)
        //console.log('Cost element',element, cost)
        //if(cost === 0) 
        if(element.value ) total_array.push(cost)
    });

    console.log(orders)
    console.log(total_array)
    console.log(checks)
    //console.log(total_array)

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' loading={loading} >
            <Typography variant='h6' style={{marginBottom:20}}>{content.title}</Typography>
            <div className={classes.orders_container}>
                <Scrollbars height='100%' >
                    {orders.length !== 0 ? orders.map((item, key) => {

                        if(!total_array[key]) return null

                        return(
                            <div className={classes.order_row}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item><Checkbox defaultChecked color="primary" checked={checks[key].value} onChange={(event)=>actions.onChangeCheck(event, key)} /></Grid>
                                    <Grid item ><Typography variant='body2'>{item.id_order}</Typography></Grid>
                                    <Grid item xs><Typography variant='body2' align="center">-</Typography></Grid>
                                    <Grid item ><Typography variant='body2'>{onGetDateFormat(item.sold_date, 'DD/MM/YYYY')}</Typography></Grid>
                                    <Grid item xs><Typography variant='body2' align="center">-</Typography></Grid>
                                    <Grid item><Typography color='primary' variant='body2'>{onGetCurrencyValue(item.total)}</Typography></Grid>
                                </Grid>
                            </div>
                        )
                    }) : null}
                </Scrollbars>
            </div>
            <div className={classes.form}>
                <Grid container >
                    <Grid item xs={12}>
                        <Header>{content.form.date.tag}</Header>
                        <InputForm data={form.date} onChange={actions.onChangeForm} />
                    </Grid>  
                </Grid>
            </div>
            <div className={classes.total_container}>
                <Typography variant="caption" style={{marginRight:16, fontWeight:600}}>Total</Typography>
                <Typography variant="h6" color='primary'>{total_array.length !== 0 ? onGetCurrencyValue(total_array.reduce(function(a, b){ return a + b; })) : null}</Typography>
            </div>
            <div className={classes.button_container}>
                <Typography color="error" style={{marginBottom:20}} >{error}</Typography>
                <RoundedButton onClick={actions.onSubmit} loading={sending} >{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default FinishDayModal

const useStyles = makeStyles(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    orders_container:{
        height:300,
        overflowY:'auto',
        //padding:'12px 40px',
    },
    order_row:{
        padding:16,
        borderBottom:'1px solid #D2D2D2'
    },
    total_container:{
        marginTop:40,
        textAlign:'center',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    form:{
        marginTop:40
    }
    
    
    
}))
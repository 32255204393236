import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/PageAdmin'
import { USER_TYPES } from '../../config';
import system_translations from '../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import AddBrandModal from './modals/AddBrandModal/AddBrandModal';
import DeleteBrandModal from './modals/DeleteBrandModal';
import EditBrandModal from './modals/EditBrandModal/EditBrandModal';
import useBrandsView from './useBrandsView';

const BrandsView = ({language, user}) => {

    const content = system_translations[language].views.brands
    const {actions, modals, view_data, system} = useBrandsView({language})

    let can_create = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_create = false

    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <AddBrandModal language={language} open={modals.add_brand} onClose={()=>actions.onChangeModalStatus('add_brand', false)} 
                onCompleted={actions.onCompleted}/>
            <EditBrandModal id={view_data.selected} language={language} open={modals.edit_brand} 
                onClose={()=>actions.onChangeModalStatus('edit_brand', false)} onCompleted={actions.onCompleted} can_edit={can_create} />
            <DeleteBrandModal language={language} open={modals.delete_brand} onClose={()=>actions.onChangeModalStatus('delete_brand', false)}
                onCompleted={actions.onCompleted} id={view_data.selected} />
            <SimpleTable 
                loading={system.loading}
                content={content.table} 
                data={view_data.brands}
                tableFilter={view_data.tableFilter}
                total={view_data.total}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onAddRegister={can_create ? ()=>actions.onChangeModalStatus('add_brand', true) : null}
                onSelectedItem={actions.onSelectItem}
                onUpdateSearch={actions.onResetTableFiler}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language,
        user:state.user 
    }
}

export default connect(mapStateToProps)(BrandsView) 
import { useEffect, useState } from "react"
import axios from 'axios'
import DocumentModal from '../../../../components/Modals/DocumentModal'
import { private_server } from "../../../../config"
import { onGetErrorMessage, onGetFileExtension, updateAuthorizationHeader } from "../../../../shared/utility"
import system_translations from "../../../../texts/system_translations"



const UploadFileModal = ({open, onClose, language, id, onCompleted}) => {

    const error_message = system_translations[language].modals.error_document

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)

    const onChangeFile = (_file) =>{
        setFile(_file)
    }

    useEffect(() => {
        if(!open){
            setFile(null)
            setError(null)
            setLoading(false)
        }
    },[open])

    const onSubmit = async() =>{
        try {
            if(!file){
                setError(error_message)
                return
            }
            setLoading(true)
            const file2send = {name:'file',ext:onGetFileExtension(file.name)}
            const response = await private_server.post(`/purchaseorder/${id}/file`, file2send)  
            const options = {headers: {'Content-Type': file.type}}
            delete axios.defaults.headers.common["Authorization"]
            await axios.put(response.data.data.url, file, options);
            onCompleted()
            setLoading(false)
            onClose()  
        } catch (error) {
            setLoading(false)
            console.log(error)
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
    }

    return(
        <DocumentModal open={open} onClose={onClose} language={language} onChangeFile={onChangeFile} file={file}
            error={error} loading={loading} onSubmit={onSubmit} onDeleteFile={()=>setFile(null)}
        />
    )

}

export default UploadFileModal 
import { useState } from 'react';
import { connect } from 'react-redux';
import PageAdmin from '../../../components/Structure/Layouts/PageAdmin';
import TopbarMenu from "../../../components/Structure/Nav/TopbarMenu"
import system_translations from "../../../texts/system_translations"
import ProductInfo from "./views/Information/ProductInfo"
import useProductView from './useProductView';
import { Typography } from '@material-ui/core';
import ComingSoon from '../../../components/Temp/ComingSoon';
import { USER_TYPES } from '../../../config';


const ProductView = ({language, match, history, user}) => {

    const content = system_translations[language].views.product
    const {system, modals, actions, view_data} = useProductView({match, language})
    const {product, view, complements, equivalences, providers, allProviders, equ_id, comp_id, prov_id} = view_data

    let can_edit = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_edit = false

    let contentView = null

    switch(view){
        case 1:
            contentView = <ProductInfo content={content.product_info} modals={modals} history={history} nopicture={content.nopicture}
            onChangeModalStatus={actions.onChangeModalStatus} language={language} product={product} complements={complements} 
            equivalences={equivalences} providers={providers} allProviders={allProviders} actions={actions} can_edit={can_edit} equ_id={equ_id} comp_id={comp_id} prov_id={prov_id}/>
            break
        case 2:
            contentView = <ComingSoon />
            break
        default:
            break
    }
  
    return(
        <PageAdmin title={content.title} goback loading={system.loading} error={system.error} onCloseErrorModal={actions.onClearError} 
            language={language}>
            {/* <div style={{marginTop:8}} />
            <TopbarMenu selected={view} menuOptions={content.menu} onChange={(id)=>actions.onChangeView(id)} /> */}
            <ProductInfo content={content.product_info} modals={modals} history={history} nopicture={content.nopicture}
            onChangeModalStatus={actions.onChangeModalStatus} language={language} product={product} complements={complements} 
            equivalences={equivalences} providers={providers} allProviders={allProviders} actions={actions} can_edit={can_edit} equ_id={equ_id} comp_id={comp_id} prov_id={prov_id}/>
        </PageAdmin>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user:state.user
    }
}

export default connect(mapStateToProps)(ProductView) 
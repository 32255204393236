import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import system_translations from '../../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import useSalesView from './useSalesView';


const SalesView = ({language, history}) => {

    const content = system_translations[language].views.sales
    const catalogs = system_translations[language].catalogs
    const {actions, view_data, system} = useSalesView({history, language})

    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <SimpleTable 
            loading={system.loading}
            filter={view_data.status_filter}
            onChangeStatusFilter={actions.onChangeStatusFilter}
            startDate={view_data.startDate}
            endDate={view_data.endDate}
            onChangeDate={actions.onChangeDate}
            catalogs={catalogs}
            content={content.table} 
            data={view_data.orders}
            onSelectedItem={actions.onSelectItem}
            onDownloadReceipt={actions.onDownloadReceipt}
            //onAddRegister={()=>actions.onChangeModalStatus('add_salegroup', true)}
            tableFilter={view_data.tableFilter}
            total={view_data.total}
            search={view_data.search}
            onUpdateTableFilter={actions.onChangeTableFilter}
            onChangeSearch={actions.onChangeSearch}
            filters={view_data.filters}
            onUpdateFilters={actions.onUpdateFilters}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(SalesView) 
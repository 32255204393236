import { useEffect, useRef, useState } from "react"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"
import { private_server } from '../../../../../config'


const useGenerateInvoiceModal = ({open, language, client, id, onCompleted, onClose}) => {

    //console.log(client)

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [payment_method, setPaymentMethod] = useState(null)
    const [receipt_type, setReceiptType] = useState(null)

    const [active_step, setActiveStep] = useState(0)
    const [disabled, setDisabled] = useState(true)

    //invoice
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [invoice_open, setInvoiceOpen] = useState(false)
    //const invoice_loading = invoice_open && form.id_client.options.length === 0;
    
    /* useEffect(() => {
        let active = true;
        let catalogs = []
    
        if (!invoice_loading) {
          return undefined;
        }
    
        (async () => {
          try{
            updateAuthorizationHeader(private_server)
            const req_clients = await private_server.get(`client/all`)
            catalogs = onGetCatalogs(req_clients.data.data.clients, 'id_client', 'name' )
            console.log(catalogs)
          }catch(error){
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
          }
          if (active) {
              let _form = onInitForm(form, client, null)
              _form.id_client.options = catalogs
              setForm(_form)
          }
        })();
    
        return () => {
          active = false;
        };
    }, [invoice_loading]); */

    useEffect(() => {
        if(!open){
            setPaymentMethod(null)
            setReceiptType(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            setError(null)
        }else{
            actions.onInitModule()
        }
    }, [open, client])
    
    const actions = {
        onInitModule: async() => { 
            setLoading(true)
            try{
                let _form = onInitForm(form, client, null) 
                ///console.log(client)  
                updateAuthorizationHeader(private_server)
                const req_cfdi = await private_server.get(`order/sat/cfdiuses`)
                const cfdi_catalogs = req_cfdi.data.data.cfdi_uses.map(item => {
                    return{
                        value: item.code, label:item.code + ' - ' + item.name
                    }
                })
                const cdfi_filter_cat = cfdi_catalogs.filter(el => el.value !== 'P01')
                //console.log(cdfi_filter_cat)
                const req_clients = await private_server.get(`client/all`)
                const catalogs = onGetCatalogs(req_clients.data.data.clients, 'id_client', 'name' )     
                _form.cfdi_use.options = cdfi_filter_cat
                _form.id_client.options = catalogs
                setForm(_form)
                setLoading(false)
            }catch(error){
                setLoading(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }

        },
       
        /* onSelectedPaymentMethod: (value) =>{
            setPaymentMethod(value)
            setDisabled(false)
        },
        onSelectedReceiptType: (value) =>{
            setReceiptType(value)
            setDisabled(false)
        }, */
        onChangeForm: async(data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            if(id === 'id_client'){
                //console.log(temp['id_client'].value)
                try{
                    updateAuthorizationHeader(private_server)
                    const _client = await private_server.get(`client/${temp['id_client'].value}`)
                    temp['cfdi_use'].value = _client.data.data.client.cfdi_use
                    temp['cfdi_use'].isValid = true
                }catch(error){
                    console.log(error)
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                
            }
            setForm(temp)
        },
        onChangeSelectOpen:(new_status) => {
            console.log('open')
            setInvoiceOpen(new_status)
        },
        onSubmit: async() => {
        
            const errors = isFormValid(form)
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            let data2send = onGetFormData(form)
            // Clean data
            /* if(client){
                console.log('hola cliente')
                data2send = onGetFormData(form)
                
            }else{
                data2send = onGetFormData(form)
            } */

            data2send.client_id = data2send.id_client
            delete data2send.id_client
            console.log(data2send)
            
            
            //const pay_data = {payment_type_id:payment_method, receipt_type_id:receipt_type}

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)          
                await private_server.post(`/order/${id}/invoice/create/me`, data2send)             
                setError(null)
                setSending(false)
                onCompleted()
                onClose()
            } catch (error) {
                console.log(error)
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.error_details : null)
            }
             
        }
    }

    return {loading, sending, error, actions, active_step, disabled, payment_method, 
        receipt_type, invoice_open, form} 
}


export default useGenerateInvoiceModal

const form_data = {
    id_client: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'id_client',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    cfdi_use: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'cfdi_use',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
}

import { Grid, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../components/Structure/Cards/SimpleCard"
import { onGetCurrencyValue } from "../../../shared/utility"


const ItemCard = ({title, headlands, summary}) => {

    const classes = useStyles()

    const nota_foliada = summary.find(el => el.sumary_type_id === "21")
    const nota_sin_registro = summary.find(el => el.sumary_type_id === "22")
    const factura = summary.find(el => el.sumary_type_id === "23")

    return(
        <div>
            <div className={classes.title}>
                <Typography variant='subtitle1'>{title}</Typography>
            </div>
            <div className={classes.card}>
                <Grid container justifyContent="center">
                    {/* {items.map((item, index) => {
                        return(
                            <Grid item xs key={item.sumary_type_id}>
                                <div className={classes.container}>
                                    <div className={classes.title}>
                                        <Typography variant='caption' style={{color:'#909090'}}>{item.summary_type}</Typography>
                                    </div>
                                    <Typography variant='body2'>{onGetCurrencyValue(item.total)}</Typography>
                                </div>
                            </Grid>
                        )
                    })} */}
                    <Grid item xs>
                        <div className={classes.container}>
                            <div className={classes.title}>
                                <Typography variant='caption' style={{color:'#909090'}}>{headlands[0]}</Typography>
                            </div>
                            <Typography variant='body2'>{nota_foliada ? onGetCurrencyValue(nota_foliada.total) : '$0.00'}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.container}>
                            <div className={classes.title}>
                                <Typography variant='caption' style={{color:'#909090'}}>{headlands[1]}</Typography>
                            </div>
                            <Typography variant='body2'>{nota_sin_registro ? onGetCurrencyValue(nota_sin_registro.total) : '$0.00'}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.container}>
                            <div className={classes.title}>
                                <Typography variant='caption' style={{color:'#909090'}}>{headlands[2]}</Typography>
                            </div>
                            <Typography variant='body2'>{factura ? onGetCurrencyValue(factura.total) : '$0.00'}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    card:{
        background:'white',
        borderRadius:15,
        boxShadow:' 0px 4px 50px rgba(0, 0, 0, 0.06)'
    },
    container:{
        padding:'30px 10px',
        textAlign:'center'
    },
    title:{
        marginBottom:20
    }
}))

export default ItemCard
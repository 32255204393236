import { useEffect, useState } from "react"
import { private_server } from "../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"

const useClientsView = ({ language}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [clients, setClients] = useState([])
    const [selected, setSelected] = useState(null)
    const [client, setClient] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_client'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        edit_client:false,
        add_client:false,
        delete_client:false,
        orders_client:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                let filter = {filter:
                    {"OR":[
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                        {"field":"rfc","operator":"LIKE","value":`%${search}%`}
                    ]}           
                }
                const req = await private_server.get(`client/all`, {params:{...params, ...filter}})
                //console.log(req)
                const _clients = req.data.data
                setClients(_clients.clients)
                setTotal(_clients.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onSelectedItem: (item, action) => {
            const {id_client} = item
            setSelected(id_client)
            setClient(item)
            if(action === 'edit') actions.onChangeModalStatus('edit_client',true)  
            if(action === 'delete') actions.onChangeModalStatus('delete_client',true) 
            if(action === 'orders') actions.onChangeModalStatus('orders_client',true)  
             
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onResetTableFiler: () => setTableFilter({limit:10, offset:0, order:1, order_by:'id_client'}),
        onChangeTableFilter: (newTableFilter) => { 
            //let newTableFilter = {...tableFilter}
            //newTableFilter.offset = 0
            setTableFilter(newTableFilter)
        },
        onComplete:async () =>{
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`client/all`, {params:{...tableFilter}})
                console.log(req)
                const _clients = req.data.data
                setClients(_clients.clients)
                setTotal(_clients.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onClearError:()=>{
            setError(null)
        }
    }

    const system = {loading, sending, error}
    const view_data = {clients, selected, total, tableFilter, search, client}

    return {system, modals, view_data, actions}
}

export default useClientsView
import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { green, grey } from '@material-ui/core/colors'
import SearchBar from '../../../components/Actions/SearchBar'
import ActionBar from '../../../components/Actions/ActionBar'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import AvatarPicture from '../../../components/Structure/DisplayData/AvatarPicture'
import empty from '../../../assets/placeholders/nobrands.png'
import nopicture from '../../../assets/placeholders/nobrand.png'
import DeleteButton from '../../../components/Actions/DeleteButton'
import AddButton from '../../../components/Actions/AddButton'


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        padding:2,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:'12%',
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        marginRight:16
    },
    empty:{
        //width:80,
        //marginBottom:40
        width:'100%',
        height:'100%',
        //objectFit:'contain'
    },
    img_container:{
        marginBottom:40,
        borderRadius:'50%',
        background:'white',
        boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        width:100,
        height:100,
        padding:25,
        boxSizing:'border-box'
        
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onUpdateSearch} = props

    
    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='center' wrap='nowrap' > 
                    <Grid item><SearchBar value={search} onChange={onChangeSearch} onUpdateSearch={onUpdateSearch} /></Grid>
                    {onAddRegister ? <Grid item><AddButton variant='table' onClick={onAddRegister}/></Grid> : null}    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}     
            {data && data.length ? (
                <div>
                    <Grid container spacing={2}>
                        {data.map(item => {
                            return(
                                <Grid item key={item.name} md={4} sm={6} xs={12}><BrandCard {...item} 
                                onDelete={onAddRegister ? ()=>onSelectedItem(item.id_brand, 'delete') : null} onEdit={()=>onSelectedItem(item.id_brand, 'edit')}/></Grid>
                            )
                        })}
                    </Grid>
                </div>                
            ): null}
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} label={content.label}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' width={80} className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable



const useStylesBrand = makeStyles(theme => ({
    root:{
        position:'relative'
    },
    pic_container:{
        display:'flex',
        justifyContent:'center',
        marginBottom:20
    },
    edit_mask:{
        position:'absolute',
        top:'20%',
        width:'100%',
        height:'80%',
        //background:'red'
        cursor:'pointer',
        zIndex:5
    }
}))

const BrandCard = ({name, logo, description, onDelete, onEdit}) =>{

    const classes = useStylesBrand()

    return(
        <SimpleCard>
            <div className={classes.root}>
                <div className={classes.edit_mask} onClick={onEdit}></div>
                <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                    <Grid item><Typography variant='subtitle2'>{name}</Typography></Grid>
                    {onDelete ? <Grid item><DeleteButton  onClick={onDelete} /></Grid> : null}
                    <Grid item xs={12}>
                        <div className={classes.pic_container}>
                            <AvatarPicture src={logo} nopicture={nopicture}/>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{description}</Typography>
                    </Grid>
                </Grid>
            </div>
        </SimpleCard>
    )
}

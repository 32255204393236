import { Icon, IconButton, makeStyles } from "@material-ui/core"
import cx from 'classnames'


const DownloadButton = ({onClick, disabled, variant}) => {

    const classes = useStyles()

    return(
        <IconButton disabled={disabled} onClick={onClick}  color='primary'
        className={cx({
            [classes.button]:true, 
            [classes.table]: variant === 'table'})}>
            <Icon>cloud_download</Icon>
        </IconButton>
    )

}

export default DownloadButton

const useStyles = makeStyles(theme => ({
    button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        //color:theme.palette.primary.main,
        padding:6
    },
    table:{
        width:40,
        height:40
    }
    
}))
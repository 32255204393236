import { useContext } from 'react';
//
import { PrinterContext } from './PrinterContext';
// import { AuthContext } from './Auth0Context';
// import { AuthContext } from './FirebaseContext';
// import { AuthContext } from './AwsCognitoContext';

// ----------------------------------------------------------------------

export const usePrinterContext = () => {
  const context = useContext(PrinterContext);

  if (!context) throw new Error('PrinterContext context must be use inside RoomProvider');

  return context;
};

import { Grid, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { ORDER_STATUSES } from "../../../../config"
import MiniTable from "./MiniTable"


const Products = ({content, data, status, onEdit, can_edit}) => {

    return(
        <div> 
            {status === ORDER_STATUSES.CREADED && can_edit ? <div >
                <Typography variant='h6'>{content.title}</Typography>
                <div style={{marginBottom:20}}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <RoundedButton onClick={onEdit} >{content.button }</RoundedButton>
                        </Grid>
                    </Grid>
                </div>
            </div> : <div style={{marginBottom:20}}><Typography variant='h6'>{content.title}</Typography></div>}
            {data && data.length !== 0 ? <MiniTable rows={data} header={content.header}/> : null}
        </div>
    )
}

export default Products
import React, { useState } from 'react';
import { Avatar, fade, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument } from '../../../shared/utility';
import EditButton from '../../Actions/EditButton';

const AvatarPicture = ({nopicture, src, onChangePicture, size, src_temp}) => {
    const classes = useStyles()
    const [hover, setHover] = useState(false)

    let selectedImage = nopicture

    let size_styles = {width:160, height:160}
    if(size) size_styles = {width:size, height:size}

    if(src){
        const isValid = isValidDocument(src)
        selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : nopicture
        //selectedImage = src
    }

    if(src_temp){
        selectedImage = src_temp
    }

    return ( 
        <div  className={classes.avatarContainer} style={size_styles}>   
            { src && isValidDocument(src) || src_temp ? <Avatar src={selectedImage} alt='' className={classes.image} style={size_styles}/> 
            : <Avatar src={selectedImage} alt='' className={classes.noimage} style={size_styles}  /> }
            {onChangePicture ? 
            <div className={classes.edit_button_container}>
                <EditButton size='small' onClick={onChangePicture} />
            </div> : null}
            
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    image:{
        width:160,
        height:160,
        border:'1px solid #D6D6D6',
    },
    noimage:{
        width:160,
        height:160,
        border:'1px solid #D6D6D6',
        padding:'23%',
        boxSizing:'border-box',   
        '& .MuiAvatar-img':{
            objectFit:'contain',
        }
        //background:'red'
    },
    container:{
        padding:32,
        borderRadius:16,
        border:`1px solid ${grey[300]}`
    },
    avatarContainer:{
        width:160,
        height:160,
        position:'relative'
    },
    nopicture:{
        width:160,
        height:160,
        borderRadius:'50%',
        background:'#E3E3E3',
    },
    overlay:{
        position:'absolute',
        top:0,
        left:0,
        background: fade('#000',0.5),
        zIndex:2,
        width:'100%',
        height:'100%',
        borderRadius:'50%',
        '&:hover':{
            cursor:'pointer'
        }
    },
    picture:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0,
        zIndex:5,
        transform:'translate(-20%, -10%)'
    }
}))
 
export default AvatarPicture;
import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { usePrinterContext } from '../../../providers/PrinterProvider/usePrinterContext';
import { blue, green, grey, red } from '@material-ui/core/colors';

const PrinterStatus = () => {

    const classes = useStyles()

    const {status, ipaddress, error, connecting, onReconnect} = usePrinterContext()

    return ( 
        <div 
            className={classes.root}
            style={{
                background: connecting ? grey[200] : (status ? green[100] : red[100])
            }}
        >
            <Grid container alignItems='center' spacing={1}>
                <Grid item>
                    <Icon fontSize='medium' >print</Icon>
                </Grid>
                <Grid item xs>
                    <Typography variant='caption' style={{lineHeight:0.5, fontSize:12}}>{ipaddress}</Typography> <br />
                    <Typography variant='caption' style={{lineHeight:0.5, fontSize:12}}>{
                        connecting ? 'Conectando...' : (status ? 'Conectado' : error)
                    }</Typography>

                </Grid>
                { connecting && <Grid item>
                    <CircularProgress style={{color:blue[300]}} size={24} />
                </Grid> }

                { !connecting && <Grid item>
                    <IconButton size='small' onClick={onReconnect} ><Icon fontSize='small'>autorenew</Icon></IconButton>
                </Grid> }
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background: grey[200],
        padding: '0px 16px',
        borderRadius:12
    }
}))
 
export default PrinterStatus;
export const actionTypes = {
    AUTH_UPDATE_USER: 'AUTH_UPDATE_USER',
    AUTH_UPDATE_STATUS: 'AUTH_UPDATE_STATUS' ,
    AUTH_UPDATE_ORDER:'AUTH_UPDATE_ORDER',
    SYS_UPDATE_DRAWER_STATUS: 'SYS_UPDATE_DRAWER_STATUS',
    SYS_UPDATE_DRAWER: 'SYS_UPDATE_DRAWER',
    SYS_UPDATE_BLUR: 'SYS_UPDATE_BLUR',
    SYS_UPDATE_TOKEN:'SYS_UPDATE_TOKEN',
    SYS_UPDATE_SESSION_STATUS:'SYS_UPDATE_SESSION_STATUS',
    SYS_UPDATE_ERROR:'SYS_UPDATE_ERROR',
    SYS_UPDATE_MODAL:'SYS_UPDATE_MODAL',
    SYS_UPDATE_PAY_MODAL:'SYS_UPDATE_PAY_MODAL',
    SYS_UPDATE_EDIT_FLAG:'SYS_UPDATE_EDIT_FLAG'
    ////////////////////////////////////////////////////

    ///////////////////////// MDOALS /////////////////////
}
import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import system_translations from "../../../../../texts/system_translations"
import usePaymentMethodModal from "./useSelectClientModal"
import Header from "../../../../../components/Structure/Texts/Header"
import SelectAutocompleteForm from "../../../../../components/Forms/SelectAutocompleteForm"


const SelectClientModal = ({open, onClose, language, origin}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.select_client
    const {loading, sending, error, form, actions, select_loading, s_open} = usePaymentMethodModal({open, language, origin})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' >
            <div className={classes.title}>
                <Typography variant='h6'>{content.title}</Typography>
            </div>  
            <Header>{content.client}</Header>
            <SelectAutocompleteForm data={form.client_id} onChange={actions.onChangeForm} 
                open={s_open} loading={select_loading}
                onOpen={()=>actions.onChangeSelectOpen(true)} onClose={()=>actions.onChangeSelectOpen(false)}
                />
            <div className={classes.button_container}>
                <RoundedButton>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default SelectClientModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #363636',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:'#363636'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
}))
import React from 'react';
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { nodata } from '../../../config';

const StatusData = ({bold, variant, catalogs, id}) => {

    let selected = catalogs.find(el => el.value === id)

    const classes = useStyles()

    return ( 
        <div style={{background: selected && selected.back ? selected.back : grey[300]}} className={classes.root} >
            <Typography variant='body2' style={{color: selected && selected.color ? selected.color : grey[300]}} 
            //variant={bold ? 'subtitle1' : (variant ? variant : 'body1')}
            >{selected ? selected.label : nodata}</Typography>
        </div>
    );
}
 
export default StatusData;

const useStyles = makeStyles(theme => ({
    root:{
        padding:'8px 24px',
        float:'left',
        //display:'flex'
    }   
}))

//con el puntito
{/* <Grid container alignItems='center' spacing={1} wrap='nowrap'>
            {selected && selected.color ? <Grid item><Icon style={{marginTop:6, fontSize:14, color:selected.color}}>brightness_1</Icon></Grid> : null}
            <Grid item><Typography style={{color: selected && selected.color ? selected.color : grey[300]}} variant={bold ? 'subtitle1' : (variant ? variant : 'body1')}>{selected ? selected.label : nodata}</Typography></Grid>
        </Grid> */}
import { Grid, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { RETURN_ORDER_STATUSES } from "../../../../config"
import MiniTable from "./MiniTable"


const Products = ({content, data, status, onEdit, onAddItem, onDeleteItem, type}) => {

    return(
        <div style={{marginTop:70}}> 
            {status === RETURN_ORDER_STATUSES.CREADED  ? <div >
                <Typography variant='h6'>{content.title}</Typography>
                <div style={{marginBottom:20}}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <RoundedButton onClick={onAddItem} >{content.button}</RoundedButton>
                        </Grid>
                    </Grid>
                </div>
            </div> : <div style={{marginBottom:20}}><Typography variant='h6'>{content.title}</Typography></div>}
            {data && data.length !== 0 ? 
            <MiniTable rows={data} header={content.header} onDeleteItem={status === RETURN_ORDER_STATUSES.COMPLETED ? null : onDeleteItem} type={type}/> 
            : null}
        </div>
    )
}

export default Products
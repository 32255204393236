import axios from 'axios'
import FileSaver from 'file-saver'
import { useEffect, useState } from "react"
import { CONFIG_SETTINGS, private_server, PURCHASES_ORDER_STATUSES } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"

const usePurchaseView = ({ history, match, language}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [purchase, setPurchase] = useState(null)
    const [purchse_item_id, setPurchseItemId] = useState(null);

    const [next_sending, setNextSending] = useState(false)

    const [modals, setModals] = useState({
        upload_file:false,
        delete_purchase:false,
        edit_purchase:false,
        add_purchase_item:false,
        cancel:false,
        delete_purchase_item:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`purchaseorder/${id}`)
                //console.log(req)
                const _purchase = req.data.data.purchase_order
                setPurchase(_purchase)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onSelectItem: (id) => {
            //history.push(`products/${id}`)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDownloadFile: async() => {
            try {
                const url = await private_server.get(`purchaseorder/${id}/file?name=file`)
                delete axios.defaults.headers.common["Authorization"]
                const response = await axios.get(url.data.data.url, {responseType: 'blob'})
                //console.log(response)
                FileSaver.saveAs(response.data, `file`)
            }catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onDeleteFile: async() =>{
            try{
                await private_server.delete(`purchaseorder/${id}/file?name=file`)
                //setLoading(true)
                const req = await private_server.get(`purchaseorder/${id}`)
                const _purchase = req.data.data.purchase_order
                setPurchase(_purchase)
                //setLoading(false)
            }catch (error) {
                //setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onClickNextButton:async (status) =>{
            if(status === PURCHASES_ORDER_STATUSES.CREADED){
                try {
                    setNextSending(true)
                    updateAuthorizationHeader(private_server)
                    const new_purchase = await private_server.post(`/purchaseorder/${id}/order`)
                    //console.log(req)
                    setPurchase(new_purchase.data.data.purchase_order)
                    setError(null)
                } catch (error) {
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setNextSending(false)
            }
            if(status === PURCHASES_ORDER_STATUSES.ORDERED){
                try {
                    setNextSending(true)
                    updateAuthorizationHeader(private_server)
                    const new_purchase = await private_server.post(`/purchaseorder/${id}/receive`)
                    //console.log(req)
                    setPurchase(new_purchase.data.data.purchase_order)
                    setError(null)
                } catch (error) {
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setNextSending(false)
            }
            if(status === PURCHASES_ORDER_STATUSES.RECEIVED){
                try {
                    setNextSending(true)
                    updateAuthorizationHeader(private_server)
                    const new_purchase = await private_server.post(`/purchaseorder/${id}/cancel`)
                    //console.log(req)
                    setPurchase(new_purchase.data.data.purchase_order)
                    setError(null)
                } catch (error) {
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setNextSending(false)
            }
            if(status === PURCHASES_ORDER_STATUSES.CANCELLED){
                try {
                    setNextSending(true)
                    updateAuthorizationHeader(private_server)
                    const new_purchase = await private_server.post(`/purchaseorder/${id}/created`)
                    //console.log(req)
                    setPurchase(new_purchase.data.data.purchase_order)
                    setError(null)
                } catch (error) {
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
                setNextSending(false)
            }
        },
        onDeletePurchaseItem: (id_item) =>{
            setPurchseItemId(id_item)
            actions.onChangeModalStatus('delete_purchase_item', true)
        },
        onClearError:()=>{
            setError(null)
        }

    }

    const system = {loading, sending, error, next_sending}
    const view_data = {purchse_item_id}

    return {system, modals, view_data, actions, purchase}
}

export default usePurchaseView
import { Button, CircularProgress, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import usePrintButton from './usePrintButton';
import ticketIcon from '../../../../../assets/icons/ticket.png'
import { blue, grey } from '@material-ui/core/colors';


const PrintButton = ({order}) => {

    const {system, modals, view_data, actions} = usePrintButton({order})

    return ( 
        <div>
            <SimpleModal open={system.loading}>
                <div>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <img src={ticketIcon} alt='' width={150}/>
                        </Grid>
                        <Grid item>
                            <CircularProgress size={40} style={{color:blue[900], marginTop:40}}/>
                        </Grid>
                        <Grid item>
                            <Typography style={{fontWeight:500}}>
                                Estableciendo comunicación
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </SimpleModal>
            <Grid container direction='column' alignItems='center'>
                <Grid item>
                    <IconButton color='primary' onClick={actions.onConnectToPrint} 
                    style={{
                        //border:`1px solid ${grey[300]}`,
                        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
                        padding:8
                    }}>
                        <Icon fontSize='small' >
                            point_of_sale 
                        </Icon>
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant='caption'>Ticket</Typography>
                </Grid>
            </Grid>
            
            
        </div>
     );
}
 
export default PrintButton;
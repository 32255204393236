import React from 'react';
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { nodata } from '../../../config';

const GeneralDisplayer = ({children}) => {


    const classes = useStyles()

    return ( 
        <div className={classes.root} >
            <Typography variant='body2'>{children}</Typography>
        </div>
    );
}
 
export default GeneralDisplayer;

const useStyles = makeStyles(theme => ({
    root:{
        background:'rgba(179, 10, 16, 0.05)',
        color:theme.palette.primary.dark,
        padding:'8px 24px',
        float:'left'
    }   
}))
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ActionModalBar from '../Actions/ActionModalBar'
import InputFile from '../Forms/InputFile';
import SimpleModal from './SimpleModal';
import system_translations from '../../texts/system_translations';

const DocumentModal = ({ file, open, onClose, onSubmit, error, loading, language, onChangeFile, onDeleteFile}) => {

    const content = system_translations[language].modals.document_modal

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>{content.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputFile id='file' file={file} content={content.input_file}
                            onChange={onChangeFile} onDelete={onDeleteFile}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar btnLabel={content.button} loading={loading} error={error} onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
     );
}
 
export default DocumentModal;
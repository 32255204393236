import { Grid, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../components/Structure/Cards/SimpleCard"
import { onGetCurrencyValue } from "../../../shared/utility"


const ItemCardPayment = ({title, headlands, summary}) => {

    const classes = useStyles()

    const cash = summary.find(el => el.sumary_type_id === "11")
    const credit_card = summary.find(el => el.sumary_type_id === "12")
    const debit_card = summary.find(el => el.sumary_type_id === "13")

    return(
        <div>
            <div className={classes.title}>
                <Typography variant='subtitle1'>{title}</Typography>
            </div>
            <div className={classes.card}>
                <Grid container justifyContent="center">
                    <Grid item xs>
                        <div className={classes.container}>
                            <div className={classes.title}>
                                <Typography variant='caption' style={{color:'#909090'}}>{headlands[0]}</Typography>
                            </div>
                            <Typography variant='body2'>{cash ? onGetCurrencyValue(cash.total) : '$0.00'}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.container}>
                            <div className={classes.title}>
                                <Typography variant='caption' style={{color:'#909090'}}>{headlands[1]}</Typography>
                            </div>
                            <Typography variant='body2'>{credit_card ? onGetCurrencyValue(credit_card.total) : '$0.00'}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.container}>
                            <div className={classes.title}>
                                <Typography variant='caption' style={{color:'#909090'}}>{headlands[2]}</Typography>
                            </div>
                            <Typography variant='body2'>{debit_card ? onGetCurrencyValue(debit_card.total) : '$0.00'}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    card:{
        background:'white',
        borderRadius:15,
        boxShadow:' 0px 4px 50px rgba(0, 0, 0, 0.06)'
    },
    container:{
        padding:'30px 10px',
        textAlign:'center'
    },
    middle_container:{
        padding:'30px 10px',
        textAlign:'center',
        
    },
    title:{
        marginBottom:20
    }
}))

export default ItemCardPayment
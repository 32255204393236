import { Button, Icon, makeStyles } from "@material-ui/core"


const OrderButton = ({onClick}) =>{

    const classes = useStyles()

    return(
        <Button onClick={onClick} className={classes.root} startIcon={<Icon>shopping_cart</Icon>}>Orden</Button>
    )
}

export default OrderButton

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.secondary.main,
        borderRadius:32,
        textTransform:'none',
        color:'white',
        padding:'6px 30px',
        '&:hover':{
            background:theme.palette.secondary.main,
        }
    },
}))

import nobrand from '../../assets/placeholders/nobrand.png'

export const translations = {
    title:'Inventario',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Código'},
            {id:3, label:'Marca'},
            {id:4, label:'Nombre'},
            {id:5, label:'Descripción'},
            {id:6, label:'Precio'},
            {id:7, label:'Ubicación'},
            
        ],
        nodata:'No existen productos registrados',
        nobrand:nobrand
    }
    
}

export default translations
import React from 'react'
import { makeStyles, TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

import './input_styles.css'

const CssTextField = withStyles({
    root: {
        //paddingTop:6,
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        
        '& fieldset': {
          //borderColor: 'red',
          //background:'green',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
          //background:'green',
        },
      },
    },
  })(TextField);

  const useStyles = makeStyles(theme => ({
    prueba:{
        background:'white',
    },
    
}))

const InputForm = props => {

  const classes = useStyles()

    const {data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, onKeyPress} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''


    return(
        <div>
            <CssTextField  value={value} variant='outlined' color='secondary' 
            error={isError} {...interConfig} onChange={onInnerChange} onKeyPress={onKeyPress}
            InputProps={
                {
                    className:'input',
                    startAdornment:startAdornment ? startAdornment : undefined,
                    endAdornment:endAdornment ? endAdornment : undefined,
                    onKeyUp:onKeyUp ? onKeyUp : undefined,
                    onKeyDown:onKeyDown ? onKeyDown : undefined,
                }}
            />
        </div>
    )
}

export default InputForm
import { useEffect, useState } from "react"
import { private_server } from "../../config"
import { onGetCatalogs, onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"
import moment from "moment"


const useAnalyticalsView = ({language, content}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [user_loading, setUserLoading] = useState(false)
    const [total_loading, setTotalLoading] = useState(false)

    const [antotal, setAntotal] = useState([])
    const [user_data, setUserData] = useState([])
    const [users, setUsers] = useState([])
    const [user_selected, setUserSelected] = useState(null);
    
    const [month_selected, setMonthSelected] = useState(0)
    const [year_selected, setYearSelected] = useState(moment().format("DD MM YYYY").substring(6));

    //console.log(year_selected)

    const [modals, setModals] = useState({
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                //const year = moment().format("DD MM YYYY")
                const req = await private_server.get(`analytic/total?year=${year_selected}`)
                const current_month = moment().format("M")
                //console.log(current_month)
                //console.log(req)
                if(req.data.data.total_data.length >= parseInt(current_month)) setMonthSelected(parseInt(current_month)-1)
                setAntotal(req.data.data.total_data)
                const req_users = await private_server.get(`user/all`)
                const _users_cat = req_users.data.data.users.map(item => {
                    return{
                        value: item.id_user, label:`${item.first_name} ${item.last_name}`, src:item.image, nopicture:content.nouser
                    }
                })
                console.log(_users_cat)
                setUsers(_users_cat)
                setUserSelected(_users_cat[0].value)
                const req_user = await private_server.get(`analytic/user/${_users_cat[0].value}?year=${year_selected}`)
                setUserData(req_user.data.data.user_data)
                
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onChangeUserFilter: async(value) => {
            setUserLoading(true)
            setUserSelected(value)
            try{
                const req_user = await private_server.get(`analytic/user/${value}?year=${year_selected}`)
                setUserData(req_user.data.data.user_data)
            }catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            setUserLoading(false)
            
        },
        onChangeTotalFilter: async(value) => {
            setTotalLoading(true)
            setYearSelected(value)
            console.log(value)
            try{
                const req = await private_server.get(`analytic/total?year=${value}`)
                setAntotal(req.data.data.total_data)
                const req_user = await private_server.get(`analytic/user/${user_selected}?year=${value}`)
                setUserData(req_user.data.data.user_data)
            }catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            setTotalLoading(false)
            
        },
        onMonthSelected: (month) =>{
            //console.log(month)
            setMonthSelected(month-1)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
    }

    const system = {loading, sending, error, user_loading, total_loading}

    return {system, modals, actions, antotal, user_data, users, month_selected, year_selected} 
}

export default useAnalyticalsView
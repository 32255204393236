import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { generic_errors } from "../../../../texts/esp/genericErrors"
import system_translations from "../../../../texts/system_translations"
import FileSaver from 'file-saver'



const useOrdersClientModal = ({open, origin, language, id, content, onClose, onCompleted}) => {

    const catalogs = system_translations[language].catalogs

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [orders, setOrders] = useState([])


    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
    }, [open])

    
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            setError(null)
            try{
                setError(null)
                updateAuthorizationHeader(private_server)
                let filter = {filter:
                    {"AND":[
                        {"field":"client_id","operator":"=","value":`${id}`},
                    ]}           
                }
                const req = await private_server.get(`order/all?order=1&order_by=id_order`, {params:{...filter}})
                setOrders(req.data.data.orders)
                setLoading(false)
            }catch(error){
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onDownloadReceipt: async(generated_receipt, invoiced, _id) =>{
            if(Boolean(generated_receipt)){
                /* try{
                    await private_server.post(`/receipt`, {order_id:id})
                }catch(error){
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                } */
            }
            if(Boolean(invoiced)){
                console.log('invoice')
                try{
                    const pdf = await private_server.get(`/order/${_id}/invoice/download/pdf/me`, {responseType: 'blob'})
                    FileSaver.saveAs(pdf.data, `factura`)
                }catch(error){
                    const _error = onGetErrorMessage(error, language)
                    setError(_error ? _error.message : null)
                }
            }
        },
        
    }

    return {loading, sending, error, orders, actions} 
}


export default useOrdersClientModal

const form_data = {
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    rfc: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'rfc',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'rfc'}
    },
    cfdi_use: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'cfdi_use',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select', }
    },
    zip: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'zip'}
    },
    tax_system: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'tax_system',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select', }
    },

}
import { Typography } from "@material-ui/core"


const ComingSoon = () => {
    
    return(
        <div style={{paddingTop:100}}><Typography variant='h4' align='center'>Próximamente</Typography></div>
    )
}

export default ComingSoon
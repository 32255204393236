import logo from '../../assets/logo_refaccionaria.png'

/// Celudoc searchbar icons
import calendar_icon from '../../assets/icons/calendar.svg'
import timer_icon from '../../assets/icons/clock.svg'
import moment from 'moment'

import logoTextIcon from '../../assets/logo-text.svg'

// Social media icons
import facebook_icon from '../../assets/icons/facebook.png'
import instagram_icon from '../../assets/icons/instagram.png'
import twitter_icon from '../../assets/icons/twitter.png'
import { USER_TYPES } from '../../config'



const general_translations = {
    sidebar:{
        logo:logo,
        title:'Monitoring System',
        list:[
            {
                name:'Usuarios',
                path:'/users',
                icon:'people',
                permissions:[USER_TYPES.ADMIN]
            },
            {
                name:'Clientes',
                path:'/clients',
                icon:'groups',
                permissions:[USER_TYPES.ADMIN, USER_TYPES.MANAGER, USER_TYPES.VENDOR]
            },
            {
                name:'Inventario',
                path:'/products',
                icon:'inventory_2',
                permissions:[USER_TYPES.ADMIN, USER_TYPES.VISOR]
            },
            {
                name:'Faltantes',
                path:'/missing',
                icon:'error',
                permissions:[USER_TYPES.ADMIN, USER_TYPES.MANAGER, USER_TYPES.VISOR]
            },
            {
                name:'Catálogos',
                path:'/catalogs',
                icon:'topic',
                options:[
                    {name:'Marcas', path:'/brands'},
                    {name:'Proveedores', path:'/providers'},
                    {name:'Grupos de compra', path:'/purchase-groups'}
                ],
                permissions:[USER_TYPES.ADMIN, USER_TYPES.MANAGER, USER_TYPES.VISOR]
            },
            {
                name:'Compras',
                path:'/purchases',
                icon:'arrow_downward',
                permissions:[USER_TYPES.ADMIN, USER_TYPES.MANAGER, USER_TYPES.VISOR]
            },
            {
                name:'Ventas',
                path:'/sales',
                icon:'arrow_upward',
                permissions:[]
            },
            {
                name:'Seguimiento',
                path:'/tracking',
                icon:'attach_money',
                permissions:[USER_TYPES.ADMIN, USER_TYPES.MANAGER]
            },
            {
                name:'Analíticos',
                path:'/analyticals',
                icon:'equalizer',
                permissions:[USER_TYPES.ADMIN, USER_TYPES.MANAGER, USER_TYPES.VISOR]
            }
        ]
    },
    topbar:{
        titles:[
            {
                url:'/',
                title:'Welcome!'
            },
            {
                url:'/devices',
                title:'Devices'
            },
            {
                url:'/locations',
                title:'Locations'
            },
        ],
        cart_button:{
            remove:'Eliminar',
            subtotal:'Subtotal',
            view_button:'Ver carrito',
            pay_button:'Pagar'
        },
        /* user_button:{
            nouser:[
                {id:1, label:'Iniciar sesión', url:'/signin'},
                {id:2, label:'Regístrate', url:'/signup'},
                {id:3, label:'Hazte un celudoc', url:'/', top_line:true},
                {id:4, label:'Nuestras membresías', url:'/'},
                {id:5, label:'Ayuda', url:'/', top_line:true},
                {id:6, label:'Contáctanos', url:'/'},
            ],
            user:[
                {id:1, label:'Consultas', url:'/account/meetings'},
                {id:2, label:'Pedidos', url:'/account/my-orders'},
                {id:3, label:'Historia clínica', url:'/',},
                {id:4, label:'Recordatorios', url:'/'},
                {id:5, label:'Cuenta', url:'/account', top_line:true},
                {id:6, label:'Celupuntos', url:'/'},
                {id:7, label:'Ayuda', url:'/', top_line:true},
                {id:8, label:'Contáctanos', url:'/'},
                {id:9, label:'Cerrar sesión', url:'/logout'},
            ]
        }, */
        user_button:[
            {id:1, label:'Mi perfil', url:'/my-profile'},
            {id:2, label:'Cerrar sesión', url:'/logout'},
        ],
        searchbar_menu:[
            {id:1, label:'Celufarma'},
            {id:2, label:'Celudoc'},
            {id:3, label:'Celuhistoria'},
        ],
        searchbar:{
            placeholder:'¿Cómo te podemos ayudar?',
            placeholder_mobile:'Buscar...',
            primary_menu:[
                {id:1, label:'Celufarma'},
                {id:2, label:'Celudoc'},
                {id:3, label:'Celuhistoria'},
            ],
            celudoc:{
                speciality_placeholder:'Especialidad',
                date_placeholder:'¿Cuándo?',
                date_options:[
                    {id:1, label:'Lo antes posible', icon:timer_icon},
                    {id:2, label:'Escoge una fecha', icon:calendar_icon},
                ],
                specialities_options:[
                    {id:1, label:'Dermatología'},
                    {id:2, label:'Medicina General'},
                    {id:3, label:'Nutrición'},
                    {id:4, label:'Psicología'},
                ],
                appointment:{
                    labels:{
                        date:'Fecha',
                        time:'Hora'
                    }
                }
            },
            celupharmacy:{
                placeholder:'Especialidad',
                speciality_options:[
                    {id:1, label:'Dermatología'},
                    {id:2, label:'Medicina General'},
                    {id:3, label:'Nutrición'},
                    {id:4, label:'Psicología'}
                ]
            }
        },
    },
    footer:{
        links:[
            {id:1, label:'Celufarma', link:'/'},
            {id:2, label:'Celudoc', link:'/'},
            {id:3, label:'Celuhistoria', link:'/'},
        ],
        legal_links:[
            {id:2, label:`Aviso de Privacidad ${moment().format('YYYY')}`, url:'/privacy', target:'_blank'},
            {id:1, label:'Términos y condiciones', url:'/terms-conditions', target:'_blank'},
        ],
        logo:logoTextIcon,
        social_media:{
            title:'Contáctanos',
            media:[
                {id:1, icon:facebook_icon, url:'/', target:'_blank'},
                {id:2, icon:instagram_icon, url:'/', target:'_blank'},
                {id:3, icon:twitter_icon, url:'/', target:'_blank'},
            ],
        }
    },
    
}

export default general_translations
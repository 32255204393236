import { useEffect, useRef, useState } from "react"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { generic_errors } from "../../../../texts/esp/genericErrors"
import system_translations from "../../../../texts/system_translations"

const useEditClientModal = ({open, origin, language}) => {

    const catalogs = system_translations[language].catalogs

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))


    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
    }, [origin, open])

    /* const onRequestSearch = async() => {
        if(timerRef.current) clearTimeout(timerRef.current)
        // Start de search
        try{
            updateAuthorizationHeader(private_server)
            const zip_request = await private_server.get(`/address/zipinfo?zip=${form.zip.value}`)
            const zip_info = zip_request.data.data.zip_information
            if(zip_info){
                console.log('Actualzar campos')
                const {municipality, state, sub_urbs, zip_code} = zip_info
                let _form = {...form}
                _form = onSetValueInForm(_form, 'zip_code_id', zip_code.id_zip_code)
                _form = onSetValueInForm(_form, 'municipality_id', municipality.id_municipality)
                _form = onSetValueInForm(_form, 'state_id', municipality.state_id)
                _form.state_id.options = [{value:municipality.state_id, label:municipality.state}]
                _form.municipality_id.options = [{value:municipality.id_municipality, label:municipality.name}]
                const suburbs_options = onGetCatalogs(sub_urbs, 'id_suburb','name')
                _form.suburb_id.options = [...suburbs_options]
                _form = onSetValueInForm(_form, 'suburb_id', null)
                setForm(_form)
            }else{
                let _form = {...form}
                _form = onSetValueInForm(_form, 'zip_code_id', null)
                _form = onSetValueInForm(_form, 'municipality_id', null)
                _form = onSetValueInForm(_form, 'state_id', null)
                _form = onSetValueInForm(_form, 'suburb_id', null)
                _form.municipality_id.options = []
                _form.state_id.options = []
                _form.suburb_id.options = []
                setForm(_form)
            }
        }catch(e){
            console.log(e)
        }
    } */
    
    const actions = {
        onInitModule: async() => {
            
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, origin)

            /* try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/address/me/${origin.id_address}`, data2send)
                const adresses_request = await private_server.get('/address/list/me')
                onCompleted(adresses_request.data.data.user_addresses)
                setError(null)
                setSending(false)

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            } */
            
        }
    }

    return {loading, sending, error, form, actions} 
}


export default useEditClientModal

const form_data = {
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    rfc: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'rfc',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    invoice_usage: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'invoice_usage',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },

}
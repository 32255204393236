import { makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import system_translations from "../../../../texts/system_translations"


const DownloadDocModal = ({open, onClose, language}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.download_doc

    return(
        <SimpleModal open={open} onClose={onClose}  >
            <div className={classes.title}>
                <Typography variant='h6'>{content.title}</Typography>
            </div>  
            <div style={{textAlign:'center'}}>
                <img src={content.image} alt='' />
            </div>
            <div style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                <Typography style={{maxWidth:200}}>{content.message}</Typography>
            </div>    
            <div className={classes.button_container}>
                <RoundedButton>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default DownloadDocModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #363636',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:'#363636'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    
}))
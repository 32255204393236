import React, { useState, useEffect } from 'react';
import { makeStyles, Fade, useTheme } from '@material-ui/core';
import { CONFIG_SETTINGS } from '../../config';
import AddButton from '../Actions/AddButton'
import EditButton from '../Actions/EditButton';
import { isValidDocument } from '../../shared/utility';

const image_ratio = {
    '1/1':'100%',
    '16/9':'56.25%',
    '4/3':'75%',
    '2/3':'133%',
    '5/4':'80%',
}

const ImageContainer = ({src, size, noborder, ratio, onUpload, onEdit, src_temp, variant, nopicture}) => {

    const classes = useStyles()
    const theme = useTheme()
    const [error, setError] = useState(false)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let selectedImage = nopicture
    if(src){
        const isValid = isValidDocument(src)
        selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : nopicture
        //selectedImage = src
    }

    if(src_temp){
        selectedImage = src_temp
    }

    //if(error) selectedImage = nopicture


    const _ratio = ratio ? ratio : '1/1'

    let image_height = image_ratio[_ratio] 

    return ( 
        <div>
            <div className={classes.image_container} style={{paddingTop:image_height}}>
                {onUpload ? <div className={classes.upload_container}><AddButton onClick={onUpload} /></div> : null}
                {onEdit ? <div className={classes.edit_container}><EditButton variant='modal' onClick={onEdit} /></div> : null}    
                {src && isValidDocument(src) && !error || src_temp ? 
                <Fade in={!error}>
                    <div className={classes.image_wrapper}>
                        <img alt='' src={selectedImage} className={classes.image} style={{objectFit: variant ? variant : 'contain'}} 
                        onLoad={onImageLoad} 
                        onError={onError} />
                    </div>     
                </Fade> 
                :
                <div className={classes.image_wrapper_noimage}><img src={nopicture}  alt='' className={classes.noimage} /></div>}  
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    image_container:{
        position:'relative',
        margin:'auto',
        width:'100%',
        paddingTop:'100%',
        boxSizing:'border-box',
        borderRadius:8,
        background:'white',
    },
    image_wrapper:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        border:'1.2508px solid #EAEAEA',
        borderRadius:2.5,
    },
    image_wrapper_noimage:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        border:'1.2508px solid #EAEAEA',
        borderRadius:2.5,
        //padding:40,
        boxSizing:'border-box',
        //background:'red',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain',
    },
    noimage:{
        //width:'100%',
        //height:'100%',
        width:150,
        //height:100,
        //objectFit:'contain',
        //padding:40,
        //boxSizing:'border-box'
    },
    svg_container:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        padding:8,
        boxSizing:'border-box'
    },
    upload_container:{
        width:'100%',
        height:'100%',
        background:'#C9C9C9',
        position:'absolute',
        top:0,
        left:0,
        zIndex:5,
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    edit_container:{
        position:'absolute',
        top:12,
        right:12,
        zIndex:5,
        //width:'100%',
        //height:'100%',
        //background:'red'
    },
    src_crop:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        //background:'red',
        //zIndex:7,
        cursor:'pointer'
    }
}))
 
export default ImageContainer;

{/* <Fade in={!error}>
                    <div className={classes.image_wrapper}>
                        <img alt='' src={imgSrc} className={classes.image} style={{objectFit: variant ? variant : 'contain'}} onLoad={onImageLoad} onError={onError} />
                    </div>     
                </Fade> */}
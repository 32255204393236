import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import InputSelectForm from "../../../../../components/Forms/InputSelectForm"
import SelectAutocompleteForm from "../../../../../components/Forms/SelectAutocompleteForm"
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"
import Header from "../../../../../components/Structure/Texts/Header"
import system_translations from "../../../../../texts/system_translations"
import useGenerateInvoiceModal from "./useGenerateInvoiceModal"


const GenerateInvoiceModal = ({open, onClose, language, client, id, onCompleted, onAddClient}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.generate_invoice
    const {loading, sending, error, actions,  form} = useGenerateInvoiceModal({open, language, 
            client, id, onCompleted, onClose})

    

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' loading={loading} >
            <div className={classes.title}>
                <Typography variant='h6'>{content.title}</Typography>
            </div>  
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Header>{content.form.id_client.tag}</Header>
                    <SelectAutocompleteForm data={form.id_client} onChange={actions.onChangeForm} 
                    //open={invoice_open} loading={invoice_loading}
                    //onOpen={()=>actions.onChangeSelectOpen(true)} onClose={()=>actions.onChangeSelectOpen(false)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Header>{content.form.cfdi_use.tag}</Header>
                    <InputSelectForm data={form.cfdi_use} onChange={actions.onChangeForm}  />
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item><RoundedButton color='white' onClick={onAddClient}>{content.button_client}</RoundedButton></Grid>
                    <Grid item><RoundedButton loading={sending} onClick={actions.onSubmit}>{content.button}</RoundedButton></Grid>
                </Grid>     
            </div>     
        </SimpleModal>
    )

}

export default GenerateInvoiceModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #363636',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:'#363636'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
}))
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import DeleteButton from '../../../components/Actions/DeleteButton';
import RoundedButton from '../../../components/Actions/RoundedButton';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import Header from '../../../components/Structure/Texts/Header';
import { nodata, USER_TYPES } from '../../../config';
import system_translations from '../../../texts/system_translations'
import Products from './components/Products';
import AddProductToPGModal from './modals/AddProductToPGModal/AddProductToPGModal';
import DeletePGProductModal from './modals/DeletePGProductModal';
import DeletePurchaseGroupModal from './modals/DeletePurchaseGroupModal';
import EditPurchaseGroupModal from './modals/EditPurchaseGroupModal/EditPurchaseGroupModal';
import usePurchaseGroupsView from './usePurchaseGroupView';


const PurchaseGroupView = ({language, history, match, user}) => {

    const id = match.params.id
    const content = system_translations[language].views.sale_group
    const classes = useStyles()
    const {actions, modals, view_data, system} = usePurchaseGroupsView({history, match, language})
    const {purchase_group, products, id_product} = view_data

    let can_edit = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_edit = false

    return(
        <Page title={content.title} goback loading={system.loading} error={system.error} onCloseErrorModal={actions.onClearError}
            language={language}>
            <EditPurchaseGroupModal language={language} open={modals.edit_purchasegroup} onClose={()=>actions.onChangeModalStatus('edit_purchasegroup', false)} 
                onCompleted={actions.onInitModule} origin={purchase_group}/>
            <DeletePurchaseGroupModal language={language} open={modals.delete_purchasegroup} onClose={()=>actions.onChangeModalStatus('delete_purchasegroup', false)}
                onCompleted={()=>{history.push('/purchase-groups')}} id={id}/>
            <AddProductToPGModal language={language} open={modals.add_product} onClose={()=>actions.onChangeModalStatus('add_product', false)}
                 id={id} onCompleted={actions.onCompletedProducts}/>
            <DeletePGProductModal language={language} open={modals.delete_product} onClose={()=>actions.onChangeModalStatus('delete_product', false)}
                 id_product={id_product} onCompleted={actions.onCompletedProducts}/>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                <div className={classes.topbar}>
                    {can_edit ? <Grid container justifyContent='flex-end' spacing={2} alignItems='center'>
                        <Grid item><RoundedButton onClick={()=>actions.onChangeModalStatus('edit_purchasegroup', true)}>{content.general.button}</RoundedButton></Grid>
                        <Grid item><DeleteButton onClick={()=>actions.onChangeModalStatus('delete_purchasegroup', true)} /></Grid>
                    </Grid> : null}  
                </div>
                <SimpleCard>
                    <Grid container>
                        <Grid item md={3}>
                            <Header>{content.general.name}</Header>
                            <Typography variant='body2'>
                                {purchase_group ? purchase_group.name : nodata}
                            </Typography>
                        </Grid>
                        <Grid item md={9}>
                            <Header>{content.general.description}</Header>
                            <Typography variant='body2'>
                                {purchase_group ? purchase_group.description : nodata}
                            </Typography>
                        </Grid>
                    </Grid>
                </SimpleCard>
                </Grid>
                <Grid item xs={12}>
                    <Products content={content.products} products={products} actions={actions} can_edit={can_edit} />
                </Grid>
            </Grid>
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user:state.user
    }
}

export default connect(mapStateToProps)(PurchaseGroupView) 

const useStyles = makeStyles(theme => ({
    topbar:{
        marginBottom:20
    },
    
    
}))
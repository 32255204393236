import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { useState } from "react"
import { green, red } from "@material-ui/core/colors"
import SimpleCard from "../../../components/Structure/Cards/SimpleCard"
import { onGetCurrencyValue } from "../../../shared/utility"
import AddButton from "./AddButton"


const EquivalencesTable = props => {

    const { content, data, onAdd, loading } = props
    const classes = useStyles()

    const header = content.header

    const [selected, setSelected] = useState(null);

    const onAddItem = (id) =>{
        setSelected(id)
        onAdd(id)
    }

    return(
        <SimpleCard>
            <div className={classes.toolbar}>
                <Typography variant='subtitle1' className={classes.title}>{content.title}</Typography>
            </div>        
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell  key={item}>
                                                <Typography variant='caption' className={classes.header}>{item}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {product_code, product_name, product_id, brand, quantity, wholesale_price, retail_price, location} = item

                                return(
                                    <TableRow key={key.toString()} className={classes.row} >
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{product_code}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{quantity}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{product_name}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{brand}</Typography></TableCell>
                                        <TableCell className={classes.cell} >
                                            <Grid container spacing={1} wrap='nowrap'>
                                                <Grid item><Icon style={{color:red[700]}}>arrow_downward</Icon></Grid>
                                                <Grid item><Typography style={{color:red[700]}}>{onGetCurrencyValue(wholesale_price)}</Typography></Grid>
                                                <Grid item><Typography>-</Typography></Grid>
                                                <Grid item><Icon style={{color:green[500]}}>arrow_upward</Icon></Grid>
                                                <Grid item><Typography style={{color:green[500]}}>{onGetCurrencyValue(retail_price)}</Typography></Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{location}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><AddButton disabled={selected === product_id && loading} onClick={()=>onAddItem(product_id)} /></TableCell>
                                    </TableRow>
                                )
                            })}                           
                        </TableBody>
                    </Table>
                </div>           
            ): null}     
        </SimpleCard>
    )
}

export default EquivalencesTable

const useStyles = makeStyles(theme => ({
    rows:{
        marginTop:30
    },
    header:{
        font:theme.typography.fontFamily,
        fontSize:'0.875rem',
        color:'#909090',
        fontWeight:500
    },
    title:{
        color:theme.palette.primary.dark
    },
    tableWrapper:{
        overflowX: 'auto',
        //background:'#fbfbfb'
    },
}))






import { Grid, makeStyles, Typography } from "@material-ui/core"
import cx from 'classnames'

const CustomSwitch = ({label1, label2, selected, onSwitch}) => {

    const classes = useStyles()

    return(
        <Grid container>
            <Grid item>
                <div style={{borderRadius:'5px 0px 0px 5px'}} className={cx({[classes.normal]:true, [classes.active]:selected === 1})}
                    onClick={()=>onSwitch(1)}>
                    <Typography variant='body2'>{label1}</Typography>
                </div>
            </Grid>
            <Grid item>
                <div style={{borderRadius:'0px 5px 5px 0px'}} className={cx({[classes.normal]:true, [classes.active]:selected === 0})}
                    onClick={()=>onSwitch(0)}>
                    <Typography variant='body2'>{label2}</Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default CustomSwitch

const useStyles = makeStyles(theme => ({
    normal:{
        padding:'12px 32px',
        background:'#D4D4D4',
        color:'#656565',
        cursor:'pointer'
    },
    active:{
        background:theme.palette.secondary.main,
        color:'white'
    }
}))
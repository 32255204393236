import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { generic_errors } from "../../../../texts/esp/genericErrors"
import system_translations from "../../../../texts/system_translations"

const useEditClientModal = ({open, origin, language, id, content, onClose, onCompleted}) => {

    const catalogs = system_translations[language].catalogs

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [client, setClient] = useState(null)


    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
    }, [open])

    
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            setError(null)
            try{
                setError(null)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`client/${id}`)
                console.log(req)
                const _client = req.data.data.client
                setClient(_client)
                let _form = onInitForm(form, _client, content.form)
                const req_catalogs = await private_server.get(`order/sat/cfdiuses`)
                const _catalogs = req_catalogs.data.data.cfdi_uses.map(item => {
                    return{
                        value: item.code, label:item.code + ' - ' + item.name
                    }})    
                _form.cfdi_use.options = _catalogs
                _form.tax_system.options = onGetCatalogs(catalogs.tax_systems, 'code', 'label')
                setForm(_form)
                setLoading(false)
            }catch(error){
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, client)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/client/${id}`, data2send)    
                setError(null)
                setSending(false)
                onClose()
                onCompleted()     
            } catch (error) {
                setSending(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                /* if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    console.log(_error_keys)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                } */
                setError(_error ? _error.error_details : null)
                //setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions} 
}


export default useEditClientModal

const form_data = {
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    rfc: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'rfc',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'rfc'}
    },
    cfdi_use: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'cfdi_use',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select', }
    },
    zip: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'zip'}
    },
    tax_system: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'tax_system',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select', }
    },

}
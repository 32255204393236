import { useEffect, useState } from "react"
import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography  } from "@material-ui/core"
import RoundedButton from "../../../../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../../../../components/Modals/SimpleModal"
import Header from "../../../../../../../components/Structure/Texts/Header"
import system_translations from "../../../../../../../texts/system_translations"
import useAddProviderModal from "./useAddProviderModal"
import DisplayError from "../../../../../../../components/Structure/DisplayData/DisplayError"
//import SelectAutocompleteForm from "../../../../../../../components/Forms/SelectAutocompleteForm"
//import ProductAutocompleteForm from "../../../../../../../components/Forms/ProductAutocomplete"
import ProductSearchBar from "../../../../../../../components/Actions/ProductSearchBar"
import AddButton from "./AddButton"

const AddProviderModal = ({open, onClose, language, id, onCompleted, allProviders, productProviders}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.complement_modal
    const {loading, sending, error, form, actions, prod_form, options, product_selected, loading_prod, search} = useAddProviderModal({open, language, onClose, id, onCompleted})

    const [selected, setSelected] = useState(null);

    const onAddItem = (id) =>{
        setSelected(id)
        //onAdd(id)
    }

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>Agregar proveedor</Typography>
                </Grid>
                <Grid item xs={12}>
                    {allProviders && allProviders.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <TableBody>
                                {allProviders.map((item,key) => {
                                    const {id_provider, name} = item
                                    return(
                                        <TableRow key={key.toString()} className={classes.row}>
                                            <TableCell className={classes.cell} style={{width:"100%"}}><Typography variant='body2'>{name}</Typography></TableCell>
                                            <TableCell className={classes.cell} ><AddButton disabled={productProviders.some(item => item.provider_id === id_provider)} onClick={()=>actions.onSubmit(id_provider)} /></TableCell>
                                        </TableRow>
                                    )
                                })}                           
                            </TableBody>
                        </Table>
                    </div>           
                    ): null}  
                </Grid>
            </Grid>
        </SimpleModal>
    )

}

export default AddProviderModal

const useStyles = makeStyles(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    pic_container:{
        position:'relative'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    },
}))
import { useEffect, useState } from "react"
import { private_server } from "../../../../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../../../shared/utility"
import { generic_errors } from "../../../../../../../texts/esp/genericErrors"
import useProduct from "../../../../../../../hooks/api/useProduct"

const useAddProviderModal = ({open, language, onClose, onCompleted, id}) => {

    
    const productManager = useProduct()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [loading_prod, setLoadingProd] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [options, setOptions] = useState([])

    const [search, setSearch] = useState(null)

    useEffect(() => {
        if(!open){
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
        }
    }, [open])

    

    let prod_form = {...form.complement_product_id}
    prod_form.options = [...options]
    
    const actions = {
        onInitModule: async() => {
            
        },
        onSubmit: async(providerSelected) => {
            console.log(providerSelected)
            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.post(`/providerproduct`, {product_id: id, provider_id: providerSelected})
                onCompleted()
                onClose()
                setError(null)
                setSending(false)

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, prod_form, options, loading_prod, search} 
}


export default useAddProviderModal

const form_data = {
    complement_product_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'provider_product_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
}
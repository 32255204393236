import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import DeleteButton from "../../../../components/Actions/DeleteButton"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"


const MiniTableClientV2 = props => {

    const { content, data, onAdd, header} = props
    const classes = useStyles()

    console.log(data)
    console.log(header)

    return(
        <SimpleCard>      
            <div className={classes.root}> 
    
                <Table className={classes.table}>
                    {header ? (
                        <TableHead>
                            <TableRow>
                                {header.map(item=>{
                                    return(
                                        <TableCell padding='default' key={item}>
                                            <Typography className={classes.header}>{item}</Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    ):null}
                    <TableBody>
                        <TableRow  >
                            <TableCell className={classes.cell} ><Typography>{data?.name}</Typography></TableCell>
                            <TableCell className={classes.cell} ><Typography>{data?.email}</Typography></TableCell>
                            <TableCell className={classes.cell} ><Typography>{data?.rfc}</Typography></TableCell>
                            <TableCell className={classes.cell} ><Typography>{data?.cfdi_use}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            
           </div>
        </SimpleCard>
    )
}

export default MiniTableClientV2

const useStyles = makeStyles(theme => ({
    root:{
        overflowX:'auto',
        overflowY:'hidden'
    },
    rows:{
        //marginTop:40
        minWidth:750
    },
    header:{
        font:theme.typography.fontFamily,
        fontSize:'0.9rem',
        //color:'#909090',
        fontWeight:700,
        //borderBottom:'1px solid #E2E2E2',
        //padding:'0px 40px 20px'
        //width:'100%'
        //padding:''
    },
    cell:{
        borderBottom:'none'
    }
   
}))

const Row = ({name, email, rfc, cfdi_use}) => {

    const classes = useStylesRow()

    return(
        <div >
            <Grid container >
                <Grid item xs><div className={classes.root}>{name}</div></Grid>
                <Grid item xs><div className={classes.root}>{email}</div></Grid>
                <Grid item xs><div className={classes.root}>{rfc}</div></Grid>
                <Grid item xs><div className={classes.root}>{cfdi_use}</div></Grid>
            </Grid>
        </div>
    )
}

const useStylesRow = makeStyles(theme => ({
    root:{
        //borderBottom:'1px solid #D2D2D2',
        padding:'20px 40px',
        font:theme.typography.fontFamily,
        //fontWeight:500
    },   
}))




import moment from "moment"
import FileSaver from 'file-saver'
import { useEffect, useState } from "react"
import { private_server } from "../../config"
import { onGetDateFormat, onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"

const useTrackingView = ({ language }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [invoices, setInvoices] = useState([])
    const [selected, setSelected] = useState(null)
    const [summary, setSummary] = useState([])
    const [date, setDate] = useState(moment().format('DD/MM/YYYY'))
    
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_global_invoice'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        finish_day:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                const req = await private_server.get(`globalinvoice/all`, {params:{...params}})
                //console.log(req)
                const _global_invoices = req.data.data
                setInvoices(_global_invoices.global_invoices)
                setTotal(_global_invoices.count)
                //const start_date = moment().utc().format('YYYY-MM-DD hh:mm:ss')
                //const end_date = moment().add(1 ,'day').utc().format('YYYY-MM-DD hh:mm:ss')
                const current_time = moment().format('HH')
                const start_date = moment().format('YYYY-MM-DD 06:00:00')
                const end_date = moment().add(1 ,'day').format('YYYY-MM-DD 06:00:00')
                console.log(start_date)
                console.log(end_date)
                const summary_req = await private_server.get(`analytic/summary?start_date=${start_date}&end_date=${end_date}`)
                setSummary(summary_req.data.data.summary)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onSelectItem: async(_date, _id) => {
            if(!_date) return 
            const momentdata = moment(_date)
            if(!momentdata.isValid()) return 
            setSelected(_id) 
            setDate(moment.utc(_date).local().format('DD/MM/YYYY'))
            const start_date = _date
            const end_date = moment(_date).add(1 ,'day').format('YYYY-MM-DD hh:mm:ss')
            console.log(start_date)
            console.log(end_date)
            const summary_req = await private_server.get(`analytic/summary?start_date=${start_date}&end_date=${end_date}`)
            setSummary(summary_req.data.data.summary)       
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeSearch: (value)=>{
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => { 
            //let newTableFilter = {...tableFilter}
            //newTableFilter.offset = 0
            setTableFilter(newTableFilter)
        },
        onClearError:()=>{
            setError(null)
        },
        onDownloadCashInvoice:async(type, _id) =>{
            try{
                if(type === 'PDF'){
                    const pdf = await private_server.get(`/globalinvoice/${_id}/cashinvoice/download/pdf`, {responseType: 'blob'})
                    FileSaver.saveAs(pdf.data, `factura`)
                }else{
                    const xml = await private_server.get(`/globalinvoice/${_id}/cashinvoice/download/xml`, {responseType: 'blob'})
                    FileSaver.saveAs(xml.data, `factura`)
                }                   
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onDownloadCardInvoice:async(type, _id) =>{
            try{
                if(type === 'PDF'){
                    const pdf = await private_server.get(`/globalinvoice/${_id}/cardinvoice/download/pdf`, {responseType: 'blob'})
                    FileSaver.saveAs(pdf.data, `factura`)
                }else{
                    const xml = await private_server.get(`/globalinvoice/${_id}/cardinvoice/download/xml`, {responseType: 'blob'})
                    FileSaver.saveAs(xml.data, `factura`)
                }                   
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        }
    }

    const system = {loading, sending, error}
    const view_data = {invoices, tableFilter, search, total, selected, summary, date}

    return {system, modals, view_data, actions}
}

export default useTrackingView
import { createStore } from 'redux'
import { updateObject } from '../shared/utility'
import { actionTypes } from './actions'

const initialState = {
    user:null,
    is_auth:false,
    token:null,
    language:'es',
    cart:null,
    ip_address:null,
    drawer_status:true,
    blur:false,
    order:null,
    edit_order_products:false,
    /////////// Modals /////////////
    modals:{
        signin:false,
        singup:false,
        register:false,
    },
    pay_modal:false,

    // Error Handler
    error:null,
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.AUTH_UPDATE_STATUS:
            return updateObject(state, {is_auth:action.is_auth})
        case actionTypes.AUTH_UPDATE_ORDER:
            return updateObject(state, {order:action.order})
        case actionTypes.SYS_UPDATE_EDIT_FLAG:
            return updateObject(state, {edit_order_products:action.edit_order_products})
        case actionTypes.SYS_UPDATE_IP_ADDRESS:
            return updateObject(state, {ip_address:action.ip_address})
        case actionTypes.SYS_UPDATE_DRAWER_STATUS:
            return updateObject(state, {drawer_status:action.drawer_status})
        case actionTypes.SYS_UPDATE_BLUR:
            return updateObject(state, {blur:action.blur})
        case actionTypes.SYS_UPDATE_TOKEN:
            return updateObject(state, {token:action.token})
        case actionTypes.SYS_UPDATE_SESSION_STATUS:
            return updateObject(state, {session_status:action.session_status})
        //////////////////////   MODALS   //////////////////////
        case actionTypes.SYS_UPDATE_MODAL:
            return updateObject(state, {modals:{...state.modals, ...action.modal}})
        case actionTypes.SYS_UPDATE_PAY_MODAL:
            return updateObject(state, {pay_modal: action.pay_modal})
        //////////////////////   ERROR HANDLER   //////////////////////
        case actionTypes.SYS_UPDATE_ERROR:
            return updateObject(state, {error:action.error})
        default:
            return state;

    }
}

export const store = createStore(reducer)


import React, { useState } from 'react';
import system_translations from '../../../../../../../texts/system_translations';
import PictureModal from '../../../../../../../components/Modals/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../../../../shared/utility'

const ChangeImageModal = (props) => {

    const {open, onClose, onUpdateDataImage, language} = props
    const content = system_translations[language].modals.change_image_modal

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        try {
            setLoading(true)
            console.log(file)
            const _file = await onGetImageURL2Blob(file)
            const data = {name:'logo', ext:onGetFileExtension(_file.name)}
            onUpdateDataImage(data, _file, file)
            onClose()
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
    }


    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} language={language}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} />
     );
}
 
export default ChangeImageModal;
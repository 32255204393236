import { Icon, IconButton, makeStyles } from "@material-ui/core"


const DeleteButton = ({size, onClick, card, disabled}) => {

    const classes = useStyles()

    return(
        <IconButton onClick={onClick} disabled={disabled} size={size ? size : 'medium'} className={card ? classes.button_card : classes.button}><Icon>delete</Icon></IconButton>
    )

}

export default DeleteButton

const useStyles = makeStyles(theme => ({
    button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        padding:6
    },
    button_card:{
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)',
        color:theme.palette.primary.main,
        padding:8
    }
    
}))
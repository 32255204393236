
const ERROR_CODES = {
    101:'La solicitud no tiene encabezado de autorización',
    102:'Token expirado',
    103:'Token no válido',
    104:'Credenciales inválidas',
    105:'Usuario no autorizado para ejecutar esta acción',
    106:'Contraseña incorrecta',
    107:'Error en autorización',
    108:'El usuario se encuentra deshabilitado',
    201:'La información enviada es inválida',
    301:'Error con el servidor o base de datos',
    302:'Error al descifrar la contraseña',
    303:'Error al cifrar la contraseña',
    304:'No se pudo subir la imagen',
    305:'No se pudo subir el archivo',
    306:'Error al listar archivos',
    307:'No se pudo eliminar el archivo',
    308:'Error al descargar archivo',
    309:'Archivo faltante',
    310:'Error en los servicios de correo',
    311:'Archivo no encontrado',
    312:'Error en el servicio de facturación',
    401:'Usuario no encontrado',
    402:'El correo electrónico ya está en uso',
    403:'El nombre de usuario ya está en uso',
    404:'Género no encontrado',
    405:'Estatus de usuario no encontrado',
    406:'Tipo de usuario no encontrado',
    407:'Producto no encontrado',
    408:'El código ya está en uso',
    409:'El nombre ya está en uso',
    410:'Marca no encontrada',
    411:'Grupo de compra no encontrado',
    412:'Equivalencia no encontrada',
    413:'Producto 1 no encontrado',
    414:'Producto 2 no encontrado',
    415:'La equivalencia ya existe',
    416:'Complemento no encontrado',
    417:'Producto principal no encontrado',
    418:'Producto complementario no encontrado',
    419:'El complemento ya existe',
    420:'Proveedor no encontrado',
    421:'Cliente no encontrado',
    422:'El RFC ya está en uso',
    423:'Orden no encontrada',
    424:'Tipo de precio no encontrado',
    425:'Estatus de orden no encontrado',
    426:'Tipo de pago no encontrado',
    427:'Vendedor no encontrado',
    428:'Tipo de recibo no encontrado',
    429:'Recibo no encontrado',
    430:'El pedido debería estar pagado',
    431:'El pedido debería estar creado o entregado',
    432:'EL pedido ya fue cancelado',
    433:'El pedido debería estar creado',
    434:'El pedido debería estar cancelado',
    435:'Artículo de pedido no encontrado',
    436:'No hay la cantidad de productos suficientes en el inventario',
    437:'El producto ya existe en la orden',
    438:'El pedido no pertenece al usuario',
    439:'Recibo no encontrado',
    440:'El pedido debería estar pagado o entregado',
    441:'El tipo de recibo del pedido debe ser una nota foliada',
    442:'El pedido ya tiene un recibo',
    443:'Compra no encontrada',
    444:'Estatus de compra no encontrado',
    445:'La compra debería estar ordenada',
    446:'La compra debería estar creada o recibida',
    447:'La compra debería estar ordenada',
    448:'La compra debería estar cancelada',
    449:'Artículo de compra no encontrado',
    450:'La compra debería estar creada',
    451:'El producto ya existe en la orden de compra',
    452:'La compra debe ser facturada',
    453:'Código fiscal del producto no encontrado',
    454:'Unidad no encontrada',
    455:'RFC no válido',
    456:'Orden de devolución no encontrada',
    457:'El pedido debería estar entregado',
    458:'Tipo de orden de devolución no encontrado',
    459:'Estatus de orden de devolución no encontrado',
    460:'La orden de devolución debería estar completada',
    461:'La orden de devolución debería estar creada',
    462:'La orden de devolución debería estar cancelada',
    463:'Artículo de orden de devolución no encontrado',
    464:'La cantidad de artículos de la orden de devolución no puede ser mayor que la cantidad de artículos del pedido',
    465:'La orden de devolución no corresponde al artículo del pedido',
    466:'La identificación del producto es necesaria para la orden de devolución del tipo: intercambio',
    467:'Se requiere venta al por mayor para la orden de devolución del tipo: intercambio',
    468:'La cantidad no puede ser mayor que la cantidad del artículo del pedido',
    469:'El artículo de pedido ya existe en una orden de devolución',
    470:'El tipo de pago o el tipo de recibo no se pueden editar si ya se generó el recibo o la factura',
    471:'El tipo de recibo del pedido debe ser un recibo no certificado',
    472:'El pedido debe tener un recibo'
}

export default ERROR_CODES
import nouser from '../../assets/placeholders/nouser.png'

export const translations = {
    title:'Detalle de venta',
    return_order_button:'Añadir devolución',
    general:{
        back_button:['Cancelar', 'Cancelar pago', 'Cancelar entrega', 'Cancelar entrega'],
        start_date:'Fecha inicio',
        sale_date:'Fecha venta',
        billing_date:'Fecha facturación',
        cancel_date:'Fecha cancelación',
        price:'Precio',
        status:'Status',
        payment_method:'Método de pago',
        document:'Documento',
        vendor:'Vendedor',
        receipt:'Recibo',
        subtotal:'Subtotal',
        taxes:'Impuestos',
        total:'Total',
        nouser:nouser,
        receipt_button:['Generar recibo', 'Cancelar recibo'],
        next_button:['Pagar', 'Entregar', 'Entregado', 'Entregado']
    },
    client:{
        title:'Cliente',
        button:'Editar',
        header:[
            'Cliente',
            'Usuario',
            'RFC',
            'Uso de factura'
        ]

    },
    products:{
        title:'Productos',
        button:'Editar',
        header:[
            'Código',
            'Marca',
            'Producto',
            'Cantidad',
            'Devoluciones',
            'Precio',
            'Total'
        ]
    },
    return_order:{
        title:'Devoluciones',
        //back_button:['Cancelar', 'Volver a crear', 'Volver a crear'],
        //next_button:['Completar', 'Cancelar', 'Volver a crear'],
        back_button:'Cancelar',
        next_button:'Completar',
        start_date:'Fecha de inicio',
        return_date:'Fecha de devolución',
        cancelled_date:'Fecha de cancelación',
        seller:'Vendedor',
        type:'Tipo',
        status:'Estatus',
        total:'Total',
        subtotal:'Subtotal',
        tax:'Impuestos',
        nouser:nouser,
        
        products:{
            title:'Productos',
            button:'Añadir',
            header:[
                'Actual',
                'Cambio',
                'Cantidad',
                'Total',
                ''
            ]
        },
    }
    
}

export default translations
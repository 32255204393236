import { Button, CircularProgress, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import DeleteButton from '../../../components/Actions/DeleteButton';
import DownloadButton from '../../../components/Actions/DownloadButton';
import RoundedButton from '../../../components/Actions/RoundedButton';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import Header from '../../../components/Structure/Texts/Header';
import { nodata, ORDER_STATUSES, USER_TYPES } from '../../../config';
import { onGetCurrencyValue, onGetDateFormat } from '../../../shared/utility';
import system_translations from '../../../texts/system_translations'
import Client from './components/Client';
import Products from './components/Products';
import DownloadDocModal from './modals/DownloadDocModal';
import ReceiptModal from './modals/ReceiptModal/ReceiptModal';
import SelectClientModal from './modals/SelectClient/SelectClientModal';
import useSaleView from './useSaleView';
import GenerateInvoiceModal from './modals/GenerateInvoiceModal/GenerateInvoiceModal';
import UserAvatarName from '../../../components/Structure/DisplayData/UserAvatarName';
import CancelReceiptModal from './modals/CancelReceiptModal';
import AddClientModal from './modals/AddClientModal/AddClientModal'
import { grey } from '@mui/material/colors';
import CancelModal from './modals/CancelModal';
import StatusData from '../../../components/Structure/DisplayData/StatusData';
import { getBottomNavigationActionUtilityClass } from '@mui/material';
import AddReturnOrderModal from './modals/AddReturnOrderModal';
import ReturnOrderView from './return_section/ReturnOrderView';
import DeleteReturnModal from './modals/DeleteReturnModal';
import CancelReturnModal from './modals/CancelReturnModal';
import AddReturnItemModal from './modals/AddReturnItemModal/AddReturnItemModal';
import DeleteReturnItemModal from './modals/DeleteReturnItemModal';
import DeleteOrderModal from './modals/DeleteOrderModal';
import PrintButton from './components/PrintButton/PrintButton';


const SaleView = ({language, history, match, onUpdatePayModal, pay_modal, user, onUpdateEditFlag}) => {

    const ID = match.params.id
    const content = system_translations[language].views.sale
    const catalogs = system_translations[language].catalogs
    const classes = useStyles()
    const {actions, modals, view_data, system} = useSaleView({history, match, onUpdatePayModal, onUpdateEditFlag})
    const {order, client, receipt_type, return_orders, return_id, return_status, return_type, return_item_id} = view_data
    const status = order ? order.order_status_id : -1

    let can_edit = true
    let print_ticket = false
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_edit = false
    if(order){
        print_ticket = order.nf_receipt_id !== null || order.receipt_id !== null
    }

    return(
        <Page title={content.title} goback loading={system.loading} error={system.error} language={language}
            onCloseErrorModal={actions.onClearError} gobackFunction={()=>{history.push('/sales')}}>
            <ReceiptModal open={pay_modal} onClose={()=>onUpdatePayModal(false)} language={language} {...order}
                client={client} id={ID} onCompleted={actions.onInitModule} 
                onGetInvoiceData={actions.onGetInvoiceData} 
                //onGetReceiptType={actions.onGetReceiptType} 
                />
            <DeleteOrderModal language={language} open={modals.delete_order} onClose={()=>actions.onChangeModalStatus('delete_order', false)} 
                id={ID} onCompleted={()=>history.push('/sales')}/>
            <GenerateInvoiceModal open={modals.generate_invoice} onClose={()=>actions.onChangeModalStatus('generate_invoice', false)} language={language} 
                client={client} id={ID} onCompleted={actions.onInitModule} onAddClient={()=>actions.onChangeModalStatus('add_client', true)} />
            <CancelReceiptModal open={modals.cancel_receipt} onClose={()=>actions.onChangeModalStatus('cancel_receipt', false)} language={language} 
                receipt_type_id={receipt_type} onUpdateOrder={actions.onUpdateOrder} order={order}/>
            <SelectClientModal open={modals.select_client} onClose={()=>actions.onChangeModalStatus('select_client', false)} language={language}  
                origin={client}/>
            <DownloadDocModal open={modals.download_doc} onClose={()=>actions.onChangeModalStatus('download_doc', false)} 
                language={language} />
            <AddClientModal  language={language} open={modals.add_client} onClose={()=>actions.onChangeModalStatus('add_client', false)} 
                onCompleted={actions.onAddClientCompleted}/>
            <CancelModal language={language} open={modals.cancel} onClose={()=>actions.onChangeModalStatus('cancel', false)} 
                onCompleted={actions.onUpdateOrder} id={ID} status={status}/>
            <AddReturnOrderModal language={language} open={modals.add_return_order} onClose={()=>actions.onChangeModalStatus('add_return_order', false)} 
                id={ID} onCompleted={actions.onReturnCompleted}/>
            <AddReturnItemModal language={language} open={modals.add_return_item} onClose={()=>actions.onChangeModalStatus('add_return_item', false)} 
                id={ID} return_id={return_id} type={return_type} onCompleted={actions.onReturnCompleted}/>
            <DeleteReturnModal language={language} open={modals.delete_return_order} onClose={()=>actions.onChangeModalStatus('delete_return_order', false)} 
                id={return_id} onCompleted={actions.onReturnCompleted}/>
            <DeleteReturnItemModal language={language} open={modals.delete_return_item} onClose={()=>actions.onChangeModalStatus('delete_return_item', false)} 
                id={return_item_id} onCompleted={actions.onReturnCompleted}/>
            <CancelReturnModal language={language} open={modals.cancel_return} onClose={()=>actions.onChangeModalStatus('cancel_return', false)} 
                onCompleted={actions.onReturnCompleted} id={return_id} status={return_status}/>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                <div className={classes.topbar}>
                    <Grid container justifyContent='flex-end' spacing={2} alignItems='center'>
                        
                        <Grid item>
                            <RoundedButton loading={system.back_loading} onClick={()=>actions.onChangeModalStatus('cancel', true)}
                            disabled={status === 4 || (order && order.generated_receipt === 1) || (order && order.invoiced === 1) || system.back_loading || !can_edit} >
                                {status !== -1 ? content.general.back_button[status-1] : nodata}
                            </RoundedButton>
                        </Grid>
                        <Grid item>
                            <DeleteButton card disabled={status !== ORDER_STATUSES.CANCELLED} onClick={()=>actions.onChangeModalStatus('delete_order', true)} />
                        </Grid>
                    </Grid>  
                </div>
                <SimpleCard>
                    <div className={classes.container} >
                        <Grid container spacing={4} >
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.start_date}</Header>
                                <Typography variant='body2'>
                                    {order ? onGetDateFormat(order.start_date, 'DD/MM/YYYY') : nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.sale_date}</Header>
                                <Typography variant='body2'>
                                    {order ? onGetDateFormat(order.sold_date, 'DD/MM/YYYY') : nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.billing_date}</Header>
                                <Typography variant='body2'>
                                    {order ? onGetDateFormat(order.invoiced_date, 'DD/MM/YYYY') : nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.cancel_date}</Header>
                                <Typography variant='body2'>
                                    {order ? onGetDateFormat(order.cancelled_date, 'DD/MM/YYYY') : nodata}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>ID</Header>
                                <Typography variant='body2'>
                                    {ID}
                                </Typography>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.vendor}</Header>
                                <UserAvatarName name={order ? order.seller : nodata} src={order ? order.seller_image : null} nopicture={content.general.nouser} />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.payment_method}</Header>
                                <div className={classes.payment_method_displayer}>
                                    <Typography variant='body2'>
                                        {order ? order.payment_type : nodata}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.status}</Header>
                                {/* <Typography variant='body2'>
                                    {order ? order.order_status : nodata}
                                </Typography> */}
                                <StatusData catalogs={catalogs.orders_statuses} id={status} />
                            </Grid>
                            
                            {/* <Grid item md={3} sm={6} xs={12}>
                                <Button className={classes.pay_button} disabled={status > 2 || system.next_sending || !can_edit} onClick={()=>actions.onClickNextButton(status)} >
                                    {status !== -1 ? content.general.next_button[status-1] : nodata}
                                    {system.next_sending ? (<CircularProgress size={24} className={classes.progress}/>) : null}
                                </Button>
                            </Grid> */}
                            
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.receipt}</Header>
                                <div className={classes.payment_method_displayer}>
                                    <Typography variant='body2'>
                                        {order ? order.receipt_type : nodata}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Header>{content.general.document}</Header>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <DownloadButton disabled={ !Boolean(order ? order.invoiced : 0)}  
                                        onClick={()=>actions.onDownloadReceipt('PDF')}/>
                                        <div style={{width:40, textAlign:'center'}}>
                                        <Typography variant='caption'>PDF</Typography>
                                        </div>
                                    </Grid>
                                    {Boolean(order ? order.invoiced : 0) ? <Grid item>
                                        <DownloadButton disabled={!(Boolean(order ? order.invoiced : 0))}  
                                        onClick={()=>actions.onDownloadReceipt('XML')}/>
                                        <div style={{width:40, textAlign:'center'}}>
                                        <Typography variant='caption'>XML</Typography>
                                        </div>
                                    </Grid> : null}
                                    {print_ticket ? <Grid item >
                                        <PrintButton order={order} />
                                    </Grid> : null}
                                </Grid>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Button className={classes.receipt_button} onClick={()=>actions.onGenerateReceipt(order ? order.receipt_type_id : null)}
                                    disabled={status !== ORDER_STATUSES.DELIVERED || system.invoice_loading || !can_edit}>
                                    {order && (Boolean(order.generated_receipt) || order && Boolean(order.invoiced)) ? content.general.receipt_button[1] : content.general.receipt_button[0]}
                                    {system.invoice_loading ? (<CircularProgress size={24} className={classes.progress}/>) : null}
                                </Button>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Button className={classes.pay_button} disabled={status > 2 || system.next_sending || !can_edit} onClick={()=>actions.onClickNextButton(status)} >
                                    {status !== -1 ? content.general.next_button[status-1] : nodata}
                                    {system.next_sending ? (<CircularProgress size={24} className={classes.progress}/>) : null}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container alignItems='center' spacing={2} >
                        <Grid item md={3} xs={12}>
                            <div className={classes.price_container}>
                            <Header>{content.general.subtotal}</Header>
                            <Typography className={classes.price} variant='body2'>
                                {order ? onGetCurrencyValue(order.subtotal) : nodata}
                            </Typography>
                            </div>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <div className={classes.price_container}>
                            <Header>{content.general.taxes}</Header>
                            <Typography className={classes.price} variant='body2'>
                                {order ? onGetCurrencyValue(order.tax) : nodata}
                            </Typography>
                            </div>
                        </Grid>
                        <Grid item xs />
                        <Grid item >
                            <div >
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item><Typography style={{fontWeight:700}} color='primary'>{content.general.total}</Typography></Grid>
                                    <Grid item><Typography variant='subtitle1'>{order ? onGetCurrencyValue(order.total) : nodata}</Typography></Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </SimpleCard>
                </Grid>
                {order && order.client ? <Grid item xs={12}>
                     <Client content={content.client} actions={actions} client={client}/>
                </Grid> : null}
                <Grid item xs={12}>
                    <Products content={content.products} data={order ? order.order_items : []} status={status}
                        onEdit={actions.onEditProducts} can_edit={can_edit}/>
                </Grid>
                {return_orders.length !== 0 ? <Grid item xs={12}>
                    <Typography  variant='h6'>{content.return_order.title}</Typography>              
                        {return_orders.map(item =>{
                            return(                               
                                <ReturnOrderView key={item.id_return_order.toString()} {...item} content={content.return_order} catalogs={catalogs} 
                                    onDelete={()=>actions.onDeleteReturnOrder(item.id_return_order)} sending={system.return_sending}
                                    onNext={()=>actions.onClickNextButtonReturn(item.return_order_status_id, item.id_return_order)}
                                    onBack={()=>actions.onClickBackButtonReturn(item.return_order_status_id, item.id_return_order)}
                                    onAddItem={()=>actions.onAddReturnItem(item.id_return_order, item.return_order_type_id)}
                                    onDeleteItem={actions.onDeleteReturnItem}/>                               
                            )
                        })}                
                </Grid> : null}
                {(status === ORDER_STATUSES.DELIVERED && (order && (Boolean(order.generated_receipt))) || status === ORDER_STATUSES.DELIVERED && (order && (Boolean(order.invoiced)))) ? <Grid item xs={12}>
                    <div style={{textAlign:'center'}}>
                        <Button className={classes.pay_button} onClick={()=>actions.onChangeModalStatus('add_return_order', true)} >
                            {content.return_order_button}
                            {system.next_sending ? (<CircularProgress size={24} className={classes.progress}/>) : null}
                        </Button>
                    </div>
                </Grid> : null}
            </Grid>
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        pay_modal:state.pay_modal,
        user:state.user,
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdatePayModal: (pay_modal) => dispatch({type:actionTypes.SYS_UPDATE_PAY_MODAL, pay_modal}),
        onUpdateEditFlag: (edit_order_products) => dispatch({type:actionTypes.SYS_UPDATE_EDIT_FLAG, edit_order_products}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleView) 

const useStyles = makeStyles(theme => ({
    topbar:{
        marginBottom:20
    },
    price_container:{
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end'
        }
    },
    container:{
        paddingBottom:48,
        borderBottom:'1px solid #D2D2D2',
        marginBottom:20
    }, 
    receipt_button:{
        background:theme.palette.secondary.main,
        padding:'12px 20%',
        color:'white',
        textTransform:'none',
        '&:hover':{
            background:theme.palette.secondary.main,
            color:'white'
        },
        '&:disabled':{
            /* background:'#909090',
            color:'rgba(54, 54, 54, 0.5)' */
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    pay_button:{
        background:theme.palette.primary.dark,
        padding:'12px 56px',
        color:'white',
        textTransform:'none',
        '&:hover':{
            background:theme.palette.primary.main,
            color:'white'
        },
        '&:disabled':{
            //background:'#909090',
            //color:'rgba(54, 54, 54, 0.5)'
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    payment_method_displayer:{
        background:'rgba(179, 10, 16, 0.05)',
        color:theme.palette.primary.dark,
        padding:'8px 24px',
        float:'left'
    },
    progress:{
        color:'white',
        marginLeft:8
    },
}))
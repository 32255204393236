import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/PageAdmin'
import system_translations from '../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import AddClientModal from './modals/AddClientModal/AddClientModal';
import DeleteClientModal from './modals/DeleteClientModal';
import EditClientModal from './modals/EditClientModal/EditClientModal';
import useClientsView from './useClientsView';
import OrdersClientModal from './modals/OrdersClientModal/OrdersClientModal';


const ClientsView = ({language, history}) => {

    const content = system_translations[language].views.clients
    const {actions, modals, system, view_data} = useClientsView({history, language})

   
    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <OrdersClientModal id={view_data.selected} language={language} open={modals.orders_client} client={view_data.client}
                onClose={()=>actions.onChangeModalStatus('orders_client', false)} onCompleted={actions.onComplete} history={history} />
            <EditClientModal id={view_data.selected} language={language} open={modals.edit_client} 
                onClose={()=>actions.onChangeModalStatus('edit_client', false)} onCompleted={actions.onComplete} />
            <AddClientModal  language={language} open={modals.add_client} onClose={()=>actions.onChangeModalStatus('add_client', false)} 
                onCompleted={actions.onComplete}/>
            <DeleteClientModal language={language} open={modals.delete_client} onClose={()=>actions.onChangeModalStatus('delete_client', false)}
                onCompleted={actions.onComplete} id={view_data.selected} />
            <SimpleTable 
                loading={system.loading}
                content={content.table} 
                data={view_data.clients}
                onSelectedItem={actions.onSelectedItem}
                tableFilter={view_data.tableFilter}
                total={view_data.total}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onAddRegister={()=>actions.onChangeModalStatus('add_client', true)}
                onUpdateSearch={actions.onResetTableFiler}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(ClientsView) 
import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import RoundedButton from '../../../../../../components/Actions/RoundedButton'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import ProductImage from '../../../../../../components/Ecommerce/ProductImage'
import { green, red } from '@material-ui/core/colors'
import Header from '../../../../../../components/Structure/Texts/Header'
import { onGetCurrencyValue } from '../../../../../../shared/utility'
import ImageContainer from '../../../../../../components/Ecommerce/ImageContainer'
import UserAvatarName from '../../../../../../components/Structure/DisplayData/UserAvatarName'


const InfoCard = ({content, onChangeModalStatus, code, name, brand, purchase_group, location, min_quantity, purchase_quantity,
    max_quantity, quantity, cost, image, sat_unit_code, sat_product_code, wholesale_price, retail_price, retail_percentage,
    wholesale_percentage, nopicture, description, brand_logo, can_edit}) =>{

    const classes = useStyles()

    return(
        <div>
            <div className={classes.topbar}>
                {can_edit ? <Grid container justifyContent='flex-end' spacing={2} alignItems='center'>
                    <Grid item><RoundedButton onClick={()=>onChangeModalStatus('edit_product', true)}>{content.button}</RoundedButton></Grid>
                    <Grid item><IconButton onClick={()=>onChangeModalStatus('delete_product', true)} className={classes.delete_button}><Icon>delete</Icon></IconButton></Grid>
                </Grid> : null}  
            </div>
            <SimpleCard>
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <ImageContainer src={image} nopicture={nopicture} />
                        <div className={classes.box}></div>
                    </Grid>
                    <Grid item md={8}>
                        <div className={classes.info_container}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Header marginBottom={0}>{content.form.code.tag}</Header>
                                <Typography>{code}</Typography>       
                            </Grid>   
                            <Grid item xs={6}>
                                <Header marginBottom={0}>{content.form.location.tag}</Header>
                                <Typography>{location}</Typography>
                            </Grid>
                            <Grid item xs={12}>                              
                                <Header marginBottom={0}>{content.form.name.tag}</Header>
                                <Typography >{name}</Typography>                             
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.description}>
                                    <Header marginBottom={0}>{content.form.description.tag}</Header>
                                    <Typography className={classes.description_field}>{description}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Header marginBottom={0}>{content.form.brand_id.tag}</Header>
                                <UserAvatarName name={brand} image={brand_logo} nopicture={content.nobrand} />
                            </Grid>
                            <Grid item xs={6}>
                                <Header marginBottom={0}>{content.form.purchase_group_id.tag}</Header>
                                <Typography>{purchase_group}</Typography>
                            </Grid>
                        </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.bottom_section}>
                    <Grid container justifyContent='space-between' spacing={3}>
                        <Grid item>
                            <Typography className={classes.title}>{content.quantity_title}</Typography>
                            <div style={{textAlign:'center', marginTop:20}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item >
                                            <Header marginBottom={0}>{content.form.quantity.tag}</Header>
                                            <Typography variant='subtitle2'>{quantity}</Typography>
                                        </Grid>
                                        <Grid item >
                                            <Header marginBottom={0}>{content.form.purchase.tag}</Header>
                                            <Typography variant='subtitle2'>{purchase_quantity}</Typography>
                                        </Grid>
                                    </Grid>                                 
                                </Grid>                               
                                <Grid item>
                                    <Header marginBottom={0}>{content.form.min_quantity.tag}</Header>
                                    <Typography variant='subtitle2'>{min_quantity}</Typography>
                                </Grid>
                                <Grid item>
                                    <Header marginBottom={0} color='white' >-</Header>
                                    <Typography>-</Typography>
                                </Grid>
                                <Grid item>
                                    <Header marginBottom={0}>{content.form.max_quantity.tag}</Header>
                                    <Typography variant='subtitle2'>{max_quantity}</Typography>
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>
                        <Grid item >
                            <Typography className={classes.title}>{content.price_title}</Typography>
                            <div style={{ marginTop:20}}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item>   
                                        <Header>{content.form.cost.tag}</Header>
                                        <Typography variant='subtitle2' style={{minWidth:82}}>{onGetCurrencyValue(cost)}</Typography>   
                                    </Grid>
                                    <Grid item>
                                        <Header>{content.form.wholesale_price.tag}</Header> 
                                        <div className={classes.price_container}>
                                            <Icon style={{color:red[700], marginRight:8}}>arrow_downward</Icon>
                                            <Typography variant='subtitle2'>{onGetCurrencyValue(wholesale_price)}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.line} style={{marginTop:32}}></div>
                                    </Grid>
                                    <Grid item>
                                        <Header>{content.form.retail_price.tag}</Header> 
                                        <div className={classes.price_container}>
                                            <Typography variant='subtitle2'>{onGetCurrencyValue(retail_price)}</Typography>
                                            <Icon style={{color:green[500]}}>arrow_upward</Icon>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item>    
                                        <div style={{minWidth:82}}></div>   
                                    </Grid>
                                    <Grid item >
                                        <div className={classes.price_container}>
                                            <Icon style={{color:red[700], marginRight:8}}>arrow_downward</Icon>
                                            <Typography variant='subtitle2'>{wholesale_percentage}%</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.line}></div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.price_container}>
                                            <Typography variant='subtitle2'>{retail_percentage}%</Typography>
                                            <Icon style={{color:green[500]}}>arrow_upward</Icon>
                                        </div>
                                    </Grid>
                                </Grid>                                             
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>{content.sat_title}</Typography>
                            <div style={{textAlign:'center', marginTop:20}}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    <Header marginBottom={0}>{content.form.sat_unit_code.tag}</Header>
                                    <Typography variant='subtitle2'>{sat_unit_code}</Typography>
                                </Grid>
                                <Grid item>
                                    <Header marginBottom={0}>{content.form.sat_product_code.tag}</Header>
                                    <Typography variant='subtitle2'>{sat_product_code}</Typography>
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </SimpleCard>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    topbar:{
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            marginTop:40
        }
    },
    delete_button:{
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)',
        color:theme.palette.primary.main,
        //marginRight:16
    },
    title:{
        color:theme.palette.primary.dark,
        fontWeight:600
    },
    bottom_section:{
        //textAlign:'center'
        marginTop:40
    },
    info_container:{
        //background:'green',
        height:'100%',
        boxSizing:'border-box',
        paddingLeft:60,
        [theme.breakpoints.down('sm')]:{
            paddingLeft:0
        }
    },
    box:{
        [theme.breakpoints.down('sm')]:{
            height:40
        }
    },
    price_container:{
        display:'flex',
        alignItems:'center',
        //width:110
    },
    line:{
        background:'black',
        width:10,
        height:0.5
    },
    description:{
        margin:'2% 0px',
        [theme.breakpoints.down('sm')]:{
            margin:0
        }
    },
    description_field:{
        minHeight:100,
        //background:'red',
        [theme.breakpoints.down('lg')]:{
            minHeight:60
        },
        [theme.breakpoints.down('md')]:{
            minHeight:60
        },
        [theme.breakpoints.down('sm')]:{
            minHeight:'auto'
        }
    }
}))

export default InfoCard


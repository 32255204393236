import { useState, useEffect, useRef } from "react"
import {  private_server, public_server, TOKEN_STATUSES } from "../../config"
import { onClearTokenSession, onGetCurrentUserProfile, onGetPrivateCurrentCart, onGetTokenFromMemory, onReAuthToken, onValidateToken, updateAuthorizationHeader } from "../../shared/utility"
const useWebsiteLayout = ({onUpdateUserData, onUpdateAuthStatus, onUpdateCart, history}) => {
    
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [busy, setBusy] = useState(false)
    const [readyState, setReadyState] = useState(false)

    const [isAuth, setIsAuth] = useState(false)

    useEffect(() => {
        actions.onInitModule()
        

    },[])

    useEffect(() => {
        console.log('revisar si existe espon')
        if(!window.epson){
            console.log('No existe!!')
            //loadMapScript()
        }else{
            console.log('Existe!!')
        }
    }, []);

    const onLoadScript = () => {
        console.log('Arfchivo cargado')
    }

    

    /* useInterval(() => {
        //actions.onCheckTokenExpirationDate()
    }, 3000) */

    const actions = {
        onInitModule: async() => {
            try {
                // Get token
                setLoading(true)
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                //setIsAuth(token_status)
                if(token_status !== TOKEN_STATUSES.NOT_VALID){
                    updateAuthorizationHeader(private_server)
                    const _user = await private_server.get('/user/me')
                    onUpdateUserData(_user.data.data.user)
                }
                
                /* updateAuthorizationHeader(private_server)
                const _user = await private_server.get('/user/me')
                onUpdateUserData(_user.data.data.user) */
                
                /* switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                    case TOKEN_STATUSES.NOT_VALID:
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        onUpdateAuthStatus(true)
                        break;
                    default:
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        onUpdateAuthStatus(true)
                        break;
                } */  
                //const session_data = onGetTokenFromMemory()
                //const token_status = onValidateToken(session_data)
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                        history.push('/signin')
                    case TOKEN_STATUSES.NOT_VALID:
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        setBusy(true)
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        setBusy(false)
                        break;
                    default:
                        //Nothing to do
                        break;
                }             
                
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onCheckTokenExpirationDate: async() => {
            if(busy || loading) return
            try {
                // Get token
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                    case TOKEN_STATUSES.NOT_VALID:
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        setBusy(true)
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        setBusy(false)
                        break;
                    default:
                        //Nothing to do
                        break;
                }                
            } catch (error) {
                console.log(error)
            }
        }
    }

    return {loading, sending, error, actions, isAuth}
}

export default useWebsiteLayout;

/* const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
} */

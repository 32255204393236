import { CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core"
import HistoricProduct from "./components/HistoricProduct"
import SimpleTable from "./components/SimpleTable"
import useProducts from "./useProducts"


const Products = ({content, language}) => {

    const classes = useStyles()
    const {system, actions,view_data} = useProducts({language, content})

    const amounts = view_data.product_data.map(item => parseFloat(item.sales_total))
    //console.log(amounts)
    const max_amount = Math.max(...amounts)
    //console.log(max_amount)
    const max_scale = Math.ceil(max_amount/100)*100
    console.log(max_scale)

    let x_scale = [0, 100, 200, 300, 400]
    if(max_scale > 0) x_scale = [0, Math.ceil(max_scale/4), Math.ceil(max_scale*2/4), Math.ceil(max_scale*3/4), max_scale]
    if(max_scale === 0) x_scale = [0, 100, 200, 300, 400]
    console.log(x_scale)
    //const x_scale = ['0', '100', '200', '300', '400']

    return(
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}><Typography variant='subtitle1'>{content.top.title}</Typography></Grid>
                <Grid item xs={12}>
                    <SimpleTable 
                        loading={system.loading}
                        content={content.top} 
                        data={view_data.top}
                        tableFilter={view_data.tableFilter}
                        total={view_data.total}
                        search={view_data.search}
                        onUpdateTableFilter={actions.onChangeTableFilter}
                        onChangeSearch={actions.onChangeSearch}
                        onChangeYear={actions.onChangeYearFilter}
                        onChangeMonth={actions.onChangeMonthFilter}
                        month={view_data.month}
                        year={view_data.year}
                        onDownloadExcel={actions.onDownloadExcel}
                        //onAddRegister={can_create ? ()=>actions.onChangeModalStatus('add_brand', true) : null}
                        //onSelectedItem={actions.onSelectItem}
                    />
                </Grid>
                <Grid item xs={12}><Typography variant='subtitle1'>{content.product_historic}</Typography></Grid>
                <Grid item xs={12}>
                    {system.loading2 ? <div style={{width:'100%', textAlign:'center', height:900}}><CircularProgress /></div> :
                    <HistoricProduct data={view_data.product_data} x_scale={x_scale} limit={max_scale !== 0 ? max_scale : 400} content={content.top} loading={system.loading2}
                        products={view_data.products} onSearchProduct={actions.onSearchProduct} onChangeProductFilter={actions.onChangeProductFilter}
                        onChangeYearFilter={actions.onChangeYearFilter2} year={view_data.year2} />}
                </Grid>
            </Grid>
        </div>
    )
}

export default Products

const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:70
    },
    
    
}))
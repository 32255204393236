import React, {useState, useRef, useEffect} from 'react'
import { Grid, Typography } from '@material-ui/core'
import CustomDropzone from './CustomDropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import SimpleModal from './SimpleModal'
import ActionModalBar from '../Actions/ActionModalBar'
import system_translations from '../../texts/system_translations'



const PictureModal = props => {

    const {open, onClose, onChangeFile, onDeleteFile, title, id, aspect_ratio, btnLabel, loading, error, language} = props
    const content = system_translations[language].modals.picture_modal

    const [file, setFile] = useState(null)
    const [src, setSrc] = useState(null)
    const [crop, setCrop] = useState({aspect:1}) //1.618
    const [croppedImage, setCroppedImage] = useState(null)
    const [_error, setError] = useState(null)
    const [_loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    let imageRef = useRef(null)


    useEffect(() => {
        if(open){
            setFile(null)
            setSrc(null)
            if(aspect_ratio) setCrop({aspect:aspect_ratio}) 
            setCroppedImage(null)
            setError(null)
            setLoading(false)
            setValid(false)
            imageRef.current = null
        }
    }, [open])

    const onDropFile = (data) => {
        setFile(data)
        if(data){
            const reader = new FileReader();
            reader.addEventListener("load", () => setSrc(reader.result))
            reader.readAsDataURL(data)
        }
    }

    const onImageLoaded = image => imageRef.current = image

    const handleOnCropChange = (crop) => {
        setCrop(crop)
    }

    const handleOnCropComplete = async(crop, pixelCrop) => {
        setValid(crop.width > 0 && crop.height > 0)
        if(imageRef && crop.width && crop.height){
            const croppedImg = await getCroppedImg(imageRef.current, crop, 'profile')
            setCroppedImage(croppedImg)
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
      
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );


      
        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');
      
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                let fileUrl
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl)
            });
        });
    }

    const onSubmit = async() => {
        onChangeFile(croppedImage)
    }

    /* const onDelete = async() => {
        onDeleteFile()
    } */


    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomDropzone onDropFile={onDropFile} content={content.dropzone}/>
                </Grid>
                {file ? (
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2' >{content.selected_image}</Typography>
                                <Typography variant='body2' >{content.cropped_area}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactCrop 
                                    src={src} crop={crop} 
                                    onImageLoaded={onImageLoaded}
                                    onChange={handleOnCropChange}
                                    onComplete = {handleOnCropComplete}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <ActionModalBar error={error} loading={loading} btnLabel={btnLabel} onDelete={onDeleteFile ? onDeleteFile : null} onSubmit={onSubmit}/>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default PictureModal
import React, {useState, useEffect} from 'react'
import { Grid, makeStyles, Typography, Tooltip, withStyles, TextField, CircularProgress } from "@material-ui/core"
import MonthFilter from "../../../../../components/Actions/MonthFilter"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard"
import { onGetCurrencyValue, onGetMonthName } from "../../../../../shared/utility"
import YearFilter from './YearFilter'
import { Autocomplete } from '@mui/material'


const HistoricProduct = ({data, x_scale, limit, content, products, onSearchProduct, onChangeProductFilter, onChangeYearFilter, loading, year}) => {

    const classes = useStyles()

    //console.log(products)
    console.log(x_scale)

    const [value, setValue] = useState(1);
    const [flag, setFlag] = useState(false)
    //const [input, setInput] = useState('');

    //console.log(products[0])

    const onChange = (e, item) => {
        setValue(item)
        onChangeProductFilter(e, item)
    }

    const onChangeInput = (e, item) =>{
        //setInput(item)
        onSearchProduct(e, item)
    }

    setTimeout(function(){
        setFlag(true)
    }, 1000)


    useEffect(() => {
        if(products && products[0]){
            console.log('hola')
            setValue(products[0].label)
            //setInput(products[0])
        } 
    }, [flag]);
    

    return(
        <div >
            {/* <Typography variant='subtitle2'>Compras</Typography> */}
            <div className={classes.filters}>
                <Grid container spacing={2}>
                    <Grid item><YearFilter options={content.year_filter_options} onChange={onChangeYearFilter} value={year} /></Grid>
                    <Grid item>
                        <Autocomplete
                            disablePortal
                            id="products"
                            value={value}
                            //inputValue={input}
                            options={products}
                            onChange={onChange}
                            onInputChange={onSearchProduct}
                            className={classes.select}
                            renderInput={(params) => 
                                <CssTextField {...params} variant='outlined'
                                //className={classes.input}
                                /* InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }} */ 
                                />}
                            noOptionsText={<Typography variant='body2'>Sin opciones </Typography>}
                            renderOption={(props, option) => {
                                //console.log(option)
                                return(
                                <div className={classes.option_container} {...props} >
                                    {/* <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs> */}
                                            <div style={{display:'flex', alignItems:'center'}}>
                                            <Typography color='primary'>{option.code}</Typography>
                                            <div className={classes.line}></div>
                                            <Typography style={{fontWeight:700}}>{option.brand}</Typography>
                                            <div className={classes.line}></div>
                                            <Typography >{option.name}</Typography>
                                            </div>
                                        {/* </Grid>
                                    </Grid>  */}      
                                </div>
                            )}
                            }
                            />
                    </Grid>
                </Grid>
            </div>
            <SimpleCard>
                <div className={classes.main}> 
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div className={classes.point}></div>
                                <Typography variant='caption' style={{ color:'#868686'}}>Ventas</Typography>
                            </div>
                        </Grid>
                    </Grid>                  
                        <div className={classes.months} >
                            <Grid container  alignItems='center' spacing={5} >
                                {data.map((item, index) => {
                                    const width = (parseFloat(item.sales_total)/ limit) *100
                                    return(
                                        <Grid item xs={12} key={item.month}>
                                            
                                                <div className={classes.row}  >
                                                    <div style={{width:'15%'}}>
                                                        <Grid container>
                                                            <Grid item xs><Typography variant='caption' >{onGetMonthName(item.month)}</Typography></Grid>
                                                            <Grid item>
                                                                <div className={classes.quantity_container}>
                                                                    <Typography variant='caption'>{item.sales_quantity}</Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Typography variant='caption' >{onGetMonthName(item.month)}</Typography> */}
                                                    </div>
                                                    {item.sales_total !== '0.00' ? <div style={{width:'85%'}}>
                                                        <CustomTooltip title={onGetCurrencyValue(item.sales_total)} placement="right" arrow >
                                                        <div style={{width:`${width}%`, background:'red', borderRadius:'0px 15px 15px 0px', color:'red'}}>
                                                            {item.sales_total}
                                                        </div>
                                                        </CustomTooltip>
                                                    </div> :
                                                    <div style={{width:'85%'}}>
                                                        <CustomTooltip title={onGetCurrencyValue(item.sales_total)} placement="right" arrow >
                                                        <div style={{width:4, height:21, background:'red', color:'red'}}>
                                                            
                                                        </div>
                                                        </CustomTooltip>
                                                    </div>}
                                                </div> 
                                            
                                        </Grid>                                  
                                    )
                                })}
                            </Grid>                    
                        </div>
                        <div className={classes.scale_container}>
                            <div style={{width:'15%'}}></div>
                            <div style={{width:'85%'}} >
                                <Grid container justifyContent='space-between'>
                                    {x_scale.map(item =>{
                                        return(
                                            <Grid item key={item.toString()}>
                                                <Typography variant='caption'>{item}</Typography>                                    
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </div>
                        <div>
                            
                            {/* <div className={classes.amounts_container}>
                                <Grid container direction='column' spacing={5} >
                                    {data.map(item => {
                                        const width = (parseFloat(item.sales_total)/ limit) *100
                                        return(
                                            <Grid item key={item.amount}>
                                                <div style={{width:`${width}%`, background:'red', borderRadius:'0px 15px 15px 0px', color:'red'}}>
                                                    {item.sales_total}
                                                </div>
                                            </Grid>                                  
                                        )
                                    })}
                                </Grid>
                            </div> */}
                        </div>
                    </div>
                </div>
            </SimpleCard>
        </div>
    )

}

export default HistoricProduct

const CssTextField = withStyles({
    root: {
        background:'white',
        //width:200,
        //boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.2)',
        //borderRadius:8,
        //border:'none',
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:8,
        //boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.2)',
        //borderColor:'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
          //background:'white'
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    },
  })(TextField);

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'rgba(21, 21, 21, 0.93)',
      color:'white',
      padding:'8px 16px',
      fontSize:12
    },
    arrow:{
        color: 'rgba(21, 21, 21, 0.93)', 
    }
  }))(Tooltip);

const useStyles = makeStyles(theme => ({
    main:{
        //overflowY:'hidden',
        
        //overflowX:'auto'
    },
    scale_container:{
        display:'flex',
        //minWidth:750
        
    },
    row:{
        display:'flex',
        width:'100%',
        cursor:'pointer',
        '&:hover':{
            color:theme.palette.primary.main
        }
    },
    tooltip:{
        background:theme.palette.secondary.main
    },
    point:{
        width:10,
        height:10,
        background:'red',
        marginRight:8
    },
    input:{
        padding:'4px 0px',
        
    },
    line:{
        width:8,
        background:'#949494',
        height:0.4,
        margin:'0px 8px'
    },
    select:{
        width:700,
        background:'white',
        borderRadius:8,
        boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.2)',
        padding:'5px 0px',
        borderColor:'white',
        //background:'green',
        '& .MuiAutocomplete-root':{
            //borderRadius:12,
            //background:'green',
            borderColor:'green',
            '&.Mui-focused fieldset':{
              //borderColor:'green'
            }
          },
        '& .MuiAutocomplete-inputRoot':{
            borderColor:'green',
            //background:'red'
        },
        '& .MuiAutocomplete-input':{
            borderColor:'pink'
        }
    },

    months:{
        width:'100%',
        //background:'red',
        padding:'40px 0px',
        //boxSizing:'border-box'
    },
    filters:{
        margin:'20px 0px'
    },
    quantity_container:{
        marginRight:20,
        padding:'0px 8px',
        borderRight:'1px solid #ED1C24',
        borderLeft:'1px solid #ED1C24'
    }
    /* graph:{
        width:'85%',
        //background:'green',
        //height:500,
        position:'relative',
        //boxSizing:'border-box'
    },
    graph_item:{
        height:'100%',
        width:'100%',
        borderLeft:'1px dashed #DDDDDD',
        marginLeft:4
        //background:'pink'
    },
    label:{
        width:'100%'
    },
    amounts_container:{
        padding:'40px 0px'
    },
    amount:{
        background:'red',
        width:'50%'
    },
     */
}))

import React, { useRef } from 'react'
import { TextField, Grid, makeStyles, withStyles, Typography, CircularProgress } from '@material-ui/core'
import { grey, red } from '@material-ui/core/colors'
import Autocomplete from '@mui/material/Autocomplete';



const useStyles = makeStyles(theme => ({
  root:{
    borderRadius:12,
    '& .MuiOutlinedInput-root':{
      borderRadius:12,
      '&.Mui-focused fieldset':{
        borderColor:theme.palette.secondary.main
      }
    },
  }
}))

const SelectAutocompleteForm = props => {


    const classes = useStyles()
    ///////////////////////////////////
    const {data, onChange, onInputChange, open, onOpen, onClose, loading, defaultValue, disabled, onUpdateSearch} = props //onSearch
    const {isValid, isVisited, value, options, rules} = data;
    const {id, label,placeholder, helperText, fullWidth} = data.config;

    ///////////////////  STATE //////////////////////

    //////////////  FUNCTIONS  /////////////////

    const onInnerChange = (e, item) => {
      let temp = {...data}
      if(item){
        temp.value = item.value
        temp.isValid = true
      }else{
        temp.value = null
        temp.isValid = rules ? false : true
      }
      temp.isVisited = true
      onChange(temp)
    }

    ///////////////////// INTERNAL VARIABLES  /////////////////////////////
    let selectedOptions = []
    let selectedValue = null
    if(data) {
      if(options){
        //console.log(options)
        selectedOptions = options
      }
      if(value !== null && value !== undefined ){
        const singleEl = options.find(el => el.value === value) 
        selectedValue = singleEl ? singleEl : selectedValue
      }
    }
    
    const timerRef = useRef()

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onUpdateSearch, 300);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }
    

    let inputlabel = null;
    if(label) inputlabel =  <Typography variant='subtitle2'  color={!isValid && isVisited ? 'error' : 'inherit'}>{label}</Typography>

    return(
      <div>
        <Autocomplete
        className={classes.root}
        fullWidth={fullWidth}
        disabled={disabled}
        value={selectedValue}
        defaultValue={defaultValue}
        options={selectedOptions}
        getOptionLabel={option => option.label}
        onKeyUp={onStartCountDown}
        onKeyDown={onStopCountDown} 
        ref={timerRef}
        onChange={onInnerChange}
        onInputChange={onInputChange}
        renderInput={params => (
          <TextField {...params} variant='outlined' color='primary' 
          label={label} error={!isValid && isVisited} placeholder={placeholder}
          helperText={(!isValid && isVisited) ? helperText : null} 
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
              
          }}
          />)}
        noOptionsText={<Typography>Sin opciones </Typography>}
        //with loading
        loading={loading}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        /* renderOption={(option, state) => {
          console.log(option)
          return(
          <div style={{margin:'2px 0px'}}>
            <Typography variant={state.selected ? 'subtitle1' : 'body2'}>{option.key}</Typography>
          </div>
          )}
        } */
        />
      </div>
      
    )
}

export default SelectAutocompleteForm
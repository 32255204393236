import React from 'react'
import { Card, CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { onGetCurrencyValue, onGetDateFormat, onGetFullname } from '../../../shared/utility'
import ActionBar from '../../../components/Actions/ActionBar'
import empty from '../../../assets/placeholders/noproviders.png'
import DeleteButton from '../../../components/Actions/DeleteButton'
import tableStyles from '../../../styles/tableStyles'
import { grey } from '@material-ui/core/colors'
import cx from 'classnames'


const useStyles = makeStyles(theme => ({
    
    cell:{
        //border:'none',
        borderBottom:'1px solid #D2D2D2'
    },
    cell_center:{
        //background:'red',
        textAlign:'center',
        border:'none'
    },
    row:{
        background:'white',
        //padding:'20px 0px',
        //borderTop:'10px solid white',
        //borderBottom:'10px solid white',
        //boxSizing:'border-box',
        //marginTop:10,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected_row:{
        background:grey[100]  
    },
    cellHeader:{
        border:'none',
        paddingTop:16,
        paddingBottom:16
        
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        padding:2,
        width:40,
        height:40,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
        //background:'#fbfbfb'
    },
    nodata:{
        marginTop:'12%',
        //background:'green',   
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        marginRight:16
    },
    receipt:{
        background:'rgba(54, 54, 54, 0.08)',
        borderRadius:3,
        padding:'8px 12px',
        fontWeight:500
    },
    payment_type:{
        background:'rgba(179, 10, 16, 0.05)',
        borderRadius:3,
        padding:'8px 12px',
        fontWeight:500,
        textAlign:'center'
    },
    empty:{
        //width:80,
        //marginBottom:40
        width:'100%',
        height:'100%',
        //objectFit:'contain'
    },
    img_container:{
        marginBottom:40,
        borderRadius:'50%',
        background:'white',
        boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        width:100,
        height:100,
        padding:25,
        boxSizing:'border-box'
        
    },
    card:{
        background:'white',
        boxShadow:' 0px 4px 50px rgba(0, 0, 0, 0.06)',
        borderRadius:15,
        padding:'40px 0px'
    },
    button_xml:{
        background:theme.palette.primary.main,
        color:'white',
        width:32,
        height:32
    },
    button_pdf:{
        background:theme.palette.primary.dark,
        color:'white',
        width:32,
        height:32
    }
}))


const SimpleTable = props => {

    const classes = useStyles()
    const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onDownloadCashInvoice,
        onDownloadCardInvoice, selected} = props

    const header = content.header

    return(
        <div>
            {/* <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='center' wrap='nowrap' > 
                    <Grid item><SearchBar value={search} onChange={onChangeSearch} /></Grid>
                    {onAddRegister ? <Grid item><AddButton variant='table' onClick={onAddRegister}/></Grid> : null}    
                </Grid>
            </div> */}
            <Card className={classes.card}>
                {loading ? (
                    <Grid container justify='center'>
                        <Grid item><CircularProgress size={32}/></Grid>
                    </Grid>
                ) : null}          
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography variant='subtitle2' style={{color:'#909090'}}>{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_global_invoice, date, cash_subtotal, cash_tax, cash_total, cash_invoice_id, cash_orders, card_subtotal, card_tax,
                                        card_total, card_invoice_id, card_orders, total_cost } = item

                                    return(
                                        <TableRow key={key.toString()} className={cx({[classes.row]:true, [classes.selected_row]: id_global_invoice === selected})} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{id_global_invoice}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetDateFormat(date, 'DD/MM/YYYY')}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetCurrencyValue(cash_subtotal)}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetCurrencyValue(cash_tax)}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetCurrencyValue(cash_total)}</Typography></TableCell>
                                            <TableCell className={classes.cell}>
                                                <Grid container spacing={1} justifyContent='center'>
                                                    <Grid item >
                                                        <Grid container>
                                                            <Grid item xs={12}><IconButton className={classes.button_xml} onClick={()=>onDownloadCashInvoice('XML', id_global_invoice)}><Icon>arrow_downward</Icon></IconButton></Grid>
                                                            <Grid item xs={12}><Typography variant='caption'>XML</Typography></Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item >
                                                        <Grid container>
                                                            <Grid item xs={12}><IconButton className={classes.button_pdf} onClick={()=>onDownloadCashInvoice('PDF', id_global_invoice)}><Icon>arrow_downward</Icon></IconButton></Grid>
                                                            <Grid item xs={12}><Typography variant='caption'>PDF</Typography></Grid>  
                                                        </Grid>          
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetCurrencyValue(card_subtotal)}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetCurrencyValue(card_tax)}</Typography></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetCurrencyValue(card_total)}</Typography></TableCell>
                                            <TableCell className={classes.cell} >
                                                <Grid container spacing={1} justifyContent='center' >
                                                    <Grid item >
                                                        <Grid container>
                                                            <Grid item xs={12}><IconButton className={classes.button_xml} onClick={()=>onDownloadCardInvoice('XML', id_global_invoice)}><Icon>arrow_downward</Icon></IconButton></Grid>
                                                            <Grid item xs={12}><Typography variant='caption'>XML</Typography></Grid>
                                                        </Grid>                                                     
                                                    </Grid>
                                                    <Grid item >
                                                        <Grid container>
                                                            <Grid item xs={12}><IconButton className={classes.button_pdf} onClick={()=>onDownloadCardInvoice('PDF', id_global_invoice)}><Icon>arrow_downward</Icon></IconButton></Grid>
                                                            <Grid item xs={12}><Typography variant='caption'>PDF</Typography></Grid>
                                                        </Grid>                              
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(date, id_global_invoice)}><Typography variant='body2'>{onGetCurrencyValue(total_cost)}</Typography></TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>           
                ): null}   
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>              
            ) : null}        
        </div>
    )
}

export default SimpleTable
import { Grid, Icon, makeStyles, Typography } from "@material-ui/core"
import { amber, yellow } from "@mui/material/colors"
import { useState } from "react"
import ActionModalBar from "../../../../components/Actions/ActionModalBar"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import DisplayError from "../../../../components/Structure/DisplayData/DisplayError"
import { ORDER_STATUSES, private_server } from "../../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../shared/utility"
import system_translations from "../../../../texts/system_translations"


const CancelModal = ({open, onClose, language, status, onCompleted, id}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.cancel_modal

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const onSubmit = async() =>{

        //console.log(status)
        
        if(status === ORDER_STATUSES.CREADED){
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const new_order = await private_server.post(`/order/${id}/cancel/me`)
                //console.log(req)
                onCompleted()
                setError(null)
                setLoading(false)
                onClose()
            } catch (error) {
                console.log(error)
                setLoading(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        }
        if(status === ORDER_STATUSES.PAID){
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const new_order = await private_server.post(`/order/${id}/created/me`)
                //console.log(req)
                onCompleted()
                setError(null)
                setLoading(false)
                onClose()
            } catch (error) {
                console.log(error)
                setLoading(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        }
        if(status === ORDER_STATUSES.DELIVERED){
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const new_order = await private_server.post(`/order/${id}/pay/me`)
                //console.log(req)
                onCompleted()
                setError(null)
                setLoading(false)
                onClose()
            } catch (error) {
                console.log(error)
                setLoading(false)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        }
    }

    return(
        <SimpleModal open={open} onClose={onClose}  >
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <div className={classes.icon_container}>
                                    <Icon fontSize='large' className={classes.icon}>warning</Icon>
                                </div>
                            </Grid>
                            <Grid item>
                                <Typography align='center'>{content.message}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.button_container}>
                            <DisplayError>{error}</DisplayError>
                            <RoundedButton loading={loading} onClick={onSubmit}>{content.button}</RoundedButton>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )

}

{/* <ActionModalBar loading={loading} error={error} btnLabel={content.button} btnColor='red' onSubmit={onSubmit}/> */}

export default CancelModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    icon:{
        color:'white'
    },
    icon_container:{
        borderRadius:'50%',
        background:amber[500],
        padding:12
    },
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #363636',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:'#363636'
    },
    button_container:{
        textAlign:'center',
        margin:'12px 0px 0px'
    },

    
}))
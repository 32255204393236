import { Grid, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import MiniTable from "./MiniTable"


const Products = ({content, products, actions, can_edit}) => {

    return(
        <div>
            <Typography variant='h6'>{content.title}</Typography>
            <div style={{marginBottom:20}}>
            {can_edit ? <Grid container justifyContent='flex-end'>
                <Grid item>
                    <RoundedButton onClick={()=>actions.onChangeModalStatus('add_product', true)}>{content.button}</RoundedButton>
                </Grid>
            </Grid> : null}
            </div>
            <MiniTable rows={products} content={content} actions={actions} can_edit={can_edit}/>
        </div>
    )
}

export default Products
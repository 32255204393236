import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const miniTableStyles = makeStyles(theme => ({
    header:{
        font:theme.typography.fontFamily,
        fontSize:'0.9rem',
        fontWeight:700,
        
        //padding:'0px 40px 20px'
    },
    row_header:{
        borderBottom:'1px solid #E2E2E2',
        padding:'20px 0px'
    },
    row_root:{
        //borderBottom:'1px solid #D2D2D2',
        padding:'20px 0px',
        font:theme.typography.fontFamily,
        //borderBottom:'1px solid #E2E2E2',
        //fontWeight:500
    }, 
    row_center:{
        textAlign:'center'
    },
    //
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
}))

export default miniTableStyles
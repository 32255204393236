import noproduct from '../../assets/noproduct.png'
import nobrand from '../../assets/placeholders/nobrand.png'
import nouser from '../../assets/placeholders/nouser.png'
import file from '../../assets/file.png'

const helper_text = 'Este campo debe contener min un caracter'
const helper_select = 'Debe seleccionar al menos una opción'
const helper_password = 'La contraseña debe contener como mínimo 8 caracteres'

export const translations = {
    provider_modal:{
        add:{
            title:'Crear proveedor',
            button:'Crear',
        },
        edit:{
            title:'Editar proveedor',
            button:'Guardar',
        },
        nopicture:noproduct,
        contact:'Contacto',
        form:{
            name:{tag:'Nombre', helperText:helper_text},
            relation_started_at:{tag:'Inicio de relación', helperText:helper_text},
            description:{tag:'Descripción', helperText:helper_text},
            contact_name:{tag:'Nombre', helperText:helper_text},
            contact_phone:{tag:'Teléfono', helperText:helper_text},
            contact_email:{tag:'Email', helperText:'Email no válido'},
            address:{tag:'Dirección', helperText:helper_text},
        } 
    },
    product_modal:{
        add:{
            title:'Crear producto',
            button:'Crear'
        },
        price_title:'Precio',
        quantity_title:'Cantidad',
        sat_title:'Información fiscal',
        nobrand:nobrand,
        form:{
            name:{tag:'Producto', helperText:helper_text},
            code:{tag:'Código', helperText:helper_text},
            location:{tag:'Ubicación', helperText:helper_text},
            description:{tag:'Descripción', helperText:helper_text},
            brand_id:{tag:'Marca', helperText:helper_text},
            purchase_group_id:{tag:'Grupo de compra', helperText:helper_text},
            quantity:{tag:'Cantidad', helperText:'Debe ser mayor a 0'},
            min_quantity:{tag:'Min', helperText:'Debe ser mayor a 0'},
            max_quantity:{tag:'Max', helperText:'Debe ser mayor a 0'},
            retail_price:{tag:'Max', helperText:helper_text},
            wholesale_price:{tag:'Min', helperText:helper_text},
            cost:{tag:'Costo', helperText:helper_text},
            sat_unit_code:{tag:'Unidad', helperText:'Este campo es requerido'},
            sat_product_code:{tag:'Código', helperText:'Este campo es requerido'},
        }
    },
    brand_modal:{
        add:{
            title:'Crear marca',
            button:'Crear',
        },
        edit:{
            title:'Editar marca',
            button:'Guardar',
        },
        nopicture:nobrand,
        form:{
            brand:{tag:'Marca', helperText:helper_text},
            description:{tag:'Descripción', helperText:helper_text},
        } 
    },
    purchase_group_modal:{
        add:{
            title:'Agregar grupo de compra',
            button:'Agregar'
        },
        edit:{
            title:'Editar grupo de compra',
            button:'Guardar'
        },
        form:{
            name:{tag:'Nombre', helperText:helper_text},
            description:{tag:'Descripción', helperText:helper_text},
        }
    },
    purchase_modal:{     
        add:{
            title:'Agregar compra',
            button:'Agregar',
        },
        edit:{
            title:'Editar compra',
            button:'Guardar'
        },
        form:{
            provider_id:{tag:'Proveedor', helperText:helper_select},
        }
    },
    add_product_item:{
        title:'Elige un producto',
        quantity:'Cantidad',
        current:'Actual',
        new:'Nuevo',
        form:{
            quantity:{tag:'Cantidad', helperText:'La cantidad sobrepasa la cantidad máxima'},
        },
        button:'Añadir'
    },
    add_product_to_pg:{
        title:'Agregar producto al grupo de compra',
        button:'Agregar',
        form:{
            id_product:{tag:'Producto', helperText:helper_select},
        }
    },
    equivalence_modal:{
        title:'Agregar equivalencia',
        form:{
            product_2_id:{tag:'Producto equivalente', helperText:helper_select},
        },
        button:'Agregar'
    },
    complement_modal:{
        title:'Agregar complemento',
        form:{
            complement_product_id:{tag:'Producto complementario', helperText:helper_select},
        },
        button:'Agregar'
    },
    client_modal:{
        edit:{
            title:'Detalle de cliente',
            button:'Listo'
        },
        add:{
            title:'Agregar cliente',
            button:'Agregar'
        },
        orders:{
            title:'Detalle de cliente',
            header:[
                {id:1, label:'ID'},
                {id:2, label:'Fecha de venta'},
                {id:3, label:'Estatus'},
                {id:4, label:'Total'},
                {id:5, label:'Método de pago'},
                {id:6, label:'Recibo'},
                {id:7, label:''},
                {id:8, label:''},
            ],
        },
        form:{
            name:{tag:'Nombre', helperText:helper_text},
            email:{tag:'Email', helperText:'Email no válido'},
            rfc:{tag:'RFC', helperText:'RFC no váilido'},
            cfdi_use:{tag:'Uso de factura', helperText:helper_text},
            zip:{tag:'Código postal', helperText:'Código postal no válido'},
            tax_system:{tag:'Régimen fiscal', helperText:helper_select},
        } 
    },
    user_modal:{
        nopicture:nouser,
        add:{
            title:'Crear usuario',
            button:'Crear'
        },
        edit:{
            title:'Editar usuario',
            button:'Guardar'
        },
        form:{
            username:{tag:'Usuario', helperText:helper_text},
            password:{tag:'Contraseña', helperText:helper_password},
            confirm_password:{tag:'Confirmar contraseña', helperText:'La contraseña no coincide'},
            first_name:{tag:'Nombre', helperText:helper_text},
            last_name:{tag:'Apellidos', helperText:helper_text},
            email:{tag:'Email', helperText:'Email no válido'},
            gender_id:{tag:'Género', helperText:helper_select},
            birth_date:{tag:'Cumpleaños', helperText:'Fecha no válida'},
            mobile_country_code:{tag:'', helperText:helper_text},
            mobile:{tag:'Celular', helperText:helper_text},
            hired_at:{tag:'Fecha de contrato', helperText:'Fecha no válida'},
            user_status_id:{tag:'Status', helperText:helper_select},
            user_type_id:{tag:'Tipo', helperText:helper_select},
        } 
    },
    payment_method:{
        title:'Método de pago',
        subtitle:'Cobrar:',
        options:[
            'Efectivo',
            'Tarjeta'
        ],
        button:'Pagado'
    },
    select_client:{
        title:'Elegir cliente',
        client:'Cliente',
        button:'Continuar'
    },
    receipt:{
        title:'Elegir recibo',
        steps:['Método de pago', 'Elegir recibo'],
        payment_method:{
            title:'Método de pago',
            subtitle:'Cobrar:',
            button:'Pagado'
        },
        receipt_type:{
            form:{
                id_client:{tag:'Cliente', helperText:helper_text},
                cfdi_use:{tag:'Uso de factura', helperText:helper_text},
            } 
        },
        back_button:'Atrás',
        button:['Siguiente', 'Pagar']
    },
    generate_invoice:{
        title:'Datos de la factura',
        form:{
            id_client:{tag:'Cliente', helperText:helper_text},
            cfdi_use:{tag:'Uso de factura', helperText:helper_text},
        },
        button:'Generar',
        button_client: 'Crear cliente' 
    },
    cancel_receipt:{
        receipt_message:'¿Estás seguro(a)? Esta acción no podrá ser revertida.',
        invoiced_message:'Esta acción no podrá ser revertida, la cancelación efectiva de la factura deberá hacerse mediante la plataforma del SAT.',
        button:'Aceptar'
    },
    cancel_modal:{
        message:'¿Seguro(a) que deseas realizar esta acción?',
        button:'Aceptar'
    },
    return_order_modal:{
        title:'Crear devolución',
        options:[{value:1, label:'Devolución'}, /*{value:2, label:'Cambio'}*/],
        button:'Crear'
    },
    finish_day:{
        title:'Revisa las órdenes',
        form:{date:{tag:'Día', helperText:helper_text}},
        total:'Total',
        button:'Continuar'
    },

    //basics
    change_password_modal:{
        title:'Cambiar contraseña',
        button:'Guardar',
        form:{
            password:{tag:'Nueva contraseña', helperText:helper_password},
        },
        form_me:{
            previous_password:{tag:'Contraseña anterior', helperText:helper_password},
            new_password:{tag:'Nueva contraseña', helperText:helper_password},
        } 
    },
    picture_modal:{
        selected_image:'Imagen seleccionada',
        cropped_area:'Seleccione el área que desea recortar',
        dropzone:{
            text:'Arrastre o de clic para subir una imagen'
        }
    },
    download_doc:{
        title:'¡Listo!',
        message:'Archivo generado exitosamente.',
        button:'Descargar',
        image:file
    },
    change_image_modal:{
        title:'Cambiar imagen',
        button:'Guardar',
        error_msg:'Debe adjuntar/seleccionar una imagen para continuar'
    },
    warning_modal: {
        title:'¿Estás seguro?',
        button:'Eliminar',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido'
    },
    error_modal:{
        default_message:'Hubo un error',
        button:'Continuar'
    },
    document_modal:{
        title:'Subir archivo',
        button:'Subir',
        input_file:{
            title:'Adjuntar archivo',
            max_size:'Tamaño máximo del archivo: 25MB',
            change_file:'Si desea modificar el archivo de click o arrastre uno nuevo en el recuadro'
        }
    },
    error_document:'Debe adjuntar un documento'
}

export default translations
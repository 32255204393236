import React, { useRef } from 'react'
import { Grid, Icon, InputBase, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'8px 16px',
        borderRadius:6,
        border:'1px solid #E3E3E3',
        color:'black',
        width:400,
        [theme.breakpoints.down('sm')]:{
            width:'80%'
        }
    },
    icon:{
        color:'black',

    }, 
    input:{
        width:'100%',
        //background:'red'
    }
}))

const SearchBar = props => {
    const classes = useStyles()
    const {value, onChange, onUpdateSearch} = props

    const timerRef = useRef()

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onUpdateSearch, 300);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onInnerChange = (e) => {
        onChange(e.target.value)
    }

    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap' justifyContent='space-between'>   
                <Grid item xs>
                    <InputBase className={classes.input} value={value} placeholder='Buscar...' onChange={onInnerChange} 
                    onKeyUp={onStartCountDown} onKeyDown={onStopCountDown} ref={timerRef} />
                </Grid>
                <Grid item>
                    <Icon className={classes.icon} fontSize='default'>search</Icon>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar
import { Button, CardActions, Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../components/Structure/Cards/SimpleCard"
import AvatarPicture from '../../../components/Structure/DisplayData/AvatarPicture'
import RoundedButton from "../../../components/Actions/RoundedButton"
import StatusData from '../../../components/Structure/DisplayData/StatusData'
import { onGetDateFormat, onGetFullname } from "../../../shared/utility"
import system_translations from "../../../texts/system_translations"
import { nodata } from "../../../config"


const UserInfo = ({content, language, first_name, last_name, username, gender_id, birth_date, email, mobile, image, hired_at, 
    user_status_id, user_type_id, actions, nopicture}) =>{

    const classes = useStyles()

    const catalogs = system_translations[language].catalogs

    const name = onGetFullname(first_name, last_name)
    const type = catalogs.user_types.find(el=>el.value === user_type_id)
    const status = catalogs.user_statuses.find(el=>el.value === user_status_id)
    const gender = catalogs.genders.find(el=>el.value === gender_id)

    return(
        <div>
            {/* <div className={classes.topbar}>
            <Grid container justifyContent='flex-end' spacing={2} alignItems='center'>
                <Grid item>
                    <RoundedButton onClick={()=>actions.onChangeModalStatus('edit_user', true)}>{content.button}</RoundedButton>
                </Grid>
                <Grid item>
                    <IconButton className={classes.delete_button} onClick={()=>actions.onChangeModalStatus('delete_user', true)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Grid>
            </Grid>  
            </div> */}
            <SimpleCard>
                <Grid container>
                    <Grid item md={5} xs={12}>
                        <div className={classes.container}>
                            <div className={classes.subcontainer}>      
                                <AvatarPicture src={image} nopicture={nopicture} /> 
                            </div>
                            <div className={classes.avatar_container} >
                                <div className={classes.name}><Typography variant='h6'>{name}</Typography></div>
                                <Button className={classes.button} onClick={()=>actions.onChangeModalStatus('change_password', true)}
                                >{content.password_button}</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.container2}>
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TitleText >{content.user}</TitleText>
                                    <Typography variant='subtitle1'>{username}</Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <TitleText >{content.gender}</TitleText>
                                    <Typography variant='subtitle1'>{gender ? gender.label : nodata}</Typography>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TitleText >{content.email}</TitleText>
                                    <Typography variant='subtitle1' style={{wordBreak:'break-all'}} >{email}</Typography>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TitleText >{content.mobile}</TitleText>
                                    <Typography variant='subtitle1'>{mobile}</Typography>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TitleText >{content.birthday}</TitleText>
                                    <Typography variant='subtitle1'>{onGetDateFormat(birth_date, 'DD/MM/YYYY')}</Typography>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TitleText >{content.covenant_date}</TitleText>
                                    <Typography variant='subtitle1'>{onGetDateFormat(hired_at, 'DD/MM/YYYY')}</Typography>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TitleText >{content.type}</TitleText>
                                    <Typography variant='subtitle1'>{type ? type.label : nodata}</Typography>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TitleText >{content.status}</TitleText>
                                    {/* <Grid container alignItems='center' spacing={1}>
                                        <Grid item><div className={classes.status} style={{background: status ? status.color : 'white'}}/></Grid>
                                        <Grid item><Typography variant='subtitle1'>{status ? status.label : nodata}</Typography></Grid>
                                    </Grid>   */}  
                                    <div style={{width:'100%'}}>
                                    <StatusData catalogs={catalogs.user_statuses} id={user_status_id} />  
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )

}

const useStyles = makeStyles(theme => ({
    container:{
        borderRight:'1px solid #D9D9D9',
        [theme.breakpoints.down('sm')]:{
            borderRight:'none'
        }
    },
    subcontainer:{
        display:'flex',
        justifyContent:'center',
        
        //background:'red'
    },
    avatar_container:{
        textAlign:'center',
        padding:'20px 0px',
    },
    button:{
        //border:`1px solid ${theme.palette.primary.main}`,
        boxShadow:'0px 0px 5px rgba(237, 28, 36, 0.33)',
        borderRadius:20,
        color:theme.palette.primary.dark,
        padding:'10px 20px',
        textTransform:'none',
        marginTop:20
    },
    name:{
        marginTop:20
    },
    container2:{
        padding:'20px 70px',
        
    },
    topbar:{
        marginBottom:20
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
        //marginRight:16
    },
    status:{
        width:10,
        height:10,
        borderRadius:'50%',
        background:'white'
    },
  
    
}))

export default UserInfo

const TitleText = (props) => {
    return(
        <Typography variant='caption' style={{color:'#909090'}}>{props.children}</Typography>
    )
}
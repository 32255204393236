import React, {useState, useEffect, useCallback} from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import SimpleCard from '../../components/Structure/Cards/SimpleCard';
import InputText from '../../components/Forms/InputForm';
import RoundedButton from '../../components/Actions/RoundedButton';
import logo from '../../assets/logo_refaccionaria.png'
import image from '../../assets/dumb_refacciones.jpg'
//import NetworkCard from './components/NetworkCard';
import { actionTypes } from '../../store/actions';
import { connect } from 'react-redux';
import { CONFIG_SETTINGS,  public_server } from '../../config';
import { onGetErrorMessage, onGetFormData, onSetErrorsToForm, onSetTokenSession } from '../../shared/utility';
import { isFormValid } from '../../components/Forms/customFunctions';
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer';
import DisplayError from '../../components/Structure/DisplayData/DisplayError'


const SignInView = ({ip_address, history, onUpdateURL, language}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [disabled, setDisabled] = useState(true)

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)

        const errors = isFormValid(temp)
        console.log(errors)
        /// Check if the form is valid
        if(errors){
            setError(null)
            setDisabled(false)
        }else{
            setDisabled(true)
        }
    }

    
    const onSubmit = async() => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        let data2send = onGetFormData(form)
        data2send.device = 'web'
            
        setLoading(true)
        try {   
            console.log(data2send)
            const response = await public_server.post('/login', data2send)
            //console.log(response)  
            const token_data = response.data.data
            onSetTokenSession(token_data)
            history.push('/sales')   
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error, language)
            if(_error.details){
                console.log('Actualizar datos')
                const _error_keys = Object.keys(_error.details).map(item => item)
                const _form = onSetErrorsToForm(form, _error_keys)
                console.log(_form)
                setForm(_form)
            }
            setError(_error ? _error.message : null)
        }
        setLoading(false)
        
    }


    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){      
            onSubmit()
        }
    }

    return ( 
        <div className={classes.root}>
            <LoadingContainer error={error} onCloseError={() => setError(null)}>
                <div className={classes.subroot}>
                    <div className={classes.image_container}>
                        <img src={image} className={classes.img} />
                    </div>
                    <div className={classes.form_container}>
                        <div className={classes.rectangle}></div>
                        <div >
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <div className={classes.logo_container}>
                                    <img src={logo} className={classes.logo} />
                                </div>
                            </div>
                            <div className={classes.form_section}>
                                <Grid container alignItems='center'>
                                    <Grid item xs><div className={classes.line}></div></Grid>
                                    <Grid item><div style={{margin:'0px 8px'}}><Typography variant='h6'>Bienvenido</Typography></div></Grid>
                                    <Grid item xs><div className={classes.line}></div></Grid>
                                </Grid>         
                                <div className={classes.form}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <InputText data={form.username} onChange={onChange} onKeyPress={handleKeyPress}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputText data={form.password} onChange={onChange} onKeyPress={handleKeyPress}/>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.action}>    
                                    <DisplayError>{error}</DisplayError>
                                    <Button onClick={onSubmit} disabled={disabled} className={classes.button}>Ingresar</Button>           
                                </div>
                            </div>
                        </div>
                    </div>
                </div>      
            </LoadingContainer>  
        </div>
     );
}



const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        height:'100vh',
        overflowY:'auto',
    },
    subroot:{
        display:'flex'
    },
    image_container:{
        width:'40%',
        height:'100vh',
        //background:'red'
    },
    rectangle:{
        width:'100%',
        position:'absolute',
        top:0,
        background:'#FBFBFB',
        height:'40%',
        zIndex:4,
        [theme.breakpoints.only('lg')]:{
            height:'30%'
        },
    },
    form_container:{
        width:'60%',
        height:'100vh',
        textAlign:'center',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'relative'
        //background:'green'
    },
    form_section:{
        width:400,
    },
    form:{
        marginTop:40
    },
    action:{
        marginTop:40
    },
    logo_container:{
        width:300,
        marginBottom:50,
        position:'relative',
        zIndex:5
        //background:'green'
    },
    logo:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    },
    line:{
        background:'#D7D7D7',
        width:'100%',
        height:0.5
    },
    img:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    button:{
        padding:'12px 54px',
        borderRadius:8,
        background:theme.palette.primary.main,
        color:'white',
        textTransform:'none',
        '&:disabled':{
            opacity:'53%',
            color:'white'
        },
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    
    network:{
        position:'absolute',
        right:32,
        top:32
    }
}))

const mapStateToProps = state => {
    return {
        ip_address: state.ip_address,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateURL: (ip_address) => dispatch({type:actionTypes.SYS_UPDATE_IP_ADDRESS, ip_address})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SignInView);

const formData = {
    username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
            label:'Usuario',
            placeholder:'Set the username'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'password',
            type:'password',
            fullWidth: true,
            label:'Contraseña',
            placeholder:'Password'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
}
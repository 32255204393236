import nouser from '../../assets/placeholders/nouser.png'

export const translations = {
    title:'Detalle de usuario',
    nopicture:nouser,
    edit_section:{
        button:'Editar',
        password_button:'Cambiar contraseña',
        user:'Usuario',
        email:'Email',
        birthday:'Cumpleaños',
        type:'Tipo',
        gender:'Género',
        mobile:'Celular',
        covenant_date:'Fecha de contrato',
        status:'Status'
    },
    sales_section:{
        title:'Ventas'
    }
    
}

export default translations
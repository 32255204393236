import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import InputForm from "../../../../components/Forms/InputForm"
import SimpleModal from "../../../../components/Modals/SimpleModal"
import Header from "../../../../components/Structure/Texts/Header"
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import system_translations from "../../../../texts/system_translations"
import useAddBrandModal from "./useAddProviderModal"


const AddProviderModal = ({open, onClose, language}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.provider_modal
    const {loading, sending, error, form, actions} = useAddBrandModal({open, language})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm' >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item>
                            <AvatarPicture  can_edit />                              
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Header>{content.name}</Header>
                                    <InputForm data={form.brand} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Header>{content.relationship_date}</Header>
                                    <InputForm data={form.brand} onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Header>{content.description}</Header>
                                    <InputForm data={form.description} onChange={actions.onChangeForm} />
                                </Grid>
                            </Grid>
                        </Grid>             
                    </Grid>      
                </Grid>
            </Grid>
            <div className={classes.contact_title}>
                <Grid container alignItems='baseline'>
                    <Grid item><Typography variant='subtitle2'>{content.contact_info.title}</Typography></Grid>
                    <Grid item xs><div className={classes.line} /></Grid>
                </Grid>
            </div>
            <div className={classes.contact_info}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Header>{content.contact_info.name}</Header>
                        <InputForm data={form.brand} onChange={actions.onChangeForm} />
                    </Grid> 
                    <Grid item xs={6}>
                        <Header>{content.contact_info.email}</Header>
                        <InputForm data={form.brand} onChange={actions.onChangeForm} />
                    </Grid> 
                    <Grid item xs={6}>
                        <Header>{content.contact_info.phone}</Header>
                        <InputForm data={form.brand} onChange={actions.onChangeForm} />
                    </Grid> 
                    <Grid item xs={6}>
                        <Header>{content.contact_info.address}</Header>
                        <InputForm data={form.brand} onChange={actions.onChangeForm} />
                    </Grid> 
                </Grid>
            </div>
            <div className={classes.button_container}>
                <RoundedButton>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddProviderModal

const useStyles = makeStyles(theme => ({
    contact_info:{
        padding:'0px 40px'
    },
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    contact_title:{
        color:theme.palette.primary.dark,
        padding:'20px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'rgba(179, 10, 16, 0.26)',
        marginLeft:4
    }
    
    
    
}))
import React, { useRef, useState } from 'react'
import { CircularProgress, Grid, Icon, InputBase, Typography, makeStyles } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    input_root:{
        background:'white',
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'8px 16px',
        borderRadius:12,
        border:'1px solid #E3E3E3',
        color:'black',
        //width:400,
        [theme.breakpoints.down('sm')]:{
            width:'80%'
        }
    },
    icon:{
        color:'black',

    }, 
    input:{
        width:'100%',
        //background:'red'
    },
    options:{
        height:400,
        overflowY:'auto',
        marginTop:8
    },
    line:{
        width:8,
        background:'#949494',
        height:0.4,
        margin:'0px 8px'
    },
    option_container:{
        padding:'10px 8px',
        cursor:'pointer',
        borderRadius:8
        //borderBottom:'1px solid #E8E8E8'
    },
    option_container_sel:{
        background:grey[200]
    },
    nodata:{
        display:'flex',
        justifyContent:'center',
        textAlign:'center',
        alignItems:'center',
        height:'100%'
    }
}))

const ProductSearchBar = props => {
    const classes = useStyles()

    const {value, onChange, onUpdateSearch, options, loading, onSelectProduct} = props

    const timerRef = useRef()

    const [selected, setSelected] = useState(null)

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onUpdateSearch, 300);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onInnerChange = (e) => {
        onChange(e.target.value)
    }

    const onSelect = (item) =>{
        setSelected(item.value)
        onSelectProduct(item)
    }

    return(
        <div>
            <div className={classes.input_root}>
                <InputBase className={classes.input} value={value} placeholder='Buscar...' onChange={onInnerChange} 
                    onKeyUp={onStartCountDown} onKeyDown={onStopCountDown} ref={timerRef} 
                    endAdornment={
                        <React.Fragment>
                          {loading ? <CircularProgress color='primary' size={20} /> : null}
                        </React.Fragment>
                    }
                />  
            </div>
            <div className={classes.options} >
                {options && options.length > 0 ? 
                options.map((option, ind) => {
                    return(
                        <div key={ind.toString()} className={cx({[classes.option_container]:true, [classes.option_container_sel]: selected === option.value})} 
                        onClick={()=>onSelect(option)} >
                            <div style={{display:'flex', alignItems:'center'}}>
                                <Typography color='primary'>{option.code}</Typography>
                                <div className={classes.line}></div>
                                <Typography style={{fontWeight:700}}>{option.brand}</Typography>
                                <div className={classes.line}></div>
                                <Typography >{option.name}</Typography>
                            </div>   
                        </div>
                    )
                })
                : 
                <div className={classes.nodata} >
                    <div>
                        <Icon color='primary' fontSize='default'>search</Icon>
                        <Typography variant='subtitle2' style={{marginTop:8}} >Sin opciones</Typography>
                    </div>  
                </div>
                }
            </div>
        </div>
    )
}

export default ProductSearchBar
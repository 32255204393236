import React, { useState } from 'react';
import axios from 'axios'
import PictureModal from '../../../../../../../components/Modals/PictureModal';
import system_translations from '../../../../../../../texts/system_translations';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../../../../shared/utility';
import { private_server } from '../../../../../../../config';

const ChangeImageModal = (props) => {

    const {id, open, onClose, onCompleted, language} = props
    const content = system_translations[language].modals.change_image_modal

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        try {
            setLoading(true)
            console.log(file)
            const _file = await onGetImageURL2Blob(file)
            const data = {name:'image', ext:onGetFileExtension(_file.name)}
            const image_req =  await private_server.post(`/product/${id}/image`, data)
            const options = {headers: {'Content-Type': file.type}}
            delete axios.defaults.headers.common["Authorization"]
            await axios.put(image_req.data.data.url, _file, options);
            onCompleted()
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
    }

    const onDelete = async() => {
        try {
            setError(null)
            setLoading(true)
            await private_server.delete(`/product/${id}/image?name=image`)
            onCompleted()
            onClose()
        } catch (error) {
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
        setLoading(false)
        
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} language={language}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete}/>
     );
}
 
export default ChangeImageModal;
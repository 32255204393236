import { Icon, makeStyles } from "@material-ui/core"
import cx from "classnames"


const StatusDisplayer = ({status}) => {

    const classes = useStyles()

    console.log(status)

    return(
        <div>
            <div className={cx({[classes.check]:true, [classes.uncheck]:status})}>
                <Icon>{status ? 'clear' : 'check'}</Icon>
            </div>
        </div>
    )
}

export default StatusDisplayer

const useStyles = makeStyles(theme => ({
    check:{
        width:24,
        height:24,
        borderRadius:'50%',
        padding:8,
        color:'rgba(52, 158, 35)',
        background:'rgba(52, 158, 35, 0.07)'
    },
    uncheck:{
        color:'rgba(237, 28, 36)',
        background:'rgba(237, 28, 36, 0.07)'
    }
}))
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { usePrinterContext } from "../../../../../providers/PrinterProvider/usePrinterContext"
import { onTruncateText } from "../../../../../shared/utility"

const MAX_CHARS = 42

const usePrintButton = ({order}) => {


    const printerManager = usePrinterContext()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const ePosDevice = useRef();
    const printer = useRef();

    const [printerIPAddress, setPrinterIPAddress] = useState("192.168.100.34");
    const [printerPort, setPrinterPort] = useState("8008");
    const [textToPrint, setTextToPrint] = useState("");

    const [connectionStatus, setConnectionStatus] = useState(false);



    const [modals, setModals] = useState({
        data:false
    })

    useEffect(() => {
        
        console.log(order)
        actions.onInitModule()
        return () => {
            if(ePosDevice.current){
                
                if(ePosDevice.current.isConnected()){
                    console.log('Desconectar...')
                    ePosDevice.current.disconnect()
                    console.log('Desconectado...')
                }
                
            }
        }

    }, [])

    const onPrintData = () => {
        console.log('Imprimir data')
        let prn = printerManager.printer.current;
        if (!prn) {
            alert("Not connected to printer");
            return;
        }
        console.log(order)
        const orderID = order?.id_order ?? ""
        const seller = order?.seller ?? ""
        let sold_date = order?.sold_date ?? null
        let folio_number = null
        let receipt_type_id = null
        if(order){
            const {nf_receipt_id, receipt_id, receipt_type} = order
            receipt_type_id = order.receipt_type_id
            folio_number = nf_receipt_id ?? null
            folio_number =  !folio_number ? (receipt_id ?? null) : folio_number
        }
        if(sold_date){
            const tempDate = moment.utc(sold_date)
            if(tempDate.isValid()) sold_date = moment(tempDate.format()).format(("DD-MM-YYYY  HH:mm:ss"))
        }
        const payment_type = order?.payment_type ?? ""

        console.log(orderID, seller, sold_date, sold_date, payment_type)
        console.log('folio ', folio_number)


        let text_array = []
        order.order_items.forEach(item => {
            let string2send = `${item.quantity} - ${onTruncateText(item.product)}`
            let price = `$${item.total}`
            const space = MAX_CHARS - string2send.length - price.length
            let spacePattern = ''
            for(let i=0; i<space; i ++) spacePattern+=" ";
            text_array.push(`#${item.code ? item.code : '-'}`)
            text_array.push(`${string2send}${spacePattern}${price}`)
        })

        let linePattern = ''
        for(let i=0; i<MAX_CHARS; i ++) linePattern+="-";

        console.log(text_array)

        const pricing_data = [
            //{name:'Subtotal', key:'subtotal'},
            //{name:'I.V.A', key:'tax'},
            {name:'Total', key:'total'},
        ]

        let price_text = []

        pricing_data.forEach(item => {
            let label = item.name
            let data = `$${order[item.key]}`
            const space = MAX_CHARS - label.length - data.length
            let spacePattern = ''
            for(let i=0; i<space; i ++) spacePattern+=".";
            price_text.push(`${label}${spacePattern}${data}`)
        })

        console.log(price_text)


//
        //console.log(text_array)
        
        prn.addTextAlign(prn.ALIGN_CENTER)
        prn.addTextSize(2,1)
        prn.addText("REFACCIONES RM")
        prn.addFeedLine(2);
        prn.addTextSize(1,1)
        prn.addText('Tenosique No. MZ-65, Int. LT-1C, \n Col. Pedregal de San Nicolás \n 3ra Sección, 14100, Tlalpan, \n Ciudad de México, MEX')
        prn.addFeedLine(2);
        prn.addText("Javier Rojas López")
        prn.addFeedLine(1);
        prn.addText("RFC. ROLJ6805085G8")
        prn.addFeedLine(1);
        prn.addText("612 - Personas físicas con actividades \n empresariales y profesionales")
        prn.addFeedLine(1);
        prn.addText("Tel. 5556306336")

        prn.addFeedLine(1);
        prn.addText(linePattern);   
        prn.addFeedLine(1);

        prn.addText(`No. orden: ${orderID}`)
        prn.addFeedLine(1);
        if(folio_number){
            prn.addText(`Comprobante de compra`)
            prn.addFeedLine(1);
            prn.addText(`No. ${folio_number}${receipt_type_id === 1 ? '*' : ''}`)
            prn.addFeedLine(1);
        }
        prn.addText(`Vendedor: ${seller}`)
        prn.addFeedLine(1);
        prn.addText(`Fecha: ${sold_date}`)
        prn.addFeedLine(1);
        prn.addText(`Pago: ${payment_type}`)

        prn.addFeedLine(1);
        prn.addText(linePattern);
        prn.addFeedLine(1);


        text_array.forEach(item => {
            prn.addText(item);
            prn.addFeedLine(1);
        })

        prn.addFeedLine(1);

        price_text.forEach(item => {
            prn.addText(item);
            prn.addFeedLine(1);
        })
        prn.addFeedLine(1);
        prn.addText("Los precios incluyen I.V.A.")

        prn.addFeedLine(1);
        prn.addText(linePattern);
        prn.addFeedLine(1);


        prn.addText("Atención a clientes")
        prn.addFeedLine(1);
        prn.addText("Lunes a viernes: 9am - 6pm\n Sábados: 9am - 4pm\n Gracias por su compra")
        prn.addFeedLine(2);
        prn.addText("Devoluciones solo el mismo día de la venta entregando el material en perfecto estado")
        prn.addFeedLine(1);
        prn.addText("En partes eléctricas no hay garantía")
        prn.addFeedLine(1);
        prn.addText("Este ticket es parte de la factura del día")
        prn.addFeedLine(5);
        prn.addCut(prn.CUT_FEED);

        //prn.onreceive = function (res) { alert(res.printjobid); };
        //prn.onerror = function (err) { alert(err.status); };

        prn.send();

        prn.onerror = function (err) { 
            alert(err.status); 
            printerManager.onReconnect()
        };

    }


    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                
                setLoading(false)
            } catch (error) {
                console.log(error)
                //const _error = onGetErrorMessage(error, language)
                //setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onConnectToPrint: async() => {
            if(printerManager.status && printerManager.printer){
                console.log('Se puede imprimir')
                onPrintData()
            }else{

            }
            /*
            if(connectionStatus && printer.current && ePosDevice.current){
                onPrintData()
                return
            }
            console.log('Connectar')
            setLoading(true)
            try {
                let ePosDev = new window.epson.ePOSDevice();
                ePosDevice.current = ePosDev;
                ePosDev.connect(printerIPAddress, printerPort, (data) => {
                    if (data === "OK") {
                        console.log('Conexion terminada con exito')
                        ePosDev.createDevice(
                            "local_printer",
                            ePosDev.DEVICE_TYPE_PRINTER,
                            { crypto: true, buffer: false },
                            (devobj, retcode) => {
                                if (retcode === "OK") {
                                    console.log('Dispositivo creado')
                                    printer.current = devobj;
                                    setLoading(false)
                                    setConnectionStatus(true);
                                    onPrintData()
                                } else {
                                    setLoading(false)
                                    throw retcode;
                                }
                            }
                        );
                    } else {
                        setLoading(false)
                        throw data;
                    }
                });
//
                //console.log(ePosDevice)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
            */


        }
    }

    const system = {loading, sending, error}
    const view_data = {}

    return {system, modals, view_data, actions}
}

export default usePrintButton
import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import SearchBar from '../../../../../components/Actions/SearchBar'
import ActionBar from '../../../../../components/Actions/ActionBar'
import empty from '../../../../../assets/placeholders/noproducts.png'
import UserAvatarName from '../../../../../components/Structure/DisplayData/UserAvatarName'
import DeleteButton from '../../../../../components/Actions/DeleteButton'
import tableStyles from '../../../../../styles/analyticsTableStyles'
import { nodata } from '../../../../../config'
import AddButton from '../../../../../components/Actions/AddButton'
import YearFilter from './YearFilter'
import { onGetMonthsCatalog, onGetCurrencyValue } from '../../../../../shared/utility'
import MonthFilter from './MonthFilter'
import DownloadButton from '../../../../../components/Actions/DownloadButton'


const SimpleTable = props => {

    const classes = tableStyles()
    const { content, data, onAddRegister, onDownloadExcel, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading,
                onChangeMonth, onChangeYear, month, year} = props

    const header = content.header

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container  spacing={2} alignItems='center' > 
                    <Grid item><MonthFilter options={onGetMonthsCatalog()} onChange={onChangeMonth} value={month} /></Grid>
                    <Grid item><YearFilter options={content.year_filter_options} onChange={onChangeYear} value={year} /></Grid>           
                    <Grid item xs></Grid>
                    <Grid item><SearchBar value={search} onChange={onChangeSearch} /></Grid>
                    <Grid item><DownloadButton variant='table' onClick={onDownloadExcel} /></Grid> 
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><div style={{height:900}}><CircularProgress size={32}/></div></Grid>
                </Grid>
            ) : null}          
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                <Typography variant='caption' className={classes.header}>{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {id_product, code, brand, brand_logo, name,  sales_quantity, return_quantity, purchase_quantity, sales_total, return_total, purchase_total} = item

                                return(
                                    <TableRow key={key.toString()} className={classes.row} >
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{id_product}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{code}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><UserAvatarName variant='body2' name={brand} image={brand_logo} nopicture={content.nobrand} /></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{name}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{sales_quantity}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{return_quantity}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{purchase_quantity}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{onGetCurrencyValue(sales_total)}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{onGetCurrencyValue(return_total)}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => {}}><Typography variant='body2'>{onGetCurrencyValue(purchase_total)}</Typography></TableCell>
                                        {/* <TableCell className={classes.cell} padding='checkbox'><DeleteButton  onClick={() => onSelectedItem(item, 'delete')}/></TableCell> */}
                                    </TableRow>
                                )
                            })}
                            
                        </TableBody>
                    </Table>
                </div>           
            ): null}   
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>              
            ) : null}        
        </div>
    )
}

export default SimpleTable
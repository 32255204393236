import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"


const useSelectClientModal = ({open, language, id, onCompleted, origin}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [s_open, setSOpen] = useState(false)
    const select_loading = s_open && form.client_id.options.length === 0;
    
    useEffect(() => {
        let active = true;
        let catalogs = []
    
        if (!select_loading) {
          return undefined;
        }
    
        (async () => {
          try{
            updateAuthorizationHeader(private_server)
            const req_clients = await private_server.get(`client/all`)
            catalogs = onGetCatalogs(req_clients.data.data.clients, 'id_client', 'name' )
            console.log(catalogs)
          }catch(error){
            console.log(error)
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
          }
          if (active) {
              let _form = {...form}
              _form.client_id.options = catalogs
              setForm(_form)
          }
        })();
    
        return () => {
          active = false;
        };
    }, [select_loading]);
    
    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
    }, [open, origin])

    const actions = {
        onInitModule: async() => {
            let _form = onInitForm(form_data, origin, null)
            setForm(_form)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeSelectOpen:(new_status) => {
            console.log('open')
            setSOpen(new_status)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, origin)

            /* try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/order/${id}`, data2send)
                onCompleted()
                setError(null)
                setSending(false)

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            } */
            
        }
    }

    return {loading, sending, error, form, actions, s_open, select_loading} 
}


export default useSelectClientModal

const form_data = {
    client_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        //open:false,
        //loading:false,
        //options:[{value:1, label:'hola'}, {value:1, label:'hello'}],
        options:[],
        config: {
            id: 'client_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    

}
import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import SimpleModal from "../../../../../components/Modals/SimpleModal"
import system_translations from "../../../../../texts/system_translations"
import useAddEquivalenceModal from "./useAddPurchaseItemModal"
import DisplayError from "../../../../../components/Structure/DisplayData/DisplayError"
import InputForm from "../../../../../components/Forms/InputForm"
import ProductSearchBar from "../../../../../components/Actions/ProductSearchBar"


const AddPurchaseItemModal = ({open, onClose, language, id, onCompleted}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.add_product_item
    const {loading, sending, error, form, actions, prod_form, options, product_selected, loading_prod, search} = useAddEquivalenceModal({open, language, onClose, id, onCompleted})

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {/* <ProductAutocompleteForm options={options} onChange={actions.onChangeProduct}
                    onInputChange={actions.onInputChange} onSearchProduct={actions.onSearchProduct} loading={loading_prod}   /> */}
                    <ProductSearchBar options={options} value={search} loading={loading_prod} onSelectProduct={actions.onChangeProduct}
                    onChange={actions.onInputChange} onUpdateSearch={actions.onSearchProduct} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item><Typography variant="subtitle2">{content.quantity}</Typography></Grid>
                        <Grid item xs={2}><InputForm data={form.quantity} onChange={actions.onChangeForm} /></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.button_container}>
                <DisplayError>{error}</DisplayError>
                <RoundedButton disabled={product_selected === null} loading={sending} onClick={actions.onSubmit}>{content.button}</RoundedButton>
            </div>
        </SimpleModal>
    )

}

export default AddPurchaseItemModal

const useStyles = makeStyles(theme => ({
    button_container:{
        textAlign:'center',
        margin:'40px 0px 0px'
    },
    pic_container:{
        position:'relative'
    },
    edit_button_container:{
        position:'absolute',
        bottom:0,
        right:0
    }
    
}))
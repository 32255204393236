import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './theme';
import MainRouter from './routes/router';
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux';
import { store } from './store/reducer';
import { PrinterProvider } from './providers/PrinterProvider/PrinterContext';


function App() {
  return (
    
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <PrinterProvider>
          <MainRouter history={createBrowserHistory()}/>
        </PrinterProvider>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;

import { useEffect, useState } from "react"
import { private_server } from "../../../../config"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { generic_errors } from "../../../../texts/esp/genericErrors"


const useEditProviderModal = ({open, language, content, onCompleted, onClose, id}) => {


    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [provider, setProvider] = useState(null)

    //image modal
    const [img_modal, setImgModal] = useState(false) 

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
    }, [open])
    
    const actions = {
        onInitModule: async() => {
            setError(null)
            setLoading(true)
            try{
                const req = await private_server.get(`/provider/${id}`)
                const _provider = req.data.data.provider
                setProvider(_provider)
                let _form = onInitForm(form_data, _provider , content.form)
                setForm(_form)
                setLoading(false)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
            
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImgModal: (status) =>{
            setImgModal(status)
        },
        onChangeImageCompleted: async() =>{
            try{
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`provider/${id}`)
                const _provider = req.data.data.provider
                setProvider(_provider)
                onCompleted()
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, provider)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/provider/${id}`, data2send)
                onCompleted()
                setError(null)
                setSending(false)
                onClose()
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, img_modal, provider} 
}


export default useEditProviderModal

const form_data = {
    name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    description: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {type: 'distance', min:1, max:255}
    },
    relation_started_at: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'relation_started_at',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'date'}
    },
    contact_name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    contact_email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    contact_phone: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_phone',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'phone_number'}
    },
    address: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'address',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },

}
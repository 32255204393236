import { useEffect, useState } from "react"
import axios from 'axios'
import { private_server } from "../../../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"


const useEditUserModal = ({open, language, content, onCompleted, onClose, catalogs, origin}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [img_modal, setImgModal] = useState(false)

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
    }, [open])
 
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            setError(null)
            let _form = onInitForm(form_data, origin, content.form)
            _form.gender_id.options = catalogs.genders
            _form.user_type_id.options = catalogs.user_types
            _form.user_status_id.options = catalogs.user_statuses
            setForm(_form)
            setLoading(false)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImgModal: (status) =>{
            setImgModal(status)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                console.log(errors)
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, origin)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/user/${origin.id_user}`, data2send)
                setError(null)
                setSending(false)
                onCompleted()
                onClose()
            } catch (error) {
                setSending(false)
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, img_modal} 
}


export default useEditUserModal

const form_data = {
    first_name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    last_name: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    username: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'username',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:255}
    },
    email: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'email'}
    },
    birth_date: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    hired_at: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'hired_at',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    gender_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },
    mobile: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'phone_number'}
    },
    user_type_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'user_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },
    user_status_id: {
        value:1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'user_status_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'select'}
    },

}
import React, {useState} from 'react';
import { Drawer, alpha, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import general_translations from '../../../texts/esp/general-translations'
import cx from 'classnames';
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router';
import { DASHBOARD_SETTINGS } from '../../../config';

const Sidebar = ({drawer_status, history, ismobile, onUpdateDrawerStatus, user}) => {

    
    const classes = useStyles()

    const [op_active, setOpActive] = useState(false)

    const isURLSelected = (path) => {
        return history.location.pathname.split('/')[1] === path.split('/')[1];
    }

    const onChangeView = (url, item) => {
        history.push(url)
        if(item){
            setOpActive(true)
        }else{
            setOpActive(false)
        }
    }

    const content = general_translations.sidebar

    const onChangeOpActive = (value) => {
        setOpActive(value)
        console.log(op_active)
    }

    return ( 
        <div>
            <Drawer open={drawer_status} 
                variant={ismobile ? 'temporary' : 'persistent'}
                classes={{paper:classes.drawer}}
                onClose={() => onUpdateDrawerStatus(false)}
            >
                <div className={classes.container}>
                    <div className={classes.logo_container}>
                        <img src={content.logo} alt='' className={classes.logo}/>
                    </div>
                    <div className={classes.menu}>
                        <Grid container>
                            {content.list.map((item,key)=>{

                                const isactive = isURLSelected(item.path)
                                let enabled = true  
                                if(item.permissions.length && user){
                                    const findpermission = item.permissions.find(el => el === user.user_type_id)
                                    
                                    if(!findpermission){
                                        //console.log('------------------')
                                        //console.log(item)
                                        //console.log(findpermission)
                                        enabled = false
                                    }
                                }
                                if(!enabled) return null

                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <SidebarItem active={isactive} {...item} onChangeView={onChangeView} isURLSelected={isURLSelected}
                                            op_active={op_active} 
                                            onChangeOpActive={onChangeOpActive}
                                            />
                                    </Grid>
                                )
                            })} 
                        </Grid>
                        
                    </div>
                </div>
                
            </Drawer>
        </div>
     );

}

const useStyles = makeStyles(theme => ({
    drawer:{
        border:0,
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        boxShadow:'4px 4px 9px rgba(0, 0, 0, 0.07)',
        background:theme.palette.secondary.main,
        
    },
    logo:{
        width:120
    },
    logo_container:{
        width:'100%',
        background:'white',
        textAlign:'center',
        padding:'32px 0px'
    }, 
    container:{
        width:DASHBOARD_SETTINGS.SIDEBAR.WIDTH,
        boxSizing:'border-box',
        height:'100%'
    },
    title:{
        color:'white',
        marginTop:8
    },
    menu:{
        borderTop:'2px solid #EDEDED',
        background:theme.palette.secondary.main,
        //height:'100%'
    }
}))

const mapStateToProps = state => {
    return {
        drawer_status:state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (drawer_status) => dispatch({type:actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));


const SidebarItem = ({active, name, path, icon, onChangeView, options, isURLSelected, op_active, onChangeOpActive}) => {

    const classes = useStylesItem()

    return(
        <div>
            <div className={cx(classes.root,
            {[classes.active]:active || options && op_active,
            })} onClick={options ? null : () => onChangeView(path)}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                        <Icon>{icon}</Icon>
                    </Grid>
                    <Grid item>
                        <Typography align='center' variant='body2'>{name}</Typography>
                    </Grid>
                </Grid>
            </div>
            {options ? <div>
                {options.map(item => {

                    const isactive = isURLSelected(item.path)

                    //onChangeOpActive(isactive)
                    //console.log(isactive)
            
                    return(
                        <div key={item.name} className={cx(classes.option,{[classes.option_active]:isactive})} 
                        onClick={() => onChangeView(item.path, true)}>
                            <Typography variant='center'>{item.name}</Typography>
                        </div>
                    )
                })}
            </div> : null}
        </div>
    )
}

const useStylesItem = makeStyles(theme => ({
    root:{
        //color:grey[300],
        color:'white',
        padding:'16px 30px',
        //background:''
        '&:hover':{
            background:'white',
            color:theme.palette.secondary.main,
            cursor:'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    active:{
        //background:theme.palette.secondary.main,
        color:theme.palette.primary.main,
        background:'rgba(237, 28, 36, 0.1)',
        '&:hover':{
            background:'rgba(237, 28, 36, 0.1)',
            color:theme.palette.primary.main,
            cursor:'auto'
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    option:{
        cursor:'pointer',
        borderLeft:'1px solid #CDCDCD',
        padding:'4px 0px',
        marginLeft:60,
        paddingLeft:20,
        color:'#767676',
    },
    option_active:{
        color:theme.palette.primary.dark,
        fontWeight:500
    }
}))
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import { USER_TYPES } from '../../../config';
import system_translations from '../../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import AddPurchaseModal from './modals/AddPurchaseModal/AddPurchaseModal';
import usePurchasesView from './usePurchasesView';


const PurchasesView = ({language, history, user}) => {

    const content = system_translations[language].views.purchases
    const catalogs  = system_translations[language].catalogs
    const {actions, modals, view_data, system} = usePurchasesView({history})

    let can_create = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_create = false

    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <AddPurchaseModal language={language} open={modals.add_purchase} onCompleted={actions.onInitModule}
            onClose={()=>actions.onChangeModalStatus('add_purchase', false)} />
            <SimpleTable 
                filter={view_data.status_filter}
                onChangeStatusFilter={actions.onChangeStatusFilter}
                startDate={view_data.startDate}
                endDate={view_data.endDate}
                onChangeDate={actions.onChangeDate}
                content={content.table} 
                catalogs={catalogs}
                data={view_data.purchases}
                onSelectedItem={actions.onSelectItem}
                onAddRegister={can_create ? ()=>actions.onChangeModalStatus('add_purchase', true) : null}
                tableFilter={view_data.tableFilter}
                total={view_data.total}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onDownloadFile={actions.onDownloadFile}
                onUpdateSearch={actions.onResetTableFiler}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user:state.user
    }
}

export default connect(mapStateToProps)(PurchasesView) 
import shipping_icon from '../../assets/icons/shipping.svg'
import center_call_icon from '../../assets/icons/center-call.svg'
import offers_icon from '../../assets/icons/offers.svg'

import logo from '../../assets/logo_refaccionaria.png'


export const translations = {
    title:'Precio',
    menu:[{id:1, label:'Mayoreo'}, {id:2, label:'Menudeo'}],
    min:'Mínimo',
    max:'Máximo',
    topbar:{
        logo:logo,
        button:'Cancelar'
    },
    cart:{
        title:'Carrito',
        subtotal:'Subtotal',
        tax:'Impuestos',
        total:'Total',
        button:'Pagar',
        existence_quantity:'En existencia:',
        location:'Ubicación:'
    },
    equivalences:{
        title:'Equivalencias',
        code:'Código',
        description:'Descripción',
        quantity:'En existencia',
        button:'Agregar',
        header:[
            'Código',
            'En existencia',
            'Producto',
            'Marca',
            'Precio',
            'Ubicación',
            ''
        ]
    },
    complementary:{
        title:'Complementarios',
        code:'Código',
        description:'Descripción',
        quantity:'En existencia',
        button:'Agregar',
        header:[
            'Código',
            'En existencia',
            'Producto',
            'Marca',
            'Precio',
            'Ubicación',
            ''
        ]
    }
    
    
}

export default translations
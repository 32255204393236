import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { generic_errors } from "../../../../texts/esp/genericErrors"
import system_translations from "../../../../texts/system_translations"
import moment from "moment"

const useFinishDayModal = ({open, onClose, language, onCompleted}) => {

    const catalogs = system_translations[language].catalogs

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [orders, setOrders] = useState([])
    const [checks, setChecks] = useState([])

    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
            setError(null)
        }
    }, [ open])

    const onGetParams = () => {
        let filter={
            "AND":[
                {"field":"generated_receipt","operator":"=","value":1},
                {"field":"receipt_type_id","operator":"=","value":1},
                {"field":"order_status_id","operator":"=","value":3},
                {"field":"invoiced","operator":"=","value":0},
            ]
        }
        filter = encodeURIComponent(JSON.stringify(filter))
        return filter
    }

    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try{
                const filter = onGetParams()
                const req = await private_server.get(`order/all?order_by=id_order&filter=${filter}`)
                let _checks = req.data.data.orders.map((item) => {
                    const _cost = parseFloat(item?.total ?? 0.00)
                    console.log(item, _cost, 'asdfasdfasdf')
                    return {id : item.id_order, value:_cost !== 0, cost:item.total}
                })
                setChecks(_checks)
                console.log(req.data.data.orders)
                setOrders(req.data.data.orders)    
                form.date.value = moment().format('YYYY-MM-DD')
                //console.log(moment().format('DD/MM/YYYY'))
            }catch(e){
                console.log(e)
                const _error = onGetErrorMessage(e, language)
                setError(_error ? _error.message : null)
            }    
            setLoading(false)
        },
        onChangeForm: (data) => {
            console.log(data)
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeCheck: (event, _index) =>{
            //console.log(event.target.checked)
            let temp = [...checks]
            temp[_index].value = event.target.checked
            console.log(temp)
            setChecks(temp)
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            console.log(checks)

            const _orders = checks.filter(el => el.value === true)
            // Clean data
            const data2send = onGetFormData(form)
            data2send.date = moment(data2send.date).add(6, 'hour').format('YYYY-MM-DD hh:mm:ss')
            data2send.orders = _orders.map((item)=> item.id)
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.post(`/globalinvoice`, data2send)
                setOrders([])
                onCompleted()
                setError(null)
                setSending(false)
                onClose()
            } catch (error) {
                setSending(false)
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, orders, checks} 
}


export default useFinishDayModal

const form_data = {
    date: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
}
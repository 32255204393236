import { Button, Grid, makeStyles } from "@material-ui/core"
import cx from 'classnames'
import SelectAutocompleteForm from "../../../../../../components/Forms/SelectAutocompleteForm"
import InputSelectForm from '../../../../../../components/Forms/InputSelectForm'
import Header from '../../../../../../components/Structure/Texts/Header'

const ReceiptTypes = ({receipts, selected, onSelectedOption, form, actions, open, loading}) => {

    const classes = useStyles()

    let invoiceContent=null

    /* if(selected === 3){
        invoiceContent = <Grid item xs={12}>
                            <Grid container xs={12} spacing={3}>
                                <Grid item xs={12}>
                                    <Header>Cliente</Header>
                                    <SelectAutocompleteForm data={form.client_id} onChange={actions.onChangeForm} 
                                    open={open} loading={loading}
                                    onOpen={()=>actions.onChangeSelectOpen(true)} onClose={()=>actions.onChangeSelectOpen(false)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Header>Uso de factura</Header>
                                    <InputSelectForm data={form.cfdi_use} onChange={actions.onChangeForm}  />
                                </Grid>
                            </Grid>
                        </Grid>
    } */

    return(   
        <Grid container justifyContent='center' spacing={3}>
            {receipts.map(item => {
                return(
                    <Grid item key={item.value}>
                        <Button className={cx({[classes.option_button]:true, [classes.option_button_active]: selected === item.value})}
                        onClick={()=>onSelectedOption(item.value)}>{item.label}</Button>
                    </Grid>
                )
            })}
            {/* {invoiceContent} */}
        </Grid>
    )
}

export default ReceiptTypes

const useStyles = makeStyles(theme => ({
    option_button:{
        background:'rgba(54, 54, 54, 0.08)',
        border:'1px solid #363636',
        borderRadius:12,
        padding:'20px 32px',
        textTransform:'none',
        color:'#363636',
        '&:hover':{
            background:'rgba(54, 54, 54, 0.08)',
        }
    },
    option_button_active:{
        background:'#363636',
        color:'white',
        '&:hover':{
            background:'#363636',
        }
    },
}))
import { MenuItem, TextField, Typography, withStyles } from "@material-ui/core";
import UserAvatarName from "../../../../../components/Structure/DisplayData/UserAvatarName";

const CssTextField = withStyles({
    root: {
        background:'white',
        width:200,
        //boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.2)',
        //borderRadius:8,
        //border:'none',
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:8,
        boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.2)',
        //borderColor:'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
          //background:'white'
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    },
  })(TextField);


const YearFilter = ({options, onChange, value}) => {

    //console.log(options)

    const _options = options ? options : []

    const onInnerChange = event => {
        const response = event.target.value;
        //let temp = {...data};
        //temp.value = response;
        onChange(response);
    } 

    return(
        <div >
            <CssTextField  /* value={value} */ variant='outlined' color='secondary'
            select onChange={onInnerChange} defaultValue={value}
            >
                {_options.map(item => {

                    return(
                        <MenuItem key={item.value.toString()} value={item.value}>
                            <Typography variant='subtitle2'>{item.label}</Typography>   
                        </MenuItem>
                    )
                })}
            </CssTextField>
        </div>
    )
}

export default YearFilter
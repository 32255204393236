import { useState } from "react"
import WarningModal from "../../../../../../components/Modals/WarningModal"
import { private_server } from "../../../../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../../../shared/utility"

const DeleteProviderModal = ({open, onClose, language, id, onCompleted}) => {

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const onSubmit = async() =>{
        console.log(id)
        try {
            setLoading(true)
            updateAuthorizationHeader(private_server)
            await private_server.delete(`/providerproduct/${id}`)    
            setError(null)
            setLoading(false)
            onClose()
            onCompleted()     
        } catch (error) {
            setLoading(false)
            console.log(error)
            const _error = onGetErrorMessage(error, language)
            setError(_error ? _error.message : null)
        }
    }

    return(
        <WarningModal open={open} onClose={onClose} language={language}
            error={error} loading={loading} onSubmit={onSubmit}
        />
    )

}

export default DeleteProviderModal 
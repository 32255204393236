import shipping_icon from '../../assets/icons/shipping.svg'
import center_call_icon from '../../assets/icons/center-call.svg'
import offers_icon from '../../assets/icons/offers.svg'

import baby_icon from '../../assets/icons/baby.png'
import drugs_icon from '../../assets/icons/pill.png'
import aids_icon from '../../assets/icons/first-aids.png'
import nutrition_icon from '../../assets/icons/nutrition.png'
import skin_icon from '../../assets/icons/skin.png'
import vision_icon from '../../assets/icons/vision.png'


export const translations = {
    title:'Grupo de compra',
    general:{
        button:'Editar',
        name:'Nombre',
        description:'Descripción'
    },
    products:{
        title:'Productos',
        button:'Agregar',
        id:'ID',
        code:'Código',
        brand:'Marca',
        description:'Producto'
    }
    
}

export default translations
import { Grid, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import CircularGraph from "./components/CircularGraph2"
import MiniTable from "./components/MiniTable"
import TotalTable from "./components/TotalTable"
import UserTable from "./components/UserTable"
import VendorsTable from "./components/VendorsTable"


const General = ({upt, sales_total_units, return_total_units, purchase_total_units, sales_total, return_total, purchase_total, balance, 
                    content, user_data, users, actions, user_loading, total_data, total_loading, month_selected, sales_receipt, sales_nf_receipt, sales_invoice,
                    year_selected}) => {

    const classes = useStyles()

    const balance_total = parseFloat(sales_total) +  Math.abs(parseFloat(return_total)) + parseFloat(purchase_total)
    //console.log(upt_total)
    let balance_p1 = 0
    let balance_p2 = 0
    let balance_p3 = 0

    if(balance_total !== 0){
        balance_p1 = (parseFloat(sales_total) * 360) / balance_total
        balance_p2 = (Math.abs(parseFloat(return_total)) * 360) / balance_total
        balance_p3 = (parseFloat(purchase_total) * 360) / balance_total
    }


    const balance_data=[
        {label:content.metrics.balance.sales, color:'#ED1C24', percent:`${balance_p1}deg`},
        {label:content.metrics.balance.return, color:'#91070C', percent:`${balance_p1}deg ${balance_p2 + balance_p1}deg`},
        {label:content.metrics.balance.purchase, color:balance_total === 0 ? 'white' : '#E0842F', percent:`${balance_p2 + balance_p1}deg ${balance_p3}deg`}
    ]

    
    const upt_total = parseFloat(sales_total_units) +  parseFloat(return_total_units)
    //console.log(upt_total)
    let upt_p1 = 0
    let upt_p2 = 0

    if(upt_total !==0){
        upt_p1 = (parseFloat(sales_total_units) * 360) / upt_total
        upt_p2 = (parseFloat(return_total_units) * 360) / upt_total
    }

    const upt_data=[
        {label:content.metrics.upt.sales, color:'#4F9144', percent:`${upt_p1}deg`},
        {label:content.metrics.upt.return, color:balance_total === 0 ? 'white' :'#231F20', percent:`${upt_p1}deg ${upt_p1 + upt_p2}deg`}
    ]

    //notes new instead of upt
    const notes_total = parseFloat(sales_receipt) +  parseFloat(sales_nf_receipt) +  parseFloat(sales_invoice)

    let note_p1 = 0
    let note_p2 = 0
    let note_p3 = 0

    if(notes_total !== 0){
        note_p1 = (parseFloat(sales_receipt) * 360) / notes_total
        note_p2 = (parseFloat(sales_nf_receipt) * 360) / notes_total
        note_p3 = (parseFloat(sales_invoice) * 360) / notes_total
    }


    const notes_data=[
        {label:content.metrics.sales.sales_receipt, color:'#ED1C24', percent:`${note_p1}deg`},
        {label:content.metrics.sales.sales_nf_receipt, color:'#91070C', percent:`${note_p1}deg ${note_p2 + note_p1}deg`},
        {label:content.metrics.sales.sales_invoice, color:notes_total === 0 ? 'white' : '#E0842F', percent:`${note_p2 + note_p1}deg ${note_p3}deg`}
    ]



    return(
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}><Typography variant='subtitle1'>{content.metrics.title}</Typography></Grid>
                <Grid item xs={12}>
                    <SimpleCard>
                        <Grid container>
                            <Grid item md={6}>
                                <div className={classes.container}>
                                    <CircularGraph data_array={balance_data} total={balance} subtitle={content.metrics.balance.title} price_sign />                   
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div className={classes.container}>
                                    <CircularGraph data_array={notes_data} total={notes_total} subtitle={content.metrics.sales.title} price_sign />                   
                                </div>
                            </Grid>
                            {/* <Grid item md={6}>
                                <div className={classes.container2}>
                                    <CircularGraph  data_array={upt_data} subtitle='UPT' total={upt} /> 
                                </div>
                            </Grid> */}
                        </Grid>
                    </SimpleCard>
                </Grid>
            </Grid>
            <div className={classes.bottom_section}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <TotalTable data={total_data} content={content.historic} onChangeFilter={actions.onChangeTotalFilter} 
                        loading={total_loading} onSelectItem={actions.onMonthSelected} month_selected={month_selected} year_selected={year_selected} />
                    </Grid>
                    <Grid item xs={12}>
                        <UserTable data={user_data} content={content.user} onChangeFilter={actions.onChangeUserFilter} 
                        loading={user_loading} users={users} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default General

const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:70
    },
    container:{
        borderRight:'1px solid #D8D8D8',
        //justifyContent:'center',
        //display:'flex'
        [theme.breakpoints.down('sm')]:{
            borderRight:'none'
        }
    },
    container2:{
        paddingLeft:40,
        [theme.breakpoints.down('sm')]:{
            paddingLeft:0
        }
    },
    bottom_section:{
        marginTop:50
    }
    
}))


import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import WebsiteLayout from '../layouts/WebsiteLayout/WebsiteLayout';
import SignInView from '../layouts/SignInView/SignInView';
import OrderView from '../views/Order/OrderView';

const MainRouter = ({ history }) => {
    return (
        <Router history={history}>
            <Switch>
                <Route path={'/order/:id'} component={OrderView} />
                <Route path={'/signin'}  component={SignInView} />
                <Route path={'/'}  component={WebsiteLayout} />
                <Redirect to='/' />
            </Switch>
        </Router>
    )
}

export default MainRouter
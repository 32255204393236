import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"


const useUserView = ({history, match, language}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [user, setUser] = useState(null)

    const [modals, setModals] = useState({
        edit_user:false,
        delete_user:false,
        change_password:false
    })

  
    useEffect(() => {
        actions.onInitModule()
    }, [])


    const actions = {
        onInitModule: async() => {
            setError(null)
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`user/${id}`)
                //console.log(req)
                const _user = req.data.data.user
                setUser(_user)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onClearError:()=>{
            setError(null)
        }
    }

    const system = {loading, sending, error}
    const view_data = {user}

    return {system, modals, view_data, actions}
}

export default useUserView
import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { onGetFullname } from '../../../shared/utility'
import { green, grey } from '@material-ui/core/colors'
import SearchBar from '../../../components/Actions/SearchBar'
import ActionBar from '../../../components/Actions/ActionBar'
import empty from '../../../assets/placeholders/noclients.png'
import UserAvatarName from '../../../components/Structure/DisplayData/UserAvatarName'
import DeleteButton from '../../../components/Actions/DeleteButton'
import tableStyles from '../../../styles/tableStyles'
import { nodata } from '../../../config'
import AddButton from '../../../components/Actions/AddButton'
import RoundedButton from '../../../components/Actions/RoundedButton'


const SimpleTable = props => {

    const classes = tableStyles()
    const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onUpdateSearch} = props

    const header = content.header

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='center' wrap='nowrap'> 
                    <Grid item><SearchBar value={search} onChange={onChangeSearch} onUpdateSearch={onUpdateSearch} /></Grid>
                    <Grid item><AddButton variant='table' onClick={onAddRegister}/></Grid>    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}          
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                <Typography variant='subtitle2'>{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {id_client, name, email, rfc, cfdi_use} = item

                                return(
                                    <TableRow key={key.toString()} className={classes.row} >
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'orders')}><Typography>{id_client}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'orders')}><Typography>{name}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'orders')}><Typography>{email}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'orders')}><Typography>{rfc !=='@empty!!' ? rfc : nodata}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'orders')}><Typography>{cfdi_use !=='@empty!!' ? cfdi_use : nodata}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><RoundedButton  onClick={() => onSelectedItem(item, 'edit')}>{content.edit_button}</RoundedButton></TableCell>
                                        <TableCell className={classes.cell} padding='checkbox'><DeleteButton  onClick={() => onSelectedItem(item, 'delete')}/></TableCell>
                                    </TableRow>
                                )
                            })}
                            
                        </TableBody>
                    </Table>
                </div>           
            ): null}   
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>              
            ) : null}        
        </div>
    )
}

export default SimpleTable
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/PageAdmin'
import { USER_TYPES } from '../../config';
import system_translations from '../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import useTrackingView from './useTrackingView';
import moment from 'moment';
import ItemCard from './components/ItemCard';
import FinishDayModal from './modals/FinishDayModal/FinishDayModal'
import ItemCardPayment from './components/ItemCardPayment';



const TrackingView = ({language, history, user}) => {

    const content = system_translations[language].views.tracking
    const {actions, modals, view_data, system} = useTrackingView({language})

    const classes = useStyles()



    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <FinishDayModal language={language} open={modals.finish_day} onClose={()=>actions.onChangeModalStatus('finish_day', false)} 
               onCompleted={actions.onInitModule} />
            <div className={classes.button_container}>
                <Button className={classes.button} onClick={()=>actions.onChangeModalStatus('finish_day', true)}>{content.button}</Button>
            </div>
            <div style={{position:'relative', width:'100%', height:40, marginBottom:40}}>
                <div className={classes.header}>
                    <Typography style={{marginRight:16}}>{content.day}</Typography>
                    <Typography>{view_data.date}</Typography>
                </div>
            </div>
            <Grid container spacing={5}>    
                <Grid item md={6} xs={12}>
                    <ItemCard title={content.voucher.title} summary={view_data.summary} headlands={content.voucher.headlands} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <ItemCardPayment title={content.payment.title} headlands={content.payment.headlands} summary={view_data.summary} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' style={{marginBottom:20}}>{content.table.title}</Typography>
                    <SimpleTable 
                    loading={system.loading}
                    content={content.table} 
                    data={view_data.invoices}
                    onSelectedItem={actions.onSelectItem}
                    tableFilter={view_data.tableFilter}
                    total={view_data.total}
                    onDownloadCashInvoice={actions.onDownloadCashInvoice}
                    onDownloadCardInvoice={actions.onDownloadCardInvoice}
                    selected={view_data.selected}
                    //search={view_data.search}
                    onUpdateTableFilter={actions.onChangeTableFilter}
                    //onChangeSearch={actions.onChangeSearch}
                    />
                </Grid>
            </Grid>     
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user: state.user
    }
}

const useStyles = makeStyles(theme => ({
    header:{
        background:theme.palette.secondary.main,
        color:'white',
        display:'flex',
        padding:'8px 0px',
        paddingLeft:40,
        position:'absolute',
        top:0,
        left:-40,
        right:-40

    },
    button_container:{
        display:'flex',
        justifyContent:'flex-end',
        padding:'20px 0px 40px'
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        boxShadow:'0px 4px 6px rgba(0, 0, 0, 0.25)',
        padding:'12px 24px',
        textTransform:'none'
    }
}))

export default connect(mapStateToProps)(TrackingView) 
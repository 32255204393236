import { useEffect, useState } from "react"
import { private_server } from "../../../../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../../../shared/utility"
import { generic_errors } from "../../../../../../../texts/esp/genericErrors"
import useProduct from "../../../../../../../hooks/api/useProduct"

const useAddComplementModal = ({open, language, onClose, onCompleted, id}) => {

    
    const productManager = useProduct()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [loading_prod, setLoadingProd] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [product_selected, setProductSelected] = useState(null);
    const [options, setOptions] = useState([])

    const [search, setSearch] = useState(null)

    useEffect(() => {
        if(!open){
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
            
        }
    }, [open])

    

    let prod_form = {...form.complement_product_id}
    prod_form.options = [...options]
    
    const actions = {
        onInitModule: async() => {
            
        },
        onChangeForm: (data) => {
            //console.log(data)
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onInputChange: (e) =>{
            //if(!e || e.target.value === "" || e.target.value === 0) return
            setSearch(e)
        },
        onSearchProduct: async(e) => {
            //console.log(e)
            //if(!e || e.target.value === "" || e.target.value === 0) return
            if(error) setError(null)
            setLoadingProd(true)
            try {
                if(!search){
                    const params = {limit:150 }
                    const filter = {filter:
                        {"OR":[
                            {"field":"name","operator":"LIKE","value":`%${search}%`},
                            {"field":"code","operator":"LIKE","value":`%${search}%`},
                            {"field":"brand","operator":"LIKE","value":`%${search}%`},
                            {"field":"description","operator":"LIKE","value":`%${search}%`},
                        ]}           
                    }
                    const req = await private_server.get(`/product/all`, {params:{...params, ...filter}})
                    const cat = req.data.data.products.map(item => {
                        //return {value:item.id_product, label:item.name}})
                        return {value: item.id_product, label: item.code + ' - ' + item.name, brand:item.brand, code:item.code, name:item.name}})
                    setOptions(cat)
                }else{
                    const params = {limit:150, search:search}
                    const _productdata = await productManager.onSearch(params)
                    const cat = _productdata.data.map(item => {
                        //return {value:item.id_product, label:item.name}})
                        return {value: item.id_product, label: item.code + ' - ' + item.name, brand:item.brand, code:item.code, name:item.name}})
                    setOptions(cat)
                }
            } catch (error) {
                console.log(error)
            }
            setLoadingProd(false)
        },
        onChangeProduct: async (item) =>{
            if(item){
                //console.log(item.value)
                setProductSelected(item.value)
            }
        },
        onSubmit: async() => {
            /* const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            console.log(data2send) */

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.post(`/complement`, {main_product_id: id, complement_product_id: product_selected})
                onCompleted()
                onClose()
                setError(null)
                setSending(false)

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, prod_form, options, product_selected, loading_prod, search} 
}


export default useAddComplementModal

const form_data = {
    complement_product_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'complement_product_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
}
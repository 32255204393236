import { Grid, Typography } from "@material-ui/core"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { PURCHASES_ORDER_STATUSES } from "../../../../config"
import MiniTable from "./MiniTable"


const Products = ({content, data, can_edit, onAdd, onDelete, status}) => {

    return(
        <div>
            <Typography variant='h6'>{content.title}</Typography>
            <div style={{marginBottom:20}}>
            {can_edit && status === PURCHASES_ORDER_STATUSES.CREADED ? <Grid container justifyContent='flex-end'>
                <Grid item>
                    <RoundedButton onClick={onAdd} >{content.button}</RoundedButton>
                </Grid>
            </Grid> : null}
            </div>
            <MiniTable rows={data} header={content.header} onDelete={ can_edit && status === PURCHASES_ORDER_STATUSES.CREADED ? onDelete : null}/>
        </div>
    )
}

export default Products
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AnalyticalsView from '../views/Analyticals/AnalyticalsView'
import BrandsView from '../views/Brands/BrandsView'
import ClientsView from '../views/Clients/ClientsView'
import ProductView from '../views/Products/Product/ProductView'
import ProductsView from '../views/Products/Products/ProductsView'
import ProvidersView from '../views/Providers/ProvidersView'
import PurchaseView from '../views/Purchases/Purchase/PurchaseView'
import PurchasesView from '../views/Purchases/Purchases/PurchasesView'
import UserView from '../views/Users/User/UserView'
import UsersView from '../views/Users/Users/UsersView'
import SalesView from '../views/Sales/Sales/SalesView'
import SaleView from '../views/Sales/Sale/SaleView'
import MissingView from '../views/Missing/MissingView'
import PurchaseGroupsView from '../views/PurchaseGroups/PurchaseGroups/PurchaseGroupsView'
import PurchaseGroupView from '../views/PurchaseGroups/PurchaseGroup/PurchaseGroupView'
import MyProfileView from '../views/MyProfile/MyProfileView'
import TrackingView from '../views/Tracking/TrackingView'

const DashboardRouter = () => {
    return(
        <Switch>
            <Route exact path='/tracking' component={TrackingView} />
            <Route exact path='/missing' component={MissingView} />
            <Route exact path='/sales/:id' component={SaleView} />
            <Route exact path='/sales' component={SalesView} />
            <Route exact path='/purchases/:id' component={PurchaseView} />
            <Route exact path='/purchases' component={PurchasesView} />
            <Route exact path='/analyticals' component={AnalyticalsView} />
            <Route exact path='/clients' component={ClientsView} />
            <Route exact path='/purchase-groups/:id' component={PurchaseGroupView} />
            <Route exact path='/purchase-groups' component={PurchaseGroupsView} />
            <Route exact path='/providers' component={ProvidersView} />
            <Route exact path='/brands' component={BrandsView} />
            <Route exact path='/products/:id' component={ProductView} />
            <Route exact path='/products' component={ProductsView} />
            <Route exact path='/users/:id' component={UserView} />
            <Route exact path='/users' component={UsersView} />
            <Route exact path='/my-profile' component={MyProfileView} />
            <Redirect to='/sales' />
        </Switch>
    )
}

export default DashboardRouter


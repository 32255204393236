import { Icon, IconButton, makeStyles } from "@material-ui/core"



const AddButton = ({size, onClick, disabled}) => {

    const classes = useStyles()

    return(
        <IconButton onClick={onClick} disabled={disabled} size={size ? size : 'medium'} className={classes.button} ><Icon style={{fontSize:16}}>add</Icon></IconButton>
    )
}

export default AddButton

const useStyles = makeStyles(theme => ({
    button:{
        background:theme.palette.primary.main,
        padding:4, 
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark
        },
    },
   
    
}))
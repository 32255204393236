
export const translations = {
    title:'Ventas',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Fecha de venta'},
            {id:3, label:'Estatus'},
            {id:4, label:'Vendedor'},
            {id:5, label:'Total'},
            {id:6, label:'Método de pago'},
            {id:7, label:'Recibo'},
            {id:8, label:''},
        ],
        nodata:'No existen ventas registradas'
    }
    
}

export default translations
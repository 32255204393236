

export const translations = {
    title:'Faltantes',
    table:{
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Código'},
            {id:3, label:'Marca'},
            {id:4, label:'Producto'},
            {id:5, label:'Descripción'},
            {id:6, label:'Actuales'},
            {id:7, label:'Comprados'},
            {id:8, label:'A comprar'},
            {id:9, label:'Grupo'},
        ],
        nodata:'No existen faltantes registrados'
    }
    
}

export default translations
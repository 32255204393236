import { Button, CircularProgress, Grid, Icon, IconButton, makeStyles, Switch, Typography } from "@material-ui/core"
import RoundedButton from "../../../components/Actions/RoundedButton"
import Header from '../../../components/Structure/Texts/Header'
import { onGetCurrencyValue } from "../../../shared/utility"
import AddButton from "./AddButton"
import cx from 'classnames'
import { green, grey, red } from "@mui/material/colors"
import { useState } from "react"


const CartCard = ({order_items, total, subtotal, tax, actions, selected, content, sending}) => {

    const classes = useStyles()
    const items = order_items ? order_items : []

    const [deleting, setDeleting] = useState(false)

    const onDeleteItem = async(id) =>{
        setDeleting(true)
        await actions.onDeleteOrderItem(id)
        setDeleting(false)
    }

    return(
        <div className={classes.root}>
            <div className={classes.title}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item><Icon style={{marginTop:4}}>shopping_cart</Icon></Grid>
                    <Grid item><Typography variant='subtitle1'>{content.title}</Typography></Grid>
                </Grid>
            </div>
            {items && items.lenght !== 0 ? items.map((item, index) => {

                const _quantity = item.quantity ? parseInt(item.quantity) : 0;

                return(
                    <div key={index.toString()} onClick={()=>actions.onSetItemSelected(item.id_order_item, item.product_id)}
                    className={cx({[classes.item]:true, [classes.item_active]: item.id_order_item === selected})}>
                        {sending && item.id_order_item === selected ? <div style={{textAlign:'center'}}><CircularProgress /></div>: 
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}>
                                <Grid container spacing={1} >
                                    <Grid item>                            
                                        {/* <div >
                                            <Grid container  alignItems="center">
                                                <Grid item><Typography style={{fontSize:'0.7rem', fontWeight: Boolean(item.wholesale) ? 500 : 400 }}>{onGetCurrencyValue(item.wholesale_price)}</Typography></Grid>
                                                <Grid item>
                                                    <Switch className={classes.switch} checked={!Boolean(item.wholesale)} 
                                                    onChange={()=>actions.onChangeWholesale(item.id_order_item, item.wholesale)} 
                                                    inputProps={{ 'aria-label': 'controlled' }} />
                                                </Grid>
                                                <Grid item><Typography style={{fontSize:'0.7rem', fontWeight: !Boolean(item.wholesale) ? 500 : 400 }}>{onGetCurrencyValue(item.retail_price)}</Typography></Grid>
                                            </Grid>
                                        </div> */} 
                                        <div >
                                            <Grid container  alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Button className={cx({[classes.nowholesale_button]:true, [classes.inactive_wholesale_button]: !Boolean(item.wholesale)}) }
                                                        onClick={()=>actions.onChangeWholesale(item.id_order_item, item.wholesale)}
                                                        startIcon={<Icon style={{fontSize:'0.9rem'}}>south</Icon>}>
                                                        {/* <Grid container spacing={1} alignItems="center">
                                                            <Grid item><Icon style={{fontSize:'0.8rem'}}>south</Icon></Grid>
                                                            <Grid item><Typography style={{fontSize:'0.7rem'}}>{onGetCurrencyValue(item.wholesale_price)}</Typography></Grid>
                                                        </Grid> */}
                                                        {onGetCurrencyValue(item.wholesale_price)}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button className={cx({[classes.wholesale_button]:true, [classes.inactive_wholesale_button] : Boolean(item.wholesale) })}
                                                        onClick={()=>actions.onChangeWholesale(item.id_order_item, item.wholesale)} 
                                                        startIcon={<Icon style={{fontSize:'0.9rem'}}>north</Icon>}>
                                                        {/* <Grid container spacing={1} alignItems="center">
                                                            <Grid item><Icon style={{fontSize:'0.8rem',marginTop:2}}>north</Icon></Grid>
                                                            <Grid item><Typography style={{fontSize:'0.8rem' }}>{onGetCurrencyValue(item.retail_price)}</Typography></Grid>
                                                        </Grid> */}
                                                        {onGetCurrencyValue(item.retail_price)}
                                                    </Button>
                                                </Grid>                                              
                                                <Grid item>
                                                    <div><Typography style={{fontWeight:700}} variant="caption">{content.existence_quantity} {item.existence_quantity}</Typography></div>
                                                </Grid>
                                            </Grid>
                                        </div>                                                                  
                                    </Grid>
                                    <Grid item xs></Grid>
                                    <Grid item>
                                        <Typography style={{fontWeight:700}} variant='caption'>{content.location} {item.location}</Typography>
                                    </Grid>
                                    <Grid item>
                                        {/* <div className={classes.button_container} onClick={()=>actions.onDeleteOrderItem(item.id_order_item)}>
                                            <Icon style={{fontSize:12}}>clear</Icon>
                                        </div> */}
                                        <IconButton className={classes.button_container} disabled={deleting} onClick={()=>onDeleteItem(item.id_order_item)}>
                                            <Icon style={{fontSize:12, color:'white'}}>clear</Icon>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item ><Typography variant='body2'>{item.code}</Typography></Grid>
                            <Grid item ><div className={classes.line}></div></Grid>
                            <Grid item xs><Typography variant='body2'>{item.product}</Typography></Grid> */}
                            <Grid item xs>
                                <div className={classes.product_label}>
                                    <font style={{color:'red'}}>{item.code}</font> - <strong>{item.brand}</strong> - {item.product}
                                </div>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <IconButton className={classes.quantity_button_minus}
                                        onClick={()=>actions.onChangeQuantity(item.id_order_item, _quantity > 1 ? _quantity - 1 : 1 )}>
                                            <Icon style={{fontSize:16}}>remove</Icon>
                                        </IconButton>
                                    </Grid>
                                    <Grid item><Typography variant='body2'>{_quantity}</Typography></Grid>
                                    <Grid item><AddButton onClick={()=>actions.onChangeQuantity(item.id_order_item, _quantity+1)} /></Grid>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </div>
                )
            }) : null}
            <div className={classes.subtotal_container}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item><Typography className={classes.header}>{content.subtotal}</Typography></Grid>
                            <Grid item><Typography variant='body2'>{onGetCurrencyValue(subtotal)}</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item><Typography className={classes.header}>{content.tax}</Typography></Grid>
                            <Grid item><Typography variant='body2'>{onGetCurrencyValue(tax)}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.total_container}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item><Typography className={classes.header}>{content.total}</Typography></Grid>
                    <Grid item><Typography variant='h6' color='primary'>{onGetCurrencyValue(total)}</Typography></Grid>
                </Grid>
            </div>
            <div className={classes.bottom_button}>
                <RoundedButton onClick={actions.onSubmit}>{content.button}</RoundedButton>
            </div>
        </div>
    )
}

export default CartCard

const useStyles = makeStyles(theme => ({
    root:{
        boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.06)'
    },
    container:{
        padding:'0px 20px'
    },
    title:{
        padding:'20px 20px',
        //display:'flex',
        color:theme.palette.primary.dark
    },
    item:{
        padding:'16px 20px 20px',
        borderBottom:'1.26667px solid #D2D2D2',
        cursor:'pointer',
        //background:'red'
    },
    item_active:{
        background:'rgba(237, 28, 36, 0.05)',
        cursor:'auto'
    },
    button_container:{
        background:'#838383',
        width:10,
        height:10,
        '&:hover':{
            background:'#838383',
        }
        //color:'white'

        //with div
        /* borderRadius:'50%',
        padding:4,
        width:10,
        height:10,
        color:'white',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer' */
    },
    line:{
        background:'#949494',
        width:20,
        height:1
    },
    quantity_button_minus:{
        background:'rgba(237, 28, 36, 0.14)',
        padding:4, 
        color:theme.palette.primary.main,
        '&:hover':{
            background:theme.palette.primary.dark,
            color:'white'
        }
    },
    subtotal_container:{
        padding:'70px 20px 20px',
        borderBottom:'1px solid #C6C6C6'
    },
    header:{
        fontWeight:700,
        fontSize:'1rem'
    },
    total_container:{
        padding:'20px',
    },
    bottom_button:{
        padding:'40px 0px',
        textAlign:'center'
    },
    switch:{
        //width:94,
    
    },
    wholesale_button:{
        background:'white',
        color:green[500],
        border:`1px solid ${green[500]}`,
        padding:'2px 12px'
    },
    nowholesale_button:{
        background:'white',
        color:red[700],
        border:`1px solid ${red[700]}`,
        padding:'2px 12px'
    },
    inactive_wholesale_button:{
        background:grey[300],
        color:'white',
        border:`1px solid ${grey[300]}`,
        //padding:2
    },
    product_label:{
        fontFamily:theme.typography.fontFamily
    }

}))
import { useEffect, useState } from "react"
import { private_server, RETURN_ORDER_TYPE } from "../../../../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/esp/genericErrors"

const useAddReturnItemModal = ({open, language, content, onClose, onCompleted, id, type, return_id}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [loading_prod, setLoadingProd] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [options, setOptions] = useState([])
    const [product_selected, setProductSelected] = useState(null);

    const [max_quantity, setMaxQuantity] = useState(null);
    const [item_id, setItemId] = useState(null);
    const [wholesale, setWholesale] = useState(null)

    const [search, setSearch] = useState(null)

    useEffect(() => {
        if(!open){
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
        }
        if(open){
            actions.onInitModule()
        }
    }, [open])

    //let prod_form = {...form.product_id}
    //prod_form.options = [...options]
    
    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try{
                setForm(onInitForm(form, null, content.form, ))
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`/orderitem/all?filter={"AND":[{"field":"order_id","operator":"=","value":"${id}"}]}`)
                //console.log(req)
                const cat = req.data.data.order_items.map(item => {
                    return {value: item.id_order_item, label: item.code + ' - ' + item.brand + ' - ' + item.product, brand:item.brand, code:item.code, 
                                name:item.product, max: item.max_return_quantity, id:item.id_order_item, wholesale:item.wholesale}})
                let _form = {...form}
                _form.order_item_id.options = cat
                setForm(_form)
            }catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            setLoading(false)  
        },
        onChangeForm: (data) => {
            //console.log(data)
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(id === 'order_item_id'){
                //console.log(temp[id])
                const _quantity =  temp[id].options.filter(el => el.value === temp[id].value);
                //console.log(_quantity)
                setMaxQuantity(_quantity[0].max)
                setItemId(_quantity[0].id)
                setWholesale(_quantity[0].wholesale)
            }
            if(id === 'quantity'){
                if (temp['quantity'].value > max_quantity ) temp['quantity'].isValid = false
            }
            if(error) setError(null)
            setForm(temp)
        },
        onInputChange: (e) =>{
            //if(!e || e.target.value === "" || e.target.value === 0) return
            setSearch(e)
        },
        onSearchProduct: async(e, label) => {
            //console.log(e.target.value)
            //console.log(label)
            //if(!e || e.target.value === "" || e.target.value === 0) return
            if(error) setError(null)
            setLoadingProd(true)
            try {
                const params = {limit:150}
                const filter = {filter:
                    {"OR":[
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                        {"field":"code","operator":"LIKE","value":`%${search}%`},
                        {"field":"brand","operator":"LIKE","value":`%${search}%`},
                        {"field":"description","operator":"LIKE","value":`%${search}%`},
                    ]}           
                }
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`/product/all`, {params:{...params, ...filter}})
                const cat = req.data.data.products.map(item => {
                    return {value: item.id_product, label: item.code + ' - ' + item.brand + ' - ' + item.name, code:item.code, brand:item.brand, name:item.name}})
                setOptions(cat)
            } catch (error) {
                console.log(error)
            }
            setLoadingProd(false)
        },
        onChangeProduct: async (item) =>{
            if(item){
                //console.log(item.value)
                setProductSelected(item.value)
            }
        },
        onSubmit: async() => {
            const errors = isFormValid(form)

            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            //console.log(return_id)
            data2send.return_order_id = return_id
            data2send.order_item_id = item_id
            data2send.wholesale = wholesale
            //console.log(type)
            if(type ===  RETURN_ORDER_TYPE.CHANGE) data2send.product_id = product_selected
            console.log(data2send)

            //const product = products.filter(el => el.code === data2send.product_2_id.split(' ')[0])
            //console.log(product[0])

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.post(`/returnorderitem`, data2send)
                onCompleted()
                onClose()
                setError(null)
                setSending(false)

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error, language)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        }
    }

    return {loading, sending, error, form, actions, max_quantity, options, loading_prod, search} 
}


export default useAddReturnItemModal

const form_data = {
    order_item_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'order_item_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
    /* product_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'product_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    }, */
    quantity: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'quantity',
            type: 'number',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
}
import { Grid, Icon, IconButton, makeStyles, Typography } from "@material-ui/core"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard"


const MiniTable = props => {

    const { title, rows, header } = props
    const classes = useStyles()

    return(
        <SimpleCard>
            <Grid container >
                <Grid item>
                    <Typography variant='subtitle2'>{title}</Typography>
                </Grid>
                {/* <Grid item>
                    <RoundedButton onClick={onAdd}>{content.button}</RoundedButton>
                </Grid> */}
            </Grid>
            {rows && rows.length ?
                <div className={classes.rows}>
                    <div className={classes.header}>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>{header[0]}</Grid>
                        <Grid item xs>{header[1]}</Grid>
                        <Grid item>{header[2]}</Grid>
                    </Grid>
                    </div>
                    {rows.map(item => {
                        return(
                            <Row {...item} key={item.code}  />
                        )
                    })}
                </div>
            : null}
        </SimpleCard>
    )
}

export default MiniTable

const useStyles = makeStyles(theme => ({
    rows:{
        marginTop:40
    },
    header:{
        font:theme.typography.fontFamily,
        fontSize:'0.875rem',
        color:'#909090',
        fontWeight:500
    },
}))

const Row = ({code, product, sold_out}) => {

    const classes = useStylesRow()

    return(
        <div className={classes.root}>
            <Grid container spacing={3} alignItems='center'>
                <Grid item xs={2}>{code}</Grid>
                <Grid item xs>{product}</Grid>
                <Grid item >{sold_out}</Grid>
            </Grid>
        </div>
    )
}

const useStylesRow = makeStyles(theme => ({
    root:{
        borderBottom:'1px solid #D2D2D2',
        padding:'8px 0px',
        font:theme.typography.fontFamily,
        fontWeight:500,
    },
    delete_button:{
        boxShadow:'0px 0px 2.86957px rgba(0, 0, 0, 0.25)',
        color:theme.palette.primary.main,
    },
    
}))




import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"


const useUsersView = ({history, language}) => {


    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [users, setUsers] = useState([])
    const [selected, setSelected] = useState(null)

    const [modals, setModals] = useState({
        add_user:false,
        delete_user:false
    })

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_user'})
    const [search, setSearch] = useState('')


    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])


    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                let filter = {filter:
                    {"OR":[
                        {"field":"username","operator":"LIKE","value":`%${search}%`},
                        {"field":"first_name","operator":"LIKE","value":`%${search}%`},
                        {"field":"last_name","operator":"LIKE","value":`%${search}%`}
                    ]}           
                }
                //console.log(filter)
                const req = await private_server.get(`user/all`, {params:{...params, ...filter}})
                //console.log(req)
                const _users = req.data.data
                setUsers(_users.users)
                setTotal(_users.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onSelectedItem: (item, action) =>{
            const {id_user} = item
            setSelected(id_user)
            if(action === 'edit') history.push(`/users/${id_user}`)
            if(action === 'delete') actions.onChangeModalStatus('delete_user',true) 
        },
        onResetTableFiler: () => setTableFilter({limit:10, offset:0, order:1, order_by:'id_user'}),
        onChangeSeach: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => { 
            //let newTableFilter = {...tableFilter}
            //newTableFilter.offset = 0
            setTableFilter(newTableFilter)
        },
        onClearError:()=>{
            setError(null)
        }
    }

    const system = {loading, sending, error}
    const view_data = {users, search, tableFilter, total, selected}

    return {system, modals, view_data, actions}
}

export default useUsersView
import { useCallback } from "react"
import { private_server } from "../../config"
import { updateAuthorizationHeader } from "../../shared/utility"

const useProduct = () => {
    
    const onSearch = useCallback(async(params) => {
        //console.log('Parametros de busqueda', params)
        updateAuthorizationHeader(private_server)

        let result = {
            data:[], 
            count:0
        }
        let url = `/product/search`
        const request = await private_server.get(url, {params:params})
        result.data = request.data.data.products
        result.count = request.data.data.count
        return result
    }, [])

    return {
        onSearch
    }

}

export default useProduct